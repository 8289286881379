import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, Button, Input, Modal, Form, Select, Checkbox } from "antd";
import searchIcon from "../../../assets/magnify.svg";
import "./cm.css";
import styled from "styled-components";
import editIcon from "../assets/editIcon.svg";
import deleteIcon from "../../../assets/delete_icon.svg";
import activeIcon from "../assets/active.svg";
import inActiveIcon from "../assets/inactive.svg";
import { addUser, getUser } from "../../../services/settingServices";

const { Option } = Select;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: ${(props) => (props.checked ? "#0036AF" : "#003AF")};
    border-color: #0036af;
  }
`;

const dummyData = [];

const UserManagement = () => {
  const [data, setData] = useState(dummyData);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("create");
  const [userData, setUserData] = useState(null);
  const [form] = Form.useForm();
  const [dataSetChanged, setDataSetChanged] = useState(false);

  // const fetchAllUser = async () => {
  //   try {
  //     const allUserData = await getUser();

  //   } catch (error) {
  //     console.log("Error fetching all user data in user Management: ",error);
  //   }
  // }
  useEffect(() => {
    setLoading(true);
    getUser()
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching all user data:", error);
        setLoading(false);
      });
  }, [dataSetChanged]);

  // Pagination handlers
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Pagination display information
  const paginationInfo = `Page ${currentPage} out of ${totalPages}`;

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((item) =>
          item.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        )
      );
    }
  }, [searchTerm, data]);
  const currentPageData = filteredData.slice(startIndex, endIndex);

  const showModal = (mode, userData = null) => {
    setMode(mode);
    setUserData(userData);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setMode("create");
    setUserData(null);
    form.resetFields();
  };
  const handleEdit = (record) => {
    const data = {
      first_name: record.first_name,
      last_name: record.last_name,
      companyName: record.company,
      email: record.email,
      role: record.role,
      isActive: record.active,
    };
    form.setFieldsValue(data);
    showModal("edit", data);
  };
  const handleDelete = async (values) => {
    const userEmail = values.email;
    if (values.role === "Super Admin") {
      console.log("Can't delete the Super Admin");
      return;
    }

    const index = data.findIndex((user) => user.email === userEmail);

    if (index !== -1) {
      // Create a new data array without the deleted user
      try {
        const response = await addUser({
          first_name: values.first_name,
          last_name: values.last_name,
          password: values.password,
          delete: true,
          email: values.email,
          role: values.role,
          active: values.isActive,
        });

        setDataSetChanged((prev) => !prev);
      } catch (error) {
        console.error("Error adding user:", error);
      }
    }
  };
  const onFinish = async (values) => {
    // console.log("Data Values:", data);
    // Handle form submission logic here
    if (mode === "create") {
      // Create a new user

      // const index = data.findIndex((user) => user.email === values.email);
      // if (index === -1) {
      //   console.log("Already Exist wit this email");
      //   return;
      // }
      // setData([
      //   ...data,
      //   {
      //     first_name: values.first_name,
      //     last_name: values.last_name,
      //     password: values.password,
      //     delete: false,
      //     email: values.email,
      //     role: values.role,
      //     active: values.isActive,
      //   },
      // ]);
      try {
        const response = await addUser({
          first_name: values.first_name,
          last_name: values.last_name,
          password: values.password,
          delete: false,
          email: values.email,
          role: values.role,
          active: values.isActive,
        });

        setDataSetChanged((prev) => !prev);
      } catch (error) {
        console.error("Error adding user:", error);
      }
    } else {
      // Edit an existing user

      // Find the index of the user in the data array
      const index = data.findIndex((user) => userData.email === user.email); // Assuming each user has an 'id' property
      if (index !== -1) {
        // Update the user data with the new values
        const updatedUser = {
          ...data[index],
          first_name: values.first_name,
          last_name: values.last_name,
          password: values.password,
          email: values.email,
          role: values.role,
          active: values.isActive,
        };
        // Create a new data array with the updated user
        const newData = [...data];
        newData[index] = updatedUser;
        setData(newData);
      }
    }
    onModalClose();
  };

  const columns = [
    {
      title: (
        <div className="column-title" style={{ marginLeft: "90px" }}>
          Name
        </div>
      ),
      dataIndex: "",
      key: "name",
      render: (_, record) => (
        <span className="text-row-key">{`${record.first_name} ${record.last_name}`}</span>
      ),
    },
    {
      title: (
        <div className="column-title" style={{ marginLeft: "110px" }}>
          Email address
        </div>
      ),
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="text-row">{text}</span>,
    },
    {
      title: (
        <div className="column-title" style={{ marginLeft: "80px" }}>
          Role
        </div>
      ),
      dataIndex: "role",
      key: "role",
      render: (text) => <span className="text-row">{text}</span>,
    },
    {
      title: (
        <div className="column-title" style={{ marginLeft: "120px" }}>
          Joined Date
        </div>
      ),
      dataIndex: "",
      key: "joinedDate",
      render: (text) => (
        <div className="text-row">
          <p>sample</p>
          {/* <p>{moment.unix(text).format("DD-MM-YYYY")}</p> */}
        </div>
      ),
    },
    {
      title: (
        <div className="column-title" style={{ marginLeft: "70px" }}>
          Status
        </div>
      ),
      dataIndex: "active",
      key: "active",
      render: (record) => (
        <img
          src={record === true ? activeIcon : inActiveIcon}
          alt="active"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div className="column-title" style={{ marginLeft: "70px" }}>
          Action
        </div>
      ),
      dataIndex: "",
      key: "action",
      render: (record) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {/* <img
        src={editIcon}
        alt="edit"
        onClick={() => handleEdit(record)}
        style={{ width: '40px', height: '40px', cursor: 'pointer' }}
      /> */}

          <img
            src={deleteIcon}
            alt="Delete"
            onClick={() => handleDelete(record)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="userManagement">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          paddingBottom: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            position: "relative",
            left: "-30px",
          }}
        >
          <Input.Search
            placeholder="Search"
            style={{ paddingLeft: "40px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
            // prefix={
            //   <img src={searchIcon} alt="search" className="search-icon" />
            // }
          />
          <Button>Filter</Button>
        </div>
        <Button
          type="primary"
          onClick={() => showModal("create")}
          style={{
            backgroundColor: "#0036AF",
            borderColor: "#0036AF",
            color: "#fff",
          }}
        >
          + ADD USER
        </Button>
      </div>
      <div>
        <div className="table-cont">
          <Table
            dataSource={currentPageData}
            columns={columns}
            pagination={false}
            className="table"
            loading={loading}
          />
        </div>
        {totalItems !== 0 && totalItems > 8 && (
          <div className="pagination-cont" style={{ paddingTop: "auto" }}>
            <div className="pagination-info">
              <span className="page-info">{paginationInfo}</span>
              <div className="prev-next-button">
                <button
                  className="button-style"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  Previous
                </button>
                <button
                  className="button-style"
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        title={mode === "create" ? "Create a new User" : "Edit User"}
        open={isModalOpen}
        onCancel={onModalClose}
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ padding: "2rem 1rem 1rem 1rem" }}
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              { required: true, message: "Please enter your first name" },
            ]}
          >
            <Input placeholder="Enter your first name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: "Please enter your last name" }]}
          >
            <Input placeholder="Enter your last name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            {mode === "create" ? (
              <Input placeholder="Enter your email" />
            ) : (
              <Input placeholder="Enter your email" disabled />
            )}
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Please enter a password" },
              {
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                message:
                  "Password must contain at least 1 number, 1 uppercase letter, 1 lowercase letter, and be at least 8 characters long",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please select a role" }]}
          >
            <Select placeholder="Select a role">
              <Option value="Super Admin">Super Admin</Option>
              <Option value="Admin">Admin</Option>
              <Option value="User">User</Option>
            </Select>
          </Form.Item>
          <Form.Item name="isActive" valuePropName="checked">
            <StyledCheckbox>Active</StyledCheckbox>
          </Form.Item>
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            style={{
              width: "100%",
              backgroundColor: "#0036AF",
              borderColor: "#0036AF",
              color: "#fff",
            }}
          >
            {mode === "create" ? "ADD USER" : "SAVE CHANGES"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManagement;
