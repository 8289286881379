import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from "react";
import { CircularProgress } from "@mui/material";
import { Button, Input } from "antd";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import paperClipIcon from "../../../assets/paperclip.svg";
import "./filePreviewModal.css";
import ScrollContainer from "../../../components/ScrollContainer";
import { addAssetFile } from "../../../services/assetServices";
import { generateRandomFileName } from "../../../utils/file_name_generator";
import { createFormData } from "../../../utils/file_form_data";

const { TextArea } = Input;

const contentStyles = {
  fontFamily: "Roboto, sans-serif",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxSizing: "border-box",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "90vh",
  width: "90vw",
};

const headerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: ".2rem",
  borderBottom: "1px solid #F5F5F5",
  height: "66px",
  fontWeight: "600",
  padding: "0rem 0.5rem",
};

const PreviewModalHeader = ({ handleModalClose }) => {
  return (
    <div style={headerStyles}>
      <div style={{ marginLeft: "10px", padding: "9px" }}>Preview</div>

      <div onClick={handleModalClose}>
        <svg
          style={{ marginRight: "5px", cursor: "pointer" }}
          width="42"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="36" height="36" rx="6" fill="#F5F5F5" />
          <path
            d="M24.4166 12.876L23.1241 11.5835L17.9999 16.7077L12.8758 11.5835L11.5833 12.876L16.7074 18.0002L11.5833 23.1243L12.8758 24.4168L17.9999 19.2927L23.1241 24.4168L24.4166 23.1243L19.2924 18.0002L24.4166 12.876Z"
            fill="#BDBDBD"
          />
        </svg>
      </div>
    </div>
  );
};

const PreviewDisplay = ({ currentFile }) => {
  return (
    <div className="preview-display-main">
      {currentFile && currentFile.type.startsWith("image/") ? (
        <img
          src={URL.createObjectURL(currentFile)}
          alt="current-preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          // onClick={() => updateContextFiles(currentFile)}
        />
      ) : currentFile && currentFile.type === "application/pdf" ? (
        <iframe
          src={URL.createObjectURL(currentFile)}
          title="PDF Preview"
          style={{ width: "100%", height: "500px" }}
        />
      ) : (
        <div>No file selected</div>
      )}
    </div>
  );
};

const PreviewThumbnail = ({
  currentFile,
  selectedFiles,
  handleThumbnailClick,
  handleFileChange,
}) => {
  const fileInputRef = useRef(null);

  return (
    <div className="preview-display-thumbnail">
      <ScrollContainer
        isVertical={false}
        width={"auto"}
        className={"content-nowrap"}
      >
        {selectedFiles.map((file, index) => (
          <div
            key={index}
            className={`preview-thumbnail-card ${
              currentFile && currentFile.name === file.name
                ? "thumbnail-card-active"
                : ""
            }`}
            onClick={() => handleThumbnailClick(file)}
          >
            {file.type.startsWith("image/") ? (
              <img
                src={URL.createObjectURL(file)}
                alt={`preview-${index}`}
                style={{
                  width: "150px",
                  height: "100px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <span>{file.name}</span>
            )}
          </div>
        ))}
      </ScrollContainer>
      <div
        className="preview-add-files"
        onClick={() => fileInputRef.current.click()}
      >
        <img src={paperClipIcon} alt="add_files" />
        <span>Add Files</span>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".pdf, image/*"
          multiple
        />
      </div>
    </div>
  );
};

const PreviewContextCard = ({ fileData, count, updateFileContext }) => {
  return (
    <div className="preview-context-card">
      <div className="context-card-content">
        <div>{count}</div>
        <div>
          <img
            src={URL.createObjectURL(fileData.file)}
            alt="current-preview"
            style={{
              width: "100%",
              height: "100px",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      <div>
        <TextArea
          placeholder="Context..."
          value={fileData.context}
          style={{ width: "100%" }}
          onChange={(e) => updateFileContext(count - 1, e.target.value)}
          autoSize={{
            minRows: 1,
            maxRows: 3,
          }}
        />
      </div>
    </div>
  );
};

const PreviewContext = forwardRef(({ onContextSubmit }, ref) => {
  const initializeContextDetail = {
    files: [],
    info: "",
  };

  const [contextDetail, setContextDetail] = useState(initializeContextDetail);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);

  const handleSubmit = async () => {
    // console.log(contextDetail);
    if (!contextDetail.info.length) {
      toast.error("Please write a message");
      setErrorStatus(true);
      return;
    } else {
      setErrorStatus(false);
    }
    if (!contextDetail.files.length) {
      toast.error("Please add parts");
      return;
    }
    const updatedFiles = [];
    let count = 0;
    setLoading(true);
    for (const fileData of contextDetail.files) {
      // "img_",
      // fileData.file.name.split(".").pop()
      // const fileName = generateRandomFileName();
      const response = await addAssetFile(
        createFormData(fileData.file, generateRandomFileName())
      );

      if (response && response?.id) {
        updatedFiles.push({
          file_id: response.id ? response.id.toString() : "",
          context: fileData.context,
        });
        count++;
      } else {
        count++;
        console.error("File upload failed for:", fileData.file.name);
      }

      if (count === contextDetail.files.length) {
        // console.log(count);
        setLoading(false);
        onContextSubmit({ files: updatedFiles, info: contextDetail.info });
      }
    }

    // console.log("Updated contextDetail:", updatedFiles);
  };

  const updateFileContext = (index, newContext) => {
    setContextDetail((prev) => {
      const updatedFiles = [...prev.files];
      updatedFiles[index].context = newContext;
      return {
        ...prev,
        files: updatedFiles,
      };
    });
  };

  useImperativeHandle(ref, () => ({
    updateContextFiles: (file) => {
      setContextDetail((previous) => {
        const fileExists = previous.files.some(
          (existingFile) => existingFile.file.name === file.name
        );

        if (!fileExists) {
          return {
            ...previous,
            files: [
              ...previous.files,
              {
                file: file,
                context: "",
              },
            ],
          };
        }

        return previous;
      });
    },
    setInitialState: () => {
      setLoading(false);
      setContextDetail(initializeContextDetail);
    },
  }));

  return (
    <div className="preview-context">
      <div className="preview-context-label">Selected Parts</div>
      {/* <div className="preview-context-main"> */}
      {contextDetail.files && contextDetail.files.length ? (
        <ScrollContainer className={"preview-context-main"} height={"600px"}>
          <>
            {contextDetail.files.map((file, index) => (
              <PreviewContextCard
                key={index}
                fileData={file}
                count={index + 1}
                updateFileContext={updateFileContext}
              />
            ))}
          </>
        </ScrollContainer>
      ) : (
        <div className="preview-context-main"></div>
      )}
      {/* </div> */}
      <div className="preview-context-footer">
        <div className="preview-context-action">
          <TextArea
            placeholder="Write Message..."
            value={contextDetail.info}
            style={{ width: "100%" }}
            className={errorStatus && "input-required"}
            onChange={(e) =>
              setContextDetail((previous) => ({
                ...previous,
                info: e.target.value,
              }))
            }
            autoSize={{
              minRows: 1,
              maxRows: 4,
            }}
            // allowClear
          />
        </div>
        <div className="preview-context-action">
          <Button
            type="primary"
            className="action-btn"
            block
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress
                size={18}
                thickness={4}
                style={{ color: "white" }}
              />
            ) : (
              "Ok"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
});

const FilesPreviewModal = forwardRef(({ onContextSubmit }, ref) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);

  const previewContextRef = useRef(null);

  const handleModalClose = () => {
    previewContextRef.current.setInitialState();
    setSelectedFiles([]);
    setCurrentFile(null);
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    toggleModal: (option) => {
      if (option) setShowModal(option);
      else handleModalClose();
    },
  }));

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(
      (file) =>
        file.type === "application/pdf" || file.type.startsWith("image/")
    );

    if (validFiles.length > 0) {
      setSelectedFiles([...selectedFiles, ...validFiles]);
      // setCurrentFile(validFiles[0]);
      // console.log(validFiles);
    } else {
      alert("Please select only PDF or image files.");
    }
  };

  const handleThumbnailClick = (file) => {
    setCurrentFile(file);
    previewContextRef.current.updateContextFiles(file);
  };

  // const updateContextFiles = (file) => {
  //   if (previewContextRef && previewContextRef.current) {
  //     previewContextRef.current.updateContextFiles(file);
  //   }
  // };

  return (
    <Modal
      open={showModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={contentStyles}>
        <PreviewModalHeader handleModalClose={handleModalClose} />

        <div className="file-preview-container">
          <div className="preview-display">
            <div className="preview-display-label">
              {currentFile ? currentFile.name : ""}
            </div>

            <PreviewDisplay
              currentFile={currentFile}
              // updateContextFiles={updateContextFiles}
            />

            <PreviewThumbnail
              currentFile={currentFile}
              selectedFiles={selectedFiles}
              handleThumbnailClick={handleThumbnailClick}
              handleFileChange={handleFileChange}
            />
          </div>

          <PreviewContext
            ref={previewContextRef}
            onContextSubmit={onContextSubmit}
          />
        </div>
      </Box>
    </Modal>
  );
});

FilesPreviewModal.displayName = "FilesPreviewModal";

export default FilesPreviewModal;
