import {
    SET_TOTAL_THREADS,
    SET_ALLOCATED_COMPILATION_THREADS,
    SET_ALLOCATED_EXECUTION_THREADS,
    SET_USED_COMPILATION_THREADS,
    SET_USED_EXECUTION_THREADS,
    SET_DYNAMIC_THREADS,
  } from '../constants/thread-types';


  // Action creator for setting total threads
export const setTotalThreads = (totalThreads) => ({
    type: SET_TOTAL_THREADS,
    payload: totalThreads,
  });
  
  // Action creator for setting allocated compilation threads
  export const setAllocatedCompilationThreads = (allocatedCompilationThreads) => ({
    type: SET_ALLOCATED_COMPILATION_THREADS,
    payload: allocatedCompilationThreads,
  });
  
  // Action creator for setting allocated execution threads
  export const setAllocatedExecutionThreads = (allocatedExecutionThreads) => ({
    type: SET_ALLOCATED_EXECUTION_THREADS,
    payload: allocatedExecutionThreads,
  });
  
  // Action creator for setting used compilation threads
  export const setUsedCompilationThreads = (usedCompilationThreads) => ({
    type: SET_USED_COMPILATION_THREADS,
    payload: usedCompilationThreads,
  });
  
  // Action creator for setting used execution threads
  export const setUsedExecutionThreads = (usedExecutionThreads) => ({
    type: SET_USED_EXECUTION_THREADS,
    payload: usedExecutionThreads,
  });
  
  // Action creator for setting dynamic threads
  export const setDynamicThreads = (dynamicThreads) => ({
    type: SET_DYNAMIC_THREADS,
    payload: dynamicThreads,
  });