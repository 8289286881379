import { SET_TIMEZONE } from "../constants/timezone-types";

const initialState = {
  selectedTimezone: null,
};

const timezoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMEZONE:
      return {
        ...state,
        selectedTimezone: action.payload,
      };
    default:
      return state;
  }
};

export default timezoneReducer;