import { CaretDownOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetch_global, fetch_localData_Col } from '../../../../../services/dataService';
import { toast } from 'react-toastify';
import GlobalLocalDropdown from '../../../../../components/GlobalLocalDropdown';
import gbutton from '../../../../../assets/G-button.svg';
import lbutton from '../../../../../assets/lbutton.svg';

const EditableDiv = ({ value, onChange, style, isKey = false }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.textContent = value;
    }
  }, [value]);

  const handleChange = () => {
    onChange(divRef.current.textContent);
  };

  return (
    <div
      ref={divRef}
      contentEditable
      // onInput={handleInput}
      onBlur={handleChange}
      onInput={isKey ? undefined : handleChange}
      style={{
        ...style,
        minWidth: '20px',
        display: 'inline-flex',
        alignItems: 'center',
        color: isKey ? '#2654BB' : '#E29500',
        marginLeft: isKey ? '0' : '3px',
        outline: 'none',
        border: 'none',
        padding: "0",

      }}
    />
  );
};

const SpecialValue = ({ value, dataType, onChange }) => {
  const color = dataType === 1 ? '#991AE7' : dataType === 2 ? '#96A7E6' : 'black';

  const handleDoubleClick = () => {
    onChange("");
  };

  return (
    <span
      style={{
        color,
        marginLeft: "3px",
        display: 'inline-flex',
        alignItems: 'center',
        gap: "3px",
        cursor: 'pointer'
      }}
      onDoubleClick={handleDoubleClick}
    >
      {dataType === 1 && (<span className="G-icon">G</span>)}
      {dataType === 2 && (<span className="L-icon">L</span>)}
      {value}
    </span>
  );
};

const ValueWithDropdown = ({ value, onChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDoubleClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSelect = (newValue) => {
    onChange(newValue);
    setShowDropdown(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <EditableDiv
        value={typeof value === 'object' ? JSON.stringify(value) : String(value)}
        onChange={onChange}
        style={{ minWidth: '50px', border: '1px solid #ccc', padding: '2px' }}
      />
      <span onClick={handleDoubleClick} style={{ marginLeft: '5px', cursor: 'pointer', color: "#888" }}><CaretDownOutlined /></span>
      {showDropdown && (
        <div
          className=''
          // onChange={(e) => handleSelect(JSON.parse(e.target.value))}
          style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1000 }}
        >
          <GlobalLocalDropdown setGlobalLocalValue={handleSelect} />
        </div>
      )}
    </div>
  );
};

const JsonEditor = ({ data, setData, linkedLocalDataId }) => {

  const [varMode, setVarMode] = useState(false);


  const [isJsonValid, setIsJsonValid] = useState(true);
  const [editableJson, setEditableJson] = useState(JSON.stringify(data.body, null, 2));

  useEffect(() => {
    // Update editableJson when data.body changes
    setEditableJson(JSON.stringify(data.body, null, 2));
  }, [data.body]);

  const validateJson = (jsonString) => {
    try {
      JSON.parse(jsonString);
      setIsJsonValid(true);
    } catch {
      setIsJsonValid(false);
    }
  };
  const handleChange = (e) => {
    const newValue = e.target.value;
    setEditableJson(newValue);
    validateJson(newValue);

    try {
      const newJson = JSON.parse(newValue);
      setData(prevData => ({
        ...prevData,
        body: newJson
      }));
      setIsJsonValid(true);
    } catch {
      setIsJsonValid(false);
    }
  };

 

  const updateJson = (path, value, isKeyChange = false) => {
    setData(prevData => {
      const newBody = JSON.parse(JSON.stringify(prevData.body));
      let current = newBody;
      for (let i = 0; i < path.length - 1; i++) {
        if (!current[path[i]]) current[path[i]] = {};
        current = current[path[i]];
      }
      if (isKeyChange) {
        const oldKey = path[path.length - 1];
        const newKey = value;
        if (oldKey !== newKey) {
          current[newKey] = current[oldKey];
          delete current[oldKey];
        }
      } else {
        current[path[path.length - 1]] = value;
      }
      return { ...prevData, body: newBody };
    });
  };

  const renderJsonObject = (obj, path = []) => {
    const entries = Object.entries(obj);
    return (
      <div style={{ marginLeft: '10px', boxSizing: "border-box" }}>
        {'{'}
        {entries.length > 0 && <br />}
        {entries.map(([key, value], index) => (
          <div key={key} style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '20px' }}>
            <EditableDiv
              value={key}
              onChange={(newKey) => updateJson([...path, key], newKey, true)}
              style={{ marginRight: '5px' }}
              isKey={true}
            />
            :
            {renderValue(value, [...path, key])}
            {index < entries.length - 1 ? ',' : ''}
            <br />
          </div>
        ))}
        {'}'}
      </div>
    );
  };

  const renderValue = (value, path) => {
    if (value && typeof value === 'object') {
      if ('data_type' in value && 'value' in value) {
        return (
          <SpecialValue
            value={value.value}
            dataType={value.data_type}
            onChange={(newValue) => updateJson(path, newValue)}
          />
        );
      } else {
        return renderJsonObject(value, path);
      }
    }
    return (
      <ValueWithDropdown
        value={value}
        onChange={(newValue) => updateJson(path, newValue)}
        linkedLocalDataId={linkedLocalDataId}
      />
    );
  };

  const handleVarClick = () => {
    setVarMode(!varMode);
  };


  return (
    <div className='formatted-json-input' style={{ fontFamily: 'monospace', position: 'relative', padding: '10px' }}>

      <div style={{ color: "#888", fontSize: "14px", paddingBottom: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
        <InfoCircleOutlined />
        {
          varMode ? (
            "Click on edit to modify the body"
          ) : (
            "Click on JSON to Variablize values"
          )
        }
      </div>

      <button
        disabled={!isJsonValid}
        onClick={handleVarClick}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '5px 10px',
          backgroundColor: isJsonValid ? '#0036AF' : "#888",
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          cursor: isJsonValid ? 'pointer' : "not-allowed",
        }}
      >
        {varMode ? (
          <EditOutlined />
        ) : (
          <Tooltip title={isJsonValid ? "" : "Not a valid JSON"}>
            JSON
          </Tooltip>
        )}
      </button>

      {!varMode ? (
        <>
          <textarea
            value={editableJson}
            onChange={handleChange}
            className='paste-json-input'
            rows={10}
            style={{ width: '100%', fontFamily: 'monospace', padding: '10px', boxSizing: 'border-box' }}
          />
        </>
      ) : (
        renderJsonObject(data.body)
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  linkedLocalDataId: state.cards.linkedLocalDataId,
});

export default connect(mapStateToProps)(JsonEditor);