import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Modal,
  Form,
  Checkbox,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import searchIcon from "../../../assets/magnify.svg";
import "./cm.css";
import styled from "styled-components";
import editIcon from "../assets/editIcon.svg";
import deleteIcon from "../../../assets/delete_icon.svg";
import activeIcon from "../assets/active.svg";
import inActiveIcon from "../assets/inactive.svg";
import {
  getAllCompanies,
  createCompany,
  updateCompanyDetails,
  updateCompanyStatus,
} from "../../../services/settingServices";

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: ${(props) => (props.checked ? "#0036AF" : "#0036AF")};
    border-color: #0036af;
  }
`;

const CompanyManagement = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("create");
  const [companyData, setCompanyData] = useState(null);
  const [form] = Form.useForm();
  const [dataSetChanged, setDataSetChanged] = useState(false);


  const handleStatusChange = async (companyId) => {
    try {
      const company = data.find((item) => item.id === companyId);
      const newStatus = company.status === "active" ? "inactive" : "active";
      const payload = {
        contract_start_date: company.contract_start_date,
        contract_end_date: company.contract_end_date,
        no_of_licenses: company.no_of_licenses,
        active: newStatus,
      };
      
      setData(
        data.map((item) =>
          item.id === companyId ? { ...item, status: newStatus } : item
        )
      );
      message.success("Status updated successfully");
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error response:", error.response.data);
        if (error.response.status === 422) {
          message.error("Validation failed. Please check the input data.");
        } else {
          message.error(`Failed to update status: ${error.response.statusText}`);
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error request:", error.request);
        message.error("Failed to update status: No response from server.");
      } else {
        // Other errors
        console.error("Error message:", error.message);
        message.error(`Failed to update status: ${error.message}`);
      }
    }
  };
  

  useEffect(() => {
    setLoading(true);
    getAllCompanies()
      .then((data) => {
        setLoading(false);
        setData(data);
        setFilteredData(data);
 
      })
      .catch((error) => {
        console.error("Error fetching all company data:", error);
        setLoading(false);
      });
  }, [dataSetChanged]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const paginationInfo = `Page ${currentPage} out of ${totalPages}`;
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((item) =>
          item.company_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, data]);
  
  
  // Log the filteredData state
 
  

  const currentPageData = filteredData.slice(startIndex, endIndex);

  const showModal = (mode, companyData = null) => {
    setMode(mode);
    setCompanyData(companyData);
    if (companyData) {
      form.setFieldsValue({
        name: companyData.company_name,
        email: companyData.email,
        contractStartDate: moment(companyData.license_start_date),
        contractEndDate: moment(companyData.license_end_date),
        noOfLicenses: companyData.total_no_of_licenses,
        isActive: companyData.status,
      });
    }
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setMode("create");
    setCompanyData(null);
    form.resetFields();
  };

  const handleEdit = (record) => {
    showModal("edit", record);
  };

  const handleDelete = async (record) => {
    try {
      await updateCompanyStatus(record.id, {
        contract_start_date: "", // Provide default value or remove if not needed
        contract_end_date: "", // Provide default value or remove if not needed
        no_of_licenses: 0, // Provide default value or remove if not needed
        active: false, // Provide default value or remove if not needed
        status: "deleted" // Set the status to "deleted"
      });
      setDataSetChanged((prev) => !prev);
      message.success("Company deleted successfully");
    } catch (error) {
      console.error("Error deleting company:", error);
      message.error("You are not authorized to carry out Delete operation");
    }
  };
  


  const onFinish = async (values) => {
    if (mode === "create") {
      try {
        const response = await createCompany({
          company_name: values.name,
          email: values.email,
          contract_start_date: values.contractStartDate.format("YYYY-MM-DD"),
          contract_end_date: values.contractEndDate.format("YYYY-MM-DD"),
          no_of_licenses: values.noOfLicenses,
          active: values.isActive, 
        });
 
        setDataSetChanged((prev) => !prev);
        message.success("Company created successfully");
      } catch (error) {
        console.error("Error adding company:", error);
        message.error("Failed to create company");
      }
    } else {
      try {
        const response = await updateCompanyDetails(companyData.id, {
          name: values.name,
          email: values.email,
          contract_start_date: values.contractStartDate.format("YYYY-MM-DD"),
          contract_end_date: values.contractEndDate.format("YYYY-MM-DD"),
          no_of_licenses: values.noOfLicenses,
          active: values.isActive, 
        });
 
        setData(
          data.map((item) =>
            item.id === companyData.id
              ? {
                  ...item,
                  company_name: values.name,
                  email: values.email,
                  contract_start_date: values.contractStartDate.format("YYYY-MM-DD"),
                  contract_end_date: values.contractEndDate.format("YYYY-MM-DD"),
                  no_of_licenses: values.noOfLicenses,
                  active: values.isActive
                }
              : item
          )
        );
        message.success("Company updated successfully");
      } catch (error) {
        console.error("Error updating company:", error);
        message.error("Failed to update company");
      }
    }
    onModalClose();
  };
  
  const columns = [
    {
      title: <div className="column-title">Company Name</div>,
      dataIndex: "company_name",
      key: "company_name",
      render: (text) => <span className="text-row-key">{text}</span>,
    },
    {
      title: <div className="column-title">Email address</div>,
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="text-row">{text}</span>,
    },
    {
      title: <div className="column-title">No. of Users</div>,
      dataIndex: "no_of_users",
      key: "no_of_users",
      render: (text) => <span className="text-row">{text}</span>,
    },
    {
      title: <div className="column-title">License</div>,
      dataIndex: "total_no_of_licenses",
      key: "total_no_of_licenses",
      render: (text) => <span className="text-row">{text}</span>,
    },
    {
      title: <div className="column-title">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <img
          src={status === "Active" ? activeIcon : inActiveIcon}
          alt="status"
          style={{ cursor: "pointer" }}
          className={`status-toggle ${record.id}`}
          onClick={() => handleStatusChange(record.id)}
        />
      ),
    },
    {
      title: <div className="column-title">Actions</div>,
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <img
            src={editIcon}
            alt="edit"
            onClick={() => handleEdit(record)}
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
          />
          <img
            src={deleteIcon}
            alt="delete"
            onClick={() => handleDelete(record)}
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="companyManagement">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "5rem",
          paddingTop: "32px",
          paddingRight: "8px",
          paddingBottom: "2rem",
        }}
      >
        <div className="search-box-container">
          <img src={searchIcon} alt="search" className="search-icon" />
          <Input
            placeholder="Search"
            className="search-box"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div>
          <Button
            type="primary"
            className="add-button"
            onClick={() => showModal("create")}
          >
            + ADD COMPANY 
          </Button>
        </div>
      </div>
      <Table
        dataSource={currentPageData}
        columns={columns}
        pagination={false}
        loading={loading}
        rowKey="id"
      />
      {totalItems > 0 && totalItems > 8 && (
        <div className="pagination-cont" style={{ paddingTop: "auto" }}>
          <div className="pagination-info">
            <span className="page-info">{paginationInfo}</span>
            <div className="prev-next-button">
              <button
                className="button-style"
                disabled={currentPage === 1}
                onClick={handlePrevPage}
              >
                Previous
              </button>
              <button
                className="button-style"
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        title={mode === "create" ? "Create a new Company" : "Edit Company"}
        visible={isModalOpen}
        onCancel={onModalClose}
        footer={[
          <Button key="cancel" onClick={onModalClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="companyForm"
            htmlType="submit"
          >
            {mode === "create" ? "Save" : "Update"}
          </Button>,
        ]}
      >
        <Form
          id="companyForm"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ padding: "2rem 1rem" }}
        >
          <Form.Item
            label="Company Name"
            name="name"
            rules={[{ required: true, message: "Please input company name!" }]}
          >
            <Input disabled={mode !== "create"} />
          </Form.Item>
          <Form.Item
            label="Company Email"
            name="email"
            rules={[{ required: true, message: "Please input company email!" }]}
          >
            <Input disabled={mode !== "create"} />
          </Form.Item>
          <Form.Item
            label="Contract Start Date"
            name="contractStartDate"
            rules={[
              { required: true, message: "Please select contract start date!" },
            ]}
          >
            <DatePicker
              value={form.getFieldValue("contractStartDate")}
              onChange={(date) =>
                form.setFieldsValue({ contractStartDate: date })
              }
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Contract End Date"
            name="contractEndDate"
            rules={[
              { required: true, message: "Please select contract end date!" },
            ]}
          >
            <DatePicker
              value={form.getFieldValue("contractEndDate")}
              onChange={(date) =>
                form.setFieldsValue({ contractEndDate: date })
              }
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="No. of Licenses"
            name="noOfLicenses"
            rules={[
              { required: true, message: "Please input number of licenses!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="isActive" valuePropName="checked">
            <Checkbox>Active</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CompanyManagement;
