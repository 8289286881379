import React, { useEffect, useRef, useState } from "react";
import Clip from "../assets/clip.svg";
import BotLogo from "../assets/bot-icon.svg";
import blueClip from "../assets/blue-clip.svg";
import { CircularProgress, Tooltip } from "@mui/material";
import FilesPreviewModal from "./FilesPreviewModal";
import { IoMdSend } from "react-icons/io";
import { ReactComponent as DatabaseIcon } from "../../../assets/database.svg";

const formatTextWithLineBreaks = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const Message = ({ message, initialMessage = false, handleModalOpen, refProp }) => {
  return initialMessage ? (
    <div className="presenceai-message">
      <img style={{ marginTop: "5px" }} src={BotLogo} alt="user" />
      <div className="initial-message">
        <p>Hello! I'm here to help you generate test cases automatically.</p>
        <div style={{ marginTop: "20px" }}>
          <p>You can get started by:</p>
          <p>1. Writing your workflow or requirements.</p>
          <p>2. Uploading a Business Requirement Document (BRD).</p>
          <p>3. Uploading a picture or document.</p>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: "10px",
          }}
          onClick={handleModalOpen}
        >
          <img src={blueClip} alt="" />
          <p style={{ color: "#1D86FF" }}>Upload File</p>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`presenceai-message ${message.data_type === 24 ? "left" : (message.data_type === 23 || message.data_type ===  10) ? "right" : ""
        }`}
      ref={refProp}
    >
      {message.data_type === 24 && (
        <>
          <img style={{ marginTop: "5px" }} src={BotLogo} alt="user" />
          <p className="ai-message-text">
          {formatTextWithLineBreaks(message.ans.answer)}

            {message.ans.steps && message.ans.steps.length > 0 && (
              <div className="p-ai-message-steps-container">
                {message.ans.steps.map((step, index) => (
                  <div key={index} className="p-ai-message-step">
                    <p className="">{index + 1}</p>
                    <div className="p-ai-message">
                      <p className="">{step.instr}</p>
                      {step.data && (
                        <p className="">
                          <Tooltip title={step.data} >
                            <DatabaseIcon style={{ width: "15px" }} />
                          </Tooltip>
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </p>
        </>
      )}

      {message.data_type === 23 && (
        <p className="ai-message-text">{message.query}</p>
      )}

      {message.data_type === 10 && (
        <p style={{color:"#1D86FF", display:"flex", alignItems:"center", gap:"3px"}} className="ai-message-text"><img style={{userSelect:"none"}} src={blueClip} alt="" /> Uploaded File/s</p>
      )}
    </div>
  );
};

const Left = ({ messages, botLoading, onContextSubmit, handleMessageSubmit }) => {
  const filesModalref = useRef(null);
  const inputRef = useRef(null);
  const [message, setMessage] = useState("");
  const messagesContainerRef = useRef(null);
  const lastMessageRef = useRef(null);

  const onMessageSubmit = () => {
    if (message.trim() === "") return;
    handleMessageSubmit(message);
    setMessage("");
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onMessageSubmit();
    }
  };

  const handleModalOpen = () => {
    filesModalref.current.toggleModal(true);
  };
  const handleModalSubmit = (data) => {
    filesModalref.current.toggleModal(false);
    onContextSubmit(data);
  };

  useEffect(() => {
    // Scroll to the bottom when messages or botLoading changes
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth"
      });
    }
  }, [messages, botLoading]);

  return (
    <div className="presenceai-left">
      <div className="presenceai-messages" ref={messagesContainerRef}>
        <Message initialMessage={true} handleModalOpen={handleModalOpen} />
        {messages.map((message, index) => (
          <Message
            key={message.id}
            message={message}
            handleModalOpen={handleModalOpen}
            refProp={index === messages.length - 1 ? lastMessageRef : null}
          />
        ))}
        {botLoading && (
          <div className="presenceai-message left">
            <img style={{ marginTop: "5px" }} src={BotLogo} alt="user" />
            <p 
            style={{
                  color: "#1D86FF",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                 className="ai-message-text">
              Generating 
              <CircularProgress
                style={{
                  // color: "#0036af",
                  width: "12px",
                  height: "12px",
                }}
              />
            </p>
          </div>
        )}
      </div>
      <div className="presenceai-input">
        <div className="presenceai-input-container">
          <img src={Clip} alt="add file" onClick={handleModalOpen} />
          <input
            type="text"
            value={message}
            disabled={botLoading}
            onChange={(e) => setMessage(e.target.value)}
            className="presenceai-input-field"
            placeholder={botLoading ? "Processing... Please wait" :"Write Message..."}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
          <div
            onClick={onMessageSubmit}
            className="send-message-btn"
          >
            <IoMdSend style={{ fontSize: '22px', cursor: "pointer", color: "white" }} />
          </div>
        </div>
      </div>
      <FilesPreviewModal
        ref={filesModalref}
        onContextSubmit={handleModalSubmit}
      />
    </div>
  );
};

export default Left;
