import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import closeIcon from '../../../assets/close-icon.svg';
import search from '../../../assets/search-icon.svg';
import { Checkbox, Table } from 'antd';
import './LinkedTestcaseModal.css';
import { toast } from 'react-toastify';
import { fetch_localdata_linked_testcases } from '../../../services/dataService';
import { fetch_Linked_Testcase_to_Data, add_testcase_association, remove_testcase_association } from '../../../services/testCaseServices';

const LinkedTestcaseModal = ({ open, handleClose, name, local_data_id, triggerLocalRefresh }) => {
  const [allTestcasesData, setAllTestcasesData] = useState([]);
  const [selectedTestcases, setSelectedTestcases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [initialCheckedIds, setInitialCheckedIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [testcasesResponse, linkedTestcasesResponse] = await Promise.all([
          fetch_localdata_linked_testcases(),
          fetch_Linked_Testcase_to_Data(local_data_id)
        ]);

        // console.log('testcasesResponse:', testcasesResponse);
        const testcases = (testcasesResponse?.test_cases || []).map(tc => ({
          ...tc,
          checked: (linkedTestcasesResponse || []).some(ltc => ltc.testcase_unique_id === tc.test_case_id)
        }));

        const initialChecked = (linkedTestcasesResponse || []).map(ltc => ltc.testcase_unique_id);

        setAllTestcasesData(testcases);
        setSelectedTestcases(linkedTestcasesResponse || []);
        setCheckedIds(initialChecked);
        setInitialCheckedIds(initialChecked);
      } catch (error) {
        toast.error('Error fetching data');
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [local_data_id]);

  const handleCheckChange = (e, key) => {
    const newCheckedIds = e.target.checked 
      ? [...checkedIds, key]
      : checkedIds.filter(id => id !== key);

    setCheckedIds(newCheckedIds);

    setAllTestcasesData(prev => prev.map(tc => 
      tc.test_case_id === key ? { ...tc, checked: e.target.checked } : tc
    ));
  };

  const handleMasterCheckChange = (e) => {
    const isChecked = e.target.checked;
    setCheckedIds(isChecked ? allTestcasesData.map(tc => tc.test_case_id) : []);
    setAllTestcasesData(prev => prev.map(tc => ({ ...tc, checked: isChecked })));
  };

  const handleSaveChanges = async () => {
    const toAdd = checkedIds.filter(id => !initialCheckedIds.includes(id));
    const toRemove = initialCheckedIds.filter(id => !checkedIds.includes(id));

    if (toAdd.length === 0 && toRemove.length === 0) {
      handleClose();
      return;
    }

    try {
      if (toAdd.length > 0) {
        await add_testcase_association(local_data_id, toAdd);
      }
      if (toRemove.length > 0) {
        await remove_testcase_association(local_data_id, toRemove);
      }
      toast.success('Testcases updated successfully');
      handleClose();
      triggerLocalRefresh();
    } catch (error) {
      toast.error('Error saving changes');
      console.error("Error saving changes:", error);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const columns = [
    {
      title: <Checkbox
        className='local-data-master-checkbox'
        onChange={handleMasterCheckChange}
        checked={checkedIds.length === allTestcasesData.length && allTestcasesData.length > 0}
        indeterminate={checkedIds.length > 0 && checkedIds.length < allTestcasesData.length}
      />,
      dataIndex: 'checked',
      render: (_, record) => (
        <Checkbox
          className='local-data-row-checkbox'
          checked={record.checked}
          onChange={(e) => handleCheckChange(e, record.test_case_id)}
        />
      ),
    },
    {
      title: 'Test Case',
      dataIndex: 'test_case_name',
    },
    {
      title: 'Attached Data',
      dataIndex: 'attached_data',
      render: (data) => data ? data : <span className='local-data-no-data-found'>No data linked</span>
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="linked-testcase-modal-box">
        <div className='linked-tc-modal-box-header'>
          <h4>Linked Testcases</h4>
          <img onClick={handleClose} src={closeIcon} alt='close-modal' />
        </div>

        <div className='linked-tc-modal-box-search'>
          <h4>{name}</h4>
          <div>
            <img src={search} alt="search" />
            <input placeholder='Search' type="text" />
          </div>
        </div>

        <div className='linked-tc-modal-box-main'>
          <Table
            dataSource={allTestcasesData}
            columns={columns}
            pagination={false}
            className='linked-tc-modal-table'
            scroll={{ y: 439 }}
            loading={loading}
            rowKey="test_case_id"
          />
        </div>

        <div className='linked-tc-modal-box-footer'>
          <div className='linked-tc-modal-box-footer-item' onClick={handleCancel}>Cancel</div>
          <div className='linked-tc-modal-box-footer-item' onClick={handleSaveChanges}>Save Changes</div>
        </div>
      </Box>
    </Modal>
  );
};

export default LinkedTestcaseModal;
