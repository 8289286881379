import React, { memo, useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import AddIcon from "../../assets/add.svg";
import RightIcon from "../../assets/right.svg";
import CloseIcon from "../../assets/close.svg";
import ArrowIcon from "../../assets/arrow_down.svg";
import { createFolder } from "../../services/testCaseServices";

const ModuleDropdown = ({
  folderList,
  recordDetails,
  selectedFolder,
  refreshModules,
}) => {
  const initialSelectedFolder = {
    id:
      recordDetails && recordDetails?.folderId ? recordDetails.folderId : null,
    name:
      recordDetails && recordDetails?.folderName
        ? recordDetails?.folderName
        : "",
  };
  const [isOptions, setIsOptions] = useState(false);
  const [isAddModule, setIsAddModule] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialSelectedFolder);
  const [options, setOptions] = useState(folderList || []);
  const [newModule, setNewModule] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelect = (option) => {
    if (loading) return;
    setSelectedOption(option);
    setIsOptions(false);
    selectedFolder(option.id);
  };

  const handleChange = (event) => {
    if (loading) return;
    const { value } = event.target;
    setNewModule(value);
  };

  const handleCancel = () => {
    if (loading) return;
    setIsAddModule(false);
  };

  const handleAddModule = async () => {
    if (loading) return;
    if (!folderList || !newModule.length) return;

    const moduleExists = options.some((option) => option.name === newModule);
    if (moduleExists) {
      toast.error("Module name already exists.");
      return;
    }

    setLoading(true);

    try {
      const response = await createFolder(newModule);
      if (response && response?.folder_id) {
        console.log(response);
        const newModuleData = { id: response?.folder_id, name: newModule };
        setOptions((previous) => [newModuleData, ...previous]);
        toast.success("Module created successfully");
        refreshModules();
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to created module");
    } finally {
      setLoading(false);
      setNewModule("");
    }

    // setIsAddModule(false);
  };

  return (
    <div className="module-select-container">
      <div>
        Module <span style={{ color: "#EF4444" }}>*</span>
      </div>
      <div className="module-select-input">
        <div
          className="module-selected-label"
          onClick={() => setIsOptions(isOptions ? false : true)}
        >
          <label>{selectedOption.name}</label>
          <img className="arrowEC" src={ArrowIcon} alt="dropdown" />
        </div>
        {isOptions && (
          <div className="module-options-container">
            <div>
              <div className="module-add-action">
                <img
                  className="arrowEC"
                  src={AddIcon}
                  alt="add"
                  onClick={() => setIsAddModule(true)}
                />
                <span>New Module</span>
              </div>
              {isAddModule && (
                <div className="module-add-content">
                  <div className="module-add-input module-box">
                    <input
                      name="module"
                      value={newModule}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="module-box" onClick={handleAddModule}>
                    {loading ? (
                      <CircularProgress
                        size={16}
                        thickness={4}
                        style={{ color: "silver" }}
                      />
                    ) : (
                      <img className="arrowEC" src={RightIcon} alt="done" />
                    )}
                  </div>
                  <div className="module-box" onClick={handleCancel}>
                    <img className="arrowEC" src={CloseIcon} alt="cancel" />
                  </div>
                </div>
              )}
            </div>
            <div
              className={`module-option-cards ${
                !isAddModule ? "module-option-size" : ""
              }`}
            >
              {options && options.length ? (
                options.map((option) => (
                  <div key={option.id} onClick={() => handleSelect(option)}>
                    {option.name}
                  </div>
                ))
              ) : (
                <div>No data found!</div>
              )}
            </div>
          </div>
        )}

        {/* {errors.nameError && <div className="error">{errors.nameError}</div>} */}
      </div>
    </div>
  );
};

export default memo(ModuleDropdown);
