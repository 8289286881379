import '../functions.css';

const RandomNumber = () => {
  return (
    <div className='functions-container'>
      <div className='functions-title-container'>
        <h4>Random Number</h4>
        <p>Generates random number</p>
      </div>
      <div className='random-number-main'>
        <p>Number of digit <span style={{color:"#EF4444"}}>*</span></p>
        <input type='number' placeholder='6' min="0" />
      </div>

      <div className='functions-example-container'>
      <span style={{color:"#757575"}}>Example:</span>
      <span style={{color:"#212121", marginLeft:"12px"}}>456875</span>
      </div>
    </div>
  )
}

export default RandomNumber