import React, { useEffect ,useState} from "react";
import "./Modal.css";
import SampleVid from "./SampleVideo.mp4"; 
import { getVideoUrlbyJobId } from "../../services/jobsServices";

const Modal = ({ isOpen, onClose, job_id }) => {
  // const [isPlaying, setIsPlaying] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const url = await getVideoUrlbyJobId(job_id);
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video URL:', error);
      }
    };

    fetchVideoUrl();
  }, [job_id]);
//   const apiWS = window.location.hostname;
// const wsProtocol = window.location.protocol

// const apiUrl = `${wsProtocol}//${apiWS}`;
// const authToken = localStorage.getItem('authToken');
// const src = `${apiUrl}/platform/video/${job_id}?auth_token=${authToken}`;
// console.log("src",src)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  // const togglePlayPause = () => {
  //   const video = document.querySelector("video");
  //   if (video.paused) {
  //     video.play();
  //   } else {
  //     video.pause();
  //   }
  //   setIsPlaying(!isPlaying);
  // };


  if (!isOpen) return null;
  // add controls to video
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <div className="video-container">
          {job_id !== null && (
            <video
              width="750"
              height="500"
              autoPlay
              controls
              loop
              muted
              playsInline
            >
              <source
                src={videoUrl ? videoUrl : SampleVid}
                type="video/mp4"
                alt={SampleVid}
              />
            </video>
          )}
        </div>
        {/* <div className="play-pause">
          <button className="play-pause-button" onClick={togglePlayPause}>
            {isPlaying ? "Pause" : "Play"}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Modal;
