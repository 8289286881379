import React from "react";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import { CircularProgress } from '@mui/material';

const CodeEditor = ({ code, onCodeChange, readOnly = false, loading }) => {
    return (
        <div style={{ position: 'relative' }}>
            <Editor
                value={code}
                onValueChange={!readOnly ? onCodeChange : () => {}}
                highlight={code => Prism.highlight(code, Prism.languages.javascript, 'javascript')}
                padding={10}
                className="code-editor"
                readOnly={readOnly}
            />
            {loading && (
                <div 
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }}
                >
                    <CircularProgress style={{color:"#0036AF"}}  />
                </div>
            )}
        </div>
    );
};

export default CodeEditor;