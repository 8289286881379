import { Box, Modal } from '@mui/material';
import closeIcon from '../../../../assets/close-icon.svg';

import './tsSyntheticData.css';
import { useState } from 'react';
import RandomNumber from './functions/randomNumber/randomNumber';

const FunctionBlock = ({ fn, isLast, onClick }) => {
    const containerClass = isLast
        ? 'synthetic-function-container synthetic-no-border-bottom'
        : 'synthetic-function-container';
    return (
        <div className={containerClass} onClick={onClick}>
            <div className='synthetic-function-name'>{fn.name}</div>
            <div className='synthetic-function-description'>{fn.description}</div>
        </div>
    );
};

const SyntheticDataModal = ({ open, handleClose }) => {
    const [functionActive, setFunctionActive] = useState(null);

    const dummyFunctions = [
        { id: 1, name: 'Current Date', description: 'Get current date' },
        { id: 2, name: 'Current Time', description: 'Get current time' },
        { id: 3, name: 'Random Number', description: 'Generate random number', fn: <RandomNumber /> },
        { id: 4, name: 'Random Text', description: 'Generate random text' },
        { id: 5, name: 'Random Email', description: 'Generate random email' },
        { id: 6, name: 'Random Phone Number', description: 'Generate random phone number' },
    ];

    const handleReset = () => {
        setFunctionActive(null);
    };

    const handleCancel = () => {
        handleClose();
    };

    const handleSave = () => {
        // Save logic
    };

    const handleFunctionClick = (id) => {
        setFunctionActive(id);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box className="synthetic-modal-container">
                <div className='synthetic-modal-topbar'>
                    <h4>Custom Functions</h4>
                    <img onClick={handleClose} src={closeIcon} alt='close-modal' />
                </div>

                {/* main synthetic box */}
                <div className='synthetic-main'>
                    <div className='synthetic-main-left'>
                        <div className='synthetic-search-bar'>
                            <div className='synthetic-search-input'>
                                <input type='text' placeholder='Search' />
                            </div>
                            <div className='synthetic-create-custom'>
                                <button>Create Custom</button>
                            </div>
                        </div>
                        <div className='synthetic-functions scrollar'>
                            {
                                dummyFunctions.map((fn, index) => {
                                    const isLast = index === dummyFunctions.length - 1;
                                    return <FunctionBlock
                                        key={index}
                                        isLast={isLast}
                                        fn={fn}
                                        onClick={() => handleFunctionClick(fn.id)}
                                    />;
                                })
                            }
                        </div>
                    </div>
                    <div className='synthetic-main-right scrollar'>
                        {
                            functionActive && dummyFunctions.find(fn => fn.id === functionActive).fn
                        }
                    </div>
                </div>

                <div className='synthetic-modal-bottom-bar'>
                    <div className='synthetic-btn' onClick={handleReset}>Reset</div>
                    <div className='synthetic-save-btn-container'>
                        <div className='synthetic-btn' onClick={handleCancel}>Cancel</div>
                        <div className='synthetic-btn save-btn' onClick={handleSave}>Save</div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default SyntheticDataModal;
