import React, { createContext, useContext, useState } from "react";

const ResponseContext = createContext();

export const useResponseContext = () => useContext(ResponseContext);

export const ResponseContextProvider = ({ children }) => {
  const [responseData, setResponseData] = useState(null);
  const [activeModalTab, setActiveModalTab] = useState("1");
  const [activeScriptTab, setActiveScriptTab] = useState("1");
  const [responseLoading, setResponseLoading] = useState(false);
  const [scriptResponseLoading, setScriptResponseLoading] = useState(false);
  const [tsVideoUrl, setTsVideoUrl] = useState('');

  const [scriptData, setScriptData] = useState({
    instr_type: 700,
    name: "",
    script: ``,
    args: {},
    save_response: [],
    recent_response: {}
  });
  const [openingRestApi, setOpeningRestApi] = useState(false);

  const [openingScript, setOpeningScript] = useState(false);


  const updateResponseData = (newData) => {
    setResponseData(newData);
  };

  const updateActiveTab = (tabKey) => {
    setActiveModalTab(tabKey);
  };

  const updateActiveScriptTab = (tabKey) => {
    setActiveScriptTab(tabKey);
  };

  const updateScriptData = (newData) => {
    setScriptData(newData);
  };

  const value = {
    scriptData,
    updateScriptData,

    responseData,
    updateResponseData,

    activeModalTab,
    updateActiveTab,

    activeScriptTab,
    updateActiveScriptTab,

    responseLoading,
    setResponseLoading,

    scriptResponseLoading,
    setScriptResponseLoading,

    openingRestApi,
    setOpeningRestApi,

    openingScript,
    setOpeningScript,

    tsVideoUrl,
    setTsVideoUrl
  };

  return (
    <ResponseContext.Provider value={value}>
      {children}
    </ResponseContext.Provider>
  );
};

export default ResponseContextProvider;
