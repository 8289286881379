import React, { useState, useEffect, useRef } from 'react';

import { CircularProgress } from '@mui/material';
import axios from 'axios';
import './tsDropDown.css';
import { fetch_Linked_Testcase_to_Data } from '../../../../../services/testCaseServices';

const TsDropDown = ({ tc_linked, localDataId }) => {
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const [linkedTestData, setLinkedTestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;


    const linkedTestsCount = tc_linked ? tc_linked : 0;


    const handleSpanClick = () => {
        setIsDropDownVisible(!isDropDownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropDownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchLinkedTestData = async () => {
            if (isDropDownVisible && localDataId && linkedTestsCount !== 0) {
                try {
                    setLoading(true);
                    const response = await fetch_Linked_Testcase_to_Data(localDataId);
                    console.log('responseTD:', response);
                    setLinkedTestData(response);
                } catch (error) {
                    console.error('Error fetching linked test data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchLinkedTestData();
    }, [isDropDownVisible, localDataId, linkedTestsCount, apiUrl]);

    return (
        <div className="text-row view-linked-tests-row data" ref={dropdownRef}>
            <span onClick={handleSpanClick}>{linkedTestsCount}</span>
            {isDropDownVisible &&
                <div className='linked-data-replace-dropdown'>

                    {loading ? (
                        <div className='ts-dropdown-loader-container'>
                        <CircularProgress style={{ color: "rgba(1,1,1,1)", border: "none", width: "14px" }} />
                    </div>
                    ) : linkedTestsCount === 0 ? (
                        <p>No test cases linked</p>
                    ) : (
                        linkedTestData.map((linkedTest, index) => (
                            <p style={{textAlign:"center"}} key={index}>{linkedTest.name}</p>
                        ))
                    )}
                </div>
            }
        </div>
    );
};

export default TsDropDown;