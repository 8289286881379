import React, { useEffect, useState, useRef } from 'react';
import gbutton from '../assets/G-button.svg';
import lbutton from '../assets/lbutton.svg';
import refreshIcon from '../assets/refresh.svg';
import { connect } from 'react-redux';
import { fetch_global, fetch_localData_Col } from '../services/dataService';

const GlobalLocalDropdown = ({
    linkedLocalDataId,
    setGlobalLocalValue,
    onClose,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [globalDataKeys, setGlobalDataKeys] = useState([]);
    const [refreshingGlobal, setRefreshingGlobal] = useState(false);
    const [localDataCol, setLocalDataCol] = useState([]);
    const [refreshingLocal, setRefreshingLocal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dataType = useRef(null);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const fetchGlobalData = async () => {
        try {
            setRefreshingGlobal(true);
            const response = await fetch_global();
            if (!response || !response.data) {
                throw new Error("Failed to fetch global data");
            }
            const globalDataKeys = response.data.GlobalDatas.map(item => item.key);
            setGlobalDataKeys(globalDataKeys);
        } catch (error) {
            console.error("Error fetching global data:", error);
        } finally {
            setRefreshingGlobal(false);
        }
    };

    const fetchLocalData = async () => {
        try {
            if (linkedLocalDataId) {
                setRefreshingLocal(true);
                const response = await fetch_localData_Col(linkedLocalDataId);
                const localDataCol = response.columns;
                setLocalDataCol(localDataCol);
            }
        } catch (error) {
            console.error("Error fetching local data:", error);
        } finally {
            setRefreshingLocal(false);
        }
    };

    const triggerGlobalRefresh = () => {
        fetchGlobalData();
    };

    const triggerLocalRefresh = () => {
        fetchLocalData();
    };

    useEffect(() => {
        if (selectedOption === 'global') {
            triggerGlobalRefresh()
        } else if (selectedOption === 'local') {
            triggerLocalRefresh();
        }

    }, [selectedOption])

    const handleGlobalClick = () => {
        setSelectedOption('global');
        dataType.current = 1;
    };

    const handleLocalClick = () => {
        setSelectedOption('local');
        dataType.current = 2;
    };

    const handleClick = (key) => {
        const newData = {
            data_type: dataType.current,
            value: key,
            save_data_name: null
        };

        setGlobalLocalValue(newData);
    };


    return (
        <div className="global-local-dropdown" ref={dropdownRef}>
            {selectedOption === null && ( // Only render when no option is selected
                <div className="values-of-suggestions">
                    <div className="container-of-values additional" style={{ paddingTop: "24px" }}>
                        <div className="additional-row-head">Options</div>
                        <div className="additional-row">
                            <img src={gbutton} alt="Global" />
                            <button onClick={handleGlobalClick}>Global Data</button>
                        </div>
                        {linkedLocalDataId && (
                            <div className="additional-row">
                                <img src={lbutton} alt="Local" />
                                <button onClick={handleLocalClick}>Local Data</button>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {selectedOption === 'global' && (
                <div className="values-of-suggestions">
                    <div className="container-of-values additional" style={{ paddingTop: "24px" }}>
                        <div style={{ position: "relative" }}>
                            <input
                                className="input-field"
                                type="text"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="Search global data"
                                style={{
                                    marginBottom: "5px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    padding: "5px",
                                    borderRadius: "4px",
                                    width: "calc(100% - 33px)",
                                    color: "black",
                                }}
                            />
                            <img
                                width={14}
                                src={refreshIcon}
                                className={`refresh-icon ${refreshingGlobal ? "rotate" : ""}`}
                                alt="refresh"
                                onClick={triggerGlobalRefresh}
                            />
                        </div>
                        {searchValue ? (
                            globalDataKeys
                                .filter(key => key.toLowerCase().includes(searchValue.toLowerCase()))
                                .map(key => (
                                    <div key={key} className="additional-row searched">
                                        <img src={gbutton} alt={key} />
                                        <button onClick={() => handleClick(key)}>
                                            {key}
                                        </button>
                                    </div>
                                ))
                        ) : (
                            globalDataKeys.map(key => (
                                <div key={key} className="additional-row">
                                    <img src={gbutton} alt={key} />
                                    <button onClick={() => handleClick(key)}>
                                        {key}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}

            {selectedOption === 'local' && (
                <div className="values-of-suggestions">
                    <div className="container-of-values additional" style={{ paddingTop: "24px" }}>
                        <div style={{ position: "relative" }}>
                            <input
                                className="input-field"
                                type="text"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="Search local data"
                                style={{
                                    marginBottom: "5px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    padding: "5px",
                                    borderRadius: "4px",
                                    width: "calc(100% - 33px)",
                                    color: "black",
                                }}
                            />
                            <img
                                width={14}
                                src={refreshIcon}
                                className={`refresh-icon ${refreshingLocal ? "rotate" : ""}`}
                                alt="refresh"
                                onClick={triggerLocalRefresh}
                            />
                        </div>
                        {searchValue ? (
                            localDataCol
                                .filter(key => key.toLowerCase().includes(searchValue.toLowerCase()))
                                .map(key => (
                                    <div key={key} className="additional-row searched">
                                        <img src={lbutton} alt={key} />
                                        <button onClick={() => handleClick(key)}>
                                            {key}
                                        </button>
                                    </div>
                                ))
                        ) : (
                            localDataCol.map(key => (
                                <div key={key} className="additional-row">
                                    <img src={lbutton} alt={key} />
                                    <button onClick={() => handleClick(key)}>
                                        {key}
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    linkedLocalDataId: state.cards.linkedLocalDataId,
});

export default connect(mapStateToProps)(GlobalLocalDropdown);