import React, { useState } from "react";
import { Resizable } from "re-resizable";
import StepStatus from "./StepStatus";
import StepCard from "./StepCard";
import EditCard from "./EditCard";
import del from "../../../assets/testcaseeditor/delete-outline.svg";
import grp from "../../../assets/testcaseeditor/folder-multiple.svg";
import IfLogo from "../../../assets/testcaseeditor/if-logo.svg";
import LoopLogo from "../../../assets/testcaseeditor/loop-logo.svg";
import expandIcon from "../../../assets/testcaseeditor/arrow-expand-vertical.svg";
import collapseIcon from "../../../assets/testcaseeditor/arrow-collapse-vertical.svg";
import close from "../../../assets/testcaseeditor/close.svg";

import "./stepContainer.css";
import { connect, useDispatch } from "react-redux";
import { addCard, editCard, deleteCard } from "../Testaction/cardsActions";
import { CircularProgress } from "@mui/material";

const StepContainer = ({
  sessionId,

  pausing,
  paused,
  recordActive,

  statusMessage,

  cards,
  loading,
  startLoading,
  stopLoading,
  disabled,

  addingStepsOn,
  editingStepsOn,
  setAddingStepsOn,
  setEditingStepsOn,

  handleAddCard,
  handleAddGroupCard,
  handleAddingStepInGroupWithArrow,
  handleDeleteStep,
  handleDeleteGroupStep,
  groupStepDeleting,
  handleUpdateCard,
  handlePlayClick,
  handleStopClick,
  handlePauseClick,
  handleRecorderClick,
  handleCancel,

  handleEditStep,
  handleAddStepInBetween,

  generationBeginHandler,

  globalRefresh,
  globalDataKeys,
  refreshingGlobal,
  triggerGlobalRefresh,

  triggerLocalRefresh,
  localDataCol,
  refreshingLocal,

  sendMsgForOrdinalHover,
  sendMsgForOrdinalSelect,

  disableLoop,
  disableGroup,

  handleConditionPop,
  groupUpdating,
  setGroupUpdating,

  handleApiSendClick,
  handleApiSaveClick,

  restApiOpen,
  openingRestApi,
  handleOpenRestApiModal,
  handleCloseRestApiModal,

  apiModaleData,
  sendMessage,
  received502,
  setReceived502,

  scriptModalOpen,
  handleOpenScriptModal,
  handleCloseScriptModal,
  handleCompileClick,

  openingScript,
  setOpeningScript,

}) => {
  /**
   *  Control steps based on user actions
   * @param {string} option
   */
  const actionController = (option) => {
    switch (option) {
      case "group":
        break;
      case "loop":
        break;
      case "if":
        break;
      case "delete":
        break;
      case "cancel":
        break;
      default:
        break;
    }
  };

  /**
   *  Control area based on user actions
   * @param {boolean} option
   */
  const areaController = (option) => {
    if (option) {
      // Expand
    } else {
      // Collapse
    }
  };

  /**
   *  handle cards actions on user actions
   * @param {string, string} value
   */
  const disabledClass = disabled ? "disabled" : "";

  console.log("cards0", cards);

  return (
    <Resizable
      defaultSize={{
        width: "485px",
        height: "100%",
      }}
      handleStyles={{
        right: {
          width: "10px",
          height: "100%",
          position: "absolute",
          top: "0",
          bottom: "0",
          right: "-5px",
          cursor: "col-resize",
        },
      }}
      maxWidth="90%"
      minWidth="30%"
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
      }}
      className="resizable-container"
      style={{ position: "relative" }}
    >
      <div className="step-container">
        <div className="step-action-container">
          <div className="step-actions">
            <button
              className="cb"
              onClick={() => actionController("group")}
            // className={`cb ${groupShortcutActive && "shortcut-active"}`}
            >
              <img style={{ margin: "0px 3px" }} src={grp} alt="Logo" />
              <p>Group</p>
            </button>
            <button onClick={() => actionController("if")} className="cb">
              <img src={IfLogo} alt="Logo" />
              If
            </button>
            <button onClick={() => actionController("loop")} className="cb">
              <img src={LoopLogo} alt="Logo" />
              Loop
            </button>
            <button
              onClick={() => actionController("delete")}
              className="cb"
            // style={{ display: selected.length > 0 ? "flex" : "none" }}
            >
              <img src={del} alt="Logo" />
              Delete
            </button>
            <button
              onClick={() => actionController("cancel")}
              className="cb"
              style={{
                color: "red",
                //   display: selected.length > 0 ? "flex" : "none",
              }}
            >
              <img src={close} alt="Logo" />
              Cancel
            </button>
          </div>
          <div className="step-actions">
            <button className="cb">
              <img
                onClick={() => areaController(true)}
                src={expandIcon}
                alt="Expand"
              />
            </button>
            <button className="cb" style={{ marginRight: "3px" }}>
              <img
                onClick={() => areaController(false)}
                src={collapseIcon}
                alt="Collapse"
              />
            </button>
          </div>
        </div>
        <div className="step-wrapper">
          <StepStatus
            sessionId={sessionId}
            pausing={pausing}
            paused={paused}
            startLoading={startLoading}
            stopLoading={stopLoading}
            statusMessage={statusMessage}
            handleStopClick={handleStopClick}
            handlePauseClick={handlePauseClick}
            generationBeginHandler={generationBeginHandler}
          />

          <div className={`step-content scrollar  ${disabledClass}`}>
            {loading ? (
              <div className="spinner-cont">
                <CircularProgress
                  style={{ color: "#1D86FF", width: "20px", height: "20px" }}
                />
              </div>
            ) : (
              cards.map((card) => (
                <StepCard
                  key={card.step_id}
                  card={card}
                  cards={cards}
                  step_id={card.step_id}
                  recordActive={recordActive}
                  paused={paused}
                  sessionId={sessionId}
                  addCard={handleAddCard}
                  handleAddGroupCard={handleAddGroupCard}
                  handleAddingStepInGroupWithArrow={handleAddingStepInGroupWithArrow}
                  updateCard={handleUpdateCard}
                  addingStepsOn={addingStepsOn}
                  editingStepsOn={editingStepsOn}
                  setEditingStepsOn={setEditingStepsOn}
                  setAddingStepsOn={setAddingStepsOn}
                  handleAddStepInBetween={handleAddStepInBetween}
                  handleEditStep={handleEditStep}
                  handleDeleteStep={handleDeleteStep}
                  handleDeleteGroupStep={handleDeleteGroupStep}
                  groupStepDeleting={groupStepDeleting}
                  handlePlayClick={handlePlayClick}
                  handleCancel={handleCancel}
                  globalRefresh={globalRefresh}
                  refreshingGlobal={refreshingGlobal}
                  globalDataKeys={globalDataKeys}
                  triggerGlobalRefresh={triggerGlobalRefresh}
                  triggerLocalRefresh={triggerLocalRefresh}
                  localDataCol={localDataCol}
                  refreshingLocal={refreshingLocal}
                  sendMsgForOrdinalHover={sendMsgForOrdinalHover}
                  sendMsgForOrdinalSelect={sendMsgForOrdinalSelect}
                  disableLoop={disableLoop}
                  disableGroup={disableGroup}
                  handleConditionPop={handleConditionPop}
                  groupUpdating={groupUpdating}
                  setGroupUpdating={setGroupUpdating}
                  handleApiSendClick={handleApiSendClick}
                  handleApiSaveClick={handleApiSaveClick}
                  restApiOpen={restApiOpen}
                  openingRestApi={openingRestApi}
                  handleOpenRestApiModal={handleOpenRestApiModal}
                  handleCloseRestApiModal={handleCloseRestApiModal}
                  apiModaleData={apiModaleData}
                  sendMessage={sendMessage}
                  received502={received502}
                  setReceived502={setReceived502}

                  openingScript={openingScript}
                  setOpeningScript={setOpeningScript}

                  scriptModalOpen={scriptModalOpen}
                  handleOpenScriptModal={handleOpenScriptModal}
                  handleCloseScriptModal={handleCloseScriptModal}
                  handleCompileClick={handleCompileClick}

                />
              ))
            )}

            {!addingStepsOn &&
              !editingStepsOn &&
              sessionId &&
              !recordActive &&
              !disabled && (
                <EditCard
                  // cards={cards}
                  addCard={handleAddCard}
                  handleAddGroupCard={handleAddGroupCard}
                  handleAddingStepInGroupWithArrow={handleAddingStepInGroupWithArrow}
                  setAddingStepsOn={setAddingStepsOn}
                  setEditingStepsOn={setEditingStepsOn}
                  handleEditStep={handleEditStep}
                  loading={loading}
                  handleCancel={handleCancel}
                  globalRefresh={globalRefresh}
                  refreshingGlobal={refreshingGlobal}
                  triggerGlobalRefresh={triggerGlobalRefresh}
                  globalDataKeys={globalDataKeys}
                  triggerLocalRefresh={triggerLocalRefresh}
                  localDataCol={localDataCol}
                  refreshingLocal={refreshingLocal}
                  disableLoop={disableLoop}
                  disableGroup={disableGroup}
                  handleDeleteStep={handleDeleteStep}
                  handleDeleteGroupStep={handleDeleteGroupStep}
                  groupStepDeleting={groupStepDeleting}
                  handleConditionPop={handleConditionPop}
                  handleAddStepInBetween={handleAddStepInBetween}
                  groupUpdating={groupUpdating}
                  setGroupUpdating={setGroupUpdating}
                  handleApiSendClick={handleApiSendClick}
                  handleApiSaveClick={handleApiSaveClick}
                  restApiOpen={restApiOpen}
                  openingRestApi={openingRestApi}
                  handleOpenRestApiModal={handleOpenRestApiModal}
                  handleCloseRestApiModal={handleCloseRestApiModal}
                  apiModaleData={apiModaleData}
                  sendMessage={sendMessage}
                  received502={received502}
                  setReceived502={setReceived502}

                  openingScript={openingScript}
                  setOpeningScript={setOpeningScript}

                  scriptModalOpen={scriptModalOpen}
                  handleOpenScriptModal={handleOpenScriptModal}
                  handleCloseScriptModal={handleCloseScriptModal}
                  handleCompileClick={handleCompileClick}

                />
              )}
          </div>
        </div>
      </div>
    </Resizable>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards.cards,
  statusMessage: state.cards.statusMessage,
});

const mapDispatchToProps = {
  addCard,
  editCard,
  deleteCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepContainer);
