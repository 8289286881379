export const addCard = (cardDetails) => ({
  type: "ADD_CARD",
  payload: cardDetails,
});

export const resetCards = () => ({
  type: "RESET_CARDS",
});

export const updateCard = (
  newText,
  id,
  newData,
  newstatus = 6,
  ordinal = null,
  do_ordinal_selection = null,
  newField3 = null
) => ({
  type: "UPDATE_CARD",
  payload: {
    step_id: id,
    instr: newText,
    data: newData,
    status: newstatus,
    ordinal: ordinal,
    do_ordinal_selection: do_ordinal_selection,
    newField3: newField3,
  },
});

export const editCard = (cardDetails) => ({
  type: "EDIT_CARD",
  payload: cardDetails,
});

export const deleteCard = (id) => ({
  type: "DELETE_CARD",
  payload: { id },
});

export const setStatusMessage = (message) => ({
  type: "SET_STATUS_MESSAGE",
  payload: message,
});

export const setConditionActive = (condition) => ({
  type: "SET_CONDITION_ACTIVE",
  payload: condition,
});

export const setLinkedLocalDataId = (Id) => ({
  type: "SET_LINKED_LOCAL_DATA_ID",
  payload: Id,
});

export const setCurrentTestcaseName = (Id) => ({
  type: "SET_CURRENT_TESTCASE_NAME",
  payload: Id,
});

export const popConditionActive = () => ({
  type: "POP_CONDITION_ACTIVE"
});

export const setExistingGroups = (groups) => ({
  type: "SET_EXISTING_GROUPS",
  payload: groups,
});

export const setSessionIdForNewTC = (sessionId) => ({
  type: "SET_SESSION_ID_FOR_NEW_TC",
  payload: sessionId,
});