import React, { useState, useEffect } from "react";
import { Select, Space, Tooltip } from "antd";

// const options = [];

// for (let i = 10; i < 36; i++) {
//   const value = i.toString(36) + i;
//   options.push({
//     label: `Module: ${value}`,
//     value,
//   });
// }

const _sharedProps = {
  mode: "multiple",
  style: { width: "100%" },
  options: [],
  placeholder: "Module",
  maxTagCount: "responsive",
};

const ModuleFilter = ({ folderList, selectedFolders }) => {
  const [value, setValue] = useState([]);
  const [sharedProps, setSharedProps] = useState(_sharedProps);
 

  useEffect(() => {
    if (folderList && folderList.length > 0) {
      const formattedFolders = folderList.map((folder) => ({
        label: folder.name,
        value: folder.id,
      }));
      setSharedProps((previous) => ({
        ...previous,
        options: formattedFolders,
      }));
    }
  }, [folderList]);

  useEffect(() => {
    selectedFolders(value);
  }, [value]);

  const selectProps = {
    value,
    onChange: setValue,
  };

  return (
    <div className="filter-container">
      <Space direction="vertical">
        <Select
          {...sharedProps}
          {...selectProps}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{ pointerEvents: "none" }}
              title={omittedValues.map(({ label }) => label).join(", ")}
            >
              <span>{`+${omittedValues.length}`}</span>
            </Tooltip>
          )}
          // onChange={handleChange}
        />
      </Space>
    </div>
  );
};

export default ModuleFilter;
