export const actionSuggestions = {
  click: [
    {
      instr_type: 1,
      clickValue: "first",
      instr: 'Click on "<span class="sp-e label">Text</span>"',
      variables: [{ name: "label", idx: 2 }],
    },
    // { instr_type: 1, clickValue: 'second', instr: 'Click on second "<span class="sp-e">Text</span>"' },
    // { instr_type: 1, clickValue: 'third', instr: 'Click on "<span class="sp-e">Text</span>" radio for "<span class="sp-e">Text</span>"' },
    // { instr_type: 1, clickValue: 'fourth', instr: 'Click on "<span class="sp-e">Text</span>" radio' },
    // { instr_type: 1, clickValue: 'fifth', instr: 'Click on radio next to "<span class="sp-e">Attribute</span>"' },
    {
      instr_type: 2,
      clickValue: "sixth",
      instr:
        'Click on "<span class="sp-e label">Text</span>" after "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 2 },
        { name: "context", idx: 4 },
      ],
    },
    {
      instr_type: 3,
      clickValue: "seventh",
      instr:
        'Click on "<span class="sp-e label">Text</span>" for "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 2 },
        { name: "context", idx: 4 },
      ],
    },
    {
      instr_type: 4,
      clickValue: "eighth",
      instr:
        'Click on "<span class="sp-e label">Text</span>" before "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 2 },
        { name: "context", idx: 4 },
      ],
    },
    {
      instr_type: 150,
      clickValue: "first",
      instr: 'click on "<span class="sp-e label">Text</span>" Checkbox',
      variables: [
        { name: "label", idx: 2 },
        { name: "checkbox", idx: 3 },
      ],
      // label_type: LabelTypes.normal,
      // label: "login",
      // element_type: "checkbox"
    },
    {
      instr_type: 151,
      instr:
        'click on "<span class="sp-e label">Text</span>" checkbox after "<span class="sp-e context">Text</span>"',
      clickValue: "second",
      variables: [
        { name: "label", idx: 2 },
        { name: "checkbox", idx: 3 },
        { name: "context", idx: 4 },
      ],
      // label_type: LabelTypes.global_data,
      // "label": "login",
      // "element_type":"checkbox",
      // "contextual_label_type": LabelTypes.local_data,
      // "contextual_label": "pwd"
    },
    {
      instr_type: 152,
      instr:
        'click on "<span class="sp-e label">Text</span>" checkbox for "<span class="sp-e context">Text</span>"',
      clickValue: "third",
      variables: [
        { name: "label", idx: 2 },
        { name: "checkbox", idx: 3 },
        { name: "context", idx: 5 },
      ],
      // "label_type": LabelTypes.global_data,
      // "label": "login",
      // "element_type": "radio",
      // "contextual_label_type": LabelTypes.local_data,
      // "contextual_label": "pwd"
    },
    {
      instr_type: 153,
      instr:
        'click on "<span class="sp-e label">Text</span>" checkbox before "<span class="sp-e context">Text</span>"',
      clickValue: "fourth",
      variables: [
        { name: "label", idx: 2 },
        { name: "checkbox", idx: 3 },
        { name: "context", idx: 5 },
      ],
      // label_type: LabelTypes.global_data,
      // "label": "login",
      // "element_type": "radio",
      // "contextual_label_type": LabelTypes.local_data,
      // "contextual_label": "pwd"
    },
  ],
  enter: [
    {
      instr_type: 6,
      clickValue: "first",
      instr: 'Enter "<span class="sp-e label">Text</span>"',
      variables: [{ name: "label", idx: 1 }],
    },
    // { instr_type: 1, clickValue: 'second', instr: 'Enter "<span class="sp-e">Textbox</span>" and save it as <span class="sp-e">var_name</span>' },
    // { instr_type: 1, clickValue: 'third', instr: 'Enter second "<span class="sp-e">Text</span>"' },
    // { instr_type: 1, clickValue: 'fourth', instr: 'Enter "<span class="sp-e">Attribute</span>"' },
    {
      instr_type: 8,
      clickValue: "fifth",
      instr:
        'Enter "<span class="sp-e label">Text</span>" after "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },
    {
      instr_type: 9,
      clickValue: "sixth",
      instr:
        'Enter "<span class="sp-e label">Text</span>" for "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },
    {
      instr_type: 10,
      clickValue: "seventh",
      instr:
        'Enter "<span class="sp-e label">Text</span>" before "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },
  ],
  verify: [
    {
      instr_type: 90,
      clickValue: "first",
      instr: 'verify "<span class="sp-e label">Text</span>" is on screen',
      variables: [{ name: "label", idx: 3 }],
      // "label_type": LabelTypes.normal,
      // "label": "status",
    },
    {
      instr_type: 91,
      clickValue: "second",
      instr:
        'verify "<span class="sp-e label">Text</span>" after "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
      // "label_type": LabelTypes.normal,
      // "label": "status",
      // "contextual_label_type": LabelTypes.global_data,
      // "contextual_label": "details",
    },
    {
      instr_type: 93,
      clickValue: "third",
      instr:
        'verify "<span class="sp-e label">Text</span>" for "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },
    {
      instr_type: 94,
      clickValue: "fourth",
      instr:
        'verify "<span class="sp-e label">Text</span>" before "<span class="sp-e context">Text</span>"',
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
      //   "label_type": LabelTypes.normal,
      // "label": "status",
      // "contextual_label_type": LabelTypes.global_data,
      // "contextual_label": "details",
    },

    // verify (Check) and asset(confirm)
    {
      instr_type: 95,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'verify "<span class="sp-e label">some_data</span>" is "<span class="sp-e context">expected_value</span>"',
      clickValue: "fifth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },

    {
      instr_type: 96,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'verify "<span class="sp-e label">some_data</span>" contains "<span class="sp-e context">expected_value</span>"',
      clickValue: "sixth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },
    {
      instr_type: 97,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'verify "<span class="sp-e label">some_data</span>" starts with "<span class="sp-e context">expected_value</span>"',
      clickValue: "seventh",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 4 },
      ],
    },
    {
      instr_type: 98,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'verify "<span class="sp-e label">some_data</span>" ends with "<span class="sp-e context">expected_value</span>"',
      clickValue: "eighth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 4 },
      ],
    },
    {
      instr_type: 99,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'verify "<span class="sp-e label">some_data</span>" is case insensitively equal to "<span class="sp-e context">expected_value</span>"',
      clickValue: "ninth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 7 },
      ],
    }

    // verify (Check) and asset(confirm)
    // {
    //   instr_type: 95,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'verify "<span class="sp-e label">some_data</span>" is "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "fifth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 3 },
    //   ],
    // },

    // {
    //   instr_type: 96,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'verify "<span class="sp-e label">some_data</span>" contains "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "sixth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 3 },
    //   ],
    // },
    // {
    //   instr_type: 97,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'verify "<span class="sp-e label">some_data</span>" starts with "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "seventh",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 4 },
    //   ],
    // },
    // {
    //   instr_type: 98,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'verify "<span class="sp-e label">some_data</span>" ends with "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "eighth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 4 },
    //   ],
    // },
    // {
    //   instr_type: 99,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'verify "<span class="sp-e label">some_data</span>" is case insensitively equal to "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "ninth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 7 },
    //   ],
    // }



    // { instr_type: 1,  instr: "Verify window exists" },
    // { instr_type: 1, clickValue: "fifth", instr: "Verify alert exists" },
    // { instr_type: 1, clickValue: "sixth", instr: "Verify pop up exists" },
    // { instr_type: 1, clickValue: "seventh", instr: "Verify new tab exists" },
    // { instr_type: 1, clickValue: "eighth", instr: "Verify tab exists" },

    // {
    //   instr_type: 1,
    //   clickValue: "ninth",
    //   instr: 'Verify the current url is "<span class="sp-e">url</span>"',
    //   variables: ["url"],
    // },
    // {
    //   instr_type: 1,
    //   clickValue: "tenth",
    //   instr: 'Verify url is "<span class="sp-e">url</span>"',
    //   variables: ["url"],
    // },
  ],
  hover: [
    {
      instr_type: 26,
      clickValue: "first",
      instr: 'Hover on "<span class="sp-e label">Text</span>"',
      variables: [{ name: "label", idx: 2 }],
    },
  ],
  hit: [
    { instr_type: 111, clickValue: "first", instr: "hit enter" },
    { instr_type: 112, clickValue: "second", instr: "hit escape" },
    { instr_type: 113, clickValue: "third", instr: "hit tab" },
    { instr_type: 114, clickValue: "fourth", instr: "hit space" },
    { instr_type: 115, clickValue: "fifth", instr: "hit backspace" },
    { instr_type: 116, clickValue: "sixth", instr: "hit delete" },
    { instr_type: 117, clickValue: "seventh", instr: "hit shift" },
    { instr_type: 118, clickValue: "eighth", instr: "hit control" },
    { instr_type: 119, clickValue: "ninth", instr: "hit alt" },
    { instr_type: 120, clickValue: "tenth", instr: "hit arrow up" },
    { instr_type: 121, clickValue: "eleventh", instr: "hit arrow down" },
    { instr_type: 122, clickValue: "twelveth", instr: "hit arrow left" },
    { instr_type: 123, clickValue: "thirteenth", instr: "hit arrow right" },
    { instr_type: 124, clickValue: "fourteenth", instr: "hit page up" },
    { instr_type: 125, clickValue: "fifteenth", instr: "hit page down" },
    { instr_type: 126, clickValue: "sixteenth", instr: "hit home" },
    { instr_type: 127, clickValue: "seventeenth", instr: "hit end" },
  ],
  assert: [
    {
      instr_type: 100,
      clickValue: "first",
      instr: 'assert "<span class="sp-e label">Text</span>" is on screen',
      variables: [{ name: "label", idx: 1 }],
      // label_type: "LabelTypes.normal",
      // label: "status"
    },
    {
      instr_type: 101,
      clickValue: "second",
      instr:
        "assert '<span class='sp-e label'>Text</span>' after '<span class='sp-e context'>Text</span>'",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
      // label_type: "LabelTypes.normal",
      // label: "status",
      // contextual_label_type: "LabelTypes.global_data",
      // contextual_label: "details"
    },
    {
      instr_type: 103,
      clickValue: "third",
      instr:
        "assert '<span class='sp-e label'>Text</span>' for '<span class='sp-e context'>Text</span>'",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
      // label_type: "LabelTypes.normal",
      // label: "status",
      // contextual_label_type: "LabelTypes.global_data",
      // contextual_label: "details"
    },
    {
      instr_type: 104,
      clickValue: "fourth",
      instr:
        "assert '<span class='sp-e label'>Text</span>' before '<span class='sp-e context'>Text</span>'",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
      // label_type: "LabelTypes.normal",
      // label: "status",
      // contextual_label_type: "LabelTypes.global_data",
      // contextual_label: "details"
    },
    {
      instr_type: 105,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'assert "<span class="sp-e label">some_data</span>" is "<span class="sp-e context">expected_value</span>"',
      clickValue: "fifth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },
    {
      instr_type: 106,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'assert "<span class="sp-e label">some_data</span>" contains "<span class="sp-e context">expected_value</span>"',
      clickValue: "sixth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 3 },
      ],
    },
    {
      instr_type: 107,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'assert "<span class="sp-e label">some_data</span>" starts with "<span class="sp-e context">expected_value</span>"',
      clickValue: "seventh",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 4 },
      ],
    },
    {
      instr_type: 108,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'assert "<span class="sp-e label">some_data</span>" ends with "<span class="sp-e context">expected_value</span>"',
      clickValue: "eighth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 4 },
      ],
    },
    {
      instr_type: 109,
      // data: "some_data",
      // expected_value: "expected_value",
      instr: 'assert "<span class="sp-e label">some_data</span>" case insensitive match "<span class="sp-e context">expected_value</span>"',
      clickValue: "ninth",
      variables: [
        { name: "label", idx: 1 },
        { name: "context", idx: 5 },
      ],
    }

    // assert (Check) and asset(confirm)
    // {
    //   instr_type: 105,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'assert "<span class="sp-e label">some_data</span>" is "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "fifth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 3 },
    //   ],
    // },
    // {
    //   instr_type: 106,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'assert "<span class="sp-e label">some_data</span>" contains "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "sixth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 3 },
    //   ],
    // },
    // {
    //   instr_type: 107,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'assert "<span class="sp-e label">some_data</span>" starts with "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "seventh",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 4 },
    //   ],
    // },
    // {
    //   instr_type: 108,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'assert "<span class="sp-e label">some_data</span>" ends with "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "eighth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 4 },
    //   ],
    // },
    // {
    //   instr_type: 109,
    //   // data: "some_data",
    //   // expected_value: "expected_value",
    //   instr: 'assert "<span class="sp-e label">some_data</span>" case insensitive match "<span class="sp-e context">expected_value</span>"',
    //   clickValue: "ninth",
    //   variables: [
    //     { name: "label", idx: 1 },
    //     { name: "context", idx: 5 },
    //   ],
    // }


  ],
  select: [
    {
      instr_type: 40,
      clickValue: "first",
      instr: 'Select "<span class="sp-e label">Text</span>"',
      variables: [{ name: "label", idx: 1 }],
    },
  ],
  upload: [
    {
      instr_type: 68,
      clickValue: "first",
      instr: 'Upload file in "<span class="sp-e label">Text</span>"',
      variables: [{ name: "label", idx: 3 }],
    },
  ],
  open: [
    {
      instr_type: 0,
      clickValue: "first",
      instr: "Open website",
    },
  ],
  switch: [
    {
      instr_type: 83,
      clickValue: "first",
      instr: "switch to original tab",
      action: "switch",
      target: "original tab",
    },
    // {
    //   instr_type: 84,
    //   clickValue: "second",
    //   instr: 'switch to specific tab',
    //   // action: "switch",
    //   // target: "specific tab",
    //   // tab_number: 2
    // },
    // {
    //   instr_type: 85,
    //   clickValue: "third",
    //   instr: "switch to last tab",
    //   // action: "switch",
    //   // target: "last tab"
    // },
    {
      instr_type: 86,
      instr: "switch to new window",
      clickValue: "fourth",
      action: "switch",
      target: "new window",
    },
    {
      instr_type: 87,
      clickValue: "fifth",
      instr: "switch to original window",
      action: "switch",
      target: "original window",
    },
    // {
    //   instr_type: 88,
    //   clickValue: "sixth",
    //   instr: "switch to specific window",
    //   // action: "switch",
    //   // target: "specific window",
    //   // window_number: 2
    // },
    // {
    //   instr_type: 89,
    //   clickValue: "seventh",
    //   instr: "switch to last window",
    //   // action: "switch",
    //   // target: "last window"
    // }
  ],
  ai: [
    {
      instr_type: 300,
      clickValue: "first",
      instr: 'AI: "<span class="sp-e label">Text</span>"',
      variables: [{ name: "label", idx: 1 }],
    },
  ],
  // checkbox: [
  //   {
  //     instr_type: 150,
  //     clickValue: "first",
  //     instr: 'click on "<span class="sp-e label">Text</span>" Checkbox',
  //     variables: [{ name: "label", idx: 2 }, { name: "checkbox", idx: 3 }],
  //     // label_type: LabelTypes.normal,
  //     // label: "login",
  //     // element_type: "checkbox"
  //   },
  //   {

  //     instr_type: 151,
  //     instr: 'click on "<span class="sp-e label">Text</span>" checkbox after "<span class="sp-e context">Text</span>"',
  //     clickValue: "second",
  //     variables: [{ name: "label", idx: 2 }, { name: "checkbox", idx: 3 }, { name: "context", idx: 4 }],
  //     // label_type: LabelTypes.global_data,
  //     // "label": "login",
  //     // "element_type":"checkbox",
  //     // "contextual_label_type": LabelTypes.local_data,
  //     // "contextual_label": "pwd"
  //   },
  //   {

  //     instr_type: 152,
  //     instr: 'click on "<span class="sp-e label">Text</span>" checkbox for "<span class="sp-e context">Text</span>"',
  //     clickValue: "third",
  //     variables: [{ name: "label", idx: 2 }, { name: "checkbox", idx: 3 }, { name: "context", idx: 5 }],
  //     // "label_type": LabelTypes.global_data,
  //     // "label": "login",
  //     // "element_type": "radio",
  //     // "contextual_label_type": LabelTypes.local_data,
  //     // "contextual_label": "pwd"
  //   },
  //   {
  //     instr_type: 153,
  //     instr: 'click on "<span class="sp-e label">Text</span>" checkbox before "<span class="sp-e context">Text</span>"',
  //     clickValue: "fourth",
  //     variables: [{ name: "label", idx: 2 }, { name: "checkbox", idx: 3 }, { name: "context", idx: 5 }],
  //     // label_type: LabelTypes.global_data,
  //     // "label": "login",
  //     // "element_type": "radio",
  //     // "contextual_label_type": LabelTypes.local_data,
  //     // "contextual_label": "pwd"
  //   },

  // ]
};

// getInstruction(1, "label", 1)
// => {
//   instr_type: 1,
//   instr: 'Click on label',
//   label_type: "label",
//   label: 1,
// }

export function getInstruction(instr_type, label = null, label_type = null, contextual_label = null, contextual_label_type = null) {
  const instructions = [
    {
      instr_type: 0,
      instr: "open website",
    },
    {
      instr_type: 1,
      instr: 'Click on label', 
      label_type: null,
      label: null,
    },
    {
      instr_type: 2,
      instr: "click on label after contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 3,
      instr: "click on label for contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 4,
      instr: "click on label before contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 6,
      instr: 'Enter label',
      label_type: null,
      label: null,
    },
    {
      instr_type: 8,
      instr: "Enter label after contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 9,
      instr: "Enter label for contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 10,
      instr: "Enter label before contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 26,
      instr: 'Hover on label',
      label_type: null,
      label: null,
    },
    {
      instr_type: 40,
      instr: 'Select label',
      label_type: null,
      label: null,
    },
    {
      instr_type: 68,
      instr: 'Upload file in label',
      label_type: null,
      label: null,
    },
    {
      instr_type: 83,
      instr: "switch to original tab",
      action: "switch",
      target: "original tab"
    },
    {
      instr_type: 86,
      instr: "switch to new window",
      action: "switch",
      target: "new window"
    },
    {
      instr_type: 87,
      instr: "switch to original window",
      action: "switch",
      target: "original window"
    },
    {
      instr_type: 90,
      instr: 'verify label is on screen',
      label_type: null,
      label: null,
    },
    {
      instr_type: 91,
      instr: "verify label after contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 93,
      instr: "verify label for contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 94,
      instr: "verify label before contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 95,
      instr: 'verify label is contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 96,
      instr: 'verify label contains contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 97,
      instr: 'verify label starts with contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 98,
      instr: 'verify label ends with contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 99,
      instr: 'verify label is case insensitively equal to contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 100,
      instr: 'assert label is on screen',
      label_type: null,
      label: null,
    },
    {
      instr_type: 101,
      instr: "assert label after contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 103,
      instr: "assert label for contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 104,
      instr: "assert label before contextual_label",
      label_type: null,
      label: null,
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 105,
      instr: 'assert label is contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 106,
      instr: 'assert label contains contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 107,
      instr: 'assert label starts with contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 108,
      instr: 'assert label ends with contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 109,
      instr: 'assert label case insensitive match contextual_label',
      data: null,
      expected_value: null,
    },
    {
      instr_type: 111,
      instr: "hit enter"
    },
    {
      instr_type: 112,
      instr: "hit escape"
    },
    {
      instr_type: 113,
      instr: "hit tab"
    },
    {
      instr_type: 114,
      instr: "hit space"
    },
    {
      instr_type: 115,
      instr: "hit backspace"
    },
    {
      instr_type: 116,
      instr: "hit delete"
    },
    {
      instr_type: 117,
      instr: "hit shift"
    },
    {
      instr_type: 118,
      instr: "hit control"
    },
    {
      instr_type: 119,
      instr: "hit alt"
    },
    {
      instr_type: 120,
      instr: "hit arrow up"
    },
    {
      instr_type: 121,
      instr: "hit arrow down"
    },
    {
      instr_type: 122,
      instr: "hit arrow left"
    },
    {
      instr_type: 123,
      instr: "hit arrow right"
    },
    {
      instr_type: 124,
      instr: "hit page up"
    },
    {
      instr_type: 125,
      instr: "hit page down"
    },
    {
      instr_type: 126,
      instr: "hit home"
    },
    {
      instr_type: 127,
      instr: "hit end"
    },
    {
      instr_type: 150,
      instr: 'click on label Checkbox',
      label_type: null,
      label: null,
      element_type: "checkbox"
    },
    {
      instr_type: 151,
      instr: 'click on label checkbox after contextual_label',
      label_type: null,
      label: null,
      element_type: "checkbox",
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 152,
      instr: 'click on label checkbox for contextual_label',
      label_type: null,
      label: null,
      element_type: "checkbox",
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 153,
      instr: 'click on label checkbox before contextual_label',
      label_type: null,
      label: null,
      element_type: "checkbox",
      contextual_label_type: null,
      contextual_label: null
    },
    {
      instr_type: 300,
      instr: 'AI: label',
      label_type: null,
      label: null,
    }
  ];

  const instruction = instructions.find(instr => instr.instr_type === instr_type);

  if (instruction) {
    if (label !== null) {
      instruction.label = label;
      instruction.label_type = label_type;
    }
    if (contextual_label !== null) {
      instruction.contextual_label = contextual_label;
      instruction.contextual_label_type = contextual_label_type;
    }
  }

  return instruction;
}


const instWithFixedLabelData = [95, 96, 97, 98, 99, 105, 106, 107, 108, 109];
export function isInFixedLabelData(value) {
  return instWithFixedLabelData.includes(value);
}
// // Example usage
// const instr_type = 4;
// const label = "Hello";
// const label_type = 2;
// const contextual_label = "Hi";
// const contextual_label_type = 1;
// const result = getInstruction(instr_type, label, label_type, contextual_label, contextual_label_type);
// console.log(result);
