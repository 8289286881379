import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styleguide.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cardsReducer from "./pages/EcomTesting/Testaction/cardsReducer";
import dataReducer from "./pages/Data/DataAction/dataReducer";
import { threadReducer } from "./redux/reducers/threadReducer";
import timezoneReducer from "./redux/reducers/timezoneReducer";
import userPrivilegesReducer from "./redux/reducers/userPrivilegesReducer";
import isUserOnlineReducer from "./redux/reducers/isOnlineReducer";


const rootReducer = combineReducers({
  cards: cardsReducer,
  data: dataReducer,
  thread: threadReducer,
  timezone: timezoneReducer,
  permissions: userPrivilegesReducer,
  isOnline: isUserOnlineReducer
  // suites: suiteReducer,
});

const cardsLoggerMiddleware = store => next => action => {
  const result = next(action);

  // Log the cards state if it changes
  if (action.type === "ADD_CARD" || action.type === "DELETE_CARD" || action.type === "UPDATE_CARD") {
    console.log("CARD_MAIN", store.getState());
  }

  return result;
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(cardsLoggerMiddleware),
});

store.subscribe(() => {
  const state = store.getState();
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
      theme="light"
    />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
