import React, { useState, useEffect, useCallback } from "react";
import "./Botgaugeloader.css";

const sentences = [
  "The first computer bug was an actual moth — how times have changed!",
  "Manual testers are like detectives, uncovering hidden flaws in the code.",
  "Bug fixing feels like cleaning: you think it's done, but there’s always more to find.",
  "Exploratory testing: where curiosity and creativity meet bugs!",
  "Without testing, releasing software is like skydiving without a parachute.",
  "Heisenbugs disappear when you try to observe them — sneaky!",
  "Software testers don’t break systems, they reveal the cracks!",
  "Regression testing is like time travel for code — checking that the past is still intact.",
  "A good test case is like a map; it leads to treasure — or bugs!",
  "Beta testing: sending your app into the wild to see how it survives.",
  "In testing, no bug is too small to cause chaos.",
  "White box testing: understanding the code inside out, literally!",
  "A perfect bug report is worth its weight in code.",
  "Automated testing: let the robots handle the repetitive tasks!",
  "Testing is the bridge between development and a user’s smile.",
  "You’re never truly done testing — there’s always another bug lurking.",
  "Bug squashing: the only kind of hunting that developers enjoy.",
  "In fuzz testing, randomness rules — and so do surprises!",
  "Testers: the unsung heroes behind flawless software.",
  "The most stubborn bugs always show up right before a release!",
  "Exploratory testing is like a treasure hunt, and bugs are the loot.",
  "Test automation: giving testers more time to do what machines can’t.",
  "A well-tested app is like a well-built bridge — safe and reliable.",
  "In testing, persistence pays off — bugs can run, but they can’t hide forever.",
  "The worst bug is the one you don’t see until the customer does!",
  "Testers don’t just find problems — they prevent disasters.",
  "A good tester always thinks like a user — and sometimes like a hacker.",
  "In software testing, if it’s not tested, it’s broken.",
  "Even the best developers make mistakes — that's why we test.",
  "Smoke testing: it’s like lighting a match to see if the system catches fire!",
  "Testing is like proofreading, but the typos can break the entire system.",
  "Without testing, software is just a series of lucky guesses.",
  "Testing: the last line of defense between users and crashes.",
  "A tester’s best friend? A bug that’s easy to reproduce!",
  "In testing, the devil’s in the details — and so are the bugs.",
  "You don’t truly know software until you’ve tested every inch of it.",
  "The only thing worse than finding a bug is not finding one.",
  "A tester’s mission: to break it before the user does!",
  "Unit tests are like safety nets for your code — always there to catch mistakes.",
  "A bug is just an uninvited guest in the code!",
  "Performance testing: the difference between ‘fast’ and ‘just too slow.’",
  "In software testing, ‘It works on my machine’ is never an excuse.",
  "Stress testing: seeing how far the system can be pushed before it breaks.",
  "Bug-free software is a myth, but well-tested software is a reality.",
  "Testers see the big picture — and spot the tiny cracks!",
  "The best way to catch a bug? Think like a user with a mission to break things.",
  "Bugs are like ninjas — they sneak in where you least expect.",
  "Load testing is the difference between one user and a million.",
  "Test cases are the blueprints to bug hunting success.",
  "The harder the bug is to find, the more satisfying it is to squash.",
  "QA: turning code from ‘It works’ to ‘It works perfectly.’",
  "Testing reveals the truth — good or bad!",
  "In software testing, the journey is as important as the result.",
  "User experience is the goal; testing is the path.",
  "Black box testing: where the tester knows what should happen but not why.",
  "Quality isn’t just a goal — it’s a habit built through testing.",
  "Testers don’t write code, they make it better.",
  "The best bugs are the ones you catch before anyone else!",
  "Boundary testing: pushing software to its absolute limits.",
  "Every bug tells a story — and testers are the narrators.",
  "In testing, creativity is key — think outside the box (and find bugs inside it!).",
  "Testing is like being a detective; you have to investigate to find the culprits.",
  "A failed test is just a bug waiting to be fixed.",
  "Testers ask the questions developers didn’t think of.",
  "Without testers, software would be a house of cards.",
  "Good testers don’t just find bugs — they think about how to break things creatively.",
  "A bug found early is worth a thousand headaches later.",
  "In testing, perfection is the enemy of good — unless you have time to spare.",
  "Continuous integration means continuous testing — and continuous improvement.",
  "In testing, no detail is too small to overlook.",
  "Testers are like locksmiths, ensuring every door opens and closes properly.",
  "The best testers think like users — and sometimes, even like hackers.",
  "In testing, patience is more than a virtue — it’s a requirement.",
  "Testers don’t fear bugs; they hunt them!",
  "Test automation is the engine, but manual testing is still the driver.",
  "In testing, good things come to those who break code creatively.",
  "A test that finds no bugs is a good test — but not always the best one!",
  "Functional testing: does it work? Non-functional testing: does it work well?",
  "Beta testers are like secret agents sent to uncover hidden issues.",
  "Regression testing: making sure new changes don’t break old successes.",
  "In testing, every bug is an opportunity for improvement.",
  "Good testers ask questions — great testers find the answers.",
  "You can automate tests, but you can’t automate creativity.",
  "A tester’s job isn’t done until the user can’t break it!",
  "In software testing, the simplest things often hide the biggest issues.",
  "Testers are the gatekeepers of quality in the digital world.",
  "A tester’s nightmare: the bug that only appears when no one’s looking!",
  "Testing is like training — you prepare software for the real world.",
  "In software, everything is a feature — until testing proves it’s a bug!",
  "The term 'bug' originated in 1947 when a real moth was found causing issues in an early computer at Harvard University.",
  "The software on the Mars Rover Curiosity had to undergo extremely rigorous testing since there's no 'hotfix' option when something is 140 million miles away!",
  "Testers are often seen as the 'breakers' of software, but this is a skill.",
  "It’s estimated that fixing a bug during the design phase costs 1 unit, during development, it costs 10 units, and after release, it costs 100 units.",
  "Testers sometimes stumble upon 'Easter eggs'—hidden features. One famous example: is a flight simulator hidden in MS Excel!",
  "The term 'dogfooding' refers to a company using its own products to test and improve them.",
  "The term 'beta test' comes from early software development, where 'alpha' testing was done by the developers, and 'beta' testing was done by actual users.",
  "The term 'Smoke Test' comes from hardware testing, where you’d 'smoke test' a circuit to see if it literally starts smoking!",
  "This testing technique involves inputting random data into a system to see what happens.",
  "Netflix developed a tool called 'Chaos Monkey' that randomly disables servers in their production environment to ensure that their system is resilient.",
  "In 1999, NASA lost a $125 million Mars Climate Orbiter due to a software bug caused by a mix-up between metric and imperial units.",
  "Mozilla celebrated finding its 1,000,000th bug in its bug-tracking system in 2010.",
  "The title of the first software tester is often attributed to Alan Turing, who rigorously tested the code of the Bombe machine during World War II.",
];

const Botgaugeloader = () => {
  const [currentSentence, setCurrentSentence] = useState("");
  const [fadeClass, setFadeClass] = useState("fade-in");

  const updateSentence = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * sentences.length);
    setFadeClass("fade-out");
    setTimeout(() => {
      setCurrentSentence(sentences[randomIndex]);
      setFadeClass("fade-in");
    }, 1000);
  }, []);

  useEffect(() => {
    updateSentence();
    const interval = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(updateSentence, 1000);
    }, 10000);

    return () => clearInterval(interval);
  }, [updateSentence]);

  return (
    <>
      <div className="loader1"></div>
      <br />
      <p
        className={`abc1 ${fadeClass}`}
        style={{
          // left: "-50px",
          position: "relative",
          maxWidth: "70%",
          wordWrap: "break-word",
        }}
      >
        {currentSentence}
      </p>
    </>
  );
};

export default Botgaugeloader;
