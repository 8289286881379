import React, { useState, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import { Button, Select } from "antd";
import { useNavigate } from "react-router-dom";
import LeftNav from "../../components/Nav/nav";
import TopNav from "../../components/Nav/topbar/topbar";
// import Table from "antd/lib/table";
import { Badge, Dropdown, Space, Table } from "antd";
import arrow from "../../assets/arrow-down.svg";
import searchIcon from "../../assets/magnify.svg";
import windowsIcon from "../../assets/table/windows 1.svg";
import linuxIcon from "../../assets/table/linux.svg";
import macIcon from "../../assets/table/mac.svg";
import firefoxIcon from "../../assets/table/firefox.svg";
import chromeIcon from "../../assets/table/chrome.svg";
import safariIcon from "../../assets/table/safari.svg";
import success from "../../assets/table/Step Status.svg";
import calIcon from "../../assets/calendar-range.svg";
import {
  executionReports,
  executionJobsByExecutionId,
} from "../../services/jobsServices";
import { Tooltip } from "antd";
import ProgressBar from "../../components/ProgressBar/progressBar";
import ErrorIcon from "@mui/icons-material/Error"; // Adjust icon import based on actual usage
import { ReactComponent as FailIcon } from "../../assets/failStatus.svg";
import { ReactComponent as SuccessIcon } from "../../assets/successStatus.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";
import { ReactComponent as WaitingIcon } from "../../assets/waitingIcon.svg";
import { ReactComponent as ProcessIcon } from "../../assets/inProcess.svg";
import CircularProgress from "@mui/material/CircularProgress";

import "./reports.css";

const NestedRowRender = (id) => {
  // console.log(id.id);
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);

  const platformIconMap = {
    linux: linuxIcon,
    windows: windowsIcon,
    mac: macIcon,
  };

  const browserIconMap = {
    chrome: chromeIcon,
    firefox: firefoxIcon,
    // Add more browser icons as needed
  };

  const handleClick = (id, jobs_id = null) => {
    if (jobs_id === null) {
      navigate(`/report/testcase/${id}/`);
    } else {
      navigate(`/report/testcase/${id}/${jobs_id}`);
    }
  };

  const fetchJobsData = async (execution_id) => {
    try {
      const jsonData = await executionJobsByExecutionId(execution_id);
      // console.log(jsonData.jobs, "data");
      setJobsData(jsonData.jobs);
    } catch (error) {
      console.error("Error fetching report jobs data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchJobsData(id.id);
    }
  }, [id]);

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Platform</div>,
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: <div style={{ textAlign: "center" }}>Browser</div>,
      dataIndex: "browser",
      key: "browser",
    },
    {
      title: <div style={{ textAlign: "center" }}>Status</div>,
      dataIndex: "Status",
      key: "Status",
      render: (status, record) => {
        const iconStyle = {
          cursor: "pointer",
          fontSize: "17px",
          // marginLeft: "10px",
        };

        switch (status) {
          case "success":
            return (
              <Tooltip title="success">
                <SuccessIcon style={iconStyle} />
              </Tooltip>
            );
          case "failed":
            return (
              <Tooltip title="failed">
                <FailIcon style={{ ...iconStyle, color: "red" }} />
              </Tooltip>
            );
          case "warning":
            return (
              <Tooltip title="warning">
                <WaitingIcon style={{ ...iconStyle, color: "yellow" }} />
              </Tooltip>
            );
          case "skipped":
            return (
              <Tooltip title="skipped">
                <InfoIcon style={{ ...iconStyle, color: "gray" }} />
              </Tooltip>
            );
          case "in progress":
            return (
              <Tooltip title="in progress">
                <CircularProgress
                  style={{
                    ...iconStyle,
                    color: "#0036af",
                    width: "10px",
                    height: "10px",
                  }}
                />
              </Tooltip>
            );
          case "not started":
          default:
            return (
              <Tooltip title="not started">
                <ErrorIcon style={iconStyle} />
              </Tooltip>
            );
        }
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>No of steps</div>,
      dataIndex: "steps",
      key: "steps",
    },
  ];
  const data = jobsData.map((job, index) => ({
    key: `${index + 1}`,
    platform: (
      <img
        src={platformIconMap[job.platform]}
        alt="icon"
        style={{ alignItems: "left" }}
      />
    ),
    job_id: job.job_unique_id,
    browser: (
      <div className="">
        <img src={browserIconMap[job.browser]} alt="icon" />v
        {job.browser_version}
      </div>
    ),
    Status:
      job.status === "success"
        ? "success"
        : `failed at step ${job.total_test_steps}`,
    steps: <div className="subT">{job.total_test_steps}</div>,
  }));

  return (
    <div style={{ padding: "0 5px" }}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleClick(id.id, record.job_id);
            },
            style: { cursor: "pointer" },
          };
        }}
      />
    </div>
  );
};

const Reports = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = await executionReports();
        console.log(jsonData, "data");
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // fetchData();
  }, []);
  const handleClick = (id, jobs_id = null) => {
    if (jobs_id === null) {
      navigate(`/report/testcase/${id}/`);
    } else {
      navigate(`/report/testcase/${id}/${jobs_id}`);
    }
  };
  const formatUnixTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const columns = [
    {
      title: (
        <div className="column-title">
          Test case <img src={arrow} alt="icon" />
        </div>
      ),
      dataIndex: "testcase_name",
      key: "1",
      render: (text, record) => {
        const truncatedText =
          text.length > 15 ? text.slice(0, 15) + "..." : text;
        return (
          <Tooltip title={text}>
            <div
              className="column-data"
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(record.execution_id)}
            >
              {truncatedText}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <div className="column-title">Status</div>,
      dataIndex: "execution_status",
      key: "2",
      className: "column-data",
      render: (status, record) => {
        const iconStyle = {
          cursor: "pointer",
          fontSize: "17px",
          // marginLeft: "10px",
        };

        switch (status) {
          case "0":
            return (
              <Tooltip title="success">
                <SuccessIcon style={iconStyle} />
              </Tooltip>
            );
          case "1":
            return (
              <Tooltip title="failed">
                <FailIcon style={{ ...iconStyle, color: "red" }} />
              </Tooltip>
            );
          case "2":
            return (
              <Tooltip title="warning">
                <WaitingIcon style={{ ...iconStyle, color: "yellow" }} />
              </Tooltip>
            );
          case "3":
            return (
              <Tooltip title="skipped">
                <InfoIcon style={{ ...iconStyle, color: "gray" }} />
              </Tooltip>
            );
          case "4":
            return (
              <Tooltip title="in progress">
                <CircularProgress
                  style={{
                    ...iconStyle,
                    color: "#0036af",
                    width: "10px",
                    height: "10px",
                  }}
                />
              </Tooltip>
            );
          case "5":
          default:
            return (
              <Tooltip title="not started">
                <ErrorIcon style={iconStyle} />
              </Tooltip>
            );
        }
      },
    },
    {
      title: <div className="column-title">Browser Combinations</div>,
      dataIndex: "total_jobs",
      key: "3",
      className: "column-data",
    },
    {
      title: <div className="column-title">Triggered At</div>,
      dataIndex: "triggered_at",
      key: "4",
      className: "column-data",
      render: (timestamp) => formatUnixTimestamp(timestamp),
    },
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (searchTerm) {
      setFilteredData(
        data.filter((item) =>
          item["1"].toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [searchTerm, data]);

  const [buttonTitle, setButtonTitle] = useState({
    platform: "",
    browser: "",
    status: "",
  });

  const { Option } = Select;

  return (
    <Container>
      <div className="App">
        <div style={{ backgroundColor: "#FAFAFA" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              margin: "30px",
              width: "98px",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <Form.Group controlId="searchBar">
                    <div className="search-container">
                      <input
                        type="text"
                        placeholder="Search"
                        className="forms"
                        style={{ paddingLeft: "40px" }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <img
                        src={searchIcon}
                        alt="search"
                        className="search-icon"
                      />
                    </div>
                  </Form.Group> */}
              <Select
                className="select-input"
                placeholder={buttonTitle.platform || "Platform"}
                onChange={(value) =>
                  setButtonTitle({ ...buttonTitle, platform: value })
                }
              >
                <Option value="Option 1">windows</Option>
                <Option value="Option 2">linux</Option>
                <Option value="Option 3">mac</Option>
              </Select>

              <Select
                className="select-input"
                placeholder={buttonTitle.browser || "Browser"}
                onChange={(value) =>
                  setButtonTitle({ ...buttonTitle, browser: value })
                }
              >
                <Option value="Option 1">chrome 100</Option>
                <Option value="Option 2">firefox 99</Option>
                <Option value="Option 3">safari 99</Option>
              </Select>

              <Select
                className="select-input"
                placeholder={buttonTitle.status || "Status"}
                onChange={(value) =>
                  setButtonTitle({ ...buttonTitle, status: value })
                }
              ></Select>
              <Button variant="primary" type="" className="filt">
                Date <img src={calIcon} alt="icon" />
              </Button>
            </div>
          </div>
          <Table
            className="table"
            columns={columns}
            dataSource={filteredData}
            rowKey="execution_id"
            expandable={{
              rowExpandable: (record) => record.execution_id,
              expandedRowRender: (record) => (
                <NestedRowRender id={record.execution_id} />
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                !expanded ? (
                  <RightOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ),
            }}
            size="medium"
          />
        </div>
      </div>
    </Container>
  );
};

export default Reports;
