import { ReactComponent as FolderIcon } from "../../../assets/testcaseeditor/folder-icon.svg";
import { ReactComponent as IFIcon } from "../../../assets/testcaseeditor/if-icon.svg";
import { ReactComponent as LoopIcon } from "../../../assets/testcaseeditor/loop-icon.svg";

export const ActionButtonList = [
  {
    id: 1,
    title: "Group",
    isSelected: false,
    isDisabled: false,
    isHidden: false,
    iconContent: <FolderIcon style={{ height: "1rem", width: "1rem" }} />,
  },
  {
    id: 2,
    title: "IF",
    isSelected: false,
    isDisabled: false,
    isHidden: false,
    iconContent: <IFIcon style={{ height: "1rem", width: "1rem" }} />,
  },
  {
    id: 3,
    title: "ELSE IF",
    isSelected: false,
    isDisabled: false,
    isHidden: false,
    iconContent: <IFIcon style={{ height: "1rem", width: "1rem" }} />,
  },
  {
    id: 4,
    title: "ELSE",
    isSelected: false,
    isDisabled: false,
    isHidden: false,
    iconContent: <IFIcon style={{ height: "1rem", width: "1rem" }} />,
  },
  {
    id: 5,
    title: "Loop",
    isSelected: false,
    isDisabled: false,
    isHidden: false,
    iconContent: <LoopIcon style={{ height: "1rem", width: "1rem" }} />,
  },
];
