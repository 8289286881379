import React, { useEffect, useState, useRef } from "react";
import { ActionButtonList } from "./constants";
import Autosuggest, { contextType } from "react-autosuggest";
import _, { last, set, xor } from "lodash";
import jsonData from "./actions.json";
import backIcon from "../../../assets/back-button-logo.svg";
import CircularProgress from "@mui/material/CircularProgress";
import "./codeGenerator.css";
import atsymbol from "../../../assets/@symbol.svg";
import cross from "../../../assets/failStatus.svg";
import gbutton from "../../../assets/G-button.svg";
import lbutton from "../../../assets/lbutton.svg";
import sbutton from "../../../assets/synthetic.svg";
import backbutton from "../../../assets/back-button-logo.svg";
import tickbutton from "../../../assets/tick-icon.svg";
import crossbutton from "../../../assets/cross-icon.svg";
import "./editCard.css";
import { connect } from "react-redux";
import { fetchLocalData } from "../../Data/DataAction/dataAction";
import { toast } from "react-toastify";
import { fetch_local } from "../../../services/dataService";
import {
  actionSuggestions,
  getInstruction,
  isInFixedLabelData,
} from "./TsSuggestionsData/data.js";
import { act } from "react";
import refreshIcon from "../../../assets/refresh.svg";
import LoopDropdown from "./dropdowns/loopDropdrown/loopDropdown.js";
import {
  setConditionActive,
  setExistingGroups,
} from "../Testaction/cardsActions.js";
import { doesLoopInstrTypeExist } from "./dropdowns/data/loop.js";
import { fetchAllFiles } from "../../../services/assetServices.js";
import GroupDropdown from "./dropdowns/groupDropdown/groupDropdown.js";
import { doesGroupInstrTypeExist } from "./dropdowns/data/group.js";
import SyntheticDataModal from "../Modals/tsSyntheticData/tsSyntheticData.js";
import { Modal, Form, Input, Select, Button, Tabs } from "antd";

import deleteIcon from "../../../assets/delete_icon.svg";

import { ReactComponent as GIcon } from "../../../assets/g.svg";
import { ReactComponent as LIcon } from "../../../assets/l.svg";

import dataDropDownArrow from "../../../assets/data-dropdown-arrow.svg";
import {
  fetch_existing_group_by_id,
  fetch_existing_groups,
  updateTestcaseUrl,
} from "../../../services/testCaseServices.js";
import { useParams } from "react-router-dom";
import { doesIfInstrTypeExist } from "./dropdowns/data/if.js";
import IfDropdown from "./dropdowns/ifDropdown/ifDropdown.js";
import ElseIfDropdown from "./dropdowns/elseIfDropdown/elseIfDropdown.js";
import { doesElseIfInstrTypeExist } from "./dropdowns/data/elseIf.js";
import ApiModal from "./modals/ApiModal.js";
import ScriptModal from "./modals/scriptModal/scriptModal.js";
import { Translate } from "@mui/icons-material";
// import { fetchGlobalData } from "../../Data/DataAction/dataAction";

// Function to escape regex characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

// Function to get regex for matching anywhere
function getRegexAnywhere(val) {
  return new RegExp(`${val}`, "i");
}

// Function to filter matching actions
function getMatchingActions(value, data) {
  const escapedValue = escapeRegexCharacters(value.trim());
  if (escapedValue === "") {
    return [];
  }
  const regex = getRegexAnywhere(escapedValue);

  return data.filter((action) => regex.test(action.name));
}

// Function to sort matches
function sortMatches(matchesArr, query) {
  return matchesArr
    .sort((a, b) => {
      const matches1 = _.startsWith(a.name, query);
      const matches2 = _.startsWith(b.name, query);
      if (!matches1 && matches2) return true;
      else if (matches1 && !matches2) return false;
      return a.name < b.name ? -1 : +(a.name > b.name);
    })
    .slice(0, 4);
}

// Function to get suggestion value
function getSuggestionValue(suggestion) {
  return suggestion.name;
}

// SubActionDropdown Component
const SubActionDropdown = ({ subActions, onSelectSubAction, position }) => {
  return (
    <div
      className="subaction-dropdown"
      style={{ top: position.top, left: position.left }}
    >
      <ul>
        {subActions.map((subAction, index) => (
          <li key={index} onClick={() => onSelectSubAction(subAction)}>
            {subAction}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Buttons = ({ button, optionController, className = "" }) => {
  return (
    <button
      key={button.id}
      className={`button ${className}`}
      // ${button.isSelected ? "btn-active" : ""}
      onClick={() => optionController(button)}
    >
      {button.iconContent}
      {button.title}
    </button>
  );
};

const EditCard = ({
  addingStepsOn,
  editingStepsOn,
  setAddingStepsOn,
  setEditingStepsOn,
  handleCancel,
  handleDeleteStep,
  handleDeleteGroupStep,
  groupStepDeleting,
  addCard,
  handleAddGroupCard,
  handleAddingStepInGroupWithArrow,
  updateCard,
  editId = "",
  card,
  cards,
  //text,
  //setText,
  //secondInput,
  //setSecondInput = "",
  isEditing,
  onSave,
  onGenerate,
  placeholder = "Step",
  className,
  onCancel,

  ifActive,
  setIfActive,
  groupActive,
  setGroupActive,
  loopActive,
  setLoopActive,
  conditionActive,
  setConditionActive,

  onBack,

  waitforaddInBetStepAck,
  inBetStepId,
  value2,
  fetchLocalData,
  localData,

  globalRefresh,
  globalDataKeys,
  triggerGlobalRefresh,
  refreshingGlobal,

  triggerLocalRefresh,
  localDataCol,
  refreshingLocal,

  disableLoop,
  disableGroup,
  enableLoop,
  enableGroup,
  linkedLocalDataId,

  handleAddStepInBetween,
  handleConditionPop,

  groupUpdating,
  setGroupUpdating,

  handleApiSendClick,
  handleApiSaveClick,

  restApiOpen,
  openingRestApi,
  handleOpenRestApiModal,
  handleCloseRestApiModal,
  apiModaleData,
  sendMessage,
  received502,
  setReceived502,

  scriptModalOpen,
  handleOpenScriptModal,
  handleCloseScriptModal,

  setExistingGroups,
  existingGroups,

  openingScript,

  handleCompileClick,
}) => {
  const { id } = useParams();

  const [selectedOption, setSelectedOption] = useState("");
  const actionButtonList = ActionButtonList.slice();
  const [text, setText] = useState("");
  const [tempText, setTempText] = useState("");
  const [tempDataText, setTempDataText] = useState("");
  const [dataText, setDataText] = useState("");
  const [dataText2, setDataText2] = useState("");
  const [showDataInput, setShowDataInput] = useState(false);

  const [showSecondInput, setShowSecondInput] = useState(false);
  const [showIntellisense, setShowIntellisense] = useState(false);
  const [actionClicked, setActionClicked] = useState("");
  const [sentenceDivs, setSentenceDivs] = useState([]);
  const [showGlobalOptions, setShowGlobalOptions] = useState(false);
  const [showLocalOptions, setShowLocalOptions] = useState(false);
  const [showSyntheticDataModal, setShowSyntheticDataModal] = useState(false);
  const [addSyntheticDataTo, setAddSyntheticDataTo] = useState(null);
  const [resetVisible, setresetVisible] = useState(false);
  const [inputVisible, setinputVisible] = useState(true);
  const [sentenceDivsVisible, setSentenceDivsVisible] = useState(false);

  const textContainerRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cache, setCache] = useState({ suggestions: [] });
  const [selectedMainAction, setSelectedMainAction] = useState(null);
  const [showSubActions, setShowSubActions] = useState(false);
  const [directInstr, setDirectInstr] = useState("");
  const [subActionsPosition, setSubActionsPosition] = useState({
    top: 0,
    left: 0,
  });

  // const [textVisibility, setT]
  const textVisibilityRef = useRef(false);

  const setInitialState = (
    labelText = "",
    labelType = 0,
    contextText = "",
    contextType = 0,
    activeText = 0
  ) => ({
    labelText,
    labelType,
    contextText,
    contextType,
    activeText,
  });

  // normal:0
  // global_data:1
  // local_data:2
  // save_data:3
  const setInitialDataState = () => ({
    data_type: 0,
    value: "",
    save_data_name: "",
  });

  const currentTextClass = useRef(null);

  const [dataValue, setDataValue] = useState(setInitialState());

  const [DataPosition, setDataPosition] = useState({ top: 0, left: 0 });
  const wrapperRef = useRef(null);

  const [selected, setSelected] = useState(
    value2 || { label: placeholder || "Step", value2: "" }
  );

  const [showDataOptions, setShowDataOptions] = useState(false);
  const [showDataOptionsData, setShowDataOptionsData] = useState(false);
  const [showGlobalOptionsData, setShowGlobalOptionsData] = useState(false);

  const [showDayDropdown, setShowDayDropdown] = useState(false);
  const [showLocalDataDropdown, setShowLocalDataDropdown] = useState(false);
  const [isGlobalDataSelected, setIsGlobalDataSelected] = useState(false);
  const [isLocalDataSelected, setIsLocalDataSelected] = useState(false);
  const [isNewGlobalDataSelected, setIsNewGlobalDataSelected] = useState(false);
  const [secondInput, setSecondInput] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredDays, setFilteredDays] = useState([]);
  const [filteredLocalCol, setFilteredLocalCol] = useState([]);
  const [filteredKeys, setFilteredKeys] = useState([]);
  const [showEditDiv, setShowEditDiv] = useState(false);
  const [showDatabar, setShowDatabar] = useState(false);
  const [localDataColumns, setLocalDataColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gicon, setgicon] = useState(false);
  const [finalSentence, setFinalSentence] = useState("");

  const [showFiles, setShowFiles] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);

  const [addingGroup, setAddingGroup] = useState(false);

  const [isBackPossible, setIsBackPossible] = useState(false);
  const [isNextPossible, setIsNextPossible] = useState(false);
  const [currentStepId, setCurrentStepId] = useState(
    editingStepsOn || addingStepsOn
  );

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const apiUrl = process.env.REACT_APP_API_URL;

  const [activeInstrType, setActiveInstrType] = useState(null);
  const currentVariables = useRef([]);
  const dataType = useRef(0);
  const activeAction = useRef(null);

  const [okDisabled, setOkDisabled] = useState(false);
  const [scriptOpen, setScriptOpen] = useState(false);

  const [restApi] = Form.useForm();
  //   const [activeTab, setActiveTab] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const getAllAssets = async () => {
    const assetFiles = await fetchAllFiles();
    if (assetFiles && assetFiles.length) setFilesList(assetFiles);
  };

  useEffect(() => {
    getAllAssets();
  }, []);

  useEffect(() => {
    if (activeInstrType !== null || editingStepsOn) {
      setOkDisabled(false);
    } else if (directInstr && selectedOption === "Group") {
      setOkDisabled(false);
    } else {
      setOkDisabled(true);
    }
  }, [activeInstrType, directInstr]);

  useEffect(() => {
    if (editingStepsOn && card.inst) {
      // Handle Loop and Group instructions
      const handleInstrType = (title) => {
        const object = actionButtonList.find(
          (action) => action.title === title
        );
        if (object) optionController(object);
      };

      if (doesLoopInstrTypeExist(card.inst.instr_type)) handleInstrType("Loop");
      if (doesGroupInstrTypeExist(card.inst.instr_type))
        handleInstrType("Group");

      // Reset and show UI elements
      setActionClicked("");
      setresetVisible(true);
      setSentenceDivsVisible(true);
      textVisibilityRef.current = false;

      // Set text and active instruction type
      setText(card.inst.instr || "");
      setActiveInstrType(card.inst.instr_type || 0);

      if (card.inst.instr_type === 300) activeAction.current = "ai";

      // Handle data text
      if (typeof card.data === "string") {
        setDataText(card.data);
      } else if (card.data.value && card.data.value.length) {
        card.data.data_type === 4
          ? setDataText2(card.data.value)
          : setDataText(card.data.value);
      }

      // Handle save data name
      if (card.data.save_data_name && card.data.save_data_name.length) {
        if (card.data.data_type === 4) {
          setDataText(card.data.save_data_name);
        } else {
          setDataText2(card.data.save_data_name);
          setIsNewGlobalDataSelected(true);
        }
      }

      // Handle data type
      if (card.data.data_type) {
        dataType.current = card.data.data_type;
        switch (card.data.data_type) {
          case 1:
            setIsGlobalDataSelected(true);
            break;
          case 2:
            setIsLocalDataSelected(true);
            break;
          case 4:
            activeAction.current = "upload";
            break;
          default:
            break;
        }
      }

      // Handle labels
      if (card.inst.label_type === 1)
        handleGlobalOptionClick(card.inst.label, "label");
      if (card.inst.contextual_label_type === 1)
        handleGlobalOptionClick(card.inst.contextual_label, "context");

      // Set data value based on instruction type
      if (doesLoopInstrTypeExist(card.inst.instr_type)) {
        setDataValue(
          setInitialState(
            card.inst.x_label,
            card.inst.x_label_type,
            card.inst.y_label,
            card.inst.y_label_type
          )
        );
      } else if (isInFixedLabelData(card.inst.instr_type)) {
        setDataValue(
          setInitialState(
            card.inst.data.value,
            card.inst.data.data_type,
            card.inst.expected_value.value,
            card.inst.expected_value.data_type
          )
        );
      } else {
        setDataValue(
          setInitialState(
            card.inst.label,
            card.inst.label_type,
            card.inst.contextual_label,
            card.inst.contextual_label_type
          )
        );
      }
    }
  }, [editingStepsOn]);

  // console.log("DATAVALUE", dataValue);

  // Global

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch_local();
        if (!response || !response.data) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        // const globalDataKeys = data.GlobalDatas.map((item) => item.key);
        // setGlobalDataKeys(globalDataKeys);
        // console.log(data);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (showGlobalOptions || showDayDropdown) {
      setFilteredDays(
        globalDataKeys.filter((key) =>
          key.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else if (showLocalOptions || showLocalDataDropdown) {
      setFilteredLocalCol(
        localDataCol.filter((key) =>
          key.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else if (showFiles) {
      setFilteredFiles(
        filesList.filter((file) =>
          file.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue]);

  const inputRef = useRef(null);
  const autoRef = useRef(null);
  const DataRef = useRef(null);
  // const selectedOptionRef = useRef()
  /**
   *  Control option based on user actions
   * @param {object} option
   */

  const handleFocused = () => {
    if (actionClicked === "") {
      setShowIntellisense(true);
    } else {
      setShowIntellisense(false);
    }
  };
  function optionController(button) {
    if (button.isDisabled || button.isHidden) return;
    // Rest will continue
    handleReset();
    setIsNextPossible(false);

    setSelectedOption(
      (previous) =>
        (previous = button.title === selectedOption ? "" : button.title)
    );
    textVisibilityRef.current = false;
    // setConditionActive(button.title);
    // Pass the selected value if required
  }

  // back and next functionality
  const checkBackNextPossibility = () => {
    let isBack = false;
    let isNext = false;

    // Memoize findCardById function
    const memoFindCardById = memoize((id, cards) => {
      for (const card of cards) {
        if (card.step_id === id) {
          return card;
        }
        if (card.sub_steps && card.sub_steps.length > 0) {
          const subCard = memoFindCardById(id, card.sub_steps);
          if (subCard) {
            return subCard;
          }
        }
      }
      return null;
    });

    const getParentId = (stepId) => {
      const steps = stepId.split(".");
      steps.pop();
      return steps.join(".");
    };

    const getPreviousCardId = (stepId) => {
      const steps = stepId.split(".");
      const lastSegment = parseInt(steps.pop(), 10);
      if (lastSegment > 1) {
        steps.push((lastSegment - 1).toString());
        return steps.join(".");
      }
      return null;
    };

    if (addingStepsOn) {
      const parentId = getParentId(addingStepsOn);
      const parentCard = memoFindCardById(parentId, cards);

      if (parentCard?.sub_steps?.length > 0) {
        const lastSubstep =
          parentCard.sub_steps[parentCard.sub_steps.length - 1];
        if (lastSubstep.step_id === addingStepsOn) {
          isBack = true;
        }
      }

      // New condition for isNext
      const isSpecialCard = memoFindCardById(addingStepsOn, cards);
      if (doesGroupInstrTypeExist(isSpecialCard.inst.instr_type)) {
        isNext = true;
      }
      if (doesLoopInstrTypeExist(isSpecialCard.inst.instr_type)) {
        isNext = true;
      }
    }

    if (editingStepsOn) {
      const parentId = getParentId(editingStepsOn);
      const parentCard = memoFindCardById(parentId, cards);

      if (parentId && parentCard?.sub_steps?.length > 0) {
        const lastSubstep =
          parentCard.sub_steps[parentCard.sub_steps.length - 1];
        if (lastSubstep.step_id === editingStepsOn) {
          isBack = true;
        }
      }

      const currentCard = memoFindCardById(editingStepsOn, cards);
      const previousCardId = getPreviousCardId(editingStepsOn);
      const previousCard = memoFindCardById(previousCardId, cards);

      if (
        currentCard &&
        previousCard &&
        !doesLoopInstrTypeExist(currentCard.inst.instr_type) &&
        (doesLoopInstrTypeExist(previousCard.inst.instr_type) ||
          doesGroupInstrTypeExist(previousCard.inst.instr_type)) &&
        !(
          doesGroupInstrTypeExist(currentCard.inst.instr_type) &&
          doesGroupInstrTypeExist(previousCard.inst.instr_type)
        )
      ) {
        isNext = true;
      }
    }

    setIsBackPossible(isBack);
    setIsNextPossible(isNext);
  };

  // Memoization helper function
  const memoize = (fn) => {
    const cache = new Map();
    return (...args) => {
      const key = JSON.stringify(args);
      if (cache.has(key)) {
        return cache.get(key);
      }
      const result = fn(...args);
      cache.set(key, result);
      return result;
    };
  };

  useEffect(() => {
    checkBackNextPossibility();
  }, [currentStepId, addingStepsOn, editingStepsOn, cards]);

  const handleBackClick = () => {
    // Memoized function to find a card by ID
    const memoizedFindCardById = (id, cards) => {
      const cache = new Map();
      const findCardById = (id, cards) => {
        if (cache.has(id)) return cache.get(id);
        for (const card of cards) {
          if (card.step_id === id) {
            cache.set(id, card);
            return card;
          }
          if (card.sub_steps && card.sub_steps.length > 0) {
            const subCard = findCardById(id, card.sub_steps);
            if (subCard) {
              cache.set(id, subCard);
              return subCard;
            }
          }
        }
        cache.set(id, null);
        return null;
      };
      return findCardById(id, cards);
    };

    // Create new card data based on current inputs
    const createNewCardData = () => {
      let newCardData = {
        instr_type: activeInstrType,
        instr: text.replaceAll("/", "") || "",
        label_type: dataValue.labelType || 0,
        label: dataValue.labelText.replaceAll('"', "") || "",
      };

      if (dataValue.contextText && dataValue.contextText.length) {
        console.log("contextText", dataValue.contextText);
        newCardData.contextual_label =
          dataValue.contextText.replaceAll('"', "") || "";
        newCardData.contextual_label_type = dataValue.contextType || 0;
      }

      if (doesLoopInstrTypeExist(activeInstrType)) {
        newCardData.x_label = dataValue.labelText;
        newCardData.x_label_type = dataValue.labelType;
        newCardData.y_label = dataValue.contextText;
        newCardData.y_label_type = dataValue.contextType;
      } else if (isInFixedLabelData(activeInstrType)) {
        newCardData.data = {
          value: dataValue.labelText,
          data_type: dataValue.labelType,
        };
        newCardData.expected_value = {
          value: dataValue.contextText,
          data_type: dataValue.contextType,
        };
      }

      const currentActionSuggestions =
        actionSuggestions[activeAction.current] || [];
      const currentSuggestion = currentActionSuggestions.find(
        (suggestion) => suggestion.instr_type === activeInstrType
      );
      if (currentSuggestion?.variables?.find((v) => v.name === "checkbox")) {
        newCardData.element_type = "checkbox";
      }

      return newCardData;
    };

    // Create new data JSON object
    const createNewDataJson = () => ({
      data_type: dataType.current,
      value: dataType.current === 4 ? dataText2 : dataText,
      save_data_name:
        dataType.current === 4 ? dataText : dataText2 === "" ? null : dataText2,
    });

    // Handle the back click logic
    if (addingStepsOn) {
      const steps = addingStepsOn.split(".");
      const isEmptyInput =
        !text && !dataValue.labelText && !dataValue.contextText;

      if (isEmptyInput && steps.length > 1) {
        // Update adding steps on for outer most loop/group
        if (steps.length === 2) {
          setAddingStepsOn(null);
        } else {
          // Update adding steps on for inner loop/group
          steps.pop();
          setAddingStepsOn(steps.join("."));
        }
      } else {
        const newCardData = createNewCardData();
        const newDataJson = createNewDataJson();

        steps.pop();
        const newStepId = steps.join(".");
        addCard(newCardData, newDataJson, newStepId);

        let newStepIdForAdding;
        if (newStepId.includes(".")) {
          const parts = newStepId.split(".");
          const lastSegment = parseInt(parts.pop(), 10) + 1;
          parts.push(lastSegment);
          newStepIdForAdding = parts.join(".");
          setAddingStepsOn(newStepIdForAdding);
        } else {
          setAddingStepsOn(null);
        }
      }
    } else if (editingStepsOn) {
      const steps = editingStepsOn.split(".");
      const newCardData = createNewCardData();
      const newDataJson = createNewDataJson();

      const currentCard = memoizedFindCardById(editingStepsOn, cards);
      const substeps = currentCard ? currentCard.sub_steps : [];

      steps.pop();
      const newStepId = steps.join(".");
      handleDeleteStep(editingStepsOn);


      // Add the parent card first
      addCard(newCardData, newDataJson, newStepId);

      // Add substeps to the new parent step
      // substeps.forEach((substep, index) => {
      //   const newSubstepId = `${Number(newStepId) + 1}.${index}`;
      //   addCard(substep.inst, substep.data, newSubstepId);
      // });

      let newStepIdForAdding;
      if (newStepId.includes(".")) {
        const parts = newStepId.split(".");
        const lastSegment = parseInt(parts.pop(), 10) + 1;
        parts.push(lastSegment);
        newStepIdForAdding = parts.join(".");
        setAddingStepsOn(newStepIdForAdding);
      } else {
        setAddingStepsOn(null);
      }
    }

    handleConditionPop();

    setEditingStepsOn(null);
  };

  const handleNextClick = () => {
    const memoize = (fn) => {
      const cache = new Map();
      return (...args) => {
        const key = JSON.stringify(args);
        if (cache.has(key)) return cache.get(key);
        const result = fn(...args);
        cache.set(key, result);
        return result;
      };
    };

    const memoizedFindCardById = memoize((id, cards) => {
      for (const card of cards) {
        if (card.step_id === id) {
          return card;
        }
        if (card.sub_steps && card.sub_steps.length > 0) {
          const subCard = memoizedFindCardById(id, card.sub_steps);
          if (subCard) {
            return subCard;
          }
        }
      }
      return null;
    });

    const getParentStepId = (stepId) => {
      const parts = stepId.split(".");
      parts[parts.length - 1] = (
        Number(parts[parts.length - 1]) - 1
      ).toString();
      return parts.join(".");
    };

    const createNewCardData = () => {
      let newCardData = {
        instr_type: activeInstrType,
        instr: text.replaceAll("/", "") || "",
        label_type: dataValue.labelType || 0,
        label: dataValue.labelText.replaceAll('"', "") || "",
      };

      if (dataValue.contextText && dataValue.contextText.length) {
        newCardData.contextual_label =
          dataValue.contextText.replaceAll('"', "") || "";
        newCardData.contextual_label_type = dataValue.contextType || 0;
      }

      if (doesLoopInstrTypeExist(activeInstrType)) {
        newCardData.x_label = dataValue.labelText;
        newCardData.x_label_type = dataValue.labelType;
        newCardData.y_label = dataValue.contextText;
        newCardData.y_label_type = dataValue.contextType;
      } else if (isInFixedLabelData(activeInstrType)) {
        newCardData.data = {
          value: dataValue.labelText,
          data_type: dataValue.labelType,
        };
        newCardData.expected_value = {
          value: dataValue.contextText,
          data_type: dataValue.contextType,
        };
      }

      const currentActionSuggestions =
        actionSuggestions[activeAction.current] || [];
      const currentSuggestion = currentActionSuggestions.find(
        (suggestion) => suggestion.instr_type === activeInstrType
      );
      if (currentSuggestion?.variables?.find((v) => v.name === "checkbox")) {
        newCardData.element_type = "checkbox";
      }

      return newCardData;
    };

    const createNewDataJson = () => ({
      data_type: dataType.current,
      value: dataType.current === 4 ? dataText2 : dataText,
      save_data_name:
        dataType.current === 4 ? dataText : dataText2 === "" ? null : dataText2,
    });

    if (addingStepsOn) {
      const currentCard = memoizedFindCardById(addingStepsOn, cards);

      const isEmptyInput =
        !text && !dataValue.labelText && !dataValue.contextText;

      if (isEmptyInput) {
        return toast.error("Please Enter Instructions");
      }


      if (currentCard) {
        const subStepsLength = currentCard.sub_steps
          ? currentCard.sub_steps.length
          : 0;
        const newSubStepId =
          subStepsLength > 0
            ? `${addingStepsOn}.${subStepsLength}`
            : `${addingStepsOn}.0`;
        const newIdToAddOn =
          subStepsLength > 0
            ? `${addingStepsOn}.${subStepsLength + 1}`
            : `${addingStepsOn}.1`;

        const newCardData = createNewCardData();
        const newDataJson = createNewDataJson();

        if (doesGroupInstrTypeExist(currentCard.inst.instr_type)) {
          handleAddingStepInGroupWithArrow({
            newIdToAddOn,
            newCardData,
            newDataJson,
            newSubStepId,
          });
        } else {
          addCard(newCardData, newDataJson, newSubStepId);
          handleAddStepInBetween(newIdToAddOn);
        }

        // let pauseAddingStepOn = true;
        // handleAddStepInBetween(newIdToAddOn, pauseAddingStepOn);
        // addCard(newCardData, newDataJson, newSubStepId);
        // setAddingStepsOn(newIdToAddOn);
      }
      return;
    }

    if (editingStepsOn) {
      const currentActionSuggestions =
        actionSuggestions[activeAction.current] || [];
      const currentSuggestion = currentActionSuggestions.find(
        (suggestion) => suggestion.instr_type === activeInstrType
      );

      const newCardData = createNewCardData();

      if (currentSuggestion?.variables?.find((v) => v.name === "checkbox")) {
        newCardData.element_type = "checkbox";
      }

      const newDataJson = createNewDataJson();

      const parentStepId = getParentStepId(editingStepsOn);
      const currentCard = memoizedFindCardById(editingStepsOn, cards);
      const substeps = currentCard ? currentCard.sub_steps : [];
      const parentCard = memoizedFindCardById(parentStepId, cards);
      const subStepsLength = parentCard ? parentCard.sub_steps.length : 0;
      const newSubStepId = `${parentStepId}.${subStepsLength}`;

      const incrementedSubStepId = newSubStepId
        .split(".")
        .map((part, index, array) =>
          index === array.length - 1 ? (Number(part) + 1).toString() : part
        )
        .join(".");

      substeps.forEach((substep, index) => {
        const newSubstepId = `${incrementedSubStepId}.${index}`;
        addCard(substep.inst, substep.data, newSubstepId);
      });
      handleDeleteStep(editingStepsOn);

      if (doesGroupInstrTypeExist(parentCard.inst.instr_type)) {
        handleAddingStepInGroupWithArrow({
          newIdToAddOn: incrementedSubStepId,
          newCardData,
          newDataJson,
          newSubStepId,
        });
      } else {
        addCard(newCardData, newDataJson, newSubStepId);
        handleAddStepInBetween(incrementedSubStepId);
      }



      // let pauseAddingStepOn = true;
      // handleAddStepInBetween(incrementedSubStepId, pauseAddingStepOn);
      // addCard(newCardData, newDataJson, newSubStepId);
      // setEditingStepsOn(null);
    }
  };

  // Memoization helper function

  const handleEditOpenWebsite = async (instr) => {
    try {
      const response = await updateTestcaseUrl(id, instr);

      return true;
    } catch (error) {
      toast.error("Error updating URL");
      return false;
    }
  };
  const handleAddApiCard = async (data) => {
    handleAddCard(data);
    handleApiSaveClick(data);
  };

  const handleAddScriptCard = async (data) => {
    handleAddCard(data);
  };

  const apiInstructions = [600, 601, 602, 603, 604];
  const doesFixedLabelDataExist = isInFixedLabelData(activeInstrType);

  const fetchGroup = async () => {
    try {
      const response = await fetch_existing_groups();
      setExistingGroups(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddCard = async (ApiData) => {
    // console.log("ATAAA", dataValue);
    if (selectedOption == "Group") {
      await fetchGroup();
      if (existingGroups.length) {
        // console.log("entered", existingGroups);
        for (let i = 0; i < existingGroups.length; i++) {
          if (existingGroups[i].name === text) {
            toast.error("Group name already exists");
            return;
          }
        }
      }
    }

    // for adding api card
    if (ApiData.instr_type && apiInstructions.includes(ApiData.instr_type)) {
      const newCardData = ApiData;
      const newDataJson = {
        data_type: 0,
        value: "",
        save_data_name: "",
      };
      if (addingStepsOn) {
        addCard(newCardData, newDataJson, addingStepsOn);
      } else {
        addCard(newCardData, newDataJson);
      }

      return;
    }

    // for adding script card
    if (ApiData.instr_type && ApiData.instr_type === 700) {
      // console.log("ApiDatac", ApiData);
      const newCardData = ApiData;
      const newDataJson = {
        data_type: 0,
        value: "",
        save_data_name: "",
      };
      if (addingStepsOn) {
        addCard(newCardData, newDataJson, addingStepsOn);
      } else {
        addCard(newCardData, newDataJson);
      }

      return;
    }

    // console.log("Adding Steps on", text, dataValue);
    const currentActionSuggestions =
      actionSuggestions[activeAction.current] || [];
    const currentSuggestion = currentActionSuggestions.find(
      (suggestion) => suggestion.instr_type === activeInstrType
    );

    if (
      (dataValue.labelText.replaceAll('"', "") == "" ||
        dataValue.contextText.replaceAll('"', "") == "") &&
      doesFixedLabelDataExist
    ) {
      toast.error("Instruction cannot be empty");
      return;
    }

    const hasVariables = currentSuggestion && currentSuggestion.variables;
    const hasAction = currentSuggestion && currentSuggestion.action;
    const hasTarget = currentSuggestion && currentSuggestion.target;

    if (doesLoopInstrTypeExist(activeInstrType)) {
      // Check for loop instruction type and handle "X" and "Y"
      const xIndex = text.indexOf("X");
      const yIndex = text.indexOf("Y");

      if (
        (xIndex !== -1 && !dataValue.labelText) ||
        (yIndex !== -1 && !dataValue.contextText)
      ) {
        toast.error(
          "Loop instruction must contain both X and Y variables with respective values."
        );
        return;
      }

      const newCardData = {
        instr_type: activeInstrType,
        instr: text.replaceAll("/", "") || "",
        x_label: dataValue.labelText.replaceAll('"', "") || "",
        x_label_type: dataValue.labelType || null,
        y_label: dataValue.contextText.replaceAll('"', "") || "",
        y_label_type: dataValue.contextType || null,
      };

      const newDataJson = {
        data_type: dataType.current,
        value: dataText,
        save_data_name: dataText2 === "" ? null : dataText2,
      };

      if (editingStepsOn) {
        updateCard(
          editingStepsOn,
          newCardData,
          newDataJson,
          card?.inst?.label === newCardData.label ? card.status : 5,
          card.ordinal,
          card.sub_steps ? card.sub_steps : [],
          card.group_id ? card.group_id : null,
          card.group_hash ? card.group_hash : null,
          card.message ? card.message : null
        );
      } else if (addingStepsOn) {
        addCard(newCardData, newDataJson, addingStepsOn);
        return; // Exit the function after adding the card
      } else {
        addCard(newCardData, newDataJson);
      }
    } else if (selectedOption == "Group" && directInstr) {
      const newCardData = {
        instr_type: 400,
        instr: directInstr,
      };

      const newDataJson = {
        data_type: 0,
        value: "",
        save_data_name: "",
      };

      if (editingStepsOn) {
        updateCard(
          editingStepsOn,
          newCardData,
          newDataJson,
          card?.inst?.label === newCardData.label ? card.status : 5,
          card.ordinal,
          card.sub_steps ? card.sub_steps : [],
          card.group_id ? card.group_id : null,
          card.group_hash ? card.group_hash : null,
          card.message ? card.message : null
        );
      } else if (addingStepsOn) {
        addCard(newCardData, newDataJson, addingStepsOn);
        return;
      } else {
        addCard(newCardData, newDataJson);
      }
    } else if (hasVariables || editingStepsOn) {
      const textCount = (text.match(/Text/g) || []).length;
      let flag = false;
      if (textCount === 2) {
        if (dataValue.labelText.length && dataValue.contextText.length)
          flag = true;
      } else if (textCount === 1) {
        if (dataValue.labelText.length) flag = true;
      }

      if (
        (!flag || activeAction == null) &&
        !editingStepsOn &&
        !doesFixedLabelDataExist
      ) {
        // console.log("YYYYYYYYYYY", flag, activeAction);
        toast.error("Instruction cannot be empty");
        return;
      }
      // console.log("ACTIVE", activeInstrType);

      const cardStructure = getInstruction(activeInstrType);
      // console.log("", cardStructure);

      const newCardData = {
        instr_type: activeInstrType,
        instr:
          cardStructure.instr
            .replaceAll("contextual_label", '"Text"')
            .replaceAll("label", '"Text"') || "",
      };

      if (doesFixedLabelDataExist) {
        newCardData.data = {
          data_type: dataValue.labelType || 0,
          value: dataValue.labelText.replaceAll('"', "") || "",
          save_data_name: null,
        };
      } else {
        newCardData.label_type = dataValue.labelType || 0;
        newCardData.label = dataValue.labelText.replaceAll('"', "") || "";
      }

      if (
        currentSuggestion &&
        currentSuggestion.variables.find((v) => v.name === "checkbox")
      ) {
        newCardData.element_type = "checkbox";
      }

      if (
        dataValue.contextText &&
        dataValue.contextText.length &&
        !doesFixedLabelDataExist
      ) {
        newCardData.contextual_label =
          dataValue.contextText.replaceAll('"', "") || "";
        newCardData.contextual_label_type = dataValue.contextType || 0;
      }

      if (
        dataValue.contextText &&
        dataValue.contextText.length &&
        doesFixedLabelDataExist
      ) {
        newCardData.expected_value = {
          data_type: dataValue.contextType || 0,
          value: dataValue.contextText.replaceAll('"', "") || "",
          save_data_name: null,
        };
      }

      const newDataJson = {
        data_type: dataType.current,
        value: dataType.current === 4 ? dataText2 : dataText,
        save_data_name:
          dataType.current === 4
            ? dataText
            : dataText2 === ""
              ? null
              : dataText2,
      };

      if (editingStepsOn) {
        // api call for editing open website instr

        // if user try to add in between a step when is in a condition, then pop the condition
        for (const condition of conditionActive) {
          handleConditionPop();
        }

        if (activeInstrType === 0) {
          // console.log("EDITING", activeInstrType);
          const success = await handleEditOpenWebsite(newDataJson.value);
          if (!success) {
            return;
          }
        }

        updateCard(
          editingStepsOn,
          newCardData,
          newDataJson,
          card?.inst?.label === newCardData.label ? card.status : 5,
          card.ordinal,
          card.sub_steps ? card.sub_steps : [],
          card.group_id ? card.group_id : null,
          card.group_hash ? card.group_hash : null,
          card.message ? card.message : null
        );
      } else if (addingStepsOn) {
        addCard(newCardData, newDataJson, addingStepsOn);
        return; // Exit the function after adding the card
      } else {
        addCard(newCardData, newDataJson);
      }
    } else {
      const newCardData = {
        instr_type: activeInstrType,
        instr: text.replaceAll("/", ""),
      };

      if (hasAction) {
        newCardData.action = currentSuggestion.action;
      }
      if (hasTarget) {
        newCardData.target = currentSuggestion.target;
      }

      if (doesFixedLabelDataExist) {
        newCardData.data = {
          data_type: dataValue.labelType || 0,
          value: dataValue.labelText.replaceAll('"', "") || "",
        };
        newCardData.expected_value = {
          data_type: dataValue.contextType || 0,
          value: dataValue.contextText.replaceAll('"', "") || "",
        };
      }

      const newDataJson = {
        data_type: dataType.current,
        value: dataText,
        save_data_name: dataText2 === "" ? null : dataText2,
      };

      if (addingStepsOn) {
        addCard(newCardData, newDataJson, addingStepsOn);
        return; // Exit the function after adding the card
      }

      addCard(newCardData, newDataJson);
    }

    if (dataText2 !== "") {
      // console.log("global should refresh");
    }
    setSelectedOption("");
    handleReset();
    setDataText("");
    setDataText2("");
    setIsGlobalDataSelected(false);
    setIsNewGlobalDataSelected(false);
    setDataValue(setInitialState());
    setShowDatabar(false);
    dataType.current = 0;
    activeAction.current = null;
    textVisibilityRef.current = true;
    triggerGlobalRefresh();
  };

  const handleClear = () => {
    setText("");
    setDataText("");
  };

  useEffect(() => {
    if (text) {
      const keywords = ["open", "enter", "select", "upload"];
      const shouldShowDataInput = keywords.some((keyword) =>
        text.toLowerCase().startsWith(keyword)
      );
      const setValue = shouldShowDataInput ? true : false;
      setShowDataInput(setValue);
    } else {
      setShowDataInput(false);
    }
  }, [text]);

  useEffect(() => {
    if (editId !== "") {
      // console.log(card);
      setText(card.instr);
      setDataText(card.data);
    }
  }, [editId]);

  const handleLastWordClick = (className) => {
    if (activeAction.current !== "ai") setShowDataOptions(true);
    triggerGlobalRefresh();

    currentTextClass.current = className;
    // console.log("Current Text Class", currentTextClass.current);
    // console.log("Class Name", className);
  };

  const handleDataOptionClick = (option) => {
    //setShowDataInput(false);
    // console.log("OptionData", option);
    if (option === "Global") {
      //   setIsGlobalDataSelected(true);
      // setShowDayDropdown(true);
      // setShowDataOptionsData(false);

      setShowDataOptionsData(false);
      setShowGlobalOptions(true);
      triggerGlobalRefresh();
    } else if (option === "Local") {
      setShowDataOptionsData(false);
      setShowLocalOptions(true);
      triggerLocalRefresh();
    } else {
      // Handle other options (e.g., "Local")
      // You can perform any action here based on the selected option
      setShowDataOptions(false); // Hide the options after selection if needed
    }
  };

  const handleSyntheticDataClick = (option) => {
    setShowSyntheticDataModal(true);

    if (option === "data") {
      setAddSyntheticDataTo("data");
      setShowDataOptionsData(false);
    } else if (option === "instruction") {
      setAddSyntheticDataTo("instruction");
      setShowDataOptions(false);
    }
  };

  const handleSyntheticCancel = () => {
    setShowSyntheticDataModal(false);
    setAddSyntheticDataTo(null);
  };

  const handleLoopOptionClick = (value, textClass) => {
    let keyData = "";
    let keyType = "";

    if (textClass === "x_label" && value.length) {
      keyType = "xLabelType";
      keyData = "xLabelText";
    } else if (textClass === "y_label" && value.length) {
      keyType = "yLabelType";
      keyData = "yLabelText";
    }

    if (keyData.length && keyType.length) {
      setDataValue((prevInputs) => ({
        ...prevInputs,
        [keyData]: value,
        [keyType]: 1,
      }));
    }

    const updateTextElement = (valueText) => {
      setTimeout(() => {
        const currentTextElements = document.querySelectorAll(
          `.word.sp-e${"." + textClass}`
        );
        if (currentTextElements.length > 0) {
          const lastCurrentTextElement =
            currentTextElements[currentTextElements.length - 1];
          lastCurrentTextElement.innerHTML = valueText;
          lastCurrentTextElement.classList.add("selected-color");
        }
      }, 0);
    };

    updateTextElement(value);
    setTempText(value);
    setShowGlobalOptions(false);
    setShowDataOptions(false);
  };

  const handleGlobalOptionClick = (day, textClass) => {
    let keyData = "";
    let keyType = "";
    if (textClass === "label" && day.length) {
      keyType = "labelType";
      keyData = "labelText";
    } else if (textClass === "context" && day.length) {
      keyType = "contextType";
      keyData = "contextText";
    }

    if (keyData.length && keyType.length) {
      setDataValue((prevInputs) => ({
        ...prevInputs,
        [keyData]: day,
        [keyType]: 1,
      }));
    }
    // console.log("Day", textClass);
    const updateTextElement = (dayText) => {
      setTimeout(() => {
        const currentTextElements = document.querySelectorAll(
          `.word.sp-e${"." + textClass}`
        );
        if (currentTextElements.length > 0) {
          const lastCurrentTextElement =
            currentTextElements[currentTextElements.length - 1];
          lastCurrentTextElement.innerHTML = dayText;
          lastCurrentTextElement.classList.add("selected-color");
        }
      }, 0);
    };

    updateTextElement(day);
    setTempText(day);
    setShowGlobalOptions(false);
    setShowLocalOptions(false);
    setShowDataOptions(false);
  };
  const handleLocalOptionClick = (day, textClass) => {
    let keyData = "";
    let keyType = "";
    if (textClass === "label" && day.length) {
      keyType = "labelType";
      keyData = "labelText";
    } else if (textClass === "context" && day.length) {
      keyType = "contextType";
      keyData = "contextText";
    }

    if (keyData.length && keyType.length) {
      setDataValue((prevInputs) => ({
        ...prevInputs,
        [keyData]: day,
        [keyType]: 2,
      }));
    }
    // console.log("Day", textClass);
    const updateTextElement = (dayText) => {
      setTimeout(() => {
        const currentTextElements = document.querySelectorAll(
          `.word.sp-e${"." + textClass}`
        );
        if (currentTextElements.length > 0) {
          const lastCurrentTextElement =
            currentTextElements[currentTextElements.length - 1];
          lastCurrentTextElement.innerHTML = dayText;
          lastCurrentTextElement.classList.add("selected-local-color");
        }
      }, 0);
    };

    updateTextElement(day);
    setTempText(day);
    setShowGlobalOptions(false);
    setShowLocalOptions(false);
    setShowDataOptions(false);
  };

  const handleGlobalOptionDataClick = (day) => {
    if (day === "Wednesday") {
      // const lastIndex = text.lastIndexOf("Text");
      // setText(text.substring(0, lastIndex) + "Wednesday" + text.substring(lastIndex + 4));

      setTimeout(() => {
        const currentTextElements = document.querySelectorAll(".word.sp-e");
        if (currentTextElements.length > 0) {
          const lastCurrentTextElement =
            currentTextElements[currentTextElements.length - 1];
          lastCurrentTextElement.innerHTML = '"Wednesday"';
          lastCurrentTextElement.classList.add("selected-color");
        }
      }, 0);
    } else if (day === "Monday") {
      setTimeout(() => {
        const currentTextElements = document.querySelectorAll(".word.sp-e");
        if (currentTextElements.length > 0) {
          const lastCurrentTextElement =
            currentTextElements[currentTextElements.length - 1];
          lastCurrentTextElement.innerHTML = '"Monday"';
          lastCurrentTextElement.classList.add("selected-color");
        }
      }, 0);
    } else if (day === "Sunday") {
      setTimeout(() => {
        const currentTextElements = document.querySelectorAll(".word.sp-e");
        if (currentTextElements.length > 0) {
          const lastCurrentTextElement =
            currentTextElements[currentTextElements.length - 1];
          lastCurrentTextElement.innerHTML = '"Sunday"';
          lastCurrentTextElement.classList.add("selected-color");
        }
      }, 0);
    } else {
      // Handle other options if needed
    }
    setShowGlobalOptionsData(false);
    setShowDataOptionsData(false);
  };

  const handleDivClick = (className, instr_type, variables) => {
    // console.log("Instr Type", instr_type);
    // console.log("hdc", className, instr_type, variables);

    const divElement = document.querySelector(`.${className}`);
    // console.log("divElement", divElement);
    if (divElement) {
      setText(divElement.innerText);
      setActionClicked(""); // commented
      // textVisibilityRef.current = false;
      if (activeAction.current === "upload") {
        setShowFiles(true);
      }
    }
    setresetVisible(true);
    // console.log("Variables", instr_type);
    setActiveInstrType(instr_type);
    currentVariables.current = variables;
    setSentenceDivsVisible(true);
  };

  const handleGroupDropdownClick = async (group_id, name) => {
    setAddingGroup(true);

    try {
      const newCardData = {
        inst: {
          instr_type: 400,
          instr: name,
        },
        data: {
          data_type: 0,
          value: "",
          save_data_name: "",
        },
        group_id: group_id,
      };

      if (addingStepsOn) {
        handleAddGroupCard(newCardData, addingStepsOn);
      } else {
        handleAddGroupCard(newCardData);
      }
    } catch (error) {
      toast.error("Failed to update group");
    } finally {
      setSelectedOption("");
      textVisibilityRef.current = false;
    }

    setAddingGroup(false);
  };

  const handleChange = (newWord, index) => {
    let name;

    if (index <= 2 || (index === 3 && activeAction.current === "upload")) {
      name = "labelText";
    } else if (index > 2) {
      name = "contextText";
    }
    // switch (index) {

    //   case 2:
    //     name = "labelText";
    //     break;
    //   case 4:
    //     name = "contextText";
    //     break;
    // }

    // setTextInputs((prevInputs) => ({
    //   ...prevInputs,
    //   [name]: newWord,
    // }));

    if (name === "labelText" && doesFixedLabelDataExist) {
      return;
    }
    setDataValue((prevInputs) => ({
      ...prevInputs,
      [name]: newWord,
    }));

    const words = selected.label.split(" ");
    words[index] = newWord;
    const newLabel = words.join(" ");
    setSelected({ ...selected, label: newLabel });
  };

  useEffect(() => {
    if (typeof text === "string") {
      const keywords = ["open", "enter", "select", "upload"];
      const shouldShowSecondInput = keywords.some((keyword) =>
        text.toLowerCase().startsWith(keyword)
      );
      setShowSecondInput(shouldShowSecondInput);
      if (!shouldShowSecondInput) {
        // Reset second input when keywords are removed
        setSecondInput("");
      }
    } else {
      setShowSecondInput(false);
      setSecondInput("");
    }
  }, [setShowSecondInput, text]);

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      (filteredDays.length > 0 || filteredLocalCol.length > 0)
    ) {
      setDataText(filteredDays[0]);
      setSearchValue("");
      setShowDayDropdown(false);
      setShowLocalDataDropdown(false);
      setShowFiles(false);
    }
  };

  useEffect(() => {
    const keywords = [
      '"Text"',
      '"Textbox"',
      "var_name",
      '"Attribute"',
      '"X"',
      '"Y"',
      '"some_data"',
      '"expected_value"',
      card && card.inst && card.inst.label ? `"${card.inst.label}"` : '\\" \\"',
    ];

    const sentences = text.split(".");
    const divs = sentences.map((sentence, index) => {
      const words = sentence
        .split(" ")
        .map((word) => word.trim().replace(/[.,!?]/g, ""));

      // Track keyword occurrences
      let keywordIndices = words.reduce((acc, word, wordIndex) => {
        if (keywords.includes(word)) {
          acc.push(wordIndex);
        }
        return acc;
      }, []);

      return (
        <div
          key={index}
          className="sentence"
        //onClick={() => setShowIntellisense(true)}
        >
          {words.map((word, wordIndex) => {
            const isTargetWord = keywords.includes(word);
            const isLastWord = wordIndex === words.length - 1;

            // Determine if the word should have the label or context class
            let wordClass = "";
            if (isTargetWord) {
              if (
                keywordIndices.length === 1 &&
                keywordIndices[0] === wordIndex
              ) {
                wordClass = "label";
                if (editingStepsOn) {
                  word = `"${dataValue.labelText}"`;
                }
              } else if (keywordIndices.length > 1) {
                if (keywordIndices[0] === wordIndex) {
                  wordClass = "label";
                  if (editingStepsOn) {
                    word = `"${dataValue.labelText}"`;
                  }
                } else if (keywordIndices[1] === wordIndex) {
                  wordClass = "context";
                  if (editingStepsOn) {
                    word = `"${dataValue.contextText}"`;
                  }
                }
              }
            }

            return (
              <span
                key={wordIndex}
                className={`word ${isTargetWord ? "sp-e" : ""} ${wordClass}`}
              >
                <EditableText
                  dataValue={dataValue}
                  setDataValue={setDataValue}
                  setInitialState={setInitialState}
                  text={text}
                  setText={setText}
                  word={word}
                  wordClass={wordClass}
                  onChange={(newWord) => handleChange(newWord, wordIndex)}
                  isLastWord={isLastWord}
                  onLastWordClick={() => handleLastWordClick(wordClass)}
                  setShowDataOptions={setShowDataOptions}
                  activeAction={activeAction}
                />
              </span>
            );
          })}
        </div>
      );
    });
    setSentenceDivs(divs);
  }, [text]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check if the key pressed is 'g' and data options are open
      if (event.key === "g" && showDataOptions) {
        handleDataOptionClick("Global");
      }
    };

    // Add event listener
    document.addEventListener("keypress", handleKeyPress);

    // Cleanup function
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [showDataOptions]); // Run effect when dataOptionsOpen changes

  // Initialize cache with JSON data
  useEffect(() => {
    setCache({ suggestions: jsonData });
    //autoRef.current.autoFocus = "true";
    //autoRef.current.focus();
    //setShowIntellisense(true);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSubActions(false);
        // setText("");
        setSecondInput("");
        setSentenceDivsVisible(true);
        // autoRef.current.focus();
        // setresetVisible(false);
        setShowGlobalOptions(false);
        setShowDataOptions(false);
        setShowIntellisense(false);
        // setActiveInstrType(null);
        currentVariables.current = [];
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const loadSuggestions = (val) => {
    setIsLoading(true);
    setSuggestions(
      sortMatches(getMatchingActions(val, cache.suggestions), val)
    );
    setIsLoading(false);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (!selectedOption) {
      loadSuggestions(value);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (evt, { suggestion }) => {
    setSelectedMainAction(suggestion);
    //setShowSubActions(true);
    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    setSubActionsPosition({
      top: wrapperRect.bottom + window.scrollY,
      left: wrapperRect.left,
    });
    const suggestionName = suggestion.name.toLowerCase();
    setActionClicked(suggestionName);
    textVisibilityRef.current = true;
  };

  const onSelectSubAction = (subAction) => {
    setSelections([...selections, { ...selectedMainAction, name: subAction }]);
    setSelectedMainAction(null); // Close the main action dropdown
    setShowSubActions(false); // Close the subactions dropdown
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const handleInputChange = (e, { newValue }) => {
    setActionClicked("");
    setText(newValue);
    setShowDataOptionsData(false);
    setShowDataOptions(false);
    setShowGlobalOptions(false);
    textVisibilityRef.current = false;

    if (selectedOption) {
      setDirectInstr(newValue);
      // console.log("OOO", newValue);
    }
  };

  useEffect(() => {
    // console.log("DirectInstr", directInstr);
  }, [directInstr]);

  const handleDataTextClick = () => {
    const dataRect = DataRef.current.getBoundingClientRect();
    setDataPosition({
      top: dataRect.bottom + window.scrollY,
      left: dataRect.left,
    });
    setShowDataOptionsData(!showDataOptionsData);
    triggerGlobalRefresh();
    //setIsGlobalDataSelected(true);
  };

  const handleDataTextSelected = () => {
    // console.log("GLB");
    setIsGlobalDataSelected(true);
    setShowDayDropdown(true);
    triggerGlobalRefresh();
    setShowDataOptionsData(false);
  };

  const handleDataTextLocalSelected = () => {
    // console.log("LCL");
    setIsLocalDataSelected(true);
    setShowLocalDataDropdown(true);
    triggerLocalRefresh();
    setShowDataOptionsData(false);
  };

  const handleDataOptionDataSelected = (option) => {
    //setShowDataInput(false);
    if (option === "Global") {
      setIsGlobalDataSelected(true);
    } else {
      setIsGlobalDataSelected(false);
    }
    setShowDataOptionsData(false); // Hide the dropdown
  };

  const handleDoubleClick = () => {
    //setActionClicked("");
    //setShowDayDropdown(false);
    //setShowGlobalOptions(false);
    setShowIntellisense(false);
    setShowDataOptionsData(false);
    setShowDataOptions(true);
    triggerGlobalRefresh();
  };

  // Function to render suggestion
  function renderSuggestion(suggestion, { query }) {
    const regexp = new RegExp(`^(.*?)(${query})(.*)$`, "i");
    let matches = getSuggestionValue(suggestion).match(regexp);
    if (!matches || matches.length < 3) return null;
    if (matches) {
      matches.shift();
      matches[1] = <b key="bold">{matches[1]}</b>;
    } else {
      matches = suggestion.name;
    }

    //setShowIntellisense(true);

    return (
      <div>
        <span className="suggestion">
          <span className="name">{matches}</span>
        </span>
      </div>
    );
  }

  const handleReset = () => {
    setText("");
    setSecondInput("");
    setSentenceDivsVisible(false);
    autoRef.current.focus();
    setresetVisible(false);
    setShowGlobalOptions(false);
    setShowDataOptions(false);
    setShowIntellisense(true);
    setActiveInstrType(null);
    currentVariables.current = [];

    setIsGlobalDataSelected(false);
    setIsLocalDataSelected(false);
    setDataText("");
    setDataText2("");
    setDataValue(setInitialState());
    setShowDatabar(false);
    dataType.current = 0;
    activeAction.current = null;
  };

  const renderActionSuggestions = () => {
    //setShowIntellisense(false);
    const actions = [
      "click",
      "enter",
      "verify",
      "hover",
      "hit",
      "assert",
      "select",
      "upload",
      "open",
      "switch",
      "ai",
      // "checkbox",
    ];
    return (
      <div className="values-of-suggestions">
        <div className="container-of-values" style={{ paddingBottom: "10px" }}>
          <div className="info-text-cont">
            <div> Actions</div>
            <img
              onClick={() => setShowIntellisense(false)}
              className="c-img"
              src={cross}
              alt="close"
            />
          </div>
          <div className="info-data">
            {actions.map((action) => (
              <div
                key={action}
                onClick={() => {
                  setActionClicked(action);
                  setShowIntellisense(false);
                  textVisibilityRef.current = true;
                }}
                className="suggestion-box"
              >
                <img src={atsymbol} alt="" />
                <div className={action}>
                  {action === "ai"
                    ? "AI"
                    : action.charAt(0).toUpperCase() + action.slice(1)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSuggestions = (action, setShowIntellisense) => {
    activeAction.current = action;
    const trimmedAction = actionSuggestions[action];
    return (
      <div className="container-of-values-d">
        <img
          src={backbutton}
          alt="back"
          onClick={() => {
            setActionClicked("");
            setShowIntellisense(true);
            textVisibilityRef.current = false;
          }}
        />
        {trimmedAction.map((act, i) => (
          <div key={i} className="suggestion-box">
            <img src={atsymbol} alt="" />
            <div
              onClick={() =>
                handleDivClick(act.clickValue, act.instr_type, act.variables)
              }
              className={act.clickValue}
              dangerouslySetInnerHTML={{ __html: act.instr }}
            ></div>
          </div>
        ))}
      </div>
    );
  };

  const handleBlueGClick = () => {
    // setShowEditDiv(true);
    setShowDatabar(true);
    dataType.current = 3;
  };

  // will fetch all local data
  const handleLocalDataClick = () => {
    setIsLoading(true);
    fetch_local()
      .then((data) => {
        // console.log("LocalData", data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching local data:", error);
        setIsLoading(false);
      });

    // use localData to display the data
  };

  // on clicking one of the localDatas
  const onLocalDataItemClick = (id) => {
    handleLocalDataItemClick(id);
  };

  // will fetch column in local data and sets the localDataColumns
  const handleLocalDataItemClick = (id) => {
    fetch_local()
      .then((data) => {
        setLocalDataColumns(data.columns);
      })
      .catch((error) => {
        // console.error("Error fetching local data by id:", error);
      });
  };

  const clickOpenRestApiModal = () => {
    if (!openingRestApi) {
      handleOpenRestApiModal();
    }
  };

  // useEffect(()=>{
  //   triggerGlobalRefresh()
  //   console.log("fetchhhh")
  // })

  const onFinishRestApi = (values) => {
    // Add your form submission logic here
    handleCloseRestApiModal();
  };

  const handleResetRestApi = () => {
    restApi.resetFields();
  };
  const [showError, setShowError] = useState(false);
  const checkGlobalDataKeys = (value) => {
    if (value.length > 1) {
      if (globalDataKeys.includes(value)) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else {
      setShowError(false); // Hide error if the value has 1 or fewer characters
    }
  };

  return (
    <div className="edit-card" ref={wrapperRef}>
      {groupUpdating && (
        <div className="updating-group">
          <CircularProgress
            style={{ width: "15px", height: "15px", color: "#1D86FF" }}
          />
          <p style={{ fontSize: "12px", color: "#1D86FF", margin: "0" }}>
            Updating group details
          </p>
        </div>
      )}

      <div className="edit-actions">
        {/* {actionButtonList.map((button) => (
          <Buttons
            key={button.id}
            button={button}
            className={selectedOption === button.title ? "btn-active" : ""}
            optionController={optionController}
          />
        ))} */}
        {actionButtonList
          .filter((button) => {
            const includesMoreThanTwoDots = (str) =>
              (str.match(/\./g) || []).length > 1;
            const includesOneDot = (str) =>
              (str.match(/\./g) || []).length === 1;
            const getLeftOfDot = (str) => (str || "").split(".")[0];

            const addingStepsParentId = getLeftOfDot(addingStepsOn);
            const editingStepsParentId = getLeftOfDot(editingStepsOn);
            const addingStepsParentInstrType = cards.find(
              (card) => card.step_id === addingStepsParentId
            )?.inst.instr_type;
            const editingStepsParentInstrType = cards.find(
              (card) => card.step_id === editingStepsParentId
            )?.inst.instr_type;
            // console.log("DIABLEGROUP", disableGroup);
            if (
              button.title === "Loop" &&
              (disableLoop ||
                (addingStepsOn || "").includes(".") ||
                (editingStepsOn || "").includes(".") ||
                includesOneDot(addingStepsOn || "") ||
                doesLoopInstrTypeExist(addingStepsParentInstrType))
            ) {
              return false;
            } else if (
              button.title === "Group" &&
              (disableGroup ||
                includesMoreThanTwoDots(addingStepsOn || "") ||
                includesMoreThanTwoDots(editingStepsOn || "") ||
                (includesOneDot(addingStepsOn || "") &&
                  doesGroupInstrTypeExist(addingStepsParentInstrType)) ||
                (includesOneDot(editingStepsOn || "") &&
                  doesGroupInstrTypeExist(editingStepsParentInstrType)))
            ) {
              return false;
            } else if (button.title === "IF") {
              return false;
            } else if (button.title === "ELSE IF") {
              return false;
            } else if (button.title === "ELSE") {
              return false;
            }
            return true;
          })
          .map((button) => (
            <Buttons
              key={button.id}
              button={button}
              className={selectedOption === button.title ? "btn-active" : ""}
              optionController={optionController}
            />
          ))}
      </div>
      <div className="edit-container edit">
        {selectedOption.length > 0 && (
          <div
            style={{
              color:
                selectedOption === "IF"
                  ? "#1D86FF"
                  : selectedOption === "ELSE"
                    ? "#1D86FF"
                    : selectedOption === "Group"
                      ? "#72BE47"
                      : selectedOption === "Loop"
                        ? "#F59E0B"
                        : "",
            }}
            // #F59E0B
            className="edit-option"
          >
            {selectedOption}
          </div>
        )}
        <div className="edit-input">
          {/* <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Step"
          /> */}
          <div className="input-containers">
            {!waitforaddInBetStepAck ? (
              <div>
                {/* {sentenceDivs.map((div, index) => div)} */}
                {sentenceDivsVisible && sentenceDivs}

                <div className="wrapper">
                  <section className="wrapper-input">
                    {isLoading ? (
                      <span className="loading-spinner"></span>
                    ) : null}
                    <form>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        onSuggestionSelected={onSuggestionSelected}
                        //shouldRenderSuggestions={handleDoubleClick}
                        inputProps={{
                          className: "input-field auto-suggest",
                          style: {
                            color: textVisibilityRef.current
                              ? "white"
                              : "black",
                            // selectedOption === "Group" ? "black" : "white",
                          },
                          placeholder: "Step",
                          value: text,
                          onChange: handleInputChange,
                          onKeyDown: handleKeyDown,
                          onDoubleClick: handleDoubleClick,
                          onFocus: handleFocused,
                          ref: autoRef,
                          //autoFocus: true,
                        }}
                      />
                      {resetVisible && (
                        <button
                          className="close-iconx"
                          type="reset"
                          onClick={() => {
                            handleReset();
                          }}
                        >
                          <img
                            className="c-img"
                            src={cross}
                            alt="close"
                            onClick={() => {
                              handleReset();
                            }}
                          />
                        </button>
                      )}
                    </form>
                    {showDataOptions && (
                      <div
                        className="values-of-suggestions"
                        style={{ marginTop: "0" }}
                      >
                        <div className="container-of-values additional">
                          <div className="additional-row-head">Options</div>
                          <div className="additional-row">
                            <img src={gbutton} />
                            <button
                              onClick={() => handleDataOptionClick("Global")}
                            >
                              Global Data
                            </button>
                          </div>
                          {linkedLocalDataId && (
                            <div className="additional-row">
                              <img
                                src={lbutton}
                                style={{ width: "25px" }}
                                alt=""
                              />
                              <button
                                onClick={() => handleDataOptionClick("Local")}
                              >
                                Local Data
                              </button>
                            </div>
                          )}

                          {/* Synthetic Data instruction dropdown */}
                          {/* <div className="additional-row">
                            <img
                              src={sbutton}
                              style={{ width: "25px" }}
                              alt=""
                            />
                            <button
                              onClick={() => handleSyntheticDataClick("instruction")}
                            >
                              Synthetic Data
                            </button>
                          </div> */}
                        </div>
                      </div>
                    )}
                    {showGlobalOptions && (
                      <div className="values-of-suggestions">
                        <div
                          className="container-of-values additional"
                          style={{ paddingTop: "24px" }}
                        >
                          {/* <div className="additional-row-head">Global</div> */}
                          <div style={{ position: "relative" }}>
                            <input
                              className="input-field"
                              type="text"
                              value={searchValue}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => setSearchValue(e.target.value)}
                              placeholder="Search global data"
                              style={{
                                marginBottom: "5px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "5px",
                                borderRadius: "4px",
                                width: "calc(100% - 33px)",
                                color: "black",
                              }}
                            />
                            <img
                              width={14}
                              src={refreshIcon}
                              className={`refresh-icon ${refreshingGlobal && "rotate"
                                }`}
                              alt="refresh"
                              onClick={() => triggerGlobalRefresh()}
                            />
                          </div>
                          {searchValue &&
                            filteredDays.map((key) => (
                              <div
                                key={key}
                                className="additional-row searched"
                              >
                                <img src={gbutton} alt={key} />
                                <button
                                  onClick={() =>
                                    handleGlobalOptionClick(
                                      key,
                                      currentTextClass.current
                                    )
                                  }
                                >
                                  {key}
                                </button>
                              </div>
                            ))}
                          {globalDataKeys.map((key) => (
                            <div key={key} className="additional-row">
                              {console.log("hey", key)}
                              <img src={gbutton} alt={key} />
                              <button
                                onClick={() =>
                                  handleGlobalOptionClick(
                                    key,
                                    currentTextClass.current
                                  )
                                }
                              >
                                {key}
                              </button>
                            </div>
                          ))}
                          {/* {globalDataKeys.map((key) => (
                            <div key={key} className="additional-row">
                              <img src={gbutton} alt={key} />
                              <button
                                onClick={() => {
                                  handleGlobalOptionClick(key);
                                }}
                              >
                                {key}
                              </button>
                            </div>
                          ))} */}
                          <div className="additional-row hidden">
                            <img src={gbutton} />
                            <button
                              onClick={() =>
                                handleGlobalOptionClick("Wednesday")
                              }
                            >
                              Wednesday
                            </button>
                          </div>
                          <div className="additional-row hidden">
                            <img src={gbutton} />
                            <button
                              onClick={() => handleGlobalOptionClick("Monday")}
                            >
                              Monday
                            </button>
                          </div>
                          <div className="additional-row hidden">
                            <img src={gbutton} />
                            <button
                              onClick={() => handleGlobalOptionClick("Sunday")}
                            >
                              Sunday
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {showLocalOptions && (
                      <div className="values-of-suggestions">
                        <div
                          className="container-of-values additional"
                          style={{ paddingTop: "24px" }}
                        >
                          {/* <div className="additional-row-head">Global</div> */}
                          <div style={{ position: "relative" }}>
                            <input
                              className="input-field"
                              type="text"
                              value={searchValue}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => setSearchValue(e.target.value)}
                              placeholder="Search local data"
                              style={{
                                marginBottom: "5px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "5px",
                                borderRadius: "4px",
                                width: "calc(100% - 33px)",
                                color: "black",
                              }}
                            />
                            <img
                              width={14}
                              src={refreshIcon}
                              className={`refresh-icon ${refreshingLocal && "rotate"
                                }`}
                              alt="refresh"
                              onClick={() => triggerLocalRefresh()}
                            />
                          </div>
                          {searchValue &&
                            filteredLocalCol.map((key) => (
                              <div
                                key={key}
                                className="additional-row searched"
                              >
                                <img src={lbutton} alt={key} />
                                <button
                                  onClick={() =>
                                    handleLocalOptionClick(
                                      key,
                                      currentTextClass.current
                                    )
                                  }
                                >
                                  {key}
                                </button>
                              </div>
                            ))}
                          {localDataCol.map((key) => (
                            <div key={key} className="additional-row">
                              <img src={lbutton} alt={key} />
                              <button
                                onClick={() =>
                                  handleLocalOptionClick(
                                    key,
                                    currentTextClass.current
                                  )
                                }
                              >
                                {key}
                              </button>
                            </div>
                          ))}
                          {/* {globalDataKeys.map((key) => (
                            <div key={key} className="additional-row">
                              <img src={gbutton} alt={key} />
                              <button
                                onClick={() => {
                                  handleGlobalOptionClick(key);
                                }}
                              >
                                {key}
                              </button>
                            </div>
                          ))} */}
                          <div className="additional-row hidden">
                            <img src={gbutton} alt="" />
                            <button
                              onClick={() =>
                                handleGlobalOptionClick("Wednesday")
                              }
                            >
                              Wednesday
                            </button>
                          </div>
                          <div className="additional-row hidden">
                            <img src={gbutton} alt="" />
                            <button
                              onClick={() => handleGlobalOptionClick("Monday")}
                            >
                              Monday
                            </button>
                          </div>
                          <div className="additional-row hidden">
                            <img src={gbutton} />
                            <button
                              onClick={() => handleGlobalOptionClick("Sunday")}
                            >
                              Sunday
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {showSyntheticDataModal && (
                      <SyntheticDataModal
                        open={showSyntheticDataModal}
                        handleClose={() => handleSyntheticCancel()}
                      />
                    )}
                  </section>
                  {showSubActions &&
                    selectedMainAction &&
                    selectedMainAction.subActions && (
                      <SubActionDropdown
                        subActions={selectedMainAction.subActions}
                        onSelectSubAction={onSelectSubAction}
                        position={subActionsPosition}
                      />
                    )}
                  {/* <section className="selections">
                  <ul>
                    {selections.map((s, index) => (
                      <li key={index}>{s.name}</li>
                    ))}
                  </ul>
                </section> */}
                </div>
                {/* {inputVisible && 
              <input
                ref={inputRef}
                type="text"
                value={text}
                onChange={(e) => {setText(e.target.value);setinputVisible(false);setSentenceDivsVisible(false);setShowIntellisense(true);autoRef.current.focus();}}
                placeholder={placeholder}
                className="input-field"
                onFocus={() => {
                  setShowIntellisense(true);
                }}
                style={{ position: "absolute", marginTop: "-37px", display: "none" }}
              />
              } */}
              </div>
            ) : (
              <p>
                {" "}
                <CircularProgress
                  style={{ height: "15px", width: "15px", color: "red" }}
                />
              </p>
            )}
            <div className="values-of-suggestions">
              {(() => {
                switch (actionClicked) {
                  case "open":
                  case "click":
                  case "enter":
                  case "verify":
                  case "hover":
                  case "hit":
                  case "assert":
                  case "select":
                  case "upload":
                  case "switch":
                  case "ai":
                    return renderSuggestions(
                      actionClicked,
                      setShowIntellisense
                    );
                  default:
                    return null;
                }
              })()}
            </div>

            {/* {actionClicked === "open" && (
              <div className="values-of-suggestions">
                {renderSuggestions("open", setShowIntellisense)}
              </div>
            )}
            {actionClicked === "click" && (
              <div className="values-of-suggestions">
                {renderSuggestions("click", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "enter" && (
              <div className="values-of-suggestions">
                {renderSuggestions("enter", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "verify" && (
              <div className="values-of-suggestions">
                {renderSuggestions("verify", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "hover" && (
              <div className="values-of-suggestions">
                {renderSuggestions("hover", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "hit" && (
              <div className="values-of-suggestions">
                {renderSuggestions("hit", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "assert" && (
              <div className="values-of-suggestions">
                {renderSuggestions("assert", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "select" && (
              <div className="values-of-suggestions">
                {renderSuggestions("select", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "upload" && (
              <div className="values-of-suggestions">
                {renderSuggestions("upload", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "switch" && (
              <div className="values-of-suggestions">
                {renderSuggestions("switch", setShowIntellisense)}
              </div>
            )}

            {actionClicked === "ai" && (
              <div className="values-of-suggestions">
                {renderSuggestions("ai", setShowIntellisense)}
              </div>
            )} */}

            {showIntellisense &&
              text === "" &&
              !selectedOption &&
              renderActionSuggestions()}
            {showIntellisense && text === "" && selectedOption == "Loop" && (
              <LoopDropdown
                handleDivClick={handleDivClick}
                handleClose={() => setShowIntellisense(false)}
              />
            )}
            {showIntellisense && text === "" && selectedOption == "Group" && (
              <GroupDropdown
                handleDivClick={handleGroupDropdownClick}
                handleClose={() => setShowIntellisense(false)}
              />
            )}
            {showIntellisense && text === "" && selectedOption == "IF" && (
              <IfDropdown
                handleDivClick={handleDivClick}
                handleClose={() => setShowIntellisense(false)}
              />
            )}
            {showIntellisense && text === "" && selectedOption == "ELSE IF" && (
              <ElseIfDropdown
                handleDivClick={handleDivClick}
                handleClose={() => setShowIntellisense(false)}
              />
            )}
            {/* {showSecondInput && (
              <div
                className="additional-data-div"
                ref={DataRef}
                position={DataPosition}
              >
                <div className="data-cont" onClick={handleDataTextClick}>
                  {" "}
                  {isGlobalDataSelected ? (
                    <span
                      style={{
                        background: "rgba(153, 26, 231, 0.45)",
                        color: "white",
                        width: "37px",
                        borderRadius: "3px",
                        textAlign: "center",
                      }}
                    >
                      G
                    </span>
                  ) : (
                    "Data"
                  )}{" "}
                </div>

                <div>
                  <input
                    type="text"
                    value={secondInput}
                    onChange={(e) => setSecondInput(e.target.value)}
                    placeholder="Additional Data"
                    className="input-field-additional"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            )} */}
          </div>
        </div>
        {/* {text.length > 0 && !showDataInput && (
          <div onClick={() => setText("")} className="edit-clear">
            Clear
          </div>
        )} */}
      </div>
      {showDataInput && (
        <div
          className="edit-container relative"
          ref={DataRef}
          position={DataPosition}
        >
          <div className="edit-inputs2">
            {activeAction.current === "upload" ? (
              <div className="data-title">
                <span
                  style={{
                    background: "#008EA1",
                    color: "white",
                    width: "37px",
                    borderRadius: "3px",
                    textAlign: "center",
                  }}
                >
                  F
                </span>
              </div>
            ) : (
              <div className="data-title" onClick={handleDataTextClick}>
                {" "}
                {isGlobalDataSelected ? (
                  <span
                    style={{
                      background: "rgba(153, 26, 231, 0.45)",
                      color: "white",
                      width: "37px",
                      borderRadius: "3px",
                      textAlign: "center",
                    }}
                  >
                    G
                  </span>
                ) : isLocalDataSelected ? (
                  <span
                    style={{
                      background: "#96A7E6",
                      color: "white",
                      width: "37px",
                      borderRadius: "3px",
                      textAlign: "center",
                    }}
                  >
                    L
                  </span>
                ) : (
                  <div className="data-dropdown-ec">
                    Data
                    <img src={dataDropDownArrow} alt="data options" />
                  </div>
                )}{" "}
              </div>
            )}

            <div className="edit-input data">
              <input
                type="text"
                value={dataText}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setIsGlobalDataSelected(false);
                    setShowDayDropdown(false);
                    activeAction.current === "upload" && setShowFiles(true);
                  }
                  setDataText(e.target.value);
                }}
                placeholder={`Add ${activeAction.current === "upload" ? "File" : "Var"
                  }`}
                onBlur={() => {
                  setIsGlobalDataSelected(false);
                  setShowDayDropdown(false);
                }}
              />
              {/* {console.log(
                "DataText",
                dataText,
                !isGlobalDataSelected,
                !isLocalDataSelected,
                !(activeAction.current === "upload")
              )} */}
              {dataText &&
                !isGlobalDataSelected &&
                !isLocalDataSelected &&
                !(activeAction.current === "upload") && (
                  <div className="g-blue-icon" onClick={handleBlueGClick}>
                    G
                  </div>
                )}

              {dataText && isNewGlobalDataSelected && (
                <div
                  onClick={() => setShowDatabar(true)}
                  style={{ cursor: "pointer" }}
                  className="g-pink-icon-absolute"
                >
                  G
                </div>
              )}

              {dataText && showDatabar && (
                <div className="databar" style={{}}>
                  <div className="g-pink-icon">G</div>
                  <input
                    type="text"
                    value={dataText2}
                    onChange={(e) => {
                      setDataText2(e.target.value);
                      checkGlobalDataKeys(e.target.value);
                    }}
                    placeholder=""
                    className="edit-input-databar"
                  />
                  <img
                    //onClick={() => setShowIntellisense(false)}
                    alt=""
                    className=""
                    src={tickbutton}
                    onClick={() => {
                      if (dataText2 === "" || null) {
                        setTempDataText("");
                      } else {
                        setTempDataText(dataText);
                      }
                      // console.log(dataText + "," + dataText2);
                      setShowDatabar(false);
                      setIsNewGlobalDataSelected(true);
                    }}
                  />
                  <img
                    //onClick={() => setShowIntellisense(false)}
                    className=""
                    alt=""
                    src={crossbutton}
                    onClick={() => {
                      setTempDataText("");
                      setShowDatabar(false);
                      setDataText2("");
                      dataType.current = 0;
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {showError && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                margin: 0,
                // marginBottom: "4px",
                // transform: Translate("10px"),
                position: "absolute",
                top: "100%",
                left: "150px",
                whiteSpace: "nowrap",
              }}
            >
              Key already exists
            </p>
          )}
          {/* <p>hello</p> */}
          {/* {text.length > 0 && (
            <div onClick={handleClear} className="edit-clear data">
              Clear
            </div>
          )} */}
          {/* <div className="edit-input-dropdowns"> */}
          {showDayDropdown && (
            <div className="values-of-suggestions">
              <div
                className="container-of-values additional"
                style={{ left: "48px", paddingTop: "16px" }}
              >
                <img
                  src={backbutton}
                  alt="back"
                  onClick={() => {
                    setIsGlobalDataSelected(false);
                    setShowDayDropdown(false);
                  }}
                  hspace="16"
                  title="Back to Normal Data"
                  vspace="6"
                />
                <div style={{ position: "relative" }}>
                  <input
                    className="input-field"
                    type="text"
                    value={searchValue}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search global data"
                    style={{
                      marginBottom: "5px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: "5px",
                      borderRadius: "4px",
                      width: "calc(100% - 40px)",
                    }}
                  />
                  <img
                    width={14}
                    src={refreshIcon}
                    className={`refresh-icon ${refreshingGlobal && "rotate"}`}
                    alt="refresh"
                    onClick={() => triggerGlobalRefresh()}
                  />
                </div>
                {searchValue &&
                  filteredDays.map((key) => (
                    <div key={key} className="additional-row searched">
                      <img src={gbutton} alt={key} />
                      <button
                        onClick={() => {
                          setDataText(key);
                          dataType.current = 1;
                          setShowDayDropdown(false);
                        }}
                      >
                        {key}
                      </button>
                    </div>
                  ))}

                {globalDataKeys.map((key) => (
                  <div key={key} className="additional-row">
                    <img src={gbutton} alt={key} />
                    <button
                      onClick={() => {
                        setDataText(key);
                        dataType.current = 1;
                        setShowDayDropdown(false);
                      }}
                    >
                      {key}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {showLocalDataDropdown && (
            <div className="values-of-suggestions">
              <div
                className="container-of-values additional"
                style={{ left: "48px", paddingTop: "16px" }}
              >
                <img
                  src={backbutton}
                  alt="back"
                  onClick={() => {
                    setIsLocalDataSelected(false);
                    setShowLocalDataDropdown(false);
                  }}
                  hspace="16"
                  title="Back to Normal Data"
                  vspace="6"
                />
                <div style={{ position: "relative" }}>
                  <input
                    className="input-field"
                    type="text"
                    value={searchValue}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search local data"
                    style={{
                      marginBottom: "5px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: "5px",
                      borderRadius: "4px",
                      width: "calc(100% - 40px)",
                    }}
                  />
                  <img
                    width={14}
                    src={refreshIcon}
                    className={`refresh-icon ${refreshingLocal && "rotate"}`}
                    alt="refresh"
                    onClick={() => triggerLocalRefresh()}
                  />
                </div>
                {searchValue &&
                  filteredLocalCol.map((key) => (
                    <div key={key} className="additional-row searched">
                      <img src={lbutton} alt={key} />
                      <button
                        onClick={() => {
                          setDataText(key);
                          dataType.current = 2;
                          setShowLocalDataDropdown(false);
                        }}
                      >
                        {key}
                      </button>
                    </div>
                  ))}

                {localDataCol.map((key) => (
                  <div key={key} className="additional-row">
                    <img src={lbutton} alt={key} />
                    <button
                      onClick={() => {
                        setDataText(key);
                        dataType.current = 2;
                        setShowLocalDataDropdown(false);
                      }}
                    >
                      {key}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {showDataOptionsData && (
            <div className="values-of-suggestions">
              <div className="container-of-values additional">
                <div className="additional-row-head">Options</div>
                <div className="additional-row">
                  <img src={gbutton} alt="" />
                  <button onClick={() => handleDataTextSelected()}>
                    Global Data
                  </button>
                </div>
                {linkedLocalDataId && (
                  <div className="additional-row">
                    <img src={lbutton} style={{ width: "25px" }} />
                    <button onClick={() => handleDataTextLocalSelected()}>
                      Local Data
                    </button>
                  </div>
                )}
                {/* Synthetic Data instruction dropdown */}
                {/* <div className="additional-row">
                  <img
                    src={sbutton}
                    style={{ width: "25px" }}
                    alt=""
                  />
                  <button
                    onClick={() => handleSyntheticDataClick("data")}
                  >
                    Synthetic Data
                  </button>
                </div> */}
              </div>
            </div>
          )}
          {showGlobalOptionsData && (
            <div className="values-of-suggestions">
              <div className="container-of-values additional">
                <div className="additional-row-head">Global</div>
                <div className="additional-row">
                  <img src={gbutton} />
                  <button
                    onClick={() => handleGlobalOptionDataClick("Wednesday")}
                  >
                    Wednesday
                  </button>
                </div>
                <div className="additional-row">
                  <img src={gbutton} />
                  <button onClick={() => handleGlobalOptionDataClick("Monday")}>
                    Monday
                  </button>
                </div>
                <div className="additional-row">
                  <img src={gbutton} />
                  <button onClick={() => handleGlobalOptionDataClick("Sunday")}>
                    Sunday
                  </button>
                </div>
              </div>
            </div>
          )}
          {showFiles && (
            <div className="values-of-suggestions">
              <div
                className="container-of-values additional"
                style={{ left: "48px", paddingTop: "16px" }}
              >
                <div style={{ position: "relative", paddingBottom: "4px" }}>
                  <input
                    className="input-field"
                    type="text"
                    value={searchValue}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search asset"
                    style={{
                      marginBottom: "5px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: "5px",
                      borderRadius: "4px",
                      width: "calc(100% - 40px)",
                    }}
                  />
                  <img
                    width={14}
                    src={refreshIcon}
                    className={`refresh-icon ${refreshingGlobal && "rotate"}`}
                    alt="refresh"
                    onClick={() => triggerGlobalRefresh()}
                  />
                </div>
                {searchValue &&
                  filteredFiles.map((file) => (
                    <div
                      key={file.id}
                      className="additional-row extra_space searched"
                    >
                      <span
                        style={{
                          background: "#008EA1",
                          color: "white",
                          width: "22px",
                          borderRadius: "3px",
                          textAlign: "center",
                        }}
                      >
                        F
                      </span>
                      <button
                        onClick={() => {
                          dataType.current = 4;
                          setDataText(file.name);
                          setDataText2(file.id);
                          setShowFiles(false);
                        }}
                        className="asset-btn"
                      >
                        <span className="asset-name">{file.name}</span>
                        <span className="asset-type">
                          {file.file_name && file.file_name.length
                            ? "." + file.file_name.split(".")[1]
                            : ""}
                        </span>
                      </button>
                    </div>
                  ))}

                {filesList.map((file) => (
                  <div key={file.id} className="additional-row extra_space">
                    <span
                      style={{
                        background: "#008EA1",
                        color: "white",
                        width: "22px",
                        borderRadius: "3px",
                        textAlign: "center",
                      }}
                    >
                      F
                    </span>
                    <button
                      onClick={() => {
                        dataType.current = 4;
                        setDataText(file.name);
                        setDataText2(file.id);
                        setShowFiles(false);
                      }}
                      className="asset-btn"
                    >
                      <span className="asset-name">{file.name}</span>
                      <span className="asset-type">
                        {file.file_name && file.file_name.length
                          ? "." + file.file_name.split(".")[1]
                          : ""}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="edit-actions-container">
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {isBackPossible && (
            <div onClick={handleBackClick}>
              <img src={backIcon} alt="back" />
            </div>
          )}
          {isNextPossible && (
            <div onClick={handleNextClick}>
              <img
                style={{ transform: "rotate(180deg)" }}
                src={backIcon}
                alt="back"
              />
            </div>
          )}
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {openingRestApi ? (
            <button className="restapi-button-open-modal">
              <div className="restapi-button-text">
                <CircularProgress
                  style={{ width: "10px", height: "10px", color: "#0036af" }}
                />
              </div>
            </button>
          ) : (
            <button
              onClick={handleOpenRestApiModal}
              className="restapi-button-open-modal"
            >
              <div className="restapi-button-text">API</div>
            </button>
          )}

          {openingScript ? (
            <button className="restapi-button-open-modal">
              <div className="restapi-button-text">
                <CircularProgress
                  style={{ width: "10px", height: "10px", color: "#0036af" }}
                />
              </div>
            </button>
          ) : (
            <button
              onClick={handleOpenScriptModal}
              className="restapi-button-open-modal"
            >
              <div className="restapi-button-text">SCR</div>
            </button>
          )}
        </div>

        <div id="modal-wrapper">
          <ApiModal
            open={restApiOpen}
            modalData={apiModaleData}
            onCancel={handleCloseRestApiModal}
            handleAddApiCard={handleAddApiCard}
            handleApiSendClick={handleApiSendClick}
            sendMessage={sendMessage}
            received502={received502}
            setReceived502={setReceived502}
          />

          <ScriptModal
            open={scriptModalOpen}
            onCancel={handleCloseScriptModal}
            handleAddScriptCard={handleAddScriptCard}
            sendMessage={sendMessage}
            received502={received502}
            setReceived502={setReceived502}
            handleCompileClick={handleCompileClick}
          />
        </div>

        <div className="edit-action-option">
          {addingStepsOn || editingStepsOn ? (
            // conditionActive == null && (
            <button
              className="edit-action-cancel edit-clear"
              onClick={handleCancel}
            >
              Cancel
            </button>
          ) : (
            // )
            <></>
          )}
          <button
            className={`edit-action-ok ${okDisabled && "disabled"} `}
            onClick={handleAddCard}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

function EditableText({
  dataValue,
  setDataValue,
  setInitialState,
  word,
  onChange,
  isLastWord,
  onLastWordClick,
  setShowDataOptions,
  activeAction,
  wordClass,
}) {
  const [innerText, setInnerText] = useState(word);
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null);
  const spanRef = useRef(null);

  const isLabel = wordClass && wordClass == "label";
  const isContextual = wordClass && wordClass == "context";

  // console.log("isLabel", isLabel, "isContextual", isContextual);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
    }
  }, [innerText]);

  const handleOnChange = (textValue) => {
    if (
      word == '"some_data"' &&
      (activeAction.current == "verify" || activeAction.current == "assert")
    ) {
      toast.error("This field can only be Global or Local data");
      return;
    }

    textValue = ensureQuotes(textValue);
    setInnerText(textValue);
    onChange(textValue);
    setShowDataOptions(false);

    setDataValue((prevState) => {
      const updatedState = { ...prevState };
      if (isLabel) {
        updatedState.labelText = textValue;
        updatedState.labelType = 0;
      } else if (isContextual) {
        updatedState.contextText = textValue;
        updatedState.contextType = 0;
      }
      return updatedState;
    });
  };

  const ensureQuotes = (text) => {
    if (!text.startsWith('"')) text = '"' + text;
    if (!text.endsWith('"')) text += '"';
    return text;
  };

  const setCursorAtPosition = (inputElement, startIndex, endIndex) => {
    if (inputElement && inputElement.focus) {
      inputElement.focus();
      inputElement.setSelectionRange(startIndex, endIndex);
    }
  };

  const handleLastWordClick = (e) => {
    setEditMode(true);
    onLastWordClick();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
        setCursorAtPosition(inputRef.current, 1, innerText.length - 1);
      }
    }, 0);

    setDataValue((prevState) => {
      const updatedState = { ...prevState };
      if (isLabel) {
        updatedState.labelType = 0;
      } else if (isContextual) {
        updatedState.contextType = 0;
      }
      return updatedState;
    });
  };

  const renderContent = () => {
    if (!editMode) {
      if (
        (isLabel && dataValue.labelType === 1) ||
        (isContextual && dataValue.contextType === 1)
      ) {
        return (
          <span className="selected-color" onClick={handleLastWordClick}>
            {word}
          </span>
        );
      } else if (
        (isLabel && dataValue.labelType === 2) ||
        (isContextual && dataValue.contextType === 2)
      ) {
        return (
          <span className="selected-local-color" onClick={handleLastWordClick}>
            {word}
          </span>
        );
      } else {
        return (
          <span
            className={activeAction.current === "ai" ? "ai-query" : ""}
            onClick={handleLastWordClick}
          >
            {word}
          </span>
        );
      }
    } else {
      return (
        <>
          <input
            className="inner-input"
            style={{
              maxWidth: activeAction.current === "ai" ? "310px" : "120px",
            }}
            type="text"
            ref={inputRef}
            value={innerText}
            onChange={(e) => handleOnChange(e.target.value)}
            autoFocus
          />
          <span ref={spanRef} className="content-hidden">
            {innerText}
          </span>
        </>
      );
    }
  };

  return renderContent();
}

const mapStateToProps = (state) => ({
  cards: state.cards.cards,
  localData: state.data.localData,
  conditionActive: state.cards.conditionActive,
  linkedLocalDataId: state.cards.linkedLocalDataId,
  existingGroups: state.cards.existingGroups,
});

const mapDispatchToProps = {
  fetchLocalData,
  setExistingGroups,
  setConditionActive,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCard);

// {/* <button
//           className="edit-action-cancel edit-clear"
//           onClick={() => handleCardAction(card.step_id, "edit")}
//         >
//           Cancel
//         </button> */}
// value={text}
// onChange={(e) => setText(e.target.value)}

// style={{
//   border: "2px solid",
//   borderColor: "#72BE47",
//   // borderColor: groupActive ? "#72BE47" : "transparent",
// }}
// onClick={() => {
//   setGroupActive(!groupActive);
//   setIfActive(false);
//   setLoopActive(false);
//   setConditionActive(!groupActive);
// }}

//handleLastWordClick --> on click on text input
// handleDataOptionClick --> after selecting global or loacal type
//handleGlobalOptionClick --> getting global data after selecting
