import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./TestingDetails.css";
import LeftNav from "../../../components/Nav/nav";
import TopNav from "../../../components/Nav/topbar/topbar";
import Linux from "./assets/linux.svg";
import MacOS from "./assets/macOS.svg";
import Windows from "./assets/windows.svg";
import ChromeLogo from "../../../assets/chrome.svg";
import MozillaFirefoxLogo from "../../../assets/firefox.svg";
import SafariLogo from "../../../assets/safari.svg";
import { executionJobsByExecutionId } from "../../../services/jobsServices";
import successStatus from "../../../assets/successStatus.svg";
import failStatus from "../../../assets/failStatus.svg";
import skippedStatus from "../../../assets/skippedStatus.svg";
import waitingStatus from "../../../assets/waitingSuccess.svg";
import CircularProgress from "@mui/material/CircularProgress";
import IndividualReport from "./individualReport";
import { setTitle } from "../../../providers/titleProvider";

// These are Enum for logos
const getSystemIcon = (systemType) => {
  switch (systemType.toLowerCase()) {
    case "linux":
      return Linux;
    case "macos":
      return MacOS;
    case "windows":
      return Windows;
    default:
      return null;
  }
};
class StepStatus {
  static SUCCESS = 0;
  static FAILED = 1;
  static WARNING = 2;
  static SKIPPED = 3;
  static IN_PROGRESS = 4;
  static NOT_STARTED = 5;
}

const getStatusIcon = (status) => {
  switch (status) {
    case StepStatus.FAILED:
      return failStatus;
    case StepStatus.SUCCESS:
      return successStatus;
    case StepStatus.WARNING:
      return waitingStatus;
    case StepStatus.SKIPPED:
      return skippedStatus;
    case StepStatus.IN_PROGRESS:
      return <CircularProgress size={12} />;
    default:
      return null;
  }
};

const getBrowserIcon = (browserType) => {
  switch (browserType.toLowerCase()) {
    case "chrome":
      return ChromeLogo;
    case "mozillafirefox":
      return MozillaFirefoxLogo;
    case "safari":
      return SafariLogo;
    default:
      return ChromeLogo;
  }
};
// sytemMode Component
const SystemModeButton = ({
  systemType,
  status,
  browser,
  selected,
  onSelect,
}) => (
  <div
    className="system-mode-button"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
      border:
        selected === systemType ? "2px solid #e0e7f5" : "2px solid transparent",
      backgroundColor: selected === systemType && "#e0e7f5",
      padding: "8px",
      cursor: "pointer",
    }}
    onClick={() => onSelect(systemType)}
  >
    <img src={getSystemIcon(systemType)} alt={systemType} />
    {status === 4 ? (
      getStatusIcon(4)
    ) : (
      <img src={getStatusIcon(status)} alt="Status" />
    )}
    {/* {console.log(status, "status")} */}
  </div>
);
// BrowserMode Component
const BrowserOption = ({
  browserType,
  version,
  status,
  job_id,
  onSelect,
  selected,
}) => (
  <div
    className="browser-option"
    style={{
      display: "flex",
      alignItems: "center",
      gap: "5px",
      borderBottom:
        selected && selected.job_id == job_id
          ? "2px solid #00299f"
          : "2px solid transparent",
      padding: "8px",
      cursor: "pointer",
    }}
    onClick={() => onSelect({ browserType, version, job_id })}
  >
    <img
      src={getBrowserIcon(browserType)}
      alt="Browser Icon"
      style={{ width: "23px", height: "16px" }}
    />
    {/* <div style={{ color: "#00008B" }}>v{version}</div> */}
    <div style={{ marginLeft: "auto" }}>
      <img
        src={getStatusIcon(status)}
        alt="Status"
        style={{ width: "18px", height: "11px" }}
      />
    </div>
  </div>
);

//  THIS IS MAIN FUNCTION //
const TestingDetails = ({}) => {
  const navigate = useNavigate();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [fetchData, setFetchData] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [selectedBrowser, setSelectedBrowser] = useState(null);
  const [Testingroutes, setTestingroutes] = useState("");

  const [tempVar, setTempVar] = useState("");

  const { exeid, jobid } = useParams();
  const [selectedjob, setSelectedJob] = useState(jobid);
  // const [job_id, setJob_id] = useState(jobid);

  useEffect(() => {
    const fetchJobsData = async (execution_id) => {
      try {
        const jsonData = await executionJobsByExecutionId(execution_id);

        setTestingroutes(jsonData.testcase_name);
        setTitle(jsonData.testcase_name, true);

        const ApiFetch = jsonData.jobs.reduce((accumulator, currentJob) => {
          // Check if the systemType exists in the accumulator
          const systemIndex = accumulator.findIndex(
            (system) => system.systemType === currentJob.platform
          );

          // If systemType not found, add it to accumulator array
          if (systemIndex === -1) {
            accumulator.push({
              systemType: currentJob.platform,
              status: currentJob.status_code, // You can set the status value here
              browser: [
                {
                  browserType: currentJob.browser,
                  version: currentJob.browser_version,
                  job_id: currentJob.job_unique_id,
                  status: 0, // You can set the status value here
                  // You can set the data value here
                },
              ],
            });
          } else {
            // Check if the browserType exists in the system
            const browserIndex = accumulator[systemIndex].browser.findIndex(
              (browser) =>
                browser.browserType === currentJob.browser &&
                browser.version === currentJob.browser_version
            );

            // If browserType not found, add it to browsers array
            if (browserIndex === -1) {
              accumulator[systemIndex].browser.push({
                browserType: currentJob.browser,
                version: currentJob.browser_version,
                job_id: currentJob.job_unique_id,
                status: 0, // You can set the status value here
                // You can set the data value here
              });
            } else {
              // If browserType already exists, update the job ID
              accumulator[systemIndex].browser[browserIndex].job_id =
                currentJob.job_unique_id;
            }
          }

          return accumulator;
        }, []);

        setFetchData(ApiFetch);
        if (selectedjob === undefined) {
          handleSystemSelect(ApiFetch[0].systemType);
          handleBrowserSelect({
            browserType: ApiFetch[0].browser[0].browserType,
            version: ApiFetch[0].browser[0].version,
            job_id: ApiFetch[0].browser[0].job_id,
          });
        } else {
          // console.log(selectedjob, "selectedjob");
          const filteredData = jsonData.jobs;
          const t = filteredData.findIndex(
            (item) => item.job_unique_id == selectedjob
          );
          // console.log(filteredData, "filteredData");
          // console.log(t, "t");
          if (t != -1) {
            handleSystemSelect(filteredData[t].platform);
            // console.log(
            //   filteredData[t].browser,
            //   "browser",
            //   filteredData[t].browser_version,
            //   "version",
            //   filteredData[t].job_unique_id,
            //   "job_id",
            //   filteredData[t].platform,
            //   "systemType"
            // );
            setSelectedJob(filteredData[t].job_unique_id);
            handleBrowserSelect(
              filteredData[t].browser,
              filteredData[t].browser_version,
              filteredData[t].job_unique_id
            );
          } else {
            handleSystemSelect(ApiFetch[0].systemType);
            handleBrowserSelect({
              browserType: ApiFetch[0].browser[0].browserType,
              version: ApiFetch[0].browser[0].version,
              job_id: ApiFetch[0].browser[0].job_id,
            });
          }
        }
        // console.log(ApiFetch, "ApiFetch");
      } catch (error) {
        console.error("Error fetching report jobs data:", error);
      }
    };
    if (exeid) {
      fetchJobsData(exeid);
    }
  }, []);

  const handleSystemSelect = (systemType) => {
    setSelectedSystem(systemType);
    setSelectedBrowser(null);
  };

  const handleBrowserSelect = ({ browserType, version, job_id }) => {
    setSelectedBrowser({ browserType, version, job_id });
    // console.log(job_id);
    if (job_id !== undefined) setSelectedJob(job_id);
    // console.log(job_id);
    // console.log(headingsByStatus);
  };

  const closeAccordion = () => {
    setTempVar(selectedBrowser);
    if (selectedBrowser) setSelectedBrowser(null);
    setIsAccordionOpen(false);
  };

  useEffect(() => {
    if (selectedjob !== undefined)
      navigate(`/report/testcase/${exeid}/${selectedjob}`, { replace: true });
    else navigate(`/report/testcase/${exeid}`, { replace: true });
  }, [selectedjob]);

  return (
    <>
      <div className="app" style={{ margin: "1rem 1rem" }}>
        <div className="main-box">
          <div>
            <div className="" style={{ fontSize: "14px", fontWeight: "400" }}>
              <span
                style={{ color: "#9E9E9E", cursor: "pointer" }}
                onClick={() => {
                  navigate("/Execution");
                }}
              >
                Reports
              </span>{" "}
              <span> / </span>{" "}
              <span style={{ color: "#616161" }}>{" " + Testingroutes}</span>
            </div>
          </div>
          <div className="testingAnalytics">
            <div className="testingAnalyticsNavbar">
              <div
                className="system-Mode"
                style={{
                  backgroundColor: "#fafafa",
                  borderRadius: "10px",
                  padding: "8px 12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  {fetchData.map(({ systemType, status, browser }) => (
                    <SystemModeButton
                      key={systemType}
                      systemType={systemType}
                      selected={selectedSystem}
                      status={status}
                      browser={browser}
                      onSelect={handleSystemSelect}
                    />
                  ))}
                </div>
              </div>
              {selectedSystem && (
                <div
                  className="tab-mode"
                  style={{
                    marginLeft: "50px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  {fetchData
                    .find((item) => item.systemType === selectedSystem)
                    .browser.map(({ browserType, version, status, job_id }) => (
                      <BrowserOption
                        key={`${browserType}-${version}`}
                        browserType={browserType}
                        selected={selectedBrowser}
                        // loopdata={data}
                        job_id={job_id}
                        onSelect={handleBrowserSelect}
                        version={version}
                        status={status}
                      />
                    ))}
                </div>
              )}
            </div>
            {selectedSystem && selectedBrowser && (
              <IndividualReport job_id={selectedjob} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TestingDetails;

// const jsonData = await executionJobsByExecutionId(execution_id);

// fetch(
//   `${apiUrl}/platform/reports/executions/${execution_id}/jobs`
// );

// if (!response.ok) {
//   throw new Error("Failed to fetch jobs data");
// }

// const jsonData = await response.json();
// console.log("jsonData", jsonData);
// console.log(jsonData.jobs, "data");
// console.log(exeid, "exeid", jobid, "jobid");
