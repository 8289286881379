import './options.css';
import option from "../../../../assets/option-icon.svg";
import { useEffect, useState } from 'react';

const Options = ({ oneRow, lastRow, lastColumn,  onOptionSelect, id, addRowAbove, addRowBelow, deleteRow, addColumnToLeft, addColumnToRight, deleteColumn,  isModalVisible, setIsModalVisible, horizontal }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.viewdata-option-container')) {
            setDropdownVisible(false);
        }
    };

    const handleOptionClick = (action) => {
        // console.log("rec", id);
        setDropdownVisible(false);
        switch (action) {
            case 'addAbove':
                addRowAbove(id);
                break;
            case 'addBelow':
                addRowBelow(id);
                break;
            case 'addColumnLeft':
                addColumnToLeft();
                break;
            case 'addColumnRight':
                addColumnToRight();
                break;
            case 'delete':
                deleteRow(id);
                break;
            case 'deleteColumn':
                deleteColumn();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [dropdownVisible]);

    const handleImgClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <div className='viewdata-option-container'>
            <img src={option} alt="option" onClick={handleImgClick} />
            {dropdownVisible && (
                <div style={{ width: `${horizontal ? "116px" : "132px"}` }} className={`viewdata-option-dropdown ${horizontal ? lastRow ? "last-row" : "horizontal" : lastColumn? "last-column": oneRow ? "vertical-uplifted":"vertical"}`}>
                    <div onClick={() =>horizontal? handleOptionClick('addAbove'):handleOptionClick('addColumnLeft') } className='viewdata-option-dropdown-item'>{horizontal ? "Add row above": "Add column left"}</div>
                    <div onClick={() => horizontal ? handleOptionClick('addBelow'):handleOptionClick('addColumnRight')} className='viewdata-option-dropdown-item'>{horizontal ? "Add row below": "Add column right"}</div>
                    <div onClick={() => horizontal ? handleOptionClick('delete'):handleOptionClick('deleteColumn')} className='viewdata-option-dropdown-item'>{horizontal ? "Delete row": "Delete column"}</div>
                </div>
            )}
        </div>
    );
}

export default Options;