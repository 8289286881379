export const elseIfActions = [
    {
        instr_type: 550,
        clickValue: "first",
        instr: 'sample condition "<span class="sp-e label">X</span>" to "<span class="sp-e context">Y</span>"',
        variables: [{ name: "x_label", idx: 2 }, { name: "y_label", idx: 4 }],
    },
    {
        instr_type: 551,
        clickValue: "second",
        instr: 'till "<span class="sp-e label">X</span>"',
        variables: [{ name: "x_label", idx: 2 }],
    },
    {
        instr_type: 552,
        clickValue: "third",
        instr: 'if'
    }
]

function getInstrTypes(actions) {
    return actions.map(action => action.instr_type);
}

const instrTypes = getInstrTypes(elseIfActions);

export function doesElseIfInstrTypeExist(type) {
    return instrTypes.includes(type);
}

