import { useState } from 'react';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './CopyCode.css'; // Ensure you have appropriate styling

const CopyCode = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="copy-code-container">
      <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
        <div onClick={handleCopy} className="copy-icon">
          {copied ? <CheckOutlined style={{ color: 'green' }} /> : <CopyOutlined />}
        </div>
      </Tooltip>
    </div>
  );
};

export default CopyCode;
