import React, { useState, useEffect } from "react";
import pause from "../../../assets/testcaseeditor/pause-icon.svg";
import stop from "../../../assets/testcaseeditor/red-pause-icon.svg";
import playStartIcon from "../../../assets/play-start.svg";
import { CircularProgress, Box, Modal } from "@mui/material";
import DeleteModal from "../../Data/components/DeleteModal";
import { useLocation, useParams } from "react-router-dom"; // Import useParams
import { useCallbackPrompt } from "../../../hooks";

const modalStyle = {
  fontFamily: "Roboto, sans-serif",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxSizing: "border-box",
  padding: "1rem",
  width: 408,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "auto",
  p: 0,
};

const innerStyleUpperButton = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: ".2rem",
  borderBottom: "1px solid #F5F5F5",
  height: "66px",
};

const StepStatus = ({
  sessionId = { sessionId },

  pausing,
  paused,
  stopLoading,
  startLoading,
  statusMessage,

  handleStopClick,
  handlePauseClick,
  generationBeginHandler,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const location = useLocation();
  const params = useParams();
  const testCaseIdPattern = /^\/Test-case\/\d+$/; // Regular expression to match "/Test-case/:id" pattern
  const isTestCaseRoute = testCaseIdPattern.test(location.pathname); // Check if current route matches "/Test-case/:id" pattern
  const [showPrompt, confirmNavigation, cancelNavigation,] = useCallbackPrompt(
    isTestCaseRoute && (sessionId !== null || showConfirmation)
  );

  const handleStartClickWithConfirmation = () => {
    //event.stopPropagation();
    if (setConfirmedNavigation === true) {
      setShowConfirmation(true);
    }
 
  };

  const handleStopClickWithConfirmation = (event) => {
    //event.stopPropagation();
    setShowConfirmation(true);
 
  };

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const handleEndSession = () => {
 
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (!isTestCaseRoute && !showPrompt) {
      cancelNavigation();
    }
  }, [isTestCaseRoute, showPrompt, cancelNavigation]);

  // useEffect(() => {
  //   if (showGenerateConfirmation) {
  //     setShowConfirmation(true);
  //   }
  // }, [showGenerateConfirmation, showConfirmation]);



  return (
    <div className="step-status">
      <div className="status-message">{statusMessage}</div>
      <div className="status-actions">
        {!sessionId ? (
          startLoading ? (
            <>
              <CircularProgress
                style={{ height: "15px", width: "15px", color: "#3498DB" }}
              />
            </>
          ) : (
            <span
              style={{
                color: "#3498db",
                background: `url(${playStartIcon}) left center no-repeat`,
                backgroundSize: "18px",
                paddingLeft: "20px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {generationBeginHandler();setConfirmedNavigation(true)}}            >
              Start
            </span>
          )
        ) : (
          <>
            {pausing && (
              <CircularProgress
                style={{ height: "15px", width: "15px", color: "#bdbdbd" }}
              />
            )}
            {!paused && !pausing && (
              <button onClick={handlePauseClick} className="cb">
                <img src={pause} alt="pause-button" />
                Pause
              </button>
            )}
            {stopLoading ? (
              <>
                <CircularProgress
                  style={{ height: "15px", width: "15px", color: "#EF4444" }}
                />
              </>
            ) : (
              <button
                //onClick={handleStopClick}
                onClick={() => handleStopClickWithConfirmation()}
                className="cb"
                style={{ color: "#EF4444" }}
              >
                <img
                  style={{ marginRight: "3px" }}
                  src={stop}
                  alt="stop-button"
                />
                Stop
              </button>
            )}
          </>
        )}
        <Modal
          open={showPrompt || showConfirmation}
          onClose={cancelNavigation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div style={innerStyleUpperButton}>
              <div style={{ marginLeft: "10px", padding: "9px" }}>
                End Session
              </div>
              <div onClick={cancelNavigation}>
                <svg
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  width="32"
                  height="26"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="6" fill="#F5F5F5" />
                  <path
                    d="M24.4166 12.876L23.1241 11.5835L17.9999 16.7077L12.8758 11.5835L11.5833 12.876L16.7074 18.0002L11.5833 23.1243L12.8758 24.4168L17.9999 19.2927L23.1241 24.4168L24.4166 23.1243L19.2924 18.0002L24.4166 12.876Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                textAlign: "left",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "left",
                height: "89px",
                color: "#616161",
                padding: "1.5rem",
                borderBottom: "1px solid #F5F5F5",
              }}
            >
              Do you want to end the session?
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                boxSizing: "border-box",
                padding: "1rem",
                paddingBottom: "0.5rem",
                paddingTop: "0.5rem",
                gap: "0.5rem",
                height: "69px",
                borderTop: "1px solid #F5F5F5",
              }}
            >
              <div
                style={{
                  padding: "8px 20px",
                  gap: "10px",
                  borderRadius: "8px",
                  border: "1px solid #eeeeee",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  cancelNavigation();
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </div>

              <div
                style={{
                  padding: "8px 20px",
                  gap: "10px",
                  borderRadius: "8px",
                  border: "1px solid #eeeeee",
                  color: "#fff",
                  cursor: "pointer",
                  background: "#EF4444",
                }}
                onClick={(event) => {
                  confirmNavigation();
                  handleStopClickWithConfirmation(event);
                  setShowConfirmation(false);
                  handleStopClick();
                }}
              >
                End
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default StepStatus;
