// StepCard.js

import React, { useState } from "react";
import EditCard from "./EditCard";

import ErrorIcon from "@mui/icons-material/Error"; // Adjust icon import based on actual usage

import { ReactComponent as FailIcon } from "../../../assets/failStatus.svg";
import { ReactComponent as InfoIcon } from "../../../assets/infoIcon.svg";

import { ReactComponent as SuccessIcon } from "../../../assets/successStatus.svg";
import { ReactComponent as DatabaseIcon } from "../../../assets/database.svg";
import { ReactComponent as PlayIcon } from "../../../assets/testcaseeditor/play-icon.svg";
import { ReactComponent as OptionIcon } from "../../../assets/testcaseeditor/option-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/testcaseeditor/trash-can-outline.svg";
import { ReactComponent as EditIcon } from "../../../assets/testcaseeditor/pencil-icon.svg";
import { ReactComponent as GIcon } from "../../../assets/g.svg";
import { ReactComponent as LIcon } from "../../../assets/l.svg";
import genAiLogo from "../../../assets/aiLogo.svg";
import newIcon from "../../../assets/newIcon.svg";

import GroupLogo from "../../../assets/testcaseeditor/group-icon.svg";
import "./stepCard.css";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress, Typography } from "@mui/material";
import Ordinal from "./ordinal/ordinal";
import { doesLoopInstrTypeExist } from "./dropdowns/data/loop";
import { connect } from "react-redux";
import { doesGroupInstrTypeExist } from "./dropdowns/data/group";
import { getInstruction, isInFixedLabelData } from "./TsSuggestionsData/data";
import { doesIfInstrTypeExist, ElseInstrType } from "./dropdowns/data/if";
import { doesElseIfInstrTypeExist } from "./dropdowns/data/elseIf";
import ApiModal from "./modals/ApiModal.js";
import ScriptModal from "./modals/scriptModal/scriptModal.js";
import { toast } from "react-toastify";

const StepCardDetails = ({ card, iconStyle }) => {
  const getColorByDataType = (dataType) => {
    switch (dataType) {
      case 1:
        return "#991AE7";
      case 2:
        return "#96A7E6";
      case 4:
        return "#008EA1";
      default:
        return "#000000";
    }
  };

  const renderIconByDataType = (dataType) => {
    switch (dataType) {
      case 1:
        return <GIcon style={iconStyle} />;
      case 2:
        return <LIcon style={iconStyle} />;
      case 4:
        return <div className="f-icon">F</div>;
      default:
        return null;
    }
  };

  if (typeof card.data === "object" && card.data.value !== "") {
    const { data_type, save_data_name, value } = card.data;
    const displayText = data_type === 4 ? save_data_name : value;
    const tooltipTitle = save_data_name || value;

    return (
      <div className="step-card-details">
        <DatabaseIcon style={iconStyle} />
        <Typography
          noWrap
          sx={{
            maxWidth: "94px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: getColorByDataType(data_type),
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {data_type === 3 ? (
              <>
                <span>{value}</span>
                <Tooltip title={tooltipTitle}>
                  <GIcon style={iconStyle} />
                </Tooltip>
              </>
            ) : (
              <>
                {data_type === 0 ? (
                  <Tooltip title={tooltipTitle}>
                    <span>{displayText}</span>
                  </Tooltip>
                ) : (
                  <>
                    {renderIconByDataType(data_type) && (
                      <Tooltip title={tooltipTitle}>
                        {renderIconByDataType(data_type)}
                      </Tooltip>
                    )}
                    <span>{displayText}</span>
                  </>
                )}
              </>
            )}
          </div>
        </Typography>
      </div>
    );
  }

  return null;
};

const StepCard = ({
  card,
  cards,
  step_id,
  level = 0,
  sessionId,

  paused,
  recordActive,

  addingStepsOn,
  setAddingStepsOn,
  handleAddStepInBetween,
  editingStepsOn,
  setEditingStepsOn,
  addCard,
  handleAddGroupCard,
  handleAddingStepInGroupWithArrow,
  handleEditStep,
  handleDeleteStep,
  handleDeleteGroupStep,
  groupStepDeleting,
  handlePlayClick,
  handleCancel,
  updateCard,
  gicon,

  globalRefresh,
  refreshingGlobal,
  globalDataKeys,
  triggerGlobalRefresh,

  triggerLocalRefresh,
  localDataCol,
  refreshingLocal,

  sendMsgForOrdinalHover,
  sendMsgForOrdinalSelect,

  conditionStepId,
  conditionActive,

  disableLoop,
  disableGroup,
  enableLoop,
  enableGroup,

  handleConditionPop,

  groupUpdating,
  setGroupUpdating,

  handleApiSendClick,
  handleApiSaveClick,

  restApiOpen,
  openingRestApi,
  handleOpenRestApiModal,
  handleCloseRestApiModal,
  apiModaleData,
  sendMessage,
  received502,
  setReceived502,
  isShowStepId = true,

  openingScript,
  setOpeningScript,

  scriptModalOpen,
  handleOpenScriptModal,
  handleCloseScriptModal,
  handleCompileClick,
}) => {
  const iconStyle = { height: "16px", width: "16px", margin: "0" };
  const statusStyle = { height: "11px", width: "11px" };
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const daysOfWeek = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];

  // const [data, setData] = useState([]);
  const [editRestApiOpen, setEditRestApiOpen] = useState(false);
  const [editScriptModalOpen, setEditScriptModalOpen] = useState(false);
  const [modalData, setModalData] = useState(apiModaleData);

  const isLoopInstr = doesLoopInstrTypeExist(card.inst.instr_type);
  const isGroupInstr = doesGroupInstrTypeExist(card.inst.instr_type);
  const isIfInstr = doesIfInstrTypeExist(card.inst.instr_type);
  const isElseIfInstr = doesElseIfInstrTypeExist(card.inst.instr_type);
  const isElseInstr = ElseInstrType == card.inst.instr_type && true;
  const isApiInstr = [600, 601, 602, 603, 604].includes(card.inst.instr_type);
  const isScriptInstr = 700;
  const doesFixedLabelDataExist = isInFixedLabelData(card.inst.instr_type);

  const HandleEditRestApiClose = () => {
    setEditRestApiOpen(false);
  };

  const HandleEditScriptModalClose = () => {
    setEditScriptModalOpen(false);
  };

  const getStatusIcon = (status, message = "") => {
    switch (status) {
      case StepStatus.SUCCESS:
        return (
          <Tooltip title={`${message}`}>
            {/* title="success"> */}
            <SuccessIcon style={{ height: "14px", width: "14px" }} />
          </Tooltip>
        );
      case StepStatus.FAILED:
        return (
          // <Tooltip title={message}>
          <Tooltip title={message}>
            {/* title="failed"> */}
            <FailIcon style={{ color: "red", height: "14px", width: "14px" }} />
          </Tooltip>
        );
      case StepStatus.WARNING:
        return (
          <Tooltip title={message}>
            {/* title="warning"> */}
            <InfoIcon
              style={{ color: "yellow", height: "14px", width: "14px" }}
            />
          </Tooltip>
        );
      case StepStatus.SKIPPED:
        return (
          <Tooltip title={message}>
            {/* title="skipped"> */}
            <InfoIcon
              style={{ color: "gray", height: "14px", width: "14px" }}
            />
          </Tooltip>
        );
      case StepStatus.IN_PROGRESS:
        return (
          <Tooltip title={message}>
            {/* title="in progress"> */}
            <CircularProgress
              style={{
                width: "11px",
                height: "11px",
                color: "#0036af",
                marginLeft: "10px",
              }}
            />
          </Tooltip>
        );
      case StepStatus.NOT_STARTED:
        return (
          <Tooltip title={message}>
            {/* title="not started"> */}
            <ErrorIcon
              style={{ color: "black", height: "14px", width: "14px" }}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="not started">
            <ErrorIcon
              style={{ color: "black", height: "14px", width: "14px" }}
            />
          </Tooltip>
        );
    }
  };

  // Enum for step status
  class StepStatus {
    static SUCCESS = 0;
    static FAILED = 1;
    static WARNING = 2;
    static SKIPPED = 3;
    static IN_PROGRESS = 4;
    static NOT_STARTED = 5;
  }

  // console.log("StepCard details ->", card);

  const getCardInstr = (cardInstr) => {
    return cardInstr.instr;
  };

  let icon = true;

  const getLoopCardInstr = (cardInstr, icon = false) => {
    let cardInstrArray = cardInstr.instr.split(/\s+/); // Split by any whitespace characters
    let quoteCount = 0;
    let xIndex = -1;
    let yIndex = -1;
    let x_label_type = cardInstr.x_label_type;
    let y_label_type = cardInstr.y_label_type;
    let x_label = cardInstr.x_label;
    let y_label = cardInstr.y_label;

    // Find positions of quotes
    cardInstrArray.forEach((word, index) => {
      if (word.startsWith('"') && word.endsWith('"')) {
        quoteCount++;
        if (quoteCount === 1) {
          xIndex = index;
        } else if (quoteCount === 2) {
          yIndex = index;
        }
      }
    });

    // Replace the quotes with x_label and y_label if they are provided
    if (xIndex !== -1 && x_label !== undefined) {
      cardInstrArray[xIndex] = x_label;
      if (x_label_type === 1 && icon) {
        cardInstrArray.splice(
          xIndex,
          0,
          '<span style="color: #991AE7;"><GIcon style={iconStyle} /></span>'
        );
      } else if (x_label_type === 2 && icon) {
        cardInstrArray.splice(
          xIndex,
          0,
          '<span style="color: #96A7E6;"><LIcon style={iconStyle} /></span>'
        );
      }
    }
    if (yIndex !== -1 && y_label !== undefined) {
      cardInstrArray[yIndex] = y_label;
      if (y_label_type === 1 && icon) {
        cardInstrArray.splice(
          yIndex,
          0,
          '<span style="color: #991AE7;"><GIcon style={iconStyle} /></span>'
        );
      } else if (y_label_type === 2 && icon) {
        cardInstrArray.splice(
          yIndex,
          0,
          '<span style="color: #96A7E6;"><LIcon style={iconStyle} /></span>'
        );
      }
    }

    // console.log("cardInstrArray ->", icon, cardInstrArray);
    return cardInstrArray.join(" "); // Join array elements into a string with spaces
  };

  // Output: Loop from start to end
  function getTooltipSentence(instr_type) {
    if (doesFixedLabelDataExist) {
      const instruction = getInstruction(instr_type);
      let tooltipSentence = instruction.instr;
      tooltipSentence = tooltipSentence.replace("label", card.inst.data?.value || "");
      tooltipSentence = tooltipSentence.replace(
        "contextual_label",
        card.inst.expected_value?.value || ""
      );

      return tooltipSentence;
    }

    const instruction = getInstruction(
      instr_type,
      card.inst.label,
      card.inst.label_type,
      card.inst.contextual_label,
      card.inst.contextual_label_type
    );
    if (instruction) {
      let tooltipSentence = instruction.instr;
      if (instruction.label) {
        tooltipSentence = tooltipSentence.replace("label", instruction.label);
      }
      if (instruction.contextual_label) {
        tooltipSentence = tooltipSentence.replace(
          "contextual_label",
          instruction.contextual_label
        );
      }
      return tooltipSentence;
    }
    return null;
  }
  function getInstructionHTML(instr_type) {
    if (doesFixedLabelDataExist) {
      const instruction = getInstruction(instr_type);
      let htmlString = instruction.instr;

      // Add additional spans based on label_type and contextual_label_type
      if (card.inst.data?.data_type === 0) {
        htmlString = htmlString.replace(
          "label",
          `<span>"${card.inst.data.value}"</span>`
        );
      } else if (card.inst.data?.data_type === 1) {
        htmlString = htmlString.replace(
          "label",
          `<span class="G-icon">G</span><span class="G-text">${card.inst.data.value}</span>`
        );
      } else if (card.inst.data?.data_type === 2) {
        htmlString = htmlString.replace(
          "label",
          `<span class="L-icon">L</span><span class="L-text">${card.inst.data.value}</span>`
        );
      }

      if (card.inst.expected_value?.data_type === 0) {
        htmlString = htmlString.replace(
          "contextual_label",
          `<span>"${card.inst.expected_value.value}"</span>`
        );
      } else if (card.inst.expected_value?.data_type === 1) {
        htmlString = htmlString.replace(
          "contextual_label",
          `<span class="G-icon">G</span><span class="G-text">${card.inst.expected_value.value}</span>`
        );
      } else if (card.inst.expected_value?.data_type === 2) {
        htmlString = htmlString.replace(
          "contextual_label",
          `<span class="L-icon">L</span><span class="L-text">${card.inst.expected_value.value}</span>`
        );
      }

      return `<div style="max-width: ${card.data !== "" && card.data.value !== "" && card.data.value !== null
        ? "96px"
        : "200px"
        };" class="Instruction">${htmlString}</div>`;
    }

    const instruction = getInstruction(
      instr_type,
      card.inst.label,
      card.inst.label_type,
      card.inst.contextual_label,
      card.inst.contextual_label_type
    );

    if (instruction) {
      const {
        instr,
        label,
        contextual_label,
        label_type,
        contextual_label_type,
      } = instruction;

      // Create the base p tag with spans for label and contextual label
      let htmlString = instr;

      // Add additional spans based on label_type and contextual_label_type
      if (label_type === 0) {
        htmlString = htmlString.replace("label", `<span>"${label}"</span>`);
      } else if (label_type === 1) {
        htmlString = htmlString.replace(
          "label",
          `<span class="G-icon">G</span><span class="G-text">${label}</span>`
        );
      } else if (label_type === 2) {
        htmlString = htmlString.replace(
          "label",
          `<span class="L-icon">L</span><span class="L-text">${label}</span>`
        );
      }

      if (contextual_label_type === 0) {
        htmlString = htmlString.replace(
          "contextual_label",
          `<span>"${contextual_label}"</span>`
        );
      } else if (contextual_label_type === 1) {
        htmlString = htmlString.replace(
          "contextual_label",
          `<span class="G-icon">G</span><span class="G-text">${contextual_label}</span>`
        );
      } else if (contextual_label_type === 2) {
        htmlString = htmlString.replace(
          "contextual_label",
          `<span class="L-icon">L</span><span class="L-text">${contextual_label}</span>`
        );
      }

      return `<div style="max-width: ${card.data !== "" && card.data.value !== "" && card.data.value !== null
        ? "96px"
        : "200px"
        };" class="Instruction">${htmlString}</div>`;
    }
    return null;
  }
  // function getInstructionHTML doesFixedLabelDataExist(instr_type) {
  //   const instruction = getInstruction(
  //     instr_type,
  //     card.inst.label,
  //     card.inst.label_type,
  //     card.inst.contextual_label,
  //     card.inst.contextual_label_type
  //   );

  //   if (instruction) {
  //     const {
  //       instr,
  //       label,
  //       contextual_label,
  //       label_type,
  //       contextual_label_type,
  //     } = instruction;

  //     // Create the base p tag with spans for label and contextual label
  //     let htmlString = instr;

  //     // Add additional spans based on label_type and contextual_label_type
  //     if (label_type === 0) {
  //       htmlString = htmlString.replace("label", `<span>"${label}"</span>`);
  //     } else if (label_type === 1) {
  //       htmlString = htmlString.replace(
  //         "label",
  //         `<span class="G-icon">G</span><span class="G-text">${label}</span>`
  //       );
  //     } else if (label_type === 2) {
  //       htmlString = htmlString.replace(
  //         "label",
  //         `<span class="L-icon">L</span><span class="L-text">${label}</span>`
  //       );
  //     }

  //     if (contextual_label_type === 0) {
  //       htmlString = htmlString.replace(
  //         "contextual_label",
  //         `<span>"${contextual_label}"</span>`
  //       );
  //     } else if (contextual_label_type === 1) {
  //       htmlString = htmlString.replace(
  //         "contextual_label",
  //         `<span class="G-icon">G</span><span class="G-text">${contextual_label}</span>`
  //       );
  //     } else if (contextual_label_type === 2) {
  //       htmlString = htmlString.replace(
  //         "contextual_label",
  //         `<span class="L-icon">L</span><span class="L-text">${contextual_label}</span>`
  //       );
  //     }

  //     return `<div style="max-width: ${
  //       card.data !== "" && card.data.value !== "" && card.data.value !== null
  //         ? "96px"
  //         : "200px"
  //     };" class="Instruction">${htmlString}</div>`;
  //   }
  //   return null;
  // }

  const apiTooltilText = (save_response_array) => {
    if (!save_response_array || save_response_array.length === 0) {
      return "No data saved";
    } else {
      return (
        <>
          <ul style={{ margin: 0, padding: 0 }}>
            {save_response_array.map((item, index) => (
              <li key={index}>{item.key}</li>
            ))}
          </ul>
        </>
      );
    }
  };

  const getCardInstrDetails = (cardInstr, option = false) => {
    let cardInstrArray = cardInstr.instr.split(" ");
    // console.log(cardInstrArray);

    const hasExtraQuotes = (_text) => {
      return /^\".*\"$/.test(_text);
    };

    let flag = false;
    cardInstrArray.map((instrWord, index) => {
      if (hasExtraQuotes(instrWord)) {
        instrWord = instrWord.slice(1, -1);
      }
      if (
        flag &&
        (instrWord.toLowerCase() === "text" ||
          instrWord.toLowerCase() === "label" ||
          instrWord === '""' ||
          instrWord === '"' ||
          instrWord === '\\"')
      ) {
        instrWord = "contextual_label";
      }
      switch (instrWord.toLowerCase()) {
        case "enter":
          cardInstrArray[index] = "Enter";
          break;
        case "click":
          cardInstrArray[index] = "Click";
          break;
        case "text":
        case "label":
          flag = true;
          let textLabelWrapper = ""; // parent div
          if (cardInstr.label_type === 1) {
            // based on condition it will add child node
            textLabelWrapper = (
              <Tooltip title={card.inst.label}>
                <Typography
                  noWrap
                  sx={{
                    maxWidth: "94px",
                    color: globalDataKeys.some((item) => item.key === card.data)
                      ? "#991AE7"
                      : "",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <GIcon style={iconStyle} />
                  </span>
                </Typography>
              </Tooltip>
            );
          }
          if (cardInstr.label_type === 2) {
            // based on condition it will add child node
            textLabelWrapper = (
              <Tooltip title={card.inst.label}>
                <Typography
                  noWrap
                  sx={{
                    maxWidth: "94px",
                    color: localDataCol.some((item) => item.key === card.data)
                      ? "#96A7E6"
                      : "",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <LIcon style={iconStyle} />
                  </span>
                </Typography>
              </Tooltip>
            );
          }
          // finally assign it to variable
          option
            ? (cardInstrArray[index] = (
              <div className="step-label">
                {textLabelWrapper}
                <div>
                  {textLabelWrapper ? (
                    <div
                      style={{
                        color:
                          card.inst.label_type === 1
                            ? "#991AE7"
                            : card.inst.label_type === 2
                              ? "#96A7E6"
                              : "#000000",
                      }}
                    >
                      {cardInstr.label}
                    </div>
                  ) : (
                    `"${cardInstr.label}"`
                  )}
                </div>
              </div>
            ))
            : (cardInstrArray[index] =
              cardInstr.label_type === 1
                ? globalDataKeys.find((item) => item.key === cardInstr.label)
                  ?.value
                : `"${cardInstr.label}"`);
          break;
        case "contextual_label":
          let contextualLabelWrapper = null; // parent div

          if (cardInstr.contextual_label_type === 1) {
            // based on condition it will add child node
            contextualLabelWrapper = (
              <Tooltip title={cardInstr.contextual_label}>
                <Typography
                  noWrap
                  sx={{
                    maxWidth: "98px",
                    color: "#991AE7",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span>
                    <GIcon style={iconStyle} />
                  </span>
                </Typography>
              </Tooltip>
            );
          }
          if (cardInstr.contextual_label_type === 2) {
            // based on condition it will add child node
            contextualLabelWrapper = (
              <Tooltip title={cardInstr.contextual_label}>
                <Typography
                  noWrap
                  sx={{
                    maxWidth: "98px",
                    color: "#96A7E6",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span>
                    <LIcon style={iconStyle} />
                  </span>
                </Typography>
              </Tooltip>
            );
          }
          option
            ? (cardInstrArray[index] = (
              <div className="step-label">
                {contextualLabelWrapper}
                <div>
                  {contextualLabelWrapper ? (
                    <div
                      style={{
                        color:
                          card.inst.contextual_label_type === 1
                            ? "#991AE7"
                            : card.inst.contextual_label_type === 2
                              ? "#96A7E6"
                              : "#000000",
                      }}
                    >
                      {cardInstr.contextual_label}
                    </div>
                  ) : (
                    `"${cardInstr.contextual_label}"`
                  )}
                </div>
              </div>
            ))
            : (cardInstrArray[index] =
              cardInstr.contextual_label_type === 1
                ? globalDataKeys.find(
                  (item) => item.key === cardInstr.contextual_label
                )?.value
                : `"${cardInstr.contextual_label}"`);
          break;

        default:
          break;
      }
    });

    return option ? (
      <div
        style={{ maxWidth: card.data.value ? "110px" : "200px" }}
        className="step-label"
      >
        {cardInstrArray.map((instr, index) => (
          <div key={index}>{instr}</div>
        ))}
      </div>
    ) : (
      cardInstrArray.join(" ")
    );
  };
  //  {/* {cardInstrArray[0].charAt(0).toUpperCase() + cardInstrArray[0].slice(1)} */}
  const lastConditionActive =
    conditionActive && conditionActive[conditionActive.length - 1];

  const handleEdit = (card) => {

    if (!paused && (isGroupInstr || isLoopInstr)) return toast.error("Pause to Edit group/loops");

    // If already deleting a group step, return
    if (groupStepDeleting !== null) return;

    if (isApiInstr) {
      setModalData({ ...card.inst });
      sendMessage({ data_type: 500 });
      setTimeout(() => {
        setEditRestApiOpen(true);
      }, 0);
    } else if (isScriptInstr == card.inst.instr_type) {
      sendMessage({ data_type: 500 });
      setEditScriptModalOpen(true);
      setReceived502(false);
    } else {
      handleEditStep(card?.step_id);
    }
  };

  const handleEditApiCard = (modalData) => {
    updateCard(
      card.step_id,
      modalData,
      card.data,
      card.status,
      card.ordinal,
      card.sub_steps,
      card.group_id ? card.group_id : null,
      card.group_hash ? card.group_hash : null,
      card.message ? card.message : null
    );
  };

  const handleEditScriptCard = (Data) => {
    updateCard(
      card.step_id,
      Data,
      card.data,
      card.status,
      card.ordinal,
      card.sub_steps,
      card.group_id ? card.group_id : null,
      card.group_hash ? card.group_hash : null,
      card.message ? card.message : null
    );
  };

  const findCardById = (id, cards) => {
    // if (!Array.isArray(cards)) {
    //   console.error('cards is not an array:', cards);
    //   return null;
    // }
    console.log("findCardById", cards);
    for (const card of cards) {
      if (card.step_id === id) {
        return card;
      }
      if (card.sub_steps && card.sub_steps.length > 0) {
        const subCard = findCardById(id, card.sub_steps);
        if (subCard) {
          return subCard;
        }
      }
    }
    return null;
  };

  console.log("StepCard ->", cards);
  const handleDelete = (step_id) => {

    // console.log("LLL")
    if (!paused && (isGroupInstr || isLoopInstr)) return toast.error("Pause to delete group/loops");

    // If already deleting a group step, return
    if (groupStepDeleting !== null) return;

    if (step_id.includes('.')) {
      const parentId = step_id.split('.').slice(0, -1).join('.');
      const parentCard = findCardById(parentId, cards);

      if (parentCard) {
        if (doesGroupInstrTypeExist(parentCard.inst.instr_type)) {
          handleDeleteGroupStep(parentCard.step_id, step_id);
        } else {
          handleDeleteStep(step_id);
        }
      } else {
        console.error(`Parent card with ID ${parentId} not found`);
        handleDeleteStep(step_id);
      }
    } else {
      handleDeleteStep(step_id);
    }
  };
  return (
    <>
      {editingStepsOn === card.step_id ? (
        <EditCard
          // cards={cards}
          addCard={addCard}
          handleAddGroupCard={handleAddGroupCard}
          handleAddingStepInGroupWithArrow={handleAddingStepInGroupWithArrow}
          addingStepsOn={addingStepsOn}
          setAddingStepsOn={setAddingStepsOn}
          editingStepsOn={editingStepsOn}
          setEditingStepsOn={setEditingStepsOn}
          handleEditStep={handleEditStep}
          handleDeleteStep={handleDeleteStep}
          handleDeleteGroupStep={handleDeleteGroupStep}
          groupStepDeleting={groupStepDeleting}
          updateCard={updateCard}
          card={card}
          handleCancel={handleCancel}
          globalRefresh={globalRefresh}
          globalDataKeys={globalDataKeys}
          triggerGlobalRefresh={triggerGlobalRefresh}
          refreshingGlobal={refreshingGlobal}
          triggerLocalRefresh={triggerLocalRefresh}
          localDataCol={localDataCol}
          refreshingLocal={refreshingLocal}
          disableLoop={disableLoop}
          disableGroup={disableGroup}
          enableLoop={enableLoop}
          enableGroup={enableGroup}
          handleAddStepInBetween={handleAddStepInBetween}
          handleConditionPop={handleConditionPop}
          groupUpdating={groupUpdating}
          setGroupUpdating={setGroupUpdating}
          handleApiSendClick={handleApiSendClick}
          handleApiSaveClick={handleApiSaveClick}
          restApiOpen={restApiOpen}
          apiModaleData={apiModaleData}
          openingRestApi={openingRestApi}
          handleOpenRestApiModal={handleOpenRestApiModal}
          handleCloseRestApiModal={handleCloseRestApiModal}
          received502={received502}
          setReceived502={setReceived502}
          openingScript={openingScript}
          setOpeningScript={setOpeningScript}
          scriptModalOpen={scriptModalOpen}
          handleCloseScriptModal={handleCloseScriptModal}
          handleOpenScriptModal={handleOpenScriptModal}
          handleCompileClick={handleCompileClick}
        />
      ) : (
        <div style={{ position: "relative" }}>
          {/* <div>
            <p>hello</p>
          </div> */}
          <div className="step-card">
            {/* the below code is for the little line connecting edit card to a step when a condition is on */}
            {(isLoopInstr || isGroupInstr) &&
              card.sub_steps &&
              card.sub_steps.length === 0 &&
              lastConditionActive &&
              lastConditionActive.started_at === step_id && (
                <div className="condition-card"></div>
              )}

            <div className="step-card-drag">
              <img src={GroupLogo} alt="Logo" />
            </div>
            <div className="step-card-content">
              <div className="step-card-count">
                {isShowStepId ? `${card.step_id}.` : ""}
              </div>
              <div className="step-card-details status">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  {card.status === "success" ? (
                    <SuccessIcon style={statusStyle} />
                  ) : (
                    getStatusIcon(card.status, card.message)
                  )}
                  {card.is_new === true ? (
                    <Tooltip title="New">
                      <img
                        src={newIcon}
                        alt="new-icon"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </Tooltip>
                  ) : (
                    card.is_ai_generated === true && (
                      <Tooltip title="AI generated">
                        <img
                          src={genAiLogo}
                          alt="ai-logo"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </Tooltip>
                    )
                  )}
                </div>
                {typeof card.inst === "string" && (
                  <div>
                    <Tooltip title={card.inst}>
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: "94px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {card.inst}
                      </Typography>
                    </Tooltip>
                  </div>
                )}
                {typeof card.inst === "object" &&
                  (isLoopInstr ? (
                    <div>
                      <Tooltip title={getLoopCardInstr(card.inst)}>
                        <div className="loop-title">
                          <div className="loop-box bg-orange">LOOP</div>
                          <div className="loop-condition">
                            <Typography
                              noWrap
                              sx={{
                                maxWidth: "104px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: getLoopCardInstr(card.inst, icon),
                                }}
                              />
                            </Typography>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ) : isGroupInstr ? (
                    <div>
                      <div className="loop-title">
                        <div className="loop-box bg-green">Group</div>
                        <div className="loop-condition">
                          <Tooltip title={card.inst.instr}>
                            <Typography
                              noWrap
                              sx={{
                                maxWidth: "104px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {card.inst.instr}
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ) : isIfInstr ? (
                    <div>
                      <div className="loop-title">
                        <div className="loop-box bg-blue">If</div>
                        <div className="loop-condition">
                          <Tooltip title={card.inst.instr}>
                            <Typography
                              noWrap
                              sx={{
                                maxWidth: "104px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {card.inst.instr}
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ) : isElseIfInstr ? (
                    <div>
                      <div className="loop-title">
                        <div className="loop-box bg-blue">Else If</div>
                        <div className="loop-condition">
                          <Tooltip title={card.inst.instr}>
                            <Typography
                              noWrap
                              sx={{
                                maxWidth: "104px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {card.inst.instr}
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ) : isElseInstr ? (
                    <div>
                      <div className="loop-title">
                        <div className="loop-box bg-blue">Else</div>
                      </div>
                    </div>
                  ) : isApiInstr || card.inst.instr_type == isScriptInstr ? (
                    <div className="api-card">
                      <div className="api-method-name">
                        <p className="api-method">
                          {isApiInstr ? card.inst.method : "SCR"}
                        </p>
                        <p className="api-name">{card.inst.name}</p>
                      </div>
                      <div className="step-card-details">
                        <DatabaseIcon style={iconStyle} />
                        <Tooltip
                          title={apiTooltilText(card.inst.save_response)}
                        >
                          <p className="api-data">
                            {card.inst.save_response
                              ? card.inst.save_response.length
                              : "0"}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  ) : doesFixedLabelDataExist ? (
                    <div>
                      <Tooltip title={getTooltipSentence(card.inst.instr_type)}>
                        <Typography noWrap>
                          {/* {getCardInstrDetails(card.inst, true)} */}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getInstructionHTML(card.inst.instr_type),
                            }}
                          />
                        </Typography>
                      </Tooltip>
                    </div>
                  ) : (
                    <div>
                      <Tooltip title={getTooltipSentence(card.inst.instr_type)}>
                        <Typography noWrap>
                          {/* {getCardInstrDetails(card.inst, true)} */}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getInstructionHTML(card.inst.instr_type),
                            }}
                          />
                        </Typography>
                      </Tooltip>
                    </div>
                  ))}
              </div>
              {typeof card.data === "string" && card.data !== "" && (
                <div className="step-card-details">
                  <DatabaseIcon style={iconStyle} />
                  <Tooltip title={card.data}>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: "94px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {card.data}
                    </Typography>
                  </Tooltip>
                </div>
              )}
              <StepCardDetails card={card} iconStyle={iconStyle} />
            </div>
            {sessionId && (
              <div
                style={{ position: "absolute", right: "0" }}
                className="step-card-actions"
              >
                {!recordActive && (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Ordinal
                      card={card}
                      sendMsgForOrdinalHover={sendMsgForOrdinalHover}
                      sendMsgForOrdinalSelect={sendMsgForOrdinalSelect}
                      ordinal_value={card.ordinal}
                      do_ordinal_selection={card.do_ordinal_selection}
                    />
                    <div className="card-action-container">
                      {!isGroupInstr && (
                        <Tooltip title={groupStepDeleting !== null ? `Currently deleting ${groupStepDeleting}` : ""}>
                          <EditIcon
                            className={card.status === 4 && !(isGroupInstr || isLoopInstr) && "disabled"}
                            onClick={() => handleEdit(card)}
                            style={{ height: "16px", width: "16px", margin: "0", opacity: groupStepDeleting !== null ? "0.5" : "1" }}
                          />
                        </Tooltip>
                      )}

                      {groupStepDeleting === card.step_id ? (
                        <CircularProgress
                          style={{
                            width: "12px",
                            height: "12px",
                            margin: "0 1px",
                          }}
                        />
                      ) : (
                        <Tooltip title={groupStepDeleting !== null ? `Currently deleting ${groupStepDeleting}` : ""}>
                          <DeleteIcon
                            className={card.status === 4 && !(isGroupInstr || isLoopInstr) && "disabled"}
                            style={{ height: "16px", width: "16px", margin: "0", opacity: groupStepDeleting !== null ? "0.5" : "1" }}
                            // className={card.status === 4 && "disabled"}
                            onClick={() => handleDelete(card.step_id)}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
                {paused && (
                  <PlayIcon
                    onClick={(event) => handlePlayClick(event, card.step_id)}
                    style={iconStyle}
                  />
                )}
                <OptionIcon style={iconStyle} />
              </div>
            )}
            {sessionId && (
              <div style={{ opacity: "" }} className="step-hover-btn-container">
                <button
                  className="step-hover-btn"
                  onClick={() => handleAddStepInBetween(card.step_id)}
                >
                  + Step
                </button>
              </div>
            )}
          </div>

          {card.sub_steps && card.sub_steps.length > 0 && (
            <div className="step-card-substeps">
              {card.sub_steps.map((subStep) => (
                <div
                  key={subStep.step_id}
                  style={{
                    marginLeft: "20px",
                    paddingTop: "20px",
                    borderLeft: "1px solid #E0E0E0",
                    paddingLeft: "10px",
                  }}
                >
                  <StepCard
                    card={subStep}
                    level={level + 1}
                    cards={cards}
                    step_id={subStep.step_id}
                    // key={card.step_id}

                    recordActive={recordActive}
                    paused={paused}
                    sessionId={sessionId}
                    addCard={addCard}
                    handleAddGroupCard={handleAddGroupCard}
                    handleAddingStepInGroupWithArrow={handleAddingStepInGroupWithArrow}
                    updateCard={updateCard}
                    addingStepsOn={addingStepsOn}
                    editingStepsOn={editingStepsOn}
                    setEditingStepsOn={setEditingStepsOn}
                    setAddingStepsOn={setAddingStepsOn}
                    handleAddStepInBetween={handleAddStepInBetween}
                    handleEditStep={handleEditStep}
                    handleDeleteStep={handleDeleteStep}
                    handleDeleteGroupStep={handleDeleteGroupStep}
                    groupStepDeleting={groupStepDeleting}
                    handlePlayClick={handlePlayClick}
                    handleCancel={handleCancel}
                    globalRefresh={globalRefresh}
                    refreshingGlobal={refreshingGlobal}
                    globalDataKeys={globalDataKeys}
                    triggerGlobalRefresh={triggerGlobalRefresh}
                    triggerLocalRefresh={triggerLocalRefresh}
                    localDataCol={localDataCol}
                    refreshingLocal={refreshingLocal}
                    sendMsgForOrdinalHover={sendMsgForOrdinalHover}
                    sendMsgForOrdinalSelect={sendMsgForOrdinalSelect}
                    disableLoop={disableLoop}
                    disableGroup={disableGroup}
                    enableLoop={enableLoop}
                    enableGroup={enableGroup}
                    handleConditionPop={handleConditionPop}
                    groupUpdating={groupUpdating}
                    setGroupUpdating={setGroupUpdating}
                    handleApiSendClick={handleApiSendClick}
                    handleApiSaveClick={handleApiSaveClick}
                    restApiOpen={restApiOpen}
                    openingRestApi={openingRestApi}
                    handleOpenRestApiModal={handleOpenRestApiModal}
                    handleCloseRestApiModal={handleCloseRestApiModal}
                    apiModaleData={apiModaleData}
                    sendMessage={sendMessage}
                    received502={received502}
                    setReceived502={setReceived502}
                    openingScript={openingScript}
                    setOpeningScript={setOpeningScript}
                    scriptModalOpen={scriptModalOpen}
                    handleOpenScriptModal={handleOpenScriptModal}
                    handleCloseScriptModal={handleCloseScriptModal}
                    handleCompileClick={handleCompileClick}
                  />
                </div>
              ))}
            </div>
          )}

          {addingStepsOn === card.step_id && (
            <div style={{ marginTop: "20px" }}>
              <EditCard
                // cards={cards}
                addCard={addCard}
                handleAddGroupCard={handleAddGroupCard}
                handleAddingStepInGroupWithArrow={handleAddingStepInGroupWithArrow}
                addingStepsOn={addingStepsOn}
                setAddingStepsOn={setAddingStepsOn}
                setEditingStepsOn={setEditingStepsOn}
                handleCancel={handleCancel}
                handleDeleteStep={handleDeleteStep}
                handleDeleteGroupStep={handleDeleteGroupStep}
                groupStepDeleting={groupStepDeleting}
                globalRefresh={globalRefresh}
                globalDataKeys={globalDataKeys}
                triggerGlobalRefresh={triggerGlobalRefresh}
                refreshingGlobal={refreshingGlobal}
                triggerLocalRefresh={triggerLocalRefresh}
                localDataCol={localDataCol}
                refreshingLocal={refreshingLocal}
                disableLoop={disableLoop}
                disableGroup={disableGroup}
                enableLoop={enableLoop}
                enableGroup={enableGroup}
                handleAddStepInBetween={handleAddStepInBetween}
                handleConditionPop={handleConditionPop}
                groupUpdating={groupUpdating}
                setGroupUpdating={setGroupUpdating}
                handleApiSendClick={handleApiSendClick}
                handleApiSaveClick={handleApiSaveClick}
                apiModaleData={apiModaleData}
                sendMessage={sendMessage}
                received502={received502}
                setReceived502={setReceived502}
                restApiOpen={restApiOpen}
                openingRestApi={openingRestApi}
                handleOpenRestApiModal={handleOpenRestApiModal}
                handleCloseRestApiModal={handleCloseRestApiModal}
                openingScript={openingScript}
                setOpeningScript={setOpeningScript}
                scriptModalOpen={scriptModalOpen}
                handleOpenScriptModal={handleOpenScriptModal}
                handleCloseScriptModal={handleCloseScriptModal}
                handleCompileClick={handleCompileClick}
              />
            </div>
          )}
          {editRestApiOpen && (
            <ApiModal
              open={editRestApiOpen}
              modalData={modalData}
              onCancel={HandleEditRestApiClose}
              handleApiSendClick={handleApiSendClick}
              handleAddApiCard={handleEditApiCard}
              sendMessage={sendMessage}
              received502={received502}
              setReceived502={setReceived502}
            />
          )}
          {editScriptModalOpen && (
            <ScriptModal
              open={editScriptModalOpen}
              onCancel={HandleEditScriptModalClose}
              handleAddScriptCard={handleEditScriptCard}

              sendMessage={sendMessage}
              received502={received502}
              setReceived502={setReceived502}
              EditData={card.inst}
              handleCompileClick={handleCompileClick}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  // cards: state.cards.cards,
  conditionStepId: state.cards.conditionStepId,
  conditionActive: state.cards.conditionActive,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StepCard);
