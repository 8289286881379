import { Modal, Button, Input, Select, Tabs } from 'antd';
import './scriptModal.css';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import closeIcon from '../../../../../assets/close-icon.svg';
import CodeEditor from './component/Editor';
import { InboxOutlined } from '@ant-design/icons';
import SaveTemplateModal from './component/SaveTemplateModal';

import gbutton from "../../../../../assets/G-button.svg";
import lbutton from "../../../../../assets/lbutton.svg";
import GlobalLocalDropdown from '../../../../../components/GlobalLocalDropdown';
import { useResponseContext } from '../../../context/ResponseContext';
import ScrollContainer from '../../../../../components/ScrollContainer';
import isValidJavaScript from '../../../../../utils/javaScriptValidation';
import { toast } from 'react-toastify';
import CopyCode from '../../../../../components/CopyCode/CopyCode';
import { create_globalData } from '../../../../../services/dataService';
import { get_all_scripts, get_script_by_id } from '../../../../../services/scriptService';


const ScriptModal = ({
    open,
    onCancel,

    EditData,

    sendMessage,
    received502,
    setReceived502,

    handleAddScriptCard,
    handleCompileClick
}) => {

    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [templates, setTemplates] = useState([]);

    const [warn, setWarn] = useState({
        name: false,
        script: false,
        args: false,
        save_response: false
    })
    const InitialData =

    {
        instr_type: 700,
        name: "",
        script:
            `// In this script, you can declare inputs using 'args' followed by an index number.
// For example:
var title = args[0]; // This sets 'title' to the value of the first input.

// You can also return multiple values by using an object notation with {} at the end.
// For example:
return { "output1": "123", "output2": "456", "output3": title };
`,
        args: {},
        save_response: [],
        recent_response: {
            result: {},
            error: ""
        }
    }

    const {
        scriptData,
        updateScriptData,
        activeScriptTab,
        updateActiveScriptTab,
        scriptResponseLoading
    } = useResponseContext();

    console.log("scriptData", scriptData);

    // const [scriptData, updateScriptData] = useState(InitialData);
    const [templateSelected, setTemplateSelected] = useState("None");
    const [focusedParamKey, setFocusedParamKey] = useState('');
    const [showGlobalLocalDropdown, setShowGlobalLocalDropdown] = useState(false);
    const [waitingFor502, setWaitingFor502] = useState(false);

    const [isSaveTemplateModalVisible, setIsSaveTemplateModalVisible] = useState(false);

    const [outputKeyValues, setOutputKeyValues] = useState({});

    const [loadingTemplateData, setLoadingTemplateData] = useState(false);

    
    useLayoutEffect(() => {
        if (EditData && open) {
 
            updateScriptData(EditData);
        } else {
            updateScriptData(InitialData);
        }
    }, [open, EditData]);

    const handleCodeChange = (newCode) => {

        setWarn({
            name: false,
            script: false,
            args: false,
            save_response: false
        })

        updateScriptData(prevData => ({
            ...prevData,
            script: newCode
        }));

    };
    const handleTemplateChange = async (value) => {
        if (value === "None") {
            return;
        }

        setLoadingTemplateData(true);
        try {
            const templateData = await get_script_by_id(value);
 
            updateScriptData(prevData => ({
                ...prevData,
                script: templateData.script_content
            }));
        } catch (error) {
            console.error("Error fetching template data:", error);
            toast.error("Error fetching template data. Please try again.");
        } finally {
            setLoadingTemplateData(false);
        }


        setTemplateSelected("None");
    }

    // Function to extract variables from code
    const extractVariablesFromCode = (code, currentArgs, recentResponse) => {
        const regex = /\bargs\[(\d+)\]/g;
        let match;
        const newVariables = { ...currentArgs }; // Start with current values
    
        const foundVariables = new Set();
    
        while ((match = regex.exec(code)) !== null) {
            const variableName = `arg${match[1]}`;
            foundVariables.add(variableName);
    
            if (!(variableName in newVariables)) {
                // If it's a new variable, initialize it with an empty string
                newVariables[variableName] = "";
            }
            // If the variable exists in recentResponse.result, use that value
            else if (recentResponse && recentResponse.result && recentResponse.result[variableName]) {
                newVariables[variableName] = recentResponse.result[variableName];
            }
            // Otherwise, keep the existing value
        }
    
        // Remove variables that are no longer in the code
        Object.keys(newVariables).forEach(key => {
            if (!foundVariables.has(key)) {
                delete newVariables[key];
            }
        });
    
        return newVariables;
    };

    
    // const extractVariablesFromCode = (code) => {
    //     // Regex to match `args[n]`
    //     const regex = /\bargs\[(\d+)\]/g;
    //     let match;
    //     const newVariables = { ...scriptData.args }; // Retain current values

    //     const foundVariables = new Set();

    //     while ((match = regex.exec(code)) !== null) {
    //         const variableName = `arg${match[1]}`;
    //         foundVariables.add(variableName);

    //         if (!(variableName in newVariables)) {
    //             newVariables[variableName] = "";
    //         }
    //     }

    //     Object.keys(newVariables).forEach(key => {
    //         if (!foundVariables.has(key)) {
    //             delete newVariables[key];
    //         }
    //     });

    //     updateScriptData(prevData => ({
    //         ...prevData,
    //         args: newVariables
    //     }));
    // };

    useEffect(() => {
        const newArgs = extractVariablesFromCode(scriptData.script, scriptData.args, scriptData.recent_response);
        updateScriptData(prevData => ({
            ...prevData,
            args: newArgs
        }));
    }, [scriptData.script, scriptData.recent_response]);


    // Function to parse return statements and update save_response
    const analyzeCodeForSaveResponse = (code, currentSaveResponse, recentResponse) => {
        const returnRegex = /return\s+({[\s\S]*?});/g;
        const matches = [...code.matchAll(returnRegex)];
        
        if (matches.length === 0) return []; // Return empty array if no object return statements found
    
        const newSaveResponse = matches.flatMap(match => {
            const returnValue = match[1].trim();
    
            // Only process object returns
            if (returnValue.startsWith('{') && returnValue.endsWith('}')) {
                try {
                    const jsonLikeString = returnValue
                        .replace(/(\w+):/g, '"$1":')
                        .replace(/'/g, '"')
                        .replace(/,\s*([\]}])/g, '$1');
    
                    const keyValuePairs = jsonLikeString.match(/"([^"]+)"\s*:\s*([^,}\]]+)/g);
    
                    if (keyValuePairs) {
                        return keyValuePairs.map(pair => {
                            const [key] = pair.match(/"([^"]+)"/);
                            const pathToKey = key.replace(/"/g, '');
    
                            // Check if path_to_key already exists in currentSaveResponse
                            const existingResponse = currentSaveResponse.find(response => response.path_to_key === pathToKey);
    
                            return {
                                key: existingResponse ? existingResponse.key : "",
                                path_to_key: pathToKey
                            };
                        });
                    }
                } catch (error) {
                    console.error("Error parsing return object:", error);
                }
            }
    
            return []; // Return empty array for non-object returns
        });
    
        return newSaveResponse;
    };
    
    // Usage in useEffect
    useEffect(() => {
        if (scriptData.script) {
            const newSaveResponse = analyzeCodeForSaveResponse(scriptData.script, scriptData.save_response, scriptData.recent_response);
            updateScriptData(prevData => ({
                ...prevData,
                save_response: newSaveResponse
            }));
        }
    }, [scriptData.script, scriptData.recent_response]);


    // console.log("scriptData", scriptData);
    const handleNameChange = (e) => {
        setWarn(prev => ({ ...prev, name: false }))
        const newName = e.target.value;
        updateScriptData(prevData => ({
            ...prevData,
            name: newName
        }));
    };

    const handleDropdownBlur = () => {
        setFocusedParamKey('');
        setShowGlobalLocalDropdown(false);
    }

    const setGlobalLocalValue = (obj) => {
        updateScriptData(prevData => ({
            ...prevData,
            args: {
                ...prevData.args,
                [focusedParamKey]: obj
            }
        }));
        handleDropdownBlur()
    };

    const handleGlobalLocalFocus = (key) => {
        setFocusedParamKey(key);
        setShowGlobalLocalDropdown(true);
    };

    const handleParamChange = (key, value) => {
        setWarn(prev => ({ ...prev, args: false }))
        // Update header with new value
        updateScriptData(prevData => ({
            ...prevData,
            args: {
                ...prevData.args,
                [key]: value
            }
        }));
    };

    const handleClearParamValue = (key) => {
        const updatedValue = "";
        updateScriptData(prevData => ({
            ...prevData,
            args: {
                ...prevData.args,
                [key]: updatedValue
            }
        }));
    };

    const handleReset = () => {
        updateActiveScriptTab("1");
        handleDropdownBlur();
        if (EditData) {
            updateScriptData(EditData);
 
        }
        else {
            updateScriptData(InitialData);
 
        }
    }

    const handleCancel = () => {
        updateScriptData(InitialData);
        sendMessage({ data_type: 501 });
        onCancel();
        handleReset();
        handleDropdownBlur();
    }

    const handleSaveClick = (data) => {
        let hasErrors = false;

        if (scriptData.name.trim() === "") {
            setWarn(prev => ({ ...prev, name: true }));
            toast.error("Please enter a name for the script.");
            hasErrors = true;
        }

        if (scriptData.script === "") {
            setWarn(prev => ({ ...prev, script: true }));
            toast.error("Please enter a script.");
            hasErrors = true;
        }

        if (Object.keys(scriptData.args).some(key => scriptData.args[key] === "")) {
            setWarn(prev => ({ ...prev, args: true }));
            toast.error("Please enter a value for all Inputs.");
            hasErrors = true;
        }

        if (scriptData.save_response.some(response => response.key === "")) {
            setWarn(prev => ({ ...prev, save_response: true }));
            toast.error("Please enter a key for all outputs.");
            hasErrors = true;
        }

        if (!isValidJavaScript(data.script)) {
            toast.error("Invalid JavaScript code. Please check your script.");
            hasErrors = true;
        }

        if (!hasErrors) {
            setWaitingFor502(true);
            handleAddGlobalVariable();

            // Send the message
            console.log("SENDING MESSAGE", 501);
            sendMessage({ data_type: 501 });
        }
    }

    const handleAddGlobalVariable = async () => {
        if (Object.keys(outputKeyValues).length === 0) return;

        // Create a mapping of path_to_key to key from save_response
        const pathToKeyMapping = scriptData.save_response.reduce((acc, { path_to_key, key }) => {
            acc[path_to_key] = key;
            return acc;
        }, {});

        // Iterate over outputKeyValues to create global data
        for (const [pathToKey, value] of Object.entries(outputKeyValues)) {
            const key = pathToKeyMapping[pathToKey];

            if (key) {
                try {
                    const response = await create_globalData({
                        key: key,
                        value: value,
                    });
                     
                } catch (error) {
                    console.error("Error adding global variable:", error);
                }
            }
        }
    };


    const fetchTemplates = async () => {
        setLoadingTemplates(true);
        try {
            const response = await get_all_scripts();
            setTemplates(response);
      
        } catch (error) {
            console.error("Error fetching scripts:", error);
            toast.error("Error fetching scripts. Please try again later.");
        } finally {
            setLoadingTemplates(false);
        }
    };
    const handleDropdownVisibleChange = (open) => {
        if (open) {
            fetchTemplates();
        }
    };

    useEffect(() => {

        if (received502 && waitingFor502 && open) {
            handleAddScriptCard(scriptData)
            onCancel();
            handleReset();
            setWaitingFor502(false);
            setReceived502(false);

            updateScriptData(InitialData);
        }
    }, [received502, waitingFor502, open]);

    const handleSaveResponseKeyChange = (index, newKey) => {

        setWarn(prev => ({ ...prev, save_response: false }))
        // Ensure saveResponse is an array
        const currentSaveResponse = Array.isArray(scriptData.save_response) ? scriptData.save_response : [];

        const updatedSaveResponse = [...currentSaveResponse];
        updatedSaveResponse[index] = { ...updatedSaveResponse[index], key: newKey };

        updateScriptData(prevData => ({
            ...prevData,
            save_response: updatedSaveResponse
        }));
    };

    const handleCompile = (data) => {
        let hasErrors = false;

        if (data.script.trim() === "") {
            setWarn(prev => ({ ...prev, script: true }));
            toast.error("Please enter a script.");
            hasErrors = true;
        }

        if (Object.keys(data.args).some(key => data.args[key] === "")) {
            setWarn(prev => ({ ...prev, args: true }));
            toast.error("Please enter a value for all Inputs.");
            hasErrors = true;
        }

        if (!isValidJavaScript(data.script)) {
            toast.error("Invalid JavaScript code. Please check your script.");
            hasErrors = true;
        }

        if (!hasErrors) {
            handleCompileClick(data);
            console.log("handleCompileClick", data);
        }
    }


 

    const generateKeyValues = () => {
        const { save_response } = scriptData;
        const { result } = scriptData.recent_response;

        // Initialize keyValues with empty strings for all path_to_key
        let keyValues = {};
        save_response.forEach(({ path_to_key }) => {
            keyValues[path_to_key] = ""; // Set initial values to empty string
        });

        // Update keyValues with actual result values if available
        if (result) {
            if (typeof result === 'string') {
                if (save_response.length === 1) {
                    const singlePathKey = save_response[0].path_to_key;
                    keyValues[singlePathKey] = result; // Assign the string result to the single key
                }
            } else if (typeof result === 'object') {
                Object.keys(result).forEach(key => {
                    if (keyValues.hasOwnProperty(key)) {
                        keyValues[key] = result[key]; // Assign values from result to the respective keys
                    }
                });
            }
        }

        setOutputKeyValues(keyValues);
    };
    useEffect(() => {
        generateKeyValues();
    }, [scriptData.recent_response, scriptData.save_response, open]);
 

    const OutputCount = scriptData.save_response?.length ? scriptData.save_response.length : 1;

    // var additionalInfo = args[0]; 
    return (
        <Modal
            className="script-modal"
            open={open}
            onCancel={handleCancel}
            width={800}
            footer={null}
            header={null}
            centered={true}
        >
            <Box
                sx={{
                    width: "100%",
                    backgroundColor: '#fff',
                    borderRadius: "8px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxSizing: "border-box"
                }}>
                <div style={{ width: "100%" }} className='linked-tc-modal-box-header'>
                    <h4>Inline Script</h4>
                    <img src={closeIcon} onClick={handleCancel} alt='close-modal' />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "20px",
                        width: "100%",
                        padding: "16px 36px",
                        boxSizing: "border-box",
                        position: "relative"
                    }}>
                    <div style={{ width: "100%", boxSizing: "border-box" }}>
                        <p style={{ margin: "0" }}>Name <span style={{ color: "#EF4444" }}>*</span></p>
                        <div style={{ padding: "10px 0 10px 0", display: "flex", alignItems: "center", gap: "10px" }}>
                            <Input
                                className={warn.name && scriptData.name === "" ? "input-error" : ""}
                                value={scriptData.name}
                                onChange={handleNameChange}
                                style={{ height: "40px" }}
                                placeholder=""
                            />
                            <Button onClick={() => setIsSaveTemplateModalVisible(true)} style={{ height: "40px" }}>Save Template</Button>
                        </div>
                    </div>
                    <div
                        style={{ position: "absolute", right: "55px", top: "130px", zIndex: "100", boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "end", gap: "10px" }}
                    >
                        <Select
                            placeholder="Template"
                            onChange={handleTemplateChange}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            value={templateSelected === "None" ? null : templateSelected}
                            style={{ minWidth: "190px", height: "40px" }}
                            loading={loadingTemplates}
                        // dropdownStyle={{ maxHeight: '200px', overflow: 'auto' }}
                        >
                            {loadingTemplates ? (
                                <Select.Option disabled value="loading">
                                    Loading...
                                </Select.Option>
                            ) : (
                                templates.map((template, index) => (
                                    <Select.Option key={index} value={template.script_id}>
                                        {template.script_name}
                                    </Select.Option>
                                ))
                            )}
                        </Select>

                        {scriptResponseLoading ? (
                            <Button
                                style={{ height: "40px", width: "82px", backgroundColor: "#0036AF" }}
                                type="primary">
                                <CircularProgress style={{ width: "14px", height: "14px", color: "#fff" }} />
                            </Button>
                        ) : (
                            <Button
                                onClick={() => handleCompile(scriptData)}
                                style={{ height: "40px", width: "82px", backgroundColor: "#0036AF" }}
                                type="primary">
                                Compile
                            </Button>
                        )}

                    </div>

                    {/* Code editor */}

                    <div className="tabs-container">
                        <Tabs
                            defaultActiveKey="1"
                            onChange={updateActiveScriptTab}
                            activeKey={activeScriptTab}
                        >
                            <Tabs.TabPane tab="JavaScript" key="1">
                                <div style={{ position: "absolute", width: "100%" }}>
                                    <CopyCode code={scriptData.script} />
                                    <ScrollContainer height={"500px"}>
                                        <CodeEditor loading={loadingTemplateData} code={scriptData.script} onCodeChange={handleCodeChange} />
                                    </ScrollContainer>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Output" key="2">
                                <ScrollContainer height={"500px"}>
                                    <div className='script-output'>
                                        {scriptData.recent_response.result && Object.keys(scriptData.recent_response.result).length > 0 ? (
                                            // Check if the result is an object
                                            typeof scriptData.recent_response.result === 'object' ? (
                                                <p>{JSON.stringify(scriptData.recent_response.result)}</p>
                                            ) : (
                                                <p>{scriptData.recent_response.result}</p>
                                            )
                                        ) : scriptData.recent_response.error === "" ? (
                                            <p style={{ margin: "0px" }}>Compile to see the output for Script</p>
                                        ) : (
                                            <p style={{ color: "#EF4444" }}>{scriptData.recent_response.error}</p>
                                        )}
                                    </div>

                                </ScrollContainer>
                            </Tabs.TabPane>

                        </Tabs>
                    </div>

                    {/* Variables */}
                    <div style={{ width: "100%", boxSizing: "border-box", paddingBottom: "20px", borderBottom: Object.keys(scriptData.args) && Object.keys(scriptData.args).length > 0 && "1px solid #F5F5F5" }}>
                        <p style={{ margin: "0" }}>Inputs</p>
                        {Object.keys(scriptData.args).length === 0 ? (

                            <div className='script-modal-dnf' style={{ textAlign: 'center', marginTop: "10px" }}>
                                <InboxOutlined style={{ fontSize: 20 }} />
                                <p>{'You can create a variable using args[0].'}</p>
                            </div>
                        ) : (
                            Object.entries(scriptData.args || {}).map(([key, value]) => (
                                <div

                                    key={key}
                                    // onBlur={handleDropdownBlur} 
                                    style={{ position: "relative", padding: "10px 0 10px 0", display: "flex", alignItems: "center", gap: "10px" }}
                                >
                                    <Input style={{ height: "40px", width: "200px" }} value={key} readOnly />
                                    <div
                                        style={{ position: "relative" }}
                                        className={`param-input-value-container ${warn.args && scriptData.args[key] === "" ? "input-error" : ""}`}
                                    >
                                        {typeof value === 'object' ? (
                                            <div className="input-data-values">
                                                {value.data_type === 1 ? (
                                                    <img src={gbutton} alt='gbutton' />
                                                ) : value.data_type === 2 ? (
                                                    <img src={lbutton} alt='lbutton' />
                                                ) : null}
                                                <p style={{ color: value.data_type === 1 ? "#991AE1" : (value.data_type === 2 ? "" : "") }}>
                                                    {value.value ? value.value : value.save_data_name}
                                                </p>
                                                <img
                                                    src={closeIcon}
                                                    className='input-close-icon'
                                                    width={18}
                                                    alt="remove file"
                                                    onClick={() => handleClearParamValue(key)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <input
                                                    placeholder="Value"
                                                    className="param-input-value"
                                                    value={typeof value === 'string' ? value : ''}
                                                    onChange={(e) => handleParamChange(key, e.target.value)}
                                                    onFocus={() => handleGlobalLocalFocus(key)}
                                                />
                                                {showGlobalLocalDropdown && focusedParamKey === key && (
                                                    <div style={{ position: "absolute", top: "40px" }}>
                                                        <GlobalLocalDropdown
                                                            setGlobalLocalValue={setGlobalLocalValue}
                                                            onClose={handleDropdownBlur}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    {/* Output */}
                    <div style={{ width: "100%", boxSizing: "border-box" }}>
                        {scriptData.save_response?.length > 0 ? (
                            scriptData.save_response.map((response, index) => (
                                <div
                                    key={index}
                                    className='script-output-input-row'
                                >
                                    <div className='script-output-input'>
                                        <p style={{ margin: "0" }}>Output {OutputCount > 1 ? index + 1 : ""} <span style={{ color: "#EF4444" }}>*</span></p>
                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <Input
                                                value={response.key}
                                                style={{ height: "40px" }}
                                                className={warn.save_response && response.key === "" ? "input-error" : ""}
                                                placeholder='Variable Name'
                                                onChange={(e) => handleSaveResponseKeyChange(index, e.target.value)}
                                            />
                                            <Input
                                                value={scriptData.recent_response && scriptData.recent_response.result ? scriptData.recent_response.result[response.path_to_key] : ""}
                                                style={{ height: "40px" }}
                                                placeholder='Value'
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div style={{ textAlign: 'center', marginTop: "10px", color: "#888" }}>
                                <InboxOutlined style={{ fontSize: 20 }} />
                                <p>{'You can create a response using return statement.'}</p>
                            </div>
                        )}
                    </div>

                    <div style={{ width: "100%", boxSizing: "border-box" }} className="modal-footer">
                        <Button onClick={handleReset}>Reset</Button>
                        <div className="footer-buttons">
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "#0036af", borderColor: "#0036af" }}
                                onClick={() => handleSaveClick(scriptData)}
                            >
                                {waitingFor502 ? (
                                    <CircularProgress
                                        style={{ width: "14px", height: "14px", color: "#fff" }}
                                    />
                                ) : (
                                    "Save"
                                )}
                            </Button>
                        </div>
                    </div>

                </div>


                <SaveTemplateModal
                    open={isSaveTemplateModalVisible}
                    onCancel={() => setIsSaveTemplateModalVisible(false)}
                    data={scriptData}
                />



            </Box>
        </Modal>
    );
}

export default ScriptModal;
