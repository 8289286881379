import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDownIcon from "../../../../assets/arrow_down.svg";
import ArrowRightIcon from "../../../../assets/arrow_right.svg";

import { ReactComponent as FailIcon } from "../../../../assets/failStatus.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/infoIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/successStatus.svg";
import ErrorIcon from "@mui/icons-material/Error";

import successStatus from "../../../../assets/successStatus.svg";
import failStatus from "../../../../assets/failStatus.svg";
import skippedStatus from "../../../../assets/skippedStatus.svg";
import waitingStatus from "../../../../assets/waitingSuccess.svg";
import db from "../assets/db.svg";
import { doesLoopInstrTypeExist } from "../../../EcomTesting/TestSteps/dropdowns/data/loop";
import { doesGroupInstrTypeExist } from "../../../EcomTesting/TestSteps/dropdowns/data/group";
import "./TreeViewReport.css";
import { getInstruction } from "../../../EcomTesting/TestSteps/TsSuggestionsData/data";

class StepStatus {
  static SUCCESS = 0;
  static FAILED = 1;
  static WARNING = 2;
  static SKIPPED = 3;
  static IN_PROGRESS = 4;
  static NOT_STARTED = 5;
}
const getStatusIcon = (status, message = "") => {
  // console.log(status, message);
  switch (status) {
    // case StepStatus.SUCCESS
    case 0:
      return (
        <Tooltip title={`${message}`}>
          {/* title="success"> */}
          <SuccessIcon style={{ height: "14px", width: "14px" }} />
        </Tooltip>
      );
    // case StepStatus.FAILED:
    case 1:
      return (
        <Tooltip title={message}>
          {/* title="failed"> */}
          <FailIcon style={{ color: "red", height: "14px", width: "14px" }} />
        </Tooltip>
      );
    // case StepStatus.WARNING:
    case 2:
      return (
        <Tooltip title={message}>
          {/* title="warning"> */}
          <InfoIcon
            style={{ color: "yellow", height: "14px", width: "14px" }}
          />
        </Tooltip>
      );
    // case StepStatus.SKIPPED:
    case 3:
      return (
        <Tooltip title={message}>
          {/* title="skipped"> */}
          <InfoIcon style={{ color: "gray", height: "14px", width: "14px" }} />
        </Tooltip>
      );
    // case StepStatus.IN_PROGRESS:
    case 4:
      return (
        <Tooltip title={message}>
          {/* title="in progress"> */}
          <CircularProgress
            style={{
              width: "11px",
              height: "11px",
              color: "#0036af",
              marginLeft: "10px",
            }}
          />
        </Tooltip>
      );
    // case StepStatus.NOT_STARTED:
    case 5:
      return (
        <Tooltip title={message}>
          {/* title="not started"> */}
          <ErrorIcon
            style={{ color: "black", height: "14px", width: "14px" }}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="not started">
          <ErrorIcon
            style={{ color: "black", height: "14px", width: "14px" }}
          />
        </Tooltip>
      );
  }
};

const LabelType = ({ type, bgcolor, textColor = "#ffffff" }) => {
  return (
    <div
      className="tv-label-type"
      style={{ backgroundColor: bgcolor, color: textColor }}
    >
      {type}
    </div>
  );
};

const StepDataContent = ({
  iconClass,
  textClass,
  letter,
  data,
  tooltipData,
  isTooltip = true,
  option = false,
}) => {
  return (
    <div className="tv-step-data">
      <img src={db} alt="db" height={16} width={16} />
      {option && <div>{data}</div>}
      {isTooltip && (
        <Tooltip title={tooltipData}>
          <div className={`tv-variable-icon ${iconClass}`}>{letter}</div>
        </Tooltip>
      )}
      {!option && <div className={textClass}>{data}</div>}
    </div>
  );
};

const StepData = ({ data }) => {
  if (!data?.value) return null;
  const renderContent = () => {
    switch (data.data_type) {
      case 0:
        return (
          <StepDataContent data={data.value} isTooltip={false} option={true} />
        );
      case 1:
        return (
          <StepDataContent
            iconClass={"tv-g-icon"}
            textClass={"tv-text-violet"}
            letter={"G"}
            data={data.value}
            tooltipData={data.value}
          />
        );
      case 2:
        return (
          <StepDataContent
            iconClass={"tv-l-icon"}
            textClass={"tv-text-blue"}
            letter={"L"}
            data={data.value}
            tooltipData={data.value}
          />
        );
      case 3:
        return (
          <StepDataContent
            iconClass={"tv-g-icon"}
            letter={"G"}
            data={data.value}
            tooltipData={data.save_data_name}
            option={true}
          />
        );
      case 4:
        return (
          <StepDataContent
            iconClass={"tv-f-icon"}
            textClass={"tv-text-cyan"}
            letter={"F"}
            data={data.save_data_name}
            tooltipData={data.value}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

const SaveResponseData = ({ data }) => {
  if (!data?.value) return null;
  const renderContent = () => {
    return (
      <StepDataContent data={data.value} isTooltip={false} option={true} />
    );
  };

  return <>{renderContent()}</>;
};

const TreeNode = ({ node }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(node?.isExpanded ? node.isExpanded : false);
  }, [node]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const hasSteps = node?.steps && node.steps.length > 0;
  const hasIterations = node?.iterations && node.iterations.length > 0;

  const isLoopInstr = doesLoopInstrTypeExist(node?.inst?.instr_type);
  const isGroupInstr = doesGroupInstrTypeExist(node?.inst?.instr_type);

  const getIconLabel = (iconText, labelText) => {
    let parentClass = "",
      childClass = "";

    switch (iconText) {
      case "G":
        parentClass = "tv-text-violet";
        childClass = "tv-variable-icon tv-g-icon";
        break;
      case "L":
        parentClass = "tv-text-blue";
        childClass = "tv-variable-icon tv-l-icon";
        break;

      default:
        break;
    }

    return `<span class="tv-value-wrap ${parentClass}"><span class="${childClass}">${iconText}</span>${labelText}</span>`;
  };

  const createStepLabel = (inst) => {
    const card = getInstruction(inst.instr_type);
    const cardInstrArray = card.instr.split(" ");
    if (!cardInstrArray.length) return "";
    let count = 0;
    const updatedArray = cardInstrArray.map((element) => {
      if (
        element === '"Text"' ||
        element === "label" ||
        element === "contextual_label"
      ) {
        let result = "";
        if (count === 0) {
          result =
            inst?.label_type === 1
              ? getIconLabel("G", inst.label)
              : inst?.label_type === 2
              ? getIconLabel("L", inst.label)
              : `"${inst.label}"`;
        } else if (count === 1) {
          result =
            inst?.contextual_label_type === 1
              ? getIconLabel("G", inst.contextual_label)
              : inst?.label_type === 2
              ? getIconLabel("L", inst.contextual_label)
              : `"${inst.contextual_label}"`;
        } else {
          result = `"${inst.label}"`;
        }
        count++;
        return result;
      }
      return element;
    });
    return `<span class="tv-step-details">${updatedArray.join(" ")}</span>`;
  };
  const createFixedLabelStep = (inst) => {
    const card = getInstruction(inst.instr_type);
    const cardInstrArray = card.instr.split(" ");
    if (!cardInstrArray.length) return "";
    let count = 0;
    const updatedArray = cardInstrArray.map((element) => {
      if (
        element === '"Text"' ||
        element === "label" ||
        element === "contextual_label"
      ) {
        let result = "";
        if (count === 0) {
          result =
            inst?.data?.data_type === 1
              ? getIconLabel("G", inst.data.value)
              : inst?.data?.data_type === 2
              ? getIconLabel("L", inst.data.value)
              : `"${inst.data.value}"`;
        } else if (count === 1) {
          result =
            inst?.expected_value?.data_type === 1
              ? getIconLabel("G", inst.expected_value.value)
              : inst?.expected_value?.data_type === 2
              ? getIconLabel("L", inst.expected_value.value)
              : `"${inst.expected_value.value}"`;
        } else {
          result = `"${inst.data.value}"`;
        }
        count++;
        return result;
      }
      return element;
    });
    return `<span class="tv-step-details">${updatedArray.join(" ")}</span>`;
  };

  const getStepName = () => {
    if (node?.inst) {
      switch (node?.inst?.instr_type) {
        case 1: // Click on label
        case 2: // click on label after contextual_label
        case 3: // click on label for contextual_label
        case 4: // click on label before contextual_label
        case 6: // Enter label
        case 8: // Enter label after contextual_label
        case 9: // Enter label for contextual_label
        case 10: // Enter label before contextual_label
        case 26: // Hover on label
        case 40: // Select label
        case 68: // Upload file in label
        case 90: // verify label is on screen
        case 91: // verify label after contextual_label
        case 93: // verify label for contextual_labe
        case 94: // verify label before contextual_label
        case 100: // assert label is on screen
        case 101: // assert label after contextual_label
        case 103: // assert label for contextual_label
        case 104: // assert label before contextual_label
          return createStepLabel(node.inst);
        case 95: // verify label is contextual_label
        case 96:
        case 97:
        case 98:
        case 99:
        case 105:
        case 106:
        case 107:
        case 108:
        case 109:
          return createFixedLabelStep(node.inst);
        case 200:
          // group
          return `<span class="tv-group-instr">${node.inst.instr
            .replace("X", node.inst.x_label)
            .replace("Y", node.inst.y_label)}</span>`;
        case 201: // loop X
          return `<span class="tv-group-instr">${node.inst.instr.replace(
            "X",
            node.inst.x_label
          )}</span>`;
        case 202: // loop all
          return `<span class="tv-group-instr">${node.inst.instr}</span>`;
        case 400: // Group
        case 401:
          return `<span class="tv-group-instr">${node.inst.instr}</span>`;
        case 600: // API's
        case 601:
        case 602:
        case 603:
        case 604:
          return `<span class="tv-api-instr"><span>${node.inst.method}</span>${node.inst.name}</span>`;
        case 700: // If
          return `<span class="tv-api-instr"><span>SCR</span>${node.inst.name}</span>`;
        default:
          return node.inst.instr;
      }
    } else if (node?.iteration) {
      return node?.iteration;
    }
  };

  const stepName = getStepName();

  return (
    <div className="tv-toggle-wrap">
      <div
        onClick={toggleExpand}
        className={`tv-toggle-container ${
          isExpanded && !isLoopInstr && !isGroupInstr && !node?.iteration
            ? ""
            : "tv-border-bottom"
        }`}
      >
        {(hasSteps || node?.data) && (
          <img
            src={isExpanded ? ArrowDownIcon : ArrowRightIcon}
            className="tv-arrow-btn"
            alt="arrow"
          />
        )}
        {node?.status > -1 && (
          <>
            {node.status !== 4
              ? getStatusIcon(node.status, node.message)
              : getStatusIcon(node.status, node.message)}
          </>
        )}

        {isGroupInstr && <LabelType bgcolor={"#10B981"} type={"group"} />}
        {isLoopInstr && <LabelType bgcolor={"#ff9900"} type={"loop"} />}

        <div
          className="tv-label-name"
          dangerouslySetInnerHTML={{
            __html: stepName,
          }}
        ></div>

        {node?.ordinal ? (
          <Tooltip title={"Ordinal"}>
            <div className="tv-ordinal">{node?.ordinal}</div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      {isExpanded && (
        <>
          {hasSteps && !hasIterations && (
            <div>
              {node.steps.map((child) => (
                <TreeNode key={child.unique_id} node={child} />
              ))}
            </div>
          )}

          {!hasSteps && hasIterations && (
            <div>
              {node.iterations.map((child) => (
                <TreeNode key={child.iteration} node={child} />
              ))}
            </div>
          )}

          {!isLoopInstr && !isGroupInstr && !node?.iteration && (
            <div className="tv-step-container tv-label-name">
              {node?.inst && (
                <>
                  {node.inst?.resolved_data &&
                    Object.keys(node.inst.resolved_data).length > 0 && (
                      <div className="tv-resolved-data">
                        {Object.entries(node.inst.resolved_data).map(
                          ([key, value]) => (
                            <div className="tv-resolved-key-value" key={key}>
                              <div>{key}</div>
                              <div>:</div>
                              <div className="tv-resolved-data-value">
                                {node.inst?.instr_type === 68
                                  ? value.substring(value.lastIndexOf("/") + 1)
                                  : value}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                </>
              )}

              {node.data && <StepData data={node.data} />}

              {node?.save_response && node?.save_response.length > 0 && (
                <div className="tv-resolved-data">
                  {node.save_response.map((obj, index) => {
                    const { key } = obj;
                    return (
                      <div className="tv-resolved-key-value" key={index}>
                        <div>{key}</div>
                        {/* <div>:</div>
                          <div className="tv-resolved-data-value">
                            {value}
                          </div> */}
                      </div>
                    );
                  })}
                </div>
              )}

              {node?.resolved_data &&
                Object.keys(node?.resolved_data).length > 0 && (
                  <div className="tv-resolved-data">
      
                    {Object.entries(node.resolved_data).map(([key, value]) => (
                      <div className="tv-resolved-key-value" key={key}>
                        <div>{key}</div>
                        <div>:</div>
                        <div className="tv-resolved-data-value">
                          {node?.inst?.instr_type === 68
                            ? value.substring(value.lastIndexOf("/") + 1)
                            : value}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const TreeViewReport = ({ reportData }) => {
 

  return (
    <div>
      {reportData.map((node) => (
        <TreeNode key={node.unique_id} node={node} />
      ))}
    </div>
  );
};

export default TreeViewReport;

// const isIfInstr = doesIfInstrTypeExist(card.inst.instr_type);
// const isElseIfInstr = doesElseIfInstrTypeExist(card.inst.instr_type);
// const isElseInstr = ElseInstrType == card.inst.instr_type && true;
// const isApiInstr = [600, 601, 602, 603, 604].includes(card.inst.instr_type);

// {
/* {node?.inst?.instr
            ? node?.inst?.instr
            : node?.iteration
            ? node?.iteration
            : ""} */
// }
// {
/* {stepName} */
// }

// {
/* {node.type === "iteration" && <div>{node.type}</div>} */
// }

//   node?.inst?.instr
//     ? node?.inst?.instr
//     : node?.iteration
//     ? node?.iteration
//     : ""
// );

// return node.inst.instr.replace("Text", node.inst.label);

// {
/* {node?.inst && (
            <span className="tv-step-value tv-step-details">
              {node.inst?.label_type === 1 && (
                <span class="tv-value-wrap tv-text-violet">
                  <span class="tv-variable-icon tv-g-icon">G</span>
                  {node.inst?.label}
                </span>
              )}

              {node.inst?.label_type === 2 && (
                <span class="tv-value-wrap tv-text-blue">
                  <span class="tv-variable-icon tv-l-icon">L</span>
                  {node.inst?.label}
                </span>
              )}

              {node.inst?.contextual_label_type === 1 && (
                <span class="tv-value-wrap tv-text-violet">
                  <span class="tv-variable-icon tv-g-icon">G</span>
                  {node.inst?.contextual_label}
                </span>
              )}

              {node.inst?.contextual_label_type === 2 && (
                <span class="tv-value-wrap tv-text-blue">
                  <span class="tv-variable-icon tv-l-icon">L</span>
                  {node.inst?.contextual_label}
                </span>
              )}
            </span>
          )} */
// }

// const treeData = [
//   {
//     id: 1,
//     status: 0,
//     name: "test",
//     type: "group",
//     children: [
//       {
//         id: 11,
//         status: 0,
//         name: "test1",
//         type: "loop",
//         children: [
//           {
//             id: 111,
//             status: 0,
//             name: "1",
//             type: "iteration",
//             children: [
//               {
//                 id: 1112,
//                 status: 0,
//                 name: "testdata",
//                 type: "step",
//                 data: { key: "key1", value: "value1" },
//                 children: [],
//               },
//               {
//                 id: 1113,
//                 status: 2,
//                 name: "testdata1",
//                 type: "step",
//                 data: "text1",
//                 children: [],
//               },
//             ],
//           },
//           {
//             id: 112,
//             status: 0,
//             name: "2",
//             type: "iteration",
//             children: [
//               {
//                 id: 1121,
//                 status: 0,
//                 name: "testdata",
//                 type: "step",
//                 data: { key: "key1", value: "value1" },
//                 children: [],
//               },
//               {
//                 id: 1122,
//                 status: 2,
//                 name: "testdata1",
//                 type: "step",
//                 data: "text1",
//                 children: [],
//               },
//             ],
//           },
//         ],
//       },
//       {
//         id: 12,
//         status: 2,
//         name: "test1",
//         type: "loop",
//         children: [
//           {
//             id: 121,
//             status: 0,
//             name: "1",
//             type: "iteration",
//             children: [
//               {
//                 id: 1211,
//                 status: 1,
//                 name: "testdata",
//                 type: "step",
//                 data: { key: "key1", value: "value1" },
//                 children: [],
//               },
//               {
//                 id: 1212,
//                 status: 2,
//                 name: "testdata1",
//                 type: "step",
//                 data: "text1",
//                 children: [],
//               },
//             ],
//           },
//           {
//             id: 122,
//             status: 0,
//             name: "2",
//             type: "iteration",
//             children: [
//               {
//                 id: 1221,
//                 status: 1,
//                 name: "testdata",
//                 type: "step",
//                 data: { key: "key1", value: "value1" },
//                 children: [],
//               },
//               {
//                 id: 1222,
//                 status: 2,
//                 name: "testdata1",
//                 type: "step",
//                 data: "text1",
//                 children: [],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 2,
//     status: 1,
//     name: "test",
//     type: "group",
//     children: [
//       {
//         id: 21,
//         status: 3,
//         name: "testdata",
//         type: "step",
//         data: { key: "key1", value: "value1" },
//         children: [],
//       },
//       {
//         id: 22,
//         status: 2,
//         name: "testdata1",
//         type: "step",
//         data: "text1",
//         children: [],
//       },
//     ],
//   },
//   {
//     id: 3,
//     status: 1,
//     name: "test",
//     type: "group",
//     children: [
//       {
//         id: 31,
//         status: 2,
//         name: "testdata",
//         type: "step",
//         data: { key: "key1", value: "value1" },
//         children: [],
//       },
//       {
//         id: 32,
//         status: 3,
//         name: "testdata1",
//         type: "step",
//         data: "text1",
//         children: [],
//       },
//       {
//         id: 33,
//         status: 1,
//         name: "testdata1",
//         type: "group",
//         children: [
//           {
//             id: 331,
//             status: 2,
//             name: "testdata",
//             type: "step",
//             data: { key: "key1", value: "value1" },
//             children: [],
//           },
//           {
//             id: 332,
//             status: 3,
//             name: "testdata1",
//             type: "step",
//             data: "text1",
//             children: [],
//           },
//         ],
//       },
//     ],
//   },
// ];
