const initialState = {
  cards: [],
  statusMessage: "",
  savedGlobalData: [],
  existingGroups: [],
  conditionActive: [],

  linkedLocalDataId: null,
  currentTestcaseName: null,
  sessionIdForNewTC: null,

};


const deleteCard = (cards, deletedCardStepId) => {
  const deleteAndUpdateIds = (cardsArray, parentId = '') => {
    let nextIndex = 1;
    return cardsArray.reduce((acc, card) => {
      if (card.step_id === deletedCardStepId) {
        return acc; // Skip this card as it's being deleted
      }

      const currentParts = card.step_id.split('.');
      const newParts = parentId ? [...parentId.split('.'), nextIndex.toString()] : [nextIndex.toString()];
      const newStepId = newParts.join('.');

      nextIndex++;

      const updatedCard = { ...card, step_id: newStepId };

      if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
        updatedCard.sub_steps = deleteAndUpdateIds(updatedCard.sub_steps, newStepId);
      }

      return [...acc, updatedCard];
    }, []);
  };

  return deleteAndUpdateIds(cards);
};


const cardsReducer = (state = initialState, action) => {

  switch (action.type) {
    case "RESET_CARDS":
      console.log("RESET_CARDS");
      return {
        ...state,
        cards: [],
      };

    case "SET_SESSION_ID_FOR_NEW_TC":
      console.log("SET_SESSION_ID_FOR_NEW_TC");
      return {
        ...state,
        sessionIdForNewTC: action.payload,
      };

    case "ADD_CARD":
      console.log("ADD_CARD", action.payload);
      const {
        inst = {
          instr_type: null,
          instr: "",
          label_type: null,
          label: "",
          contextual_label_type: null,
          contextual_label: "",
        },
        step_id,
        data,
        status,
        ordinal,
        do_ordinal_selection,
        sub_steps = [],
        message,
      } = action.payload;

      const cardDetails = {
        inst,
        step_id,
        data,
        status,
        ordinal,
        do_ordinal_selection,
        sub_steps,
        parent_id: null,
        message,
      };
      if (action.payload.group_id) {
        // console.log("group_id", action.payload.group_id);
        cardDetails.group_id = action.payload.group_id;
      }
      if (action.payload.group_hash) {
        cardDetails.group_hash = action.payload.group_hash;
      }

      const addCardToHierarchy = (cards, newCard) => {
        const stepIdParts = newCard.step_id.split(".");
        const parentStepId = stepIdParts.slice(0, -1).join(".");
        const currentStepId = stepIdParts.join(".");

        if (parentStepId === "") {
          // This is a top-level card
          return insertCard(cards, newCard);
        }

        return cards.map((card) => {
          if (card.step_id === parentStepId) {
            const updatedCard = {
              ...card,
              sub_steps: insertCard(
                card.sub_steps ? [...card.sub_steps] : [],
                newCard
              ),
            };
            newCard.parent_id = parentStepId;
            return updatedCard;
          }

          if (card.sub_steps && card.sub_steps.length > 0) {
            return {
              ...card,
              sub_steps: addCardToHierarchy(card.sub_steps, newCard),
            };
          }

          return card;
        });
      };

      const insertCard = (cards, newCard) => {
        const existingIndex = cards.findIndex(
          (card) => card.step_id === newCard.step_id
        );
        if (existingIndex !== -1) {
          const newSubSteps = [
            ...cards.slice(0, existingIndex),
            newCard,
            ...cards.slice(existingIndex),
          ];
          return adjustStepIds(newSubSteps, existingIndex + 1);
        } else {
          return [...cards, newCard];
        }
      };

      const adjustStepIds = (cards, startIndex) => {
        return cards.map((card, index) => {
          if (index >= startIndex) {
            const stepIdParts = card.step_id.split(".");
            stepIdParts[stepIdParts.length - 1] = (
              parseInt(stepIdParts[stepIdParts.length - 1], 10) + 1
            ).toString();
            const newStepId = stepIdParts.join(".");

            return {
              ...card,
              step_id: newStepId,
              sub_steps: adjustSubStepIds(
                card.sub_steps ? [...card.sub_steps] : [],
                newStepId
              ),
            };
          }
          return card;
        });
      };

      const adjustSubStepIds = (subSteps, parentStepId) => {
        return subSteps.map((subStep, index) => {
          const newSubStepId = `${parentStepId}.${index + 1}`;

          return {
            ...subStep,
            step_id: newSubStepId,
            sub_steps: adjustSubStepIds(
              subStep.sub_steps ? [...subStep.sub_steps] : [],
              newSubStepId
            ),
          };
        });
      };

      const newCards = addCardToHierarchy([...state.cards], cardDetails);

      console.log("ADD_CARD", newCards);
      return {
        ...state,
        cards: newCards,
      };

    case "DELETE_CARD":
      console.log("DELETE_CARDS");
      return {
        ...state,
        cards: deleteCard(state.cards, action.payload.id),
      };

    case "EDIT_CARD":
      const updateCard = (cards, step_id, updateData) => {
        return cards.map((card) => {
          if (card.step_id === step_id) {
            // Update the card with the new data
            let updatedCard = {
              ...card,
              inst: {
                ...card.inst,
                ...updateData.inst,
              },
              data: updateData.data,
              status: updateData.status,
              ordinal: updateData.ordinal,
              message: updateData.message,
              do_ordinal_selection: updateData.do_ordinal_selection,
            };

            // Check and add group_id and group_hash if they exist in action.payload
            if (updateData.group_id) {
              updatedCard.group_id = updateData.group_id;
            }
            if (updateData.group_hash) {
              updatedCard.group_hash = updateData.group_hash;
            }

            return updatedCard;
          }

          // Recursively update sub_steps if present
          if (card.sub_steps) {
            return {
              ...card,
              sub_steps: updateCard(card.sub_steps, step_id, updateData),
            };
          }

          return card;
        });
      };

      const updatedCards = updateCard(
        state.cards,
        action.payload.step_id,
        action.payload
      );

      return {
        ...state,
        cards: updatedCards,
      };


    case "SET_STATUS_MESSAGE":
      return {
        ...state,
        statusMessage: action.payload,
      };

    case "SET_CONDITION_ACTIVE":
      // console.log("action.payload", action.payload);
      return {
        ...state,
        conditionActive: [...state.conditionActive, action.payload],
      };

    case "POP_CONDITION_ACTIVE":
      return {
        ...state,
        conditionActive: state.conditionActive.slice(0, -1),
      };

    case "SET_LINKED_LOCAL_DATA_ID":
      return {
        ...state,
        linkedLocalDataId: action.payload,
      };

    case "SET_CURRENT_TESTCASE_NAME":
      return {
        ...state,
        currentTestcaseName: action.payload,
      };

    case "SET_EXISTING_GROUPS":
      return {
        ...state,
        existingGroups: action.payload,
      };
    default:
      return state;
  }
};

export default cardsReducer;
