import React from "react";

const ScrollContainer = ({
  height = "100%",
  width = "100%",
  children,
  className = "",
  isVertical = true,
}) => {
  return (
    <>
      <div
        className={`scroll-container ${
          isVertical ? "vertical" : "horizontal"
        } ${className}`}
        style={{ height: `${height}`, width: `${width}`, position: "relative" }}
      >
        {children}
      </div>
      <style jsx="true">{`
        .scroll-container.vertical {
          overflow-y: auto;
          overflow-x: hidden;
        }

        .scroll-container.horizontal {
          overflow-y: hidden;
          overflow-x: auto;
        }

        .scroll-container.vertical::-webkit-scrollbar {
          width: 8px;
        }

        .scroll-container.horizontal::-webkit-scrollbar {
          height: 8px;
        }

        .scroll-container::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 4px;
        }

        .scroll-container::-webkit-scrollbar-thumb {
          background-color: #dfdfdf;
          border-radius: 4px;
        }

        .scroll-container::-webkit-scrollbar-thumb:hover {
          background: #aaaaaa;
        }
      `}</style>
    </>
  );
};

export default ScrollContainer;
