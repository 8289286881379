import pauseIcon from "../../../../../assets/testcaseeditor/red-pause-icon.svg";
import infoIcon from "../../../../../assets/info-icon.svg";
import playIcon from "../../../../../assets/testcaseeditor/greem-play-icon.svg";
import optionIcon from "../../../../../assets/testcaseeditor/option-icon.svg";
import firefoxIcon from "../../../../../assets/firefox.svg";
import chromeIcon from "../../../../../assets/chrome.svg";

import { useState } from "react";
import "./vcNav.css";

const VcNav = ({ sessionId, handleRecorderClick, recordActive }) => {
  const verifyDropdownData = [
    'Verify _css{"Selector"} begins with "Text"',
    'Verify _css{"Selector"} begins with "Text"',
    'Verify _css{"Selector"} begins with "Text"',
    'Verify _css{"Selector"} begins with "Text"',
    'Verify _css{"Selector"} begins with "Text"',
    'Verify _css{"Selector"} begins with "Text"',
  ];

  const [verifyDropdownActive, setVerifyDropdownActive] = useState(false);
  const [hoverDropdownActive, setHoverDropdownActive] = useState(false);

  const onHoverClick = () => {
    setHoverDropdownActive(!hoverDropdownActive);
  };

  const onVerifyClick = () => {
    setVerifyDropdownActive(!verifyDropdownActive);
  };

  const handleVerifyClick = () => {
    setVerifyDropdownActive(!verifyDropdownActive);
  };

  const handleVerifyDropdownClick = (event) => {
    event.stopPropagation();
  };
  return (
    sessionId ? (
      <div className="websoket-nav" style={{ height: "auto" }}>
        <div className="websoket-nav__options">

          <div
            onClick={handleRecorderClick}
            className="websoket-nav__option record-btn"
            style={{
              marginRight: "12px",
            }}
          >
            {recordActive ? (
              <>
                <img src={pauseIcon} alt="stop" />
                <p style={{ margin: "0xp 2px" }}>Recorder is ON</p>
                <img src={infoIcon} alt="info" />
              </>
            ) : !recordActive ? (
              <>
                <img src={playIcon} alt="record" />
                <p style={{ margin: "0xp 2px" }}>Record</p>
                <img src={infoIcon} alt="info" />
              </>
            ) : (
              <></>
            )}
          </div>



          {!recordActive ? (
            <div className="websoket-nav__dropdown-container">
              <div className="browser-dropdown">
                <select className="form-select">
                  <option value="chrome">
                    <div className="dropdown-option">
                      <img src={chromeIcon} alt="chrome" />
                      <p>Chrome</p>
                    </div>
                  </option>
                  <option value="firefox">
                    <div className="dropdown-option">
                      <img src={firefoxIcon} alt="firefox" />
                      <p>Firefox</p>
                    </div>
                  </option>
                </select>
              </div>

              <div className="platform-dropdown">
                <select className="form-select">
                  <option value="1">Web</option>
                  <option value="2">linux</option>
                </select>
              </div>
            </div>
          ) : (
            <div className="websoket-nav__hover-verify">
              <div className="websoket-nav__hover">
                Hover
                <img src={optionIcon} alt="" />
              </div>
              <div onClick={handleVerifyClick} className="websoket-nav__verify">
                Verify
                <img src={optionIcon} alt="" />
                <div
                  style={{
                    display: verifyDropdownActive ? "flex" : "none",
                  }}
                  className="websoket-nav__verify-dropdown"
                >
                  {verifyDropdownData.map((itm, index) => (
                    <p key={index} onClick={handleVerifyDropdownClick}>
                      {itm}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ) : (<></>)
  );
};

export default VcNav;
