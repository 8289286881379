import "./testcaseModal.css";
import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { cloneTestCase } from "../../services/settingServices";

const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;

const CloneModal = (props) => {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [testCaseDetails, setTestCaseDetails] = React.useState({
    name: "",
    url: "",
    id: props ? props.id : null,
  });

  const [errors, setErrors] = React.useState({
    nameError: "",
    urlError: "",
  });

  const validateName = (name) => {
    if (!name.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nameError: "Name can't be empty.",
      }));
      return false;
    } else if (props.testCaseData && name) {
      const duplicateName = props.testCaseData.filter((item) => {
        return item.name == name;
      });
      if (duplicateName.length > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: "Duplicate name",
        }));
        return false;
      }
    }
    return true;
  };

  const validateURL = (url) => {
    if (!url.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        urlError: "URL can't be empty.",
      }));
      return false;
    } else if (!urlRegex.test(url)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        urlError: "Enter a valid URL.",
      }));
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTestCaseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: "",
    }));
  };

  const handleClone = async () => {
    const { name } = testCaseDetails;
    const apiUrl = process.env.REACT_APP_API_URL;
    if (validateName(name)) {
      setShowCircularProgress(true);

      const data_to_send = {
        testcase_name: name,
        testcase_id: props.cloneData.test_case_id,
        user_id: 1,
      };
 

      // await fetch(`${apiUrl}/platform/clone_testcase/`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data_to_send),
      // })
      await cloneTestCase(data_to_send)
        .then((response) => {
          if (response.status !== 200 || !response.data) {
            throw new Error("Network response was not ok");
          }
 
          return response.data; // Parse the JSON of the response
        })
        .then((data) => {
 
          setShowCircularProgress(false);
          navigate(`/Test-case/${data.testcase_id}`, {
            state: {
              session_id: data.session_id,
              CreatedTestCaseName: data_to_send.name,
            },
          }); // Use the navigate function with the testcase_id
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          setShowCircularProgress(false);
          setErrors("An error occurred, please try again later.");
        });

      props.closebychildmodal();
    }
  };

 

  useEffect(() => {
    if (!errors.nameError && testCaseDetails.name.trim().length >= 1) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [errors.nameError, testCaseDetails.name]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    background: "white",
    border: "px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container_testcasemodal">
            <div className="top-content_testcasemodal">
              <div>Clone Test Case</div>
              <div
                onClick={props.closebychildmodal}
                className="close_button_testcasemodal"
              >
                <CloseIcon />
              </div>
            </div>
            <div className="name-input_testcasemodal">
              <div>Cloning from</div>
              <div>
                <input
                  name="cloningFromTestCase"
                  value={props.cloneData.name}
                  disabled
                  className="input_textcasemodal"
                />
              </div>
            </div>

            <div className="name-input_testcasemodal">
              <div>Name of the new test case.</div>
              <div>
                <input
                  name="name"
                  value={testCaseDetails.name}
                  onChange={handleChange}
                  className="input_textcasemodal"
                />
                {errors.nameError && (
                  <div className="error">{errors.nameError}</div>
                )}
              </div>
            </div>

            {/* <div className="url-input_testcasemodal">
              <div>URL</div>
              <div>
                <input
                  name="url"
                  value={testCaseDetails.url}
                  onChange={handleChange}
                  className="input_textcasemodal"
                />
                {errors.urlError && (
                  <div className="error">{errors.urlError}</div>
                )}
              </div>
            </div> */}

            <div className="button_container_testcasemodal">
              <div>
                <button
                  className="button_testcasemodal"
                  onClick={props.closebychildmodal}
                >
                  Cancel
                </button>
              </div>

              <div>
                {!showCircularProgress && (
                  <button
                    onClick={handleClone}
                    className={
                      isButtonDisabled
                        ? "button_testcasemodalcreate_disabled"
                        : "button_testcasemodalcreate"
                    }
                  >
                    <span>Clone</span>
                  </button>
                )}
                {showCircularProgress && (
                  <button className="button_testcasemodalcreate-spinner">
                    <span>
                      <CircularProgress
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CloneModal;
