import React, {
  forwardRef,
  useMemo,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
} from "react";
import { Table, Input } from "antd";
import { fetchTestcases } from "../../../../services/suitesServices";

const TestCases = forwardRef(({ selectedTestCases = [], errors }, ref) => {
  const [loading, setLoading] = useState(false);
  const [testCases, setTestCases] = useState([]);
  const [checkedTestCases, setCheckedTestCases] = useState([]);
  const [searchText, setSearchText] = useState("");
  const page = useRef(1);
  const isNext = useRef(true);

  useImperativeHandle(ref, () => ({
    getCheckedTestCases: () => checkedTestCases,
  }));

  const removeDuplicateIds = (array) => {
    const uniqueMap = new Map();
    array.forEach((testCase) => {
      if (
        !selectedTestCases.some(
          (selected) => selected.id === testCase.test_case_id
        ) &&
        !checkedTestCases.some(
          (checked) => checked.test_case_id === testCase.test_case_id
        )
      ) {
        uniqueMap.set(testCase.test_case_id, testCase);
      }
    });
    return Array.from(uniqueMap.values());
  };

  const setCheckStatus = (row) => {
    if (!row) return;
    const rowElement = document
      .querySelector(`.test-case-name[data-row-id="${row.test_case_id}"]`)
      .closest("tr");

    if (rowElement) {
      if (rowElement.classList.contains("ant-table-row-selected")) {
      } else if (rowElement.classList.contains("highlight-row")) {
        rowElement.classList.remove("highlight-row");
      } else {
        rowElement.classList.add("highlight-row");
      }
    }

    const isChecked = checkedTestCases.some(
      (tc) => tc.test_case_id === row.test_case_id
    );
    if (isChecked) {
      setCheckedTestCases((prev) =>
        prev.filter((tc) => tc.test_case_id !== row.test_case_id)
      );
      setTestCases((prev) => [...prev, row]);
    } else {
      setTimeout(() => {
        setCheckedTestCases((prev) => [...prev, row]);
        setTestCases((prev) =>
          prev.filter((tc) => tc.test_case_id !== row.test_case_id)
        );
      }, 1000);
    }
  };

  const getTestCases = async (pageNumber = 1, search = "") => {
    try {
      setLoading(true);
      const data = await fetchTestcases(pageNumber, search);
      if (data && data.results && data.results.length) {
        const newTestCases = removeDuplicateIds(data.results);
        setTestCases((prev) =>
          pageNumber === 1 ? newTestCases : [...prev, ...newTestCases]
        );
        isNext.current = !!data.next;
      } else {
        isNext.current = false;
        if (pageNumber === 1) setTestCases([]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      isNext.current
    ) {
      page.current += 1;
      getTestCases(page.current, searchText);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    page.current = 1;
    if (value.length > 2) {
      getTestCases(1, value);
    } else if (value === "") {
      getTestCases(1);
    }
  };

  useEffect(() => {
    getTestCases(1, searchText);
  }, []);

  useEffect(() => {
    if (
      testCases.length + checkedTestCases.length < 10 &&
      isNext.current &&
      !loading
    ) {
      getTestCases((page.current += 1), searchText);
    }
  }, [testCases]);

  const columns = [
    {
      title: <span className="tc-title">Test Case</span>,
      dataIndex: "name",
      key: "test_case_id",
      render: (_, record) => (
        <span
          className="test-case-name"
          data-row-id={record.test_case_id}
          onClick={() => setCheckStatus(record)}
        >
          {record.name}
        </span>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys: checkedTestCases.map((tc) => tc.test_case_id),
    onSelect: (record, selected) => {
      setCheckStatus(record);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const newChecked = [...checkedTestCases, ...changeRows];
        setCheckedTestCases(newChecked);
        setTestCases((prev) =>
          prev.filter(
            (tc) =>
              !changeRows.some((cr) => cr.test_case_id === tc.test_case_id)
          )
        );
      } else {
        const uncheckedIds = new Set(changeRows.map((row) => row.test_case_id));
        setCheckedTestCases((prev) =>
          prev.filter((tc) => !uncheckedIds.has(tc.test_case_id))
        );
        setTestCases((prev) => [...prev, ...changeRows]);
      }
    },
  };

  const filteredAndSortedTestCases = useMemo(() => {
    const lowercasedSearch = searchText.toLowerCase();
    const filtered = [...checkedTestCases, ...testCases].filter((tc) =>
      tc.name.toLowerCase().includes(lowercasedSearch)
    );
    return filtered.sort((a, b) => {
      const aChecked = checkedTestCases.some(
        (tc) => tc.test_case_id === a.test_case_id
      );
      const bChecked = checkedTestCases.some(
        (tc) => tc.test_case_id === b.test_case_id
      );
      if (aChecked && !bChecked) return -1;
      if (!aChecked && bChecked) return 1;
      return 0;
    });
  }, [checkedTestCases, testCases, searchText]);

  return (
    <div className="test-cases-container">
      <div className="tc-input-search">
        <Input.Search
          placeholder="Search test cases"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ marginBottom: "16px", width: "50%" }}
        />
      </div>

      <div className="tc-table-wrap">
        <div className="tc-count">
          {checkedTestCases.length > 0 ? checkedTestCases.length : ""}
        </div>
        <Table
          columns={columns}
          rowSelection={rowSelection}
          dataSource={filteredAndSortedTestCases}
          pagination={false}
          scroll={{ y: 300 }}
          loading={loading}
          onScroll={handleScroll}
          rowKey="test_case_id"
          style={{ height: "100%" }}
          id="testcases-table"
        />
      </div>
      <span className="tc-error test-case-error">
        {errors && errors.isTestCase
          ? "Please select at least one test case"
          : ""}
      </span>
    </div>
  );
});

TestCases.displayName = "TestCases";

export default TestCases;
