import React, { useState, useEffect } from "react";
import "./carausel.css";

const Carousel = ({ data, initialUid }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // console.log(data);

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? data.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    setCurrentIndex(currentIndex === data.length - 1 ? 0 : currentIndex + 1);
  };

  useEffect(() => {
    const initialIndex = data.findIndex((item) => item.uid === initialUid);
    if (initialIndex !== -1) {
      setCurrentIndex(initialIndex);
    }
  }, [data, initialUid]);

  return (
    <div
      className="carousel"
      style={{
        width: "100%",
        height: "610px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "relative",
          paddingLeft: "3rem",
          width: "100%",
          zIndex: 0,
        }}
      >
        {/* Content for the top fixed div */}
        <div
          style={{
            display: "flex",
            flexDirection: "row", // Align items horizontally
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "0.4px",
            textAlign: "left",
          }}
        >
          <p>
            Step: {currentIndex + 1} : {data[currentIndex].heading}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          padding: "20px",
          overflowY: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Content for the middle div */}
        <div className="carousel-image">
          <img
            style={{ height: "420px" }}
            src={data[currentIndex].pics[0].picsvg}
            alt={data[currentIndex].heading}
          />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          padding: "1rem",
          zIndex: 0,
        }}
      >
        {/* Content for the bottom fixed div */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            className="next"
            onClick={currentIndex === 0 ? null : goToPrevious}
          >
            <div
              style={{
                padding: "8px 10px",
                gap: "10px",
                borderRadius: "4px",
                background:
                  currentIndex === 0 ? "rgba(0, 54, 175, 0.8)" : "#0036AF",
                color: "white",
                border: "none",
                cursor: currentIndex === 0 ? "not-allowed" : "pointer",
                opacity: currentIndex === 0 ? "0.8" : "1",
              }}
            >
              <span>&#10094;</span>{" "}
              <span style={{ padding: "8px 10px", gap: "10px" }}>
                {currentIndex === 0 ? "Prev. Step" : "Prev. Step"}
              </span>
            </div>
          </button>

          <button
            className="next"
            onClick={currentIndex === data.length - 1 ? null : goToNext}
          >
            <div
              style={{
                padding: "8px 10px",
                gap: "10px",
                borderRadius: "4px",
                background:
                  currentIndex === data.length - 1
                    ? "rgba(0, 54, 175, 0.8)"
                    : "#0036AF",
                color: "white",
                border: "none",
                cursor:
                  currentIndex === data.length - 1 ? "not-allowed" : "pointer",
                opacity: currentIndex === data.length - 1 ? "0.8" : "1",
              }}
            >
              <span style={{ padding: "8px 10px", gap: "10px" }}>
                {currentIndex === data.length - 1 ? "Next. Step" : "Next. Step"}
              </span>{" "}
              <span>&#10095;</span>
            </div>
          </button>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Carousel;
