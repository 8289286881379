export const createFormData = (file, name) => {
  if (!file) {
    console.error("Please select atleast one file for upload.");
    return null;
  }
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  return formData;
};
