import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import {
  addCard,
  editCard,
  deleteCard,
  updateCard,
  resetCards,
  setStatusMessage,
  setConditionActive,
  setLinkedLocalDataId,
  setCurrentTestcaseName,
  popConditionActive,
  setSessionIdForNewTC,
} from "../../EcomTesting/Testaction/cardsActions";
import useWebSocket from "react-use-websocket";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import StepContainer from "./StepContainer";
import VideoContainer from "./videoContainer/VideoContainer";
import { toast } from "react-toastify";
import { useWebSocketContext } from "./../context/WebSocketContext";
import {
  TestcaseData,
  TestcaseDetails,
  fetch_existing_group_by_id,
  fetch_existing_groups,
  generateSessionId,
  updateTestcaseUrl,
} from "../../../services/testCaseServices";
import { apiWS } from "../../../services/apiServices";
import {
  fetch_global,
  fetch_localData_Col,
} from "../../../services/dataService";
import { doesLoopInstrTypeExist } from "./dropdowns/data/loop";
import { doesGroupInstrTypeExist } from "./dropdowns/data/group";
import { set } from "lodash";
import { useResponseContext } from "../context/ResponseContext";
import Botgaugeloader from "./Botgaugeloader";
import "./Botgaugeloader.css";
import { useSelector } from "react-redux";
import { setIsOnline } from "../../../redux/actions/isOnlineAction";
import { isIdLessThanOrEqualToCurrentStep } from "../../../utils/compare_step_id";

const TestSteps = ({
  cards,
  savedGlobalData,
  addCard,
  deleteCard,
  editCard,
  resetCards,
  setStatusMessage,
  setConditionActive,
  conditionActive,
  conditionStepId,
  setSessionIdForNewTC,
  sessionIdForNewTC,

  setLinkedLocalDataId,
  setCurrentTestcaseName,
  linkedLocalDataId,
  popConditionActive,

  setIsOnline
}) => {
  // const clientLink = "https://demoqa.com/login";
  const { id } = useParams();

  const authToken = localStorage.getItem("authToken");
  let url = `wss://${apiWS}/livesteps?token=${authToken}`;

  const {
    contentLoading,
    setContentLoading,
    sessionId,
    setSessionId,
    setTestSessionId,
  } = useWebSocketContext();

  const {
    activeScriptTab,
    updateActiveScriptTab,
    updateScriptData,

    updateActiveTab,

    setResponseLoading,
    setScriptResponseLoading,

    openingRestApi,
    setOpeningRestApi,

    openingScript,
    setOpeningScript,
  } = useResponseContext();



  const isOnline = useSelector(state => state.isOnline.isOnline);

  // for keeping track of the data_type 700
  const [ignoreMessages, setIgnoreMessages] = useState(false);
  const reconnectingRef = useRef(null);

  // console.log("isOnline?", navigator.onLine);

  const [addingStepsOn, setAddingStepsOn] = useState(null);
  const [editingStepsOn, setEditingStepsOn] = useState(null);
  const [sendPageLoad, setSendPageLoad] = useState(false);
  const [prevTestVideo, setPrevTestVideo] = useState(null);
  const [recordActive, setRecordActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [pausing, setPausing] = useState(false);
  const [paused, setPaused] = useState(true);
  const [disabled, setDisabled] = useState(false);
  // const [sessionId, setSessionId] = useState(
  //   location?.state?.session_id || null
  // );

  const [disableLoop, setDisableLoop] = useState(false);
  const [disableGroup, setDisableGroup] = useState(false);

  const [groupUpdating, setGroupUpdating] = useState(false);
  const [groupStepDeleting, setGroupStepDeleting] = useState(null);
  const [groupStepDelete, setGroupStepDelete] = useState(false);
  const [restApiOpen, setRestApiOpen] = useState(false);

  const [cardDataAddedWithNextBackArrow, setCardDataAddedWithNextBackArrow] =
    useState(null);
  // const [openingRestApi, setOpeningRestApi] = useState(false);
  const [received502, setReceived502] = useState(false);

  const [scriptModalOpen, setScriptModalOpen] = useState(false);

  const [globalRefresh, setGlobalRefresh] = useState(false);
  const triggerGlobalRefresh = () => {
    // console.log("triggering global refresh");
    setGlobalRefresh(!globalRefresh);
  };
  const [globalDataKeys, setGlobalDataKeys] = useState([]);
  const [refreshingGlobal, setRefreshingGlobal] = useState(false);
  const [apiModaleData, setApiModaleData] = useState();
  const isMounted = useRef(false);

  const fetchGlobalData = async () => {
    try {
      setRefreshingGlobal(true);
      const response = await fetch_global();
      if (!response || !response.data) {
        throw new Error("Failed to fetch global data");
      }
      const globalDataKeys = response.data.GlobalDatas.map((item) => item.key);
      setGlobalDataKeys(globalDataKeys);
      // setData(data);
    } catch (error) {
      throw error;
    } finally {
      setRefreshingGlobal(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGlobalData();
        console.log("refreshing global data");
      } catch (error) {
        toast.error("Error fetching global data");
      }
    };

    fetchData();
  }, [globalRefresh]);

  const [localRefresh, setLocalRefresh] = useState(false);
  const triggerLocalRefresh = () => {
    // console.log("triggering global refresh");
    setLocalRefresh(!localRefresh);
  };
  const [localDataCol, setLocalDataCol] = useState([]);
  const [refreshingLocal, setRefreshingLocal] = useState(false);

  const fetchLocalData = async () => {
    // if(linedl)
    try {
      if (linkedLocalDataId) {
        setRefreshingLocal(true);
        const response = await fetch_localData_Col(linkedLocalDataId);
        const localDataCol = response.columns;
        setLocalDataCol(localDataCol);
      }
    } catch (error) {
      throw error;
    } finally {
      setRefreshingLocal(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("in fetch");
        await fetchLocalData();
      } catch (error) {
        toast.error("Error fetching local data");
      }
    };

    fetchData();
  }, [localRefresh]);

  const cardExistsRecursive = (cards, step_id) => {
    const checkCard = (card) => {
      if (card.step_id === step_id) {
        return true;
      }
      if (card.sub_steps && card.sub_steps.length > 0) {
        return card.sub_steps.some(checkCard);
      }
      return false;
    };

    return cards.some(checkCard);
  };

  const handleOnline = useCallback(() => {
    setIsOnline(true);
    // console.log('Network is back online');
    toast.success("Network connection restored");
    window.location.reload();
  }, []);

  const handleOffline = useCallback(() => {
    setIsOnline(false);
    // console.log('Network is offline');
    toast.error("Network connection lost");
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [handleOnline, handleOffline]);

  const currentlyGeneratingStep = useRef("0");
  const handleMessage = useCallback(
    (event) => {
      if (event.data === "success") {
        if (reconnectingRef.current) {
          console.log("Reconnecting701");
          reconnectingRef.current = null;
          handleReconnect();
        }
        setDisabled(false);
        return;
      }

      const messageData = JSON.parse(event.data);

      if (ignoreMessages) {
        if (messageData.data_type === 701) {
          populateCards(messageData);
          setIgnoreMessages(false);
        }
        return;
      }

      if (messageData.overall_status && messageData.overall_status == 0) {
        console.log("Overall status is 0");
        setDisabled(false);
      }
      if (
        messageData.step &&
        // (messageData.step.inst === "open website" ||
        //   (typeof messageData.step.inst === "object" &&
        //     messageData.step.inst.instr === "open website")) &&
        messageData.step.step_id === "1"
      ) {
        handleSetStatusMessage("Generation is in progress");
        setDisabled(false);
      }

      if (messageData.step && messageData.data_type === 28) {
        const { inst, step_id, data, status } = messageData.step;
        const cardExists = cardExistsRecursive(cards, step_id);

        const newCard = {
          step_id,
          inst,
          data,
          status,
          ordinal: messageData.step.ordinal || "",
          message: messageData.step.message || "",
        };
        if (messageData.step.group_id) {
          newCard.group_id = messageData.step.group_id;
        }
        if (messageData.step.group_hash) {
          newCard.group_hash = messageData.step.group_hash;
        }

        triggerGlobalRefresh();

        // to keep track of the current in-progress step
        if(messageData.step.status == 4){
          console.log("currentlyGeneratingStep", step_id);
          currentlyGeneratingStep.current = step_id;
        } else {
          currentlyGeneratingStep.current = "0";
          console.log("currentlyGeneratingStep Reset");
        }

        if (cardExists && !recordActive) {
          console.log("Editing 28", newCard);
          editCard(newCard);
        } else {
          console.log("Adding 28", newCard);
          addCard(newCard);
          if (recordActive) {
            if (addingStepsOn !== null) {
              setAddingStepsOn((prev) => (parseInt(prev) + 1).toString());
            }
          }
        }
      }

      if (messageData.data_type === 29) {
        terminateSession();
      }

      if (messageData.data_type === 39) {
        setStopLoading(false);
        terminateSession();
        toast.error("Error occurred. Restart the session");
      }

      if (messageData.data_type === 42) {
        setPaused(true);
        setPausing(false);
        handleSetStatusMessage(
          "Generation is paused. Resume by clicking the play button on the desired step"
        );

        if (openingRestApi) {
          setRestApiOpen(true);
          sendJsonMessage({ data_type: 500 });
          // console.log("QOQOQO");
          handleOpenRestApiModal();
          console.log("Sending Message", { data_type: 500 });
          setOpeningRestApi(false);
        } else if (openingScript) {
          setScriptModalOpen(true);
          sendJsonMessage({ data_type: 500 });
          console.log("Sending Message", { data_type: 500 });
          setOpeningScript(false);
        }
      }

      if (messageData.data_type === 91) {
        setGroupUpdating(false);

        if (groupStepDeleting !== null) {
          // console.log("Group 0");
          setGroupStepDelete(true);
        }
        if (cardDataAddedWithNextBackArrow !== null) {
          // console.log("Arrow 1");
          handleAddCard(
            cardDataAddedWithNextBackArrow?.newCardData || {},
            cardDataAddedWithNextBackArrow?.newDataJson || {},
            cardDataAddedWithNextBackArrow?.newSubStepId || null
          );
          setCardDataAddedWithNextBackArrow(null);
          setEditingStepsOn(null);

          setAddingStepsOn(
            cardDataAddedWithNextBackArrow?.newIdToAddOn || null
          );
        }
      }

      if (messageData.data_type === 77) {
        // console.log("Ordinal selection", messageData);
        const { inst, step_id, data, status } = messageData.step;
        const { elements_length } = messageData;
        editCard({
          step_id,
          inst,
          data,
          status,
          do_ordinal_selection: "true",
          ordinal: elements_length,
        });
      }

      if (messageData.data_type === 301) {
        // console.log("messageData", messageData);
        updateActiveTab("5");
        setResponseLoading(false);
        setApiModaleData({ ...messageData.inst });
      }

      if (messageData.data_type === 303) {
        // console.log("scriptMessageData", messageData);
        updateActiveScriptTab("2");
        setScriptResponseLoading(false);
        updateScriptData({ ...messageData.inst });
      }

      if (messageData.data_type === 502) {
        console.log("messageData 502", messageData);
        setReceived502(true);
      }
    },
    [
      cards,
      addCard,
      editCard,
      recordActive,
      addingStepsOn,
      openingRestApi,
      openingScript,
      groupUpdating,
      groupStepDeleting,
      cardDataAddedWithNextBackArrow,
      ignoreMessages,
    ]
  );

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
    useWebSocket(sessionId ? url : null, {
      onOpen: () => {
        console.log("WebSocket connected");
        if (!sendPageLoad) {
          sendJsonMessage({ data_type: 0, session_id: sessionId });
          setSendPageLoad(true);
        }
        toast.success("Connection established");
      },
      onMessage: handleMessage,
      onClose: (event) => {
        console.log("WebSocket disconnected");
      },
      onError: (error) => {
        console.error("WebSocket error:", error);
        toast.error("Connection error");
      },
    });

  const handleReset = useCallback(() => {
    console.log("Resetting");
    resetCards();

    conditionActive.forEach(() => {
      popConditionActive();
    });
    setAddingStepsOn(null);
    setEditingStepsOn(null);
    setLinkedLocalDataId(null);
    setCurrentTestcaseName(null);
    handleSetStatusMessage("");
    setRecordActive(false);
    setPaused(true);
    setReceived502(false);
    setGroupStepDeleting(null);
    setSendPageLoad(false);
    currentlyGeneratingStep.current = "0";

  }, [resetCards, conditionActive, popConditionActive]);

  useEffect(() => {
    if (!isMounted.current) {
      console.log("Resetting2");
      handleReset();
      isMounted.current = true;
    }

    return () => {
      console.log("Resetting2");
      handleReset();
      // const socket = getWebSocket();
      // if (socket && socket.readyState === WebSocket.OPEN) {
      //   socket.close();
      // }
    };
  }, []);

  console.log("cards1", cards);
  const terminateSession = () => {
    const ws = getWebSocket();
    if (ws && ws.readyState === WebSocket.OPEN) {
      try {
        console.log("Terminating session");
        ws.close();

        handleReset();
        fetchTestcaseDetailsAfterTerminate();

        handleSetStatusMessage("Session has ended");
      } catch (error) {
        toast.error("Error stopping the session");
      }
    }
  };

  useEffect(() => {}, [conditionActive]);
  const fetchTestcaseDetailsAfterTerminate = async () => {
    console.log("Fetching testcase details after terminate");
    try {
      setLoading(true);
      const data = await TestcaseDetails(id);
      populateCards(data);
    } catch (error) {
      console.error("ERROR", error);
    } finally {
      setSessionId(null);
      setTestSessionId(null);
      // setSessionIdForNewTC(null);

      setLoading(false);

      setRecordActive(false);
      setAddingStepsOn(null);
      setEditingStepsOn(null);

      // setReconnectAttempts(0);
      // clearReconnectTimeout();

      reconnectingRef.current = null;
      setDisabled(false);
      setStopLoading(false);
    }
  };

  // Responsible for fetching local data id //
  const fetchTestcaseData = async (id) => {
    try {
      const data = await TestcaseData(id);

      console.log("Testcase_Data", data);

      const localDataId = data.local_data ?? null;

      if (localDataId) {
        console.log("Local data id1", localDataId);
        setLinkedLocalDataId(localDataId);
      } else {
        console.log("No local data id2");
      }
    } catch (error) {
      console.error("ERROR", error);
    }
  };
  useEffect(() => {
    fetchTestcaseData(id);

    return () => {
      setLinkedLocalDataId(null);
    };
  }, [id]);

  const sendMessage = (data) => {
    if (data) {
      sendJsonMessage(data);
    }
  };
  // websocket actions button
  const handlePlayClick = (event, id) => {
    handleConditionPop();
    setPaused(false);
    event.stopPropagation();
    // console.log("Playing card with ID:", id);
    if (recordActive) {
      closeRecorder();
      handleSetStatusMessage(
        "Recording has stopped, and generation is in progress"
      );
    } else {
      handleSetStatusMessage("Generation is in progress");
    }

    sendJsonMessage({ data_type: 43, step_to_play: id });
  };
  const handlePauseClick = () => {
    sendJsonMessage({ data_type: 41 });
    setPausing(true);
  };
  console.log("SessionIdForNewTC", sessionIdForNewTC);
  const handleStopClick = () => {
    console.log("Stopping session");
    if (readyState === WebSocket.OPEN) {
      sendJsonMessage({ data_type: 30 });
      // if (sessionIdForNewTC) {
      //   setLoading(true);
      // }
      setSessionIdForNewTC(null);
      setStopLoading(true);
      setDisabled(true);
      handleSetStatusMessage("Session has ended");
    }
  };

  const handleRecorderClick = () => {
    if (!paused) {
      handlePauseClick();
      handleSetStatusMessage(
        "Generation is paused as the recorder is in action"
      );
    } else if (!recordActive) {
      handleSetStatusMessage("Recorder is in action");
    } else if (recordActive) {
      handleSetStatusMessage("Recorder is stopped");
    }

    if (recordActive) {
      console.log("Stopping recorder");
      sendJsonMessage({ data_type: 45 });
      setAddingStepsOn(null);
      setEditingStepsOn(null);
      setRecordActive(false);
    } else {
      console.log("Starting recorder");
      let editor_no = addingStepsOn
        ? Number(addingStepsOn) + 1
        : cards.length + 1;
      editor_no = String(editor_no);
      sendJsonMessage({ data_type: 44, step_editor_no: editor_no });
      setRecordActive(true);
    }
  };
  const closeRecorder = () => {
    console.log("Stopping recorder");
    sendJsonMessage({ data_type: 45 });
    setRecordActive(false);
  };

  // card actions
  const handleCancel = () => {
    setAddingStepsOn(null);
    setEditingStepsOn(null);

    for (const condition of conditionActive) {
      handleConditionPop();
    }

    if (recordActive) {
      closeRecorder();
      handleSetStatusMessage("Recorder is stopped");
    }
  };
  const findCardById = (id, cards) => {
    for (const card of cards) {
      if (card.step_id === id) {
        return card;
      }
      if (card.sub_steps && card.sub_steps.length > 0) {
        const subCard = findCardById(id, card.sub_steps);
        if (subCard) {
          return subCard;
        }
      }
    }
    return null;
  };

  console.log("conditionActive", conditionActive);
  const handleAddStepInBetween = async (id, pauseAddingStepOn = false) => {
    // if user try to add in between a step when is in a condition, then pop the condition
    for (const condition of conditionActive) {
      handleConditionPop();
    }

    const newConditionArray = [];

    const idArray = id.split(".");

    for (let i = 0; i < idArray.length; i++) {
      let condition;
      idArray.pop();
      const parentId = idArray.join(".");
      // console.log("ParentId", parentId);
      const parentCard = findCardById(parentId, cards);
      // console.log("ParentCard", parentCard);

      if (parentCard && doesGroupInstrTypeExist(parentCard.inst.instr_type)) {
        condition = "Group";
      } else if (
        parentCard &&
        doesLoopInstrTypeExist(parentCard.inst.instr_type)
      ) {
        condition = "Loop";
      }

      if (condition) {
        newConditionArray.unshift({
          condition: condition,
          started_at: parentId,
        });
      }
    }

    if (newConditionArray.length > 0) {
      newConditionArray.forEach((condition) => {
        const existingCondition = conditionActive.find(
          (activeCondition) =>
            activeCondition.condition === condition.condition &&
            activeCondition.started_at === condition.started_at
        );

        if (!existingCondition) {
          setConditionActive(condition);
        }
      });
    }

    if (!paused && isIdLessThanOrEqualToCurrentStep(id, currentlyGeneratingStep.current)) {
      handlePauseClick();
      handleSetStatusMessage(
        "Generation is paused. Resume by clicking the play button on the desired step"
      );
    }

    if (!pauseAddingStepOn) {
      setAddingStepsOn(id);
    }

    // for refreshing the group
    for (const condition of newConditionArray) {
      if (condition.condition === "Group") {
        const groupCard = findCardById(condition.started_at, cards);

        if (groupCard.group_id) {
          await handleGroupUpdate(condition.started_at, groupCard.group_id);
        }
      }
    }
  };
  const handleEditStep = async (id) => {

  
    if (!paused && isIdLessThanOrEqualToCurrentStep(id, currentlyGeneratingStep.current)) {
      handlePauseClick();
      handleSetStatusMessage(
        "Generation is paused. Resume by clicking the play button on the desired step"
      );
    }

    // if user try to add in between a step when is in a condition, then pop the condition
    for (const condition of conditionActive) {
      handleConditionPop();
    }

    const newConditionArray = [];

    const idArray = id.split(".");

    for (let i = 0; i < idArray.length; i++) {
      let condition;
      idArray.pop();
      const parentId = idArray.join(".");

      const parentCard = findCardById(parentId, cards);

      if (parentCard && doesGroupInstrTypeExist(parentCard.inst.instr_type)) {
        condition = "Group";
      } else if (
        parentCard &&
        doesLoopInstrTypeExist(parentCard.inst.instr_type)
      ) {
        condition = "Loop";
      }

      if (condition) {
        newConditionArray.unshift({
          condition: condition,
          started_at: parentId,
        });
      }
    }

    if (newConditionArray.length > 0) {
      newConditionArray.forEach((condition) => {
        const existingCondition = conditionActive.find(
          (activeCondition) =>
            activeCondition.condition === condition.condition &&
            activeCondition.started_at === condition.started_at
        );

        if (!existingCondition) {
          setConditionActive(condition);
        }
      });
    }

    // Set the step to edit
    setAddingStepsOn(null);
    setEditingStepsOn(id);

    // for refreshing the group
    for (const condition of newConditionArray) {
      if (condition.condition === "Group") {
        const groupCard = findCardById(condition.started_at, cards);
        if (groupCard.group_id) {
          await handleGroupUpdate(condition.started_at, groupCard.group_id);
        }
      }
    }
  };
  const enableGroup = () => {
    setDisableGroup(false);
  };
  const enableLoop = () => {
    setDisableLoop(false);
  };

  // Function to get the current step index

  const handleAddGroupCard = async (card, id) => {
    console.log("Adding group card", card, id);
    const newCard = {
      step_id: id || "1",
      ...card,
      new_step: true,
    };

    if (cards.length === 0) {
      sendMessage({ data_type: 27, steps_data: [newCard] });
      addCard(newCard);
      return;
    }

    let newId;

    if (id === undefined && cards.length > 0 && conditionActive.length === 0) {
      const index = cards.length - 1;
      newId = (parseInt(cards[index].step_id) + 1).toString();
      newCard.step_id = newId;
    }

    if (id !== undefined && cards.length > 0 && conditionActive.length === 0) {
      const stepIdParts = id.split(".");
      const lastPartIndex = stepIdParts.length - 1;
      stepIdParts[lastPartIndex] = (
        parseInt(stepIdParts[lastPartIndex]) + 1
      ).toString();
      newId = stepIdParts.join(".");
      newCard.step_id = newId;

      setAddingStepsOn(stepIdParts.join("."));
    }

    // else {
    //   setAddingStepsOn(newId);
    // }

    if (conditionActive.length > 0) {
      const lastCondition = conditionActive[conditionActive.length - 1];
      const childId = `${lastCondition.started_at}.1`;

      // for adding in between of a loop/group card
      if (id !== undefined) {
        if (lastCondition.started_at === id) {
          newCard.step_id = `${id}.1`;
          setAddingStepsOn(`${id}.1`);
        } else {
          const stepIdParts = id.split(".");
          const lastPartIndex = stepIdParts.length - 1;
          stepIdParts[lastPartIndex] = (
            parseInt(stepIdParts[lastPartIndex]) + 1
          ).toString();
          newId = stepIdParts.join(".");
          newCard.step_id = newId;
          setAddingStepsOn(newId);
        }
      } else {
        if (
          doesGroupInstrTypeExist(card.inst.instr_type) ||
          doesLoopInstrTypeExist(card.inst.instr_type)
        ) {
          newCard.step_id = childId;
          setAddingStepsOn(childId);
        } else {
          newCard.step_id = childId;
          setAddingStepsOn(childId);
        }
      }
    }

    sendMessage({ data_type: 27, steps_data: [newCard] });
    addCard(newCard);
  };

  const apiInstructions = [600, 601, 602, 603, 604];
  const scriptInstruction = 700;

  const handleAddCard = async (instrObj, data, id) => {
    const { instr_type = null, instr = "" } = instrObj;

    console.log("Adding card", instrObj, data, id);
    if (
      instr === "" &&
      !apiInstructions.includes(instr_type) &&
      scriptInstruction !== instr_type
    ) {
      toast.error("Instruction cannot be empty");
      return;
    }

    let newCard = {
      step_id: addingStepsOn || "1",
      data: data,
      status: 5,
      ordinal: "",
      message: "",
      instrType: null,
      do_ordinal_selection: null,
      newField3: null,
      sub_steps: [], // Initialize sub_steps as an empty array
    };

    if (
      !apiInstructions.includes(instr_type) &&
      scriptInstruction !== instr_type
    ) {
      newCard.inst = {
        instr_type: instr_type,
        instr: instr,
      };
    } else {
      newCard.inst = instrObj;
    }

    newCard.new_step = true;

    if (instrObj.label) {
      newCard.inst.label = instrObj.label;
    }
    if (instrObj.label_type || instrObj.label_type === 0) {
      newCard.inst.label_type = instrObj.label_type;
    }
    if (instrObj.contextual_label) {
      newCard.inst.contextual_label = instrObj.contextual_label;
    }
    if (
      (instrObj.contextual_label_type ||
        instrObj.contextual_label_type === 0) &&
      instrObj.contextual_label
    ) {
      newCard.inst.contextual_label_type = instrObj.contextual_label_type;
    }

    if (instrObj.element_type) {
      newCard.inst.element_type = instrObj.element_type;
    }

    if (instrObj.action) {
      newCard.inst.action = instrObj.action;
    }
    if (instrObj.target) {
      newCard.inst.target = instrObj.target;
    }

    if (instrObj.instr_type === 202) {
      newCard.inst.x_label = "all";
    }

    if (instrObj.x_label) {
      newCard.inst.x_label = instrObj.x_label;
    }

    if (instrObj.y_label) {
      newCard.inst.y_label = instrObj.y_label;
    }
    if (instrObj.x_label_type || instrObj.x_label_type === 0) {
      newCard.inst.x_label_type = instrObj.x_label_type;
    }
    if (instrObj.y_label_type || instrObj.y_label_type === 0) {
      newCard.inst.y_label_type = instrObj.y_label_type;
    }

    if (instrObj.data) {
      newCard.inst.data = instrObj.data;
    }
    if (instrObj.expected_value) {
      newCard.inst.expected_value = instrObj.expected_value;
    }

    if (cards.length === 0) {
      sendMessage({ data_type: 27, steps_data: [newCard] });
      console.log("Message", 27, [newCard]);
      addCard(newCard);

      if (doesLoopInstrTypeExist(instr_type)) {
        setDisableLoop(true);
        setConditionActive({ condition: "Loop", started_at: "1" });
      }

      if (doesGroupInstrTypeExist(instr_type)) {
        setDisableGroup(true);
        setDisableLoop(true);
        setConditionActive({ condition: "Group", started_at: "1" });
      }
      // if(doesElseIfInstrTypeExist(instr_type)){
      //   setConditionActive({ condition: "ElseIf", started_at: "1" });
      // }

      return;
    }

    let newId;

    // for adding card normally
    if (id === undefined && cards.length > 0 && conditionActive.length === 0) {
      const index = cards.length - 1;
      newId = (parseInt(cards[index].step_id) + 1).toString();
      newCard.step_id = newId;
    }

    // for adding normal card in between
    if (id !== undefined && cards.length > 0 && conditionActive.length === 0) {
      const stepIdParts = id.split(".");
      const lastPartIndex = stepIdParts.length - 1;
      stepIdParts[lastPartIndex] = (
        parseInt(stepIdParts[lastPartIndex]) + 1
      ).toString();
      newId = stepIdParts.join(".");
      newCard.step_id = newId;
    }

    // for adding card in loop/group
    if (conditionActive.length > 0) {
      const lastCondition = conditionActive[conditionActive.length - 1];
      const childId = `${lastCondition.started_at}.1`;

      // for adding in between of a loop/group card
      if (id !== undefined) {
        const AddingCard = findCardById(id, cards);
        if (
          lastCondition.started_at === id &&
          AddingCard.sub_steps?.length == 0
        ) {
          newCard.step_id = `${id}.1`;
          setAddingStepsOn(`${id}.1`);
        } else {
          const stepIdParts = id.split(".");
          const lastPartIndex = stepIdParts.length - 1;
          stepIdParts[lastPartIndex] = (
            parseInt(stepIdParts[lastPartIndex]) + 1
          ).toString();
          newId = stepIdParts.join(".");
          newCard.step_id = newId;
        }
      } else {
        if (
          doesGroupInstrTypeExist(instr_type) ||
          doesLoopInstrTypeExist(instr_type)
        ) {
          newCard.step_id = childId;
        } else {
          newCard.step_id = childId;
          setAddingStepsOn(childId);
        }
      }
    }
    // adding conditionActive
    if (doesLoopInstrTypeExist(instr_type) && cards.length > 0) {
      setDisableLoop(true);
      setConditionActive({ condition: "Loop", started_at: newCard.step_id });
      console.log("Loop condition active", newCard.step_id);
    }

    if (doesGroupInstrTypeExist(instr_type) && cards.length > 0) {
      setDisableGroup(true);
      setDisableLoop(true);
      setConditionActive({ condition: "Group", started_at: newCard.step_id });
    }

    sendMessage({ data_type: 27, steps_data: [newCard] });
    addCard(newCard);

    if (
      addingStepsOn !== null &&
      (conditionActive.length === 0 ||
        conditionActive[conditionActive.length - 1].started_at !== id)
    ) {
      const stepIdParts = addingStepsOn.split(".");
      const lastPartIndex = stepIdParts.length - 1;
      stepIdParts[lastPartIndex] = (
        parseInt(stepIdParts[lastPartIndex]) + 1
      ).toString();
      console.log("bottom", stepIdParts.join("."));
      setAddingStepsOn(stepIdParts.join("."));
    }
  };

  const handleAddingStepInGroupWithArrow = async ({
    newIdToAddOn,
    newCardData,
    newDataJson,
    newSubStepId,
  }) => {
    setCardDataAddedWithNextBackArrow({
      newCardData,
      newDataJson,
      newSubStepId,
      newIdToAddOn,
    });
    let pauseAddingStepOn = true;
    handleAddStepInBetween(newIdToAddOn, pauseAddingStepOn);
  };

  const handleConditionPop = () => {
    if (conditionActive.length > 0) {
      const lastCondition = conditionActive[conditionActive.length - 1];
      if (lastCondition.condition === "Loop") {
        setDisableLoop(false);
        popConditionActive();
        // Message for closing loop
        console.log("Sending message:", {
          data_type: 51,
          parent_step: lastCondition.started_at,
        });
        sendJsonMessage({
          data_type: 51,
          parent_step: lastCondition.started_at,
        });
      }
      if (lastCondition.condition === "Group") {
        setDisableGroup(false);
        setDisableLoop(false);
        popConditionActive();
        // Message for closing group
        console.log("Sending message:", {
          data_type: 51,
          parent_step: lastCondition.started_at,
        });
        sendJsonMessage({
          data_type: 51,
          parent_step: lastCondition.started_at,
        });
      }
    }
  };

  const handleGroupUpdate = async (id, group_id) => {
    if (!paused) {
      handlePauseClick();
      handleSetStatusMessage("Generation is paused");
    }
    setGroupUpdating(true);
    sendJsonMessage({ data_type: 90, group_step_to_refetch: id });
  };

  // for deleting group step
  useEffect(() => {
    if (groupStepDelete) {
      handleDeleteStep(groupStepDeleting);
      setGroupStepDelete(false);
      setGroupStepDeleting(null);
    }
  }, [groupStepDelete]);

const handleDeleteStep = (id) => {
    if (!paused && isIdLessThanOrEqualToCurrentStep(id, currentlyGeneratingStep.current)) {
      handlePauseClick();
      handleSetStatusMessage(
        "Generation is paused. Resume by clicking the play button on the desired step"
      );
    }
  
    const deleteStepRecursively = (step) => {
      const isLoop = doesLoopInstrTypeExist(step.inst.instr_type);
      const isGroup = doesGroupInstrTypeExist(step.inst.instr_type);
  
      // For groups, delete the parent step first
      if (isGroup) {
        console.log("Sending message:", {
          data_type: 47,
          step_to_delete: step.step_id,
        });
        sendJsonMessage({ data_type: 47, step_to_delete: step.step_id });
        handleConditionPop();
      }
  
      if (step.sub_steps && step.sub_steps.length > 0) {
        let subSteps = step.sub_steps;
        if (isLoop) {
          // For loops, reverse the order of sub-steps
          subSteps = [...subSteps].reverse();
        }
  
        subSteps.forEach((subStep) => {
          deleteStepRecursively(subStep);
        });
      }
  
      // For loops and non-group steps, delete after processing sub-steps
      if (!isGroup) {
        console.log("Sending message:", {
          data_type: 47,
          step_to_delete: step.step_id,
        });
        sendJsonMessage({ data_type: 47, step_to_delete: step.step_id });
        handleConditionPop();
      }
    };
  
    const findStepById = (steps, id) => {
      for (const step of steps) {
        if (step.step_id === id) {
          return step;
        }
        if (step.sub_steps && step.sub_steps.length > 0) {
          const found = findStepById(step.sub_steps, id);
          if (found) return found;
        }
      }
      return null;
    };
  
    const stepToDelete = findStepById(cards, id);
    if (stepToDelete) {
      deleteStepRecursively(stepToDelete);
    }
  
    setEditingStepsOn(null);
    setAddingStepsOn(null);
  
    // Perform the delete action
    deleteCard(id);
  };
  const handleDeleteGroupStep = (group_Card_id, step_id) => {
    if (!paused) {
      handlePauseClick();
      handleSetStatusMessage("Generation is paused");
    }
    setGroupStepDeleting(step_id);
    sendJsonMessage({ data_type: 90, group_step_to_refetch: group_Card_id });
  };
  const handleUpdateCard = async (
    id,
    instrObj,
    data,
    status = 5,
    ordinal = "",
    sub_steps,
    group_id,
    group_hash,
    message = ""
  ) => {
    const updatedCard = {
      step_id: id,
      inst: instrObj,
      data,
      status,
      ordinal,
      message,
      sub_steps,
      new_step: false,
    };
    console.log("updatedCard", updatedCard);

    // if (instrObj.instr_type === 0) {
    //   const success = await handleEditOpenWebsite(instrObj.instr);
    //   if (!success) {
    //     return; // Exit early if the API call encountered an error
    //   }
    // }
    if (group_id) {
      updatedCard.group_id = group_id;
    }
    if (group_hash) {
      updatedCard.group_hash = group_hash;
    }

    sendMessage({ data_type: 27, steps_data: [updatedCard] });
    console.log("Message", 27, [updateCard]);

    editCard(updatedCard);
    setEditingStepsOn(null);
  };

  const handleReconnect = () => {
    console.log("Reconnecting...");
    sendMessage({ data_type: 700 });
    setIgnoreMessages(true);
  };

  function populateCards(data) {
    console.log("Populating cards", data);

    if (!data || typeof data !== "object") {
      console.error("Invalid data object");
      return;
    }

    const isPaused = data.is_paused ?? false;
    const isRecorderOn = data.is_recorder_on ?? false;
    const testStepsJson = data.test_steps_json ?? [];
    const localDataId = data.local_data_id ?? null;
    const lastGenerationId = data.last_generation_id ?? null;

    if (isPaused) {
      setPaused(true);
    }

    if (isRecorderOn) {
      setRecordActive(true);
    }

    if (testStepsJson.length > 0) {
      console.log("inside fetch testcase", testStepsJson);
      testStepsJson.forEach((card) => {
        const newCard = {
          inst: card.inst,
          step_id: card.step_id,
          data: card.data,
          instrType: null,
          status: card.status,
          ordinal: card.ordinal ?? "",
          message: card.message,
          ...(card.group_id ? { group_id: card.group_id } : {}),
          ...(card.group_hash ? { group_hash: card.group_hash } : {}),
        };
        addCard(newCard);
      });

      if (lastGenerationId && lastGenerationId !== "undefined") {
        setPrevTestVideo(lastGenerationId);
      }
    } else {
      console.log("No test steps JSON data");
    }

    if (localDataId) {
      setLinkedLocalDataId(localDataId);
    } else {
      console.log("No local data id");
    }

    const hasLastGenerationId =
      lastGenerationId !== null && lastGenerationId !== "undefined";
    // console.log(hasLastGenerationId);
  }

  const fetchTestcaseDetails = async () => {
    console.log("Fetching testcase details");
    setLoading(true);
    try {
      const data = await TestcaseDetails(id);
      console.log("TestcaseDetails", data);
      if (data && data.session_id != null) {
        reconnectingRef.current = true;
        setTestSessionId(data.session_id);
        // 700
      } else {
        populateCards(data);
      }
    } catch (error) {
      console.error("ERROR", error);
    } finally {
      setLoading(false);
    }
  };

  const generationBeginHandler = () => {
    // console.log("generationBeginHandler");
    setContentLoading(true);
    setStartLoading(true);
    setDisabled(true);
    handleSetStatusMessage("Session is getting started...");
    generateTestCase().then(() => {
      setStartLoading(false);
      setDisabled(false);
      handleSetStatusMessage(
        "Session started. Generate by clicking the play button on the desired step"
      );
    });
  };
  const generateTestCase = async () => {
    // setCurrentState("generating");
    const data_to_send = {
      testcase_id: id,
    };

    try {
      const data = await generateSessionId(data_to_send);
      if (data) {
        if (data?.detail) {
          setContentLoading(false);
          toast.error(data?.detail);
        } else if (data?.session_id) {
          setTestSessionId(data.session_id);
        }
        // setSessionId(data.session_id);
      } else {
        // generation error ocurred (handle using toast)
        toast.error("Generation error ocurred");
      }
    } catch (error) {
      console.error("Error fetching testcase details:", error);
    }
  };

  // handle Status Message
  const handleSetStatusMessage = (message) => {
    setStatusMessage(message);
  };

  // ordinal
  const sendMsgForOrdinalHover = (option) => {
    console.log("Hovering over", option);
    sendJsonMessage({ data_type: 78, ordinal: option });
  };
  const sendMsgForOrdinalSelect = (option) => {
    sendJsonMessage({ data_type: 79, ordinal: option });
  };

  const flag = useRef(false);
  useEffect(() => {
    if (flag.current) return;

    // if(location?.state?.session_id){
    if (sessionIdForNewTC) {
      setTestSessionId(sessionIdForNewTC);
    } else {
      fetchTestcaseDetails();
    }
    flag.current = true;
  }, [id]);

  useEffect(() => {
    // if (location?.state?.session_id) {
    if (sessionIdForNewTC) {
      if (stopLoading) {
        handleSetStatusMessage("Session has ended");
        setSessionId(null);
        setTestSessionId(null);
        setSessionIdForNewTC(null);
        setRecordActive(false);
        setAddingStepsOn(null);
        setEditingStepsOn(null);
      } else {
        setPaused(false);
        handleSetStatusMessage("Session is getting started...");
        setDisabled(true);
      }
    }
  }, []);

  const handleOpenRestApiModal = () => {
    // reset the received502 flag
    setReceived502(false);

    console.log("clicked");
    if (!paused) {
      setOpeningRestApi(true);
      handlePauseClick();
      handleSetStatusMessage("Generation is paused");
    } else {
      setRestApiOpen(true);
      sendJsonMessage({ data_type: 500 });
      console.log("Sending Message", { data_type: 500 });
    }
  };
  const handleCloseRestApiModal = () => {
    setApiModaleData(null);
    setRestApiOpen(false);
    setOpeningRestApi(false);
  };
  const handleApiSendClick = (data) => {
    sendJsonMessage({ data_type: 300, inst: data });
    setResponseLoading(true);
    console.log("Sending Message", { data_type: 300 });
  };
  const handleApiSaveClick = (data) => {
    // sendJsonMessage({ data_type: 501 });
    // console.log("Sending Message", { data_type: 501 });
  };

  const handleCompileClick = (data) => {
    setScriptResponseLoading(true);
    sendJsonMessage({ data_type: 302, inst: data });
  };

  // Script Modal Functions
  const handleOpenScriptModal = () => {
    // reset the received502 flag
    setReceived502(false);

    if (!paused) {
      handlePauseClick();
      handleSetStatusMessage("Generation is paused");
      setOpeningScript(true);
    } else {
      setScriptModalOpen(true);
      sendJsonMessage({ data_type: 500 });
    }
  };
  const handleCloseScriptModal = () => {
    // setApiModaleData(null);
    setOpeningScript(false);
    setScriptModalOpen(false);
  };

  return (
    <>
      <div className="container-layout">
        {/* Trying to reconnect (data_type: 700) */}
        {ignoreMessages && isOnline && (
          <div className="loading-content">
            <p style={{ color: "#0036AF" }}>
              reconnecting...{" "}
              <CircularProgress
                size={12}
                thickness={6}
                style={{ color: "#0036AF" }}
              />
            </p>
          </div>
        )}

        <StepContainer
          // cards={data}
          pausing={pausing}
          paused={paused}
          sessionId={sessionId}
          loading={loading}
          stopLoading={stopLoading}
          startLoading={startLoading}
          disabled={disabled}
          recordActive={recordActive}
          addingStepsOn={addingStepsOn}
          editingStepsOn={editingStepsOn}
          setAddingStepsOn={setAddingStepsOn}
          setEditingStepsOn={setEditingStepsOn}
          handleAddCard={handleAddCard}
          handleAddGroupCard={handleAddGroupCard}
          handleAddingStepInGroupWithArrow={handleAddingStepInGroupWithArrow}
          handleDeleteStep={handleDeleteStep}
          handleDeleteGroupStep={handleDeleteGroupStep}
          groupStepDeleting={groupStepDeleting}
          handleUpdateCard={handleUpdateCard}
          handlePlayClick={handlePlayClick}
          handlePauseClick={handlePauseClick}
          handleEditStep={handleEditStep}
          handleAddStepInBetween={handleAddStepInBetween}
          handleStopClick={handleStopClick}
          generationBeginHandler={generationBeginHandler}
          handleCancel={handleCancel}
          globalRefresh={globalRefresh}
          refreshingGlobal={refreshingGlobal}
          triggerGlobalRefresh={triggerGlobalRefresh}
          globalDataKeys={globalDataKeys}
          triggerLocalRefresh={triggerLocalRefresh}
          localDataCol={localDataCol}
          refreshingLocal={refreshingLocal}
          sendMsgForOrdinalHover={sendMsgForOrdinalHover}
          sendMsgForOrdinalSelect={sendMsgForOrdinalSelect}
          disableLoop={disableLoop}
          disableGroup={disableGroup}
          enableLoop={enableLoop}
          enableGroup={enableGroup}
          handleConditionPop={handleConditionPop}
          groupUpdating={groupUpdating}
          handleApiSendClick={handleApiSendClick}
          handleApiSaveClick={handleApiSaveClick}
          restApiOpen={restApiOpen}
          openingRestApi={openingRestApi}
          handleOpenRestApiModal={handleOpenRestApiModal}
          handleCloseRestApiModal={handleCloseRestApiModal}
          apiModaleData={apiModaleData}
          sendMessage={sendMessage}
          received502={received502}
          setReceived502={setReceived502}
          openingScript={openingScript}
          setOpeningScript={setOpeningScript}
          scriptModalOpen={scriptModalOpen}
          handleOpenScriptModal={handleOpenScriptModal}
          handleCloseScriptModal={handleCloseScriptModal}
          handleCompileClick={handleCompileClick}
          setGroupUpdating={setGroupUpdating}
        />
        <VideoContainer
          sessionId={sessionId}
          recordActive={recordActive}
          prevTestVideo={prevTestVideo}
          handleRecorderClick={handleRecorderClick}
        />
        {contentLoading && (
          <div className="loding-screen1">
            {/* <CircularProgress
            size={32}
            thickness={6}
            style={{ color: "#0036AF" }}
          /> */}
            <Botgaugeloader />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards.cards,
  savedGlobalData: state.cards.savedGlobalData,
  conditionActive: state.cards.conditionActive,
  conditionStepId: state.cards.conditionStepId,
  linkedLocalDataId: state.cards.linkedLocalDataId,
  sessionIdForNewTC: state.cards.sessionIdForNewTC,
});

const mapDispatchToProps = {
  updateCard,
  addCard,
  editCard,
  deleteCard,
  resetCards,
  setStatusMessage,
  setConditionActive,

  setLinkedLocalDataId,
  setCurrentTestcaseName,
  popConditionActive,
  setSessionIdForNewTC,

  setIsOnline
};

export default connect(mapStateToProps, mapDispatchToProps)(TestSteps);
