import React, { useState } from 'react';
import { BiMinus, BiPlus } from "react-icons/bi";
import { ReactComponent as DatabaseIcon } from "../../../assets/database.svg";
import { Tooltip } from 'antd';
import { RiErrorWarningFill } from "react-icons/ri";

const Step = ({ step, depth = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const hasSubSteps = step.sub_steps && step.sub_steps.length > 0;

    const formatInstruction = (inst) => {
        if (!inst) return '';
        // if (inst.label && inst.contextual_label) {
        if (inst.actual_label && inst.actual_contextual_label) {
            return inst.instr.replace(/"Text"|\"Text\"/, inst.actual_label).replace(/"Text"|\"Text\"/, inst.actual_contextual_label);
        } else if (inst.actual_label) {
            return inst.instr.replace(/"Text"|\"Text\"/, inst.actual_label);
        } else {
            return inst.instr;
        }
    };

    return (
        <div className={`step depth-${depth}`}>
            <div className={`step-header ${isExpanded ? 'expanded' : ''}`}>
                <div className="step-title">
                    {hasSubSteps && (
                        <button className="expand-button" onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? <BiMinus size={24} /> : <BiPlus size={24} />}
                        </button>
                    )}
                    <span className={hasSubSteps ? "has-sub-steps" : "has-no-sub-steps"} style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                        {formatInstruction(step.inst)}
                        {step.data && step.data.value !== "" && (
                            <Tooltip title={step.data.value}>
                                <DatabaseIcon />
                            </Tooltip>
                        )}
                    </span>
                </div>
                <button className="edit-button">
                    <p>Update</p>
                </button>
            </div>
            {isExpanded && hasSubSteps && (
                <div className="sub-steps">
                    {step.sub_steps.map((subStep) => (
                        <Step key={subStep.step_id} step={subStep} depth={depth + 1} />
                    ))}
                </div>
            )}
        </div>
    );
};

const TestCase = ({ testcase }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="testcase">
            <div className={`p-ai-testcase-header ${isExpanded ? 'expanded' : ''}`}>
                <button className="expand-button" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <BiMinus size={24} /> : <BiPlus size={24} />}
                </button>
                <h2>{testcase.name}</h2>
            </div>
            {isExpanded && testcase.testcase_json && testcase.testcase_json.test_steps_json && (
                <div className="testcase-steps">
                    {testcase.testcase_json.test_steps_json.map((step) => (
                        <Step key={step.step_id} step={step} />
                    ))}
                </div>
            )}
        </div>
    );
};

const Right = ({ testcases }) => {
    if (!testcases || testcases.length === 0) {
        return (
            <div className="presenceai-right">
                <div className="no-tc-avail-container">
                    <RiErrorWarningFill
                        style={{
                            width: "100px",
                            height: "100px",
                            color: "#dfdfdf",
                        }} />
                    <p style={{ color: "#bdbdbd", userSelect:"none" }}>No test cases available.</p>
                </div>
                <div className='presenceai-right-header'>
                    <div className='push-btn'>
                        <p>Push</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="presenceai-right">
            <div className="scrollable-container">
                {testcases.map((testcase, index) => (
                    <TestCase key={index} testcase={testcase} />
                ))}
            </div>
            <div className='presenceai-right-header'>
                <div className='push-btn'>
                    <p>Push</p>
                </div>
            </div>
        </div>
    );
};

export default Right;