import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import GlobalData from "./GlobalData";
import LocalData from "./LocalData";
import magnify from "../../assets/magnify.svg";
import TopNav from "../../components/Nav/topbar/topbar";
import LeftNav from "../../components/Nav/nav";
import UploadData from "./components/UploadDataModal";
import "./DataPage.css";

const DataPage = () => {
  const location = useLocation();
  const [showGlobalData, setShowGlobalData] = useState(true);
  const [isGlobalActive, setIsGlobalActive] = useState(true);
  const [isLocalActive, setIsLocalActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddDataPopUp, setShowAddDataPopUp] = useState(false);
  const [showGlobalDataAddModal, setShowGlobalDataAddModal] = useState(false);
  const [showLocalDataAddModal, setShowLocalDataAddModal] = useState(false);
  const [refreshGlobal, setRefreshGlobal] = useState(false);
  const [refreshLocal, setRefreshLocal] = useState(false);
  const [uploadDataCallback, setUploadDataCallback] = useState(null);

  useEffect(() => {
    const localActive = location?.state?.triggerLocalActive;
    if (localActive) {
      setIsLocalActive(true);
      setIsGlobalActive(false);
    } else {
      setIsLocalActive(false);
      setIsGlobalActive(true);
    }
  }, [location]);

  const initialDataValue = {
    key: "",
    value: "",
  };

  const [isUpdate, setIsUpdate] = useState(false);
  const [dataValue, setDataValue] = useState(initialDataValue);

  const triggerGlobalRefresh = () => {
    setRefreshGlobal(!refreshGlobal);
  };

  const triggerLocalRefresh = () => {
    setRefreshLocal(!refreshLocal);
  };

  const handleshowGlobalDataAddModalClose = () => {
    setIsUpdate(false);
    setShowGlobalDataAddModal(false);
    if (uploadDataCallback) uploadDataCallback(); // Trigger callback
  };

  const handleshowLocalDataAddModalClose = () => {
    setShowLocalDataAddModal(false);
  };

  const setAddPopUp = () => {
    setShowAddDataPopUp(!showAddDataPopUp);
  };

  const handleEditModel = (key, value, onCloseCallback) => {
    // console.log(key, value);

    setDataValue((prevState) => ({
      ...prevState,
      key,
      value,
    }));
    setIsUpdate(true);
    setShowGlobalDataAddModal(true);

    // Pass the onCloseCallback to UploadData
    setUploadDataCallback(() => onCloseCallback);
  };

  const setGlobalActiveHandler = () => {
    setShowGlobalData(true);
    setIsGlobalActive(true);
    setIsLocalActive(false);
  };

  const setLocalActiveHandler = () => {
    setShowGlobalData(false);
    setIsLocalActive(true);
    setIsGlobalActive(false);
  };

  const handleNewGlobalDataClick = () => {
    setShowGlobalDataAddModal(true);
    setShowAddDataPopUp(false);
  };

  const handleNewLocalDataClick = () => {
    setShowLocalDataAddModal(true);
    setShowAddDataPopUp(false);
  };

  return (
    <div className="application-data app">
      <div className="right-side-data">
        <div className="top-panel">
          <div className="search-div">
            <div className="input-cont">
              <img src={magnify} alt="search" />
              <input
                placeholder="Search"
                className="input-in"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="add-new-data">
            <button onClick={setAddPopUp} className="add-data-button">
              {" "}
              <span className="plus-span">+</span> New Data
            </button>
            {showAddDataPopUp && (
              <div className="pop-up-add">
                <div className="add-new-type-option">
                  <div onClick={() => handleNewGlobalDataClick()}>
                    New Global Data
                  </div>
                  <div onClick={() => handleNewLocalDataClick()}>
                    New Local Data
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="container-for-background">
          <div className="lower-upper-panel">
            <div className="local-global-descriptor">
              <div
                onClick={setGlobalActiveHandler}
                className={isGlobalActive ? "active-type" : "inactive-type"}
              >
                <p>Global</p>
              </div>
              <div
                onClick={setLocalActiveHandler}
                className={isLocalActive ? "active-type" : "inactive-type"}
              >
                <p>Local</p>
              </div>
            </div>
          </div>
          <div className="data-container">
            {isGlobalActive && (
              <GlobalData
                triggerGlobalRefresh={triggerGlobalRefresh}
                refreshGlobal={refreshGlobal}
                searchValue={searchQuery}
                handleEditModel={handleEditModel}
              />
            )}
            {isLocalActive && (
              <LocalData
                triggerLocalRefresh={triggerLocalRefresh}
                refreshLocal={refreshLocal}
                searchValue={searchQuery}
              />
            )}
          </div>

          {showGlobalDataAddModal && (
            <UploadData
              triggerGlobalRefresh={triggerGlobalRefresh}
              open={showGlobalDataAddModal}
              handleClose={handleshowGlobalDataAddModalClose}
              dataType="1"
              isUpdate={isUpdate}
              dataValue={dataValue}
              onCloseCallback={triggerGlobalRefresh} // Pass callback to UploadData
            />
          )}
          {showLocalDataAddModal && (
            <UploadData
              triggerLocalRefresh={triggerLocalRefresh}
              open={showLocalDataAddModal}
              handleClose={handleshowLocalDataAddModalClose}
              dataType="2"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DataPage;
