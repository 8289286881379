import React from "react";
import logo from "../../assets/BotGauge-logo.png";
import "./forgot.css";

import EmailIcon from "@mui/icons-material/Email";

const ForgetPassword = () => {
  return (
    <>
      <div className="parent-container">
        <div className="left-forgot-password">
          <div className="text-container">
            <div className="main-heading">
              <p>
                {" "}
                Automate Your <br /> Web App Testing
              </p>
            </div>
            <div className="sub-heading">
              <p>
                {" "}
                Ultimate automatic testing framework tailored for web apps
                across multiple browsers.
              </p>
              <p>
                {" "}
                Ensure consistency, efficiency, and quality like never before.
              </p>
            </div>
          </div>
        </div>
        <div className="right-forgot-password">
          <div className="forgot-password-form-container">
            <div className="logo_and_texts-fp">
              <img className="logo-forgot-password" src={logo} alt="logo" />
              <div className="welcome-message">
                {" "}
                Welcome <span className="excl-forgot-password">! </span>
              </div>
              <div className="detail-heading-fp">
                Enter the email associated with your account and
                <br />
                we will send an email with instructions to reset your password.
              </div>
            </div>
            <form className="forgot-password-form">
              <div className="input-container-fg">
              <EmailIcon  className="fg-email" />
              <input
                className="input-forgot-password"
                placeholder="Ex: abc@gmail.com"
              />
              </div>

              <button className="forgot-password-submit-button">
                <p className="forgot-password-click-text"> SEND </p>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
