import apiService from './apiServices';

export const startExecutionTestCase = async (payload) => {
    try {
        const response = await apiService.post(`/platform/start_execution/`,  JSON.stringify(payload));
        return response.data;
      } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }
};

