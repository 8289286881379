//testcase.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import Table from "antd/lib/table";
import Moment from "react-moment";
//import { toast } from "react-toastify";
import searchIcon from "../../assets/magnify.svg";
import aiLogo from "../../assets/aiLogo.svg";
import play from "../../assets/table/table-play-icon.svg";
import wait from "../../assets/table/table-waitlist-icon.svg";
import deleteIcon from "../../assets/table/table-delete-icon.svg";
import cloneIcon from "../../assets/cloneButton.svg";
import bars from "../../assets/EWT/bars.svg";
import BasicModal from "./testcaseModal";
import { connect } from "react-redux";
import DateRangePicker from "./datePicker";
import { useNavigate } from "react-router-dom";
import "./testcase.css";
import genAiLogo from "./genAiTestCase/genAiLogo.svg";
import GenAiTestCaseModal from "./genAiTestCase/GenAITestcaseModal";
import ExecutionModalLinuxOnly from "./ExecutionModalLinuxOnly";
import CloneModal from "./cloneTestCaseModal";
import DeleteModal from "./DeleteModal";
import { CircularProgress } from "@mui/material";
import { Tooltip } from "antd";
import { getStatusIcon } from "../../assets/getStatusIcon/getStatusIcon";
import FolderIcon from "../../assets/folder.svg";
import EditIcon from "../../assets/edit.svg";
import arrowCollapse from "../../assets/arrow-collapse-vertical.svg";
import arrow from "../../assets/arrow.svg";
import {
  // deleteTestCase,
  // deleteFolder,
  // fetchTestcasePage,
  fetchFolders,
  fetchTestcaseByFolderId,
  updateFolder,
  searchTestCaseFolders,
} from "../../services/testCaseServices";
import RightIcon from "../../assets/right.svg";
import CloseIcon from "../../assets/close.svg";
import ModuleFilter from "./ModuleFilter";
// import { debounce } from "../../utils/debounce";
import { toast } from "react-toastify";
import _ from "lodash";
import { useExecuteTest } from "../suites/useExecuteTest";
import { throttle } from "../../utils/throttle";
import { setTitle } from "../../providers/titleProvider";
// import moment from "moment";
// import { RightOutlined, DownOutlined } from "@ant-design/icons";
// import arrowExpand from "../../assets/arrow-expand-vertical.svg";

let isModuleFetched = false;

const initialFolderStatus = {
  folderId: null,
  editId: null,
  loadingId: null,
  editValue: "",
  isLoading: false,
};

const TestCase = () => {
  const navigate = useNavigate();

  // const [data, setData] = useState([]);
  const [filterDateSelected, setFilterDateSelected] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [genAiModal, setGenAiModal] = useState(false);
  const [executeModalOpen, setExecuteModalOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shortSearchError, setShortSearchError] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [cloneRecord, setCloneRecord] = useState(null);
  const [tableHeight, setTableHeight] = useState(window.innerHeight - 120);
  const [isTestCaseEdit, setIsTestCaseEdit] = useState(false);
  const [recordDetails, setRecordDetails] = useState(null);
  const [folderList, setFolderList] = useState([]);
  const [folderStatus, setFolderStatus] = useState(initialFolderStatus);

  const page = useRef(1);
  const isNext = useRef(true);
  const { executeTestHandler, showCircularProgress } =
    useExecuteTest("testcase");
  const [chromeSelected, setChromeSelected] = useState(false);
  const [firefoxSelected, setFirefoxSelected] = useState(false);
  const [id, setId] = useState(null);

  const handleStop = () => {};
  const handleClone = (record) => {
    setCloneRecord(record);
    setCloneModalOpen(true);
  };

  const doDelete = (id) => {
    setDeleteModalOpen(true);

    setDeleteRecord(id);
  };

  const CloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeleteRecord(null);
  };

  const execHistory = (record) => {
    handleStop(record);

    navigate(`/Test-case/${record.test_case_id}/tab/3`);
  };

  const navToTestCase = (_testcase) => {
    setTitle(_testcase.name);
    navigate(`/Test-case/${_testcase.test_case_id}`, {
      state: { testCaseName: _testcase.name },
    });
  };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  // const handlePrevPage = () => {
  //   setCurrentPage((prev) => Math.max(prev - 1, 1));
  //   scrollToTop();
  // };
  // const handleNextPage = () => {
  //   setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  //   scrollToTop();
  // };
  //const paginationInfo = `Page ${currentPage} out of ${totalPages}`;
  // const handleExecute = async (record) => {
  //   await executeTestHandler(
  //     record.test_case_id,
  //     chromeSelected,
  //     firefoxSelected
  //   );
  // };

  const columns = [
    {
      title: (
        <div className="column-title">
          <div
            style={{
              padding: "10px",
              paddingLeft: "20px",
              display: "flex",
              gap: "10px",
            }}
          >
            <img
              className="arrowEC"
              src={arrowCollapse}
              alt="arrow-collapse"
              onClick={() => handleGroupsExpand(false)}
            />
          </div>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div
          className={`table-testcase-module ${
            folderStatus.folderId === record.id && record.test_cases.length > 1
              ? "table-border-bottom"
              : ""
          }`}
        >
          {folderStatus.folderId === record.id ? (
            <div
              style={{
                padding: "0px 15px",
                display: "flex",
              }}
            >
              <img
                className="arrowEC"
                src={arrow}
                alt="arrow-expand"
                style={{ transform: "rotate(90deg)" }}
                onClick={() => handleExpand(record, false)}
              />
            </div>
          ) : (
            <div
              style={{
                padding: "0px 15px",
                display: "flex",
              }}
            >
              <img
                className="arrowEC"
                src={arrow}
                alt="arrow-expand"
                onClick={() => handleExpand(record, true)}
              />
            </div>
          )}
          <img
            src={FolderIcon}
            alt="folder_icon"
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />

          {folderStatus.editId === record.id ? (
            <>
              <input
                className="module-name-edit"
                type="text"
                value={folderStatus.editValue}
                onChange={handleInputChange}
              />
              {!folderStatus.isLoading ? (
                <>
                  <Button
                    className="table-button d-flex"
                    type="danger"
                    style={{ paddingLeft: "14px" }}
                    onClick={() => saveEdit(record)}
                  >
                    <img src={RightIcon} alt="edit" />
                  </Button>
                  <Button
                    className="table-button d-flex"
                    type="danger"
                    style={{ paddingLeft: "16px" }}
                    onClick={() => cancelEdit(record)}
                  >
                    <img src={CloseIcon} alt="cancel" />
                  </Button>
                </>
              ) : (
                <div className="loader-container">
                  <CircularProgress
                    size={16}
                    thickness={4}
                    style={{ color: "silver" }}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="module-name-container">
              <div className="module-name-wrap">{text}</div>
              <Button
                className="table-button"
                type="danger"
                style={{ padding: "0px 20px" }}
                onClick={() => handleEdit(record, true)}
              >
                <img src={EditIcon} alt="edit" />
              </Button>
            </div>
          )}

          {!folderStatus.editId && folderStatus.loadingId === record.id ? (
            <div className="table-loader">
              <CircularProgress
                size={16}
                thickness={4}
                style={{ color: "silver" }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: (
        <div
          className="column-title"
          style={{ textAlign: "left", marginLeft: "14px" }}
        >
          Test Case
        </div>
      ),
      dataIndex: "test_cases",
      key: "test_cases",
      width: 400,
      render: (_testcases, record) => (
        <div
          className={`table-testcase-container table-border-left ${
            _testcases.length > 0 ? "" : "tc-h-50"
          }`}
        >
          {folderStatus.folderId === record.id ? (
            <>
              {_testcases.length > 0 &&
                _testcases.map((_testcase, index) => {
                  const isLongText = _testcase.name.length > 50;
                  const displayedText = isLongText
                    ? `${_testcase.name.substring(0, 50)}...`
                    : _testcase.name;
                  const isUppercase =
                    _testcase.name === _testcase.name.toUpperCase();

                  return (
                    <div
                      key={_testcase.test_case_id}
                      className={`table-testcase ${
                        index !== _testcases.length - 1
                          ? "table-border-bottom"
                          : ""
                      }`}
                    >
                      {_testcase.is_ai_generated === true && (
                        <Tooltip title="AI generated">
                          <img
                            src={aiLogo}
                            alt="ai-logo"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                        </Tooltip>
                      )}
                      <Tooltip title={isLongText ? _testcase.name : ""}>
                        <p
                          style={{
                            cursor: "pointer",
                            margin: 0,
                            fontSize: isUppercase ? "0.75rem" : "0.8rem", // Different sizes for uppercase
                            lineHeight: "1rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                            display: "block",
                            textTransform: isUppercase ? "uppercase" : "none", // Apply transformation
                            letterSpacing: isUppercase ? "-0.5px" : "normal", // Fine-tune spacing for uppercase
                          }}
                          onClick={() => navToTestCase(_testcase)}
                        >
                          <span>{displayedText}</span>
                        </p>
                      </Tooltip>
                    </div>
                  );
                })}
            </>
          ) : (
            <div className="table-testcase"></div>
          )}
        </div>
      ),
    },
    {
      title: <div className="column-title">Recent Runs</div>,
      dataIndex: "test_cases",
      key: "recent-runs",
      render: (_testcases, record) => (
        <div
          className="table-testcase-data"
          // style={{ paddingLeft: "50px" }} // Shifts the content to the right
        >
          {folderStatus.folderId === record.id ? (
            <>
              {_testcases.length > 0 &&
                _testcases.map((_testcase, index) => (
                  <div
                    key={_testcase.test_case_id}
                    className={
                      index !== _testcases.length - 1
                        ? "table-border-bottom"
                        : ""
                    }
                    style={{ width: "100%" }}
                  >
                    <div
                      className="table-testcase"
                      style={{ paddingLeft: "50px" }}
                    >
                      {_testcase.last_five_jobs.map((job, jobIndex) => (
                        <div
                          key={jobIndex}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {getStatusIcon(job.status)}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </>
          ) : (
            <div className="table-testcase"></div>
          )}
        </div>
      ),
    },
    {
      title: <div className="column-title">Created</div>,
      dataIndex: "test_cases",
      key: "created-date",
      render: (_testcases, record) => (
        <div className="table-testcase-data">
          {folderStatus.folderId === record.id ? (
            <>
              {_testcases.length > 0 && (
                <>
                  {_testcases.map((_testcase, index) => (
                    <div
                      className={`table-testcase ${
                        index !== _testcases.length - 1
                          ? "table-border-bottom"
                          : ""
                      }`}
                      style={{ justifyContent: "center" }}
                    >
                      <span style={{ fontSize: "0.8rem" }}>
                        <Moment format="DD/MM/YYYY HH:mm:ss">
                          {_testcase.created_at * 1000}
                        </Moment>
                      </span>
                    </div>
                  ))}

                  {folderStatus.loadingId === record.id ? (
                    <div className="table-testcase">
                      {/* <div className="table-loader"> */}
                      <CircularProgress
                        size={16}
                        thickness={4}
                        style={{ color: "silver" }}
                      />
                      {/* </div> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="table-testcase"></div>
          )}
        </div>
      ),
    },
    {
      title: <div className="column-title">Updated</div>,
      dataIndex: "test_cases",
      key: "updated-date",
      render: (_testcases, record) => (
        <div className="table-testcase-data">
          {folderStatus.folderId === record.id ? (
            <>
              {_testcases.length > 0 &&
                _testcases.map((_testcase, index) => (
                  <div
                    key={_testcase.test_case_id}
                    className={`table-testcase ${
                      index !== _testcases.length - 1
                        ? "table-border-bottom"
                        : ""
                    }`}
                    style={{ justifyContent: "center" }}
                  >
                    <span style={{ fontSize: "0.8rem" }}>
                      <Moment format="DD/MM/YYYY HH:mm:ss">
                        {_testcase.updated_at * 1000}
                      </Moment>
                    </span>
                  </div>
                ))}
            </>
          ) : (
            <div className="table-testcase"></div>
          )}
        </div>
      ),
    },
    {
      title: <div className="column-title">Action</div>,
      dataIndex: "test_cases",
      width: 166, // Adjust the width for Action column as needed
      key: "actions",
      render: (_testcases, record) => (
        <div className="table-testcase-data">
          {folderStatus.folderId === record.id ? (
            <>
              {_testcases.length > 0 &&
                _testcases.map((_testcase, index) => (
                  <div
                    key={_testcase.test_case_id}
                    className={`table-testcase ${
                      index !== _testcases.length - 1
                        ? "table-border-bottom"
                        : ""
                    }`}
                    style={{ justifyContent: "center", gap: "0.3rem" }}
                    onClick={() => {
                      setId(_testcase.test_case_id);
                      console.log(_testcase);
                    }}
                  >
                    <Tooltip title="Execute" placement="top">
                      <Button
                        className="table-button d-flex"
                        type="primary"
                        onClick={() => {
                          setId(record.id);
                          ExecutionModalLinuxOnlyOpen(record.id);
                        }}
                      >
                        <img src={play} alt="execute-testcase" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Execution History" placement="top">
                      <Button
                        className="table-button d-flex"
                        type="danger"
                        onClick={() => execHistory(_testcase)}
                      >
                        <img src={wait} alt="wait" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                      <Button
                        className="table-button d-flex"
                        type="danger"
                        onClick={() => handleOpen(true, record, _testcase)}
                      >
                        <img src={EditIcon} alt="edit" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Clone" placement="top">
                      <Button
                        className="table-button d-flex"
                        type="danger"
                        onClick={() => handleClone(_testcase)}
                      >
                        <img src={cloneIcon} alt="clone" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Button
                        className="table-button d-flex"
                        type="default"
                        onClick={() => doDelete(_testcase.test_case_id)}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </Button>
                    </Tooltip>
                  </div>
                ))}
            </>
          ) : (
            <div className="table-testcase"></div>
          )}
        </div>
      ),
    },
  ];

  // const debouncedSearch = useRef(
  //   _.debounce(async (search) => {
  //     await fetchData(currentPage, search);
  //   }, 1000)
  // ).current;

  const updateModules = (response, isSearch = false) => {
    if (!response.length) {
      setFilteredData([]);
      return;
    }

    const mapedFolders = [];
    const folders = response;

    if (!isSearch) setFolderList(response);

    folders.forEach((folder) => {
      const mapedFolder = {
        id: folder.id,
        name: folder.name,
        test_cases: [],
      };

      mapedFolders.push(mapedFolder);
    });

    setFilteredData((previous) => {
      const uniqueFoldersMap = new Map();

      if (!isSearch) {
        previous.forEach((folder) => {
          uniqueFoldersMap.set(folder.id, folder);
        });
      }

      mapedFolders.forEach((folder) => {
        uniqueFoldersMap.set(folder.id, folder);
      });

      const uniqueFoldersArray = Array.from(uniqueFoldersMap.values());

      return uniqueFoldersArray;
    });
  };

  const getAllUniqueTestCases = (item, results) => {
    const existingIds = new Set(
      item.test_cases.map((testCase) => testCase.test_case_id)
    );

    const uniqueNewTestCases = results.filter(
      (testCase) => !existingIds.has(testCase.test_case_id)
    );

    return [...item.test_cases, ...uniqueNewTestCases];
  };

  const fetchModuleTestcases = async (
    folderId,
    pageNumber = 0,
    search = ""
  ) => {
    if (folderStatus.loadingId) return;
    setFolderStatus((previous) => ({
      ...previous,
      loadingId: folderId,
    }));
    const res = await fetchTestcaseByFolderId(folderId, pageNumber, search);

    if (res && res.results && res.results.length) {
      setFilteredData((previous) => {
        return previous.map((item) => {
          if (item.id === folderId) {
            return {
              ...item,
              test_cases:
                pageNumber === 1
                  ? [...res.results]
                  : getAllUniqueTestCases(item, res.results),
            };
          }
          return item;
        });
      });
      isNext.current = !!res.next;
      isNext.current && (() => (page.current += 1))();
      // if (page.current === 2 && isNext.current) {
      //   fetchModuleTestcases(folderId, page.current, searchTerm);
      // }
    } else {
      isNext.current = false;
      if (pageNumber === 1)
        setFilteredData((previous) => {
          return previous.map((item) => {
            if (item.id === folderId) {
              return {
                ...item,
                test_cases: [],
              };
            }
            return item;
          });
        });
    }
    setFolderStatus((previous) => ({
      ...previous,
      loadingId: null,
    }));
  };

  const fetchModules = async () => {
    try {
      setLoading(true);

      isModuleFetched = true;
      const response = await fetchFolders();

      if (response && response.length) {
        updateModules(response);
        setLoading(false);
      } else {
        updateModules([]);
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (startDate && endDate) {
      fetchModules();
    }
  }, [startDate, endDate]);
  const searchFilterhandler = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    // debouncedSearch(searchTerm);
  };

  // Debounced fetchData function

  const fetchSearchTestCaseFolders = async (search) => {
    if (search && search.length) {
      setLoading(true);
      const response = await searchTestCaseFolders(search.trim());
      if (response && response.length) {
        updateModules(response, true);
        setLoading(false);
      } else {
        updateModules([]);
        setLoading(false);
      }
    }
  };

  // const debouncedFetchSearchTestCases = debounce(async (_searchTerm) => {
  //   await fetchSearchTestCaseFolders(_searchTerm);
  // }, 2000);

  useEffect(() => {
    if (searchTerm.length > 2) {
      page.current = 1;
      isNext.current = true;
      setShortSearchError(false);
      fetchSearchTestCaseFolders(searchTerm);
      setFolderStatus(initialFolderStatus);
    } else if (searchTerm === "") {
      page.current = 1;
      isNext.current = true;
      setShortSearchError(false);
      setFolderStatus(initialFolderStatus);
      setTimeout(() => {
        fetchModules();
      }, 0);
    } else {
      setShortSearchError(true);
    }
  }, [searchTerm]);

  const handleOpen = (option = false, record = null, _testcase = null) => {
    if (option && record && _testcase) {
      setRecordDetails({
        folderId: record.id,
        folderName: record.name,
        testcaseId: _testcase.test_case_id,
        testcaseName: _testcase.name,
      });
    }
    setIsTestCaseEdit(option);
    setIsModalOpen(true);
  };

  const handleGenOpen = () => {
    setGenAiModal(true);
  };

  const handleGenClose = () => {
    setGenAiModal(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    if (isTestCaseEdit) {
      setIsTestCaseEdit(false);
      setRecordDetails(null);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - (scrollHeight / 2 - 150) &&
      !loading &&
      isNext.current &&
      folderStatus.folderId
    ) {
      // page.current += 1;
      // fetchData(page.current, searchTerm);
      throttle(
        fetchModuleTestcases(folderStatus.folderId, page.current, searchTerm),
        2000
      );
    }
  };

  const handleExpand = (record, option) => {
    if (folderStatus.isLoading || folderStatus.loadingId) return;

    setFolderStatus((previous) => ({
      ...previous,
      folderId: option ? record.id : null,
      editId: null,
      editValue: "",
    }));

    if (option) {
      page.current = 1;
      isNext.current = true;
      fetchModuleTestcases(record.id, page.current, searchTerm);
    }
  };

  const handleGroupsExpand = (option) => {
    setFolderStatus((previous) => ({
      ...previous,
      folderId: null,
    }));
  };

  const handleEdit = (record, option) => {
    setFolderStatus((previous) => ({
      ...previous,
      editId: option ? record.id : null,
      editValue: record.name,
    }));
  };

  const handleInputChange = (e) => {
    setFolderStatus((previous) => ({
      ...previous,
      editValue: e.target.value,
    }));
  };

  const saveEdit = async (record) => {
    if (folderStatus.isLoading) return;
    if (!folderStatus.editValue.length) return;

    if (record.name === folderStatus.editValue) {
      setFolderStatus((previous) => ({
        ...previous,
        editValue: "",
        editId: null,
      }));
      return;
    }

    const nameExists = filteredData.some(
      (_data) => _data.name === folderStatus.editValue && _data.id !== record.id
    );

    if (nameExists) {
      toast.error("Folder name already exists.");
      return;
    }

    setFolderStatus((previous) => ({
      ...previous,
      isLoading: true,
    }));

    const response = await updateFolder(record.id, folderStatus.editValue);
    if (response) {
      setFilteredData((prevData) =>
        prevData.map((_data) =>
          _data.id === record.id
            ? { ..._data, name: folderStatus.editValue }
            : _data
        )
      );
      toast.success("Module name updated successfully");
    } else {
      toast.else("Failed to update module name");
    }

    setFolderStatus((previous) => ({
      ...previous,
      editValue: "",
      editId: null,
      isLoading: false,
    }));
  };

  const cancelEdit = (record) => {
    if (!folderStatus.isLoading)
      setFolderStatus((previous) => ({
        ...previous,
        editValue: "",
        editId: null,
      }));
  };

  const ExecutionModalLinuxOnlyClose = (id) => {
    console.log(id);
    setExecuteModalOpen(false);
    navigate(`/Test-case/${id}/tab/3`);
  };
  const ExecutionModalLinuxOnlyOpen = (id) => {
    setExecuteModalOpen(true);
  };

  const dateRangeFilterClearHandler = () => {
    setFilterDateSelected(!filterDateSelected);
    setStartDate(null);
    setEndDate(null);
  };

  const handleSelectedFolders = (folders) => {
    if (folderList.length === filteredData.length && !folders.length) return;

    if (folders.length) {
      const selectedFolderDetails = folderList.filter((folder) => {
        if (folders.includes(folder.id)) {
          folder.test_cases = [];
          return folder;
        }
      });

      setFilteredData(selectedFolderDetails);
    } else {
      updateModules(folderList);
    }
  };

  const handleRefreshModules = () => {
    fetchModules();
  };

  useEffect(() => {
    const updateTableHeight = () => {
      setTableHeight(window.innerHeight - 200);
    };

    window.addEventListener("resize", updateTableHeight);
    updateTableHeight();

    if (!isModuleFetched) fetchModules();

    return () => {
      window.removeEventListener("resize", updateTableHeight);
      if (!loading) isModuleFetched = false;
    };
  }, []);

  return (
    <div className="testcase-container">
      <div className="testcase-header">
        <div className="total-upper-search-container">
          <div className="upper-search-container">
            <Form.Group controlId="searchBar">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search"
                  className="forms"
                  style={{ paddingLeft: "40px" }}
                  onChange={searchFilterhandler}
                />
                <img src={searchIcon} alt="search" className="search-icon" />
                {shortSearchError && (
                  <div className="search-container-notification">
                    <p>should have at least 3 characters</p>
                  </div>
                )}
              </div>
            </Form.Group>
            <div className="date-filter-container">
              <div
                onClick={() => setFilterDateSelected(!filterDateSelected)}
                className="intermediate-click-handler"
              >
                <div>
                  <img src={bars} alt="bars" />
                </div>
                <div>Filter</div>
              </div>

              {filterDateSelected && (
                <div>
                  <DateRangePicker
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              )}

              {filterDateSelected && (
                <div onClick={dateRangeFilterClearHandler}>
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5.5" cy="6" r="5.5" fill="#FF9D87" />
                    <path
                      d="M8 4.00357L7.49643 3.5L5.5 5.49643L3.50357 3.5L3 4.00357L4.99643 6L3 7.99643L3.50357 8.5L5.5 6.50357L7.49643 8.5L8 7.99643L6.00357 6L8 4.00357Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            {filteredData && filteredData.length ? (
              <ModuleFilter
                folderList={folderList}
                selectedFolders={handleSelectedFolders}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div style={{ paddingRight: "30px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              className="r"
              style={{ marginTop: "auto" }}
              onClick={handleGenOpen}
            >
              <img src={genAiLogo} alt="" /> GEN AI
            </Button>
            <Button className="r" onClick={() => handleOpen()}>
              + NEW TEST CASE
            </Button>
          </div>
          {isModalOpen && (
            <BasicModal
              isOpen={isModalOpen}
              handleClose={handleClose}
              closebychildmodal={handleClose}
              testCaseData={filteredData}
              recordDetails={recordDetails}
              isEdit={isTestCaseEdit}
              folderList={folderList}
              refreshModules={handleRefreshModules}
            />
          )}

          {executeModalOpen && (
            <ExecutionModalLinuxOnly
              mode={"testcase"}
              open={executeModalOpen}
              TestCase={id}
              handleClose={() => ExecutionModalLinuxOnlyClose(id)}
              // setActiveKey={setActiveKey}
            />
          )}
          {DeleteModalOpen && (
            <DeleteModal
              open={DeleteModalOpen}
              handleClose={CloseDeleteModal}
              deleteRecord={deleteRecord}
            />
          )}
          {cloneModalOpen && (
            <CloneModal
              isOpen={cloneModalOpen}
              handleClose={() => {
                setCloneModalOpen(false);
                setCloneRecord(null);
              }}
              closebychildmodal={() => {
                setCloneModalOpen(false);
                setCloneRecord(null);
                fetchModuleTestcases(folderStatus.folderId, 1, "");
              }}
              cloneData={cloneRecord}
              testCaseData={filteredData}
            />
          )}
        </div>
      </div>

      <div className="testcase-body">
        <Table
          className="table"
          loading={loading}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          scroll={{ y: tableHeight }}
          onScroll={handleScroll}
          style={{ height: "100%", width: "100%" }}
          rowKey="test_case_id"
          size="medium"
        />
        {/* <ERPWebTestingTable /> */}
      </div>

      {genAiModal && (
        <GenAiTestCaseModal isOpen={genAiModal} handleClose={handleGenClose} />
      )}
    </div>
  );
};

const mapStatetoProps = (state) => ({
  testcases: state.testcases,
});

export default connect(mapStatetoProps, null)(TestCase);

// import apiService, { endpoints } from "../../services/apiServices";
// import { apiUrl } from "../../services/apiServices";

// import LeftNav from "../../components/Nav/nav";
// import TopNav from "../../components/Nav/topbar/topbar";
// import { Container } from "react-bootstrap";

// const [currentPage, setCurrentPage] = useState(1);
// const pageSize = 10;
// const [nextPageUrl, setNextPageUrl] = useState(null);
// const [prevPageUrl, setPrevPageUrl] = useState(null);
// const totalItems = data.length;
// const [totalPages, setTotalPages] = useState(null);
// const startIndex = (currentPage - 1) * pageSize;
// const endIndex = Math.min(startIndex + pageSize, totalItems);
// const [currentPageData, setCurrentPageData] = useState([]);
// const fetchDataRan = useRef(false);

// const scrollToTop = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };

// deleteData={deleteRecord}
// setDeleteRecord={setDeleteRecord}

// const handlePrevPage = () => {
//   setCurrentPage((prev) => Math.max(prev - 1, 1));
//   scrollToTop();
// };
// const handleNextPage = () => {
//   setCurrentPage((prev) => Math.min(prev + 1, totalPages));
//   scrollToTop();
// };
// const paginationInfo = `Page ${currentPage} out of ${totalPages}`;

// {
//   /* Pagination */
// }
// {

// const createTestCaseModules = (testCases) => {
//   if (!testCases.length) return;
//   const _modules = [
//     {
//       id: "1",
//       name: "default",
//       isExpanded: false,
//       isEdit: false,
//       test_cases: [],
//     },
//   ];
//   let modulesLength = _modules.length;
//   testCases.forEach((testCase) => {
//     if (_modules[modulesLength - 1].test_cases.length < 5) {
//       _modules[modulesLength - 1].test_cases.push(testCase);
//     } else {
//       _modules.push({
//         id: (modulesLength + 1).toString(),
//         name: `Folder ${modulesLength}`,
//         isExpanded: false,
//         isEdit: false,
//         test_cases: [testCase],
//       });
//       modulesLength = _modules.length;
//     }
//   });

//   return _modules;
// };

//  {/* </div> */}
//     {/* </div> */}
/* {totalItems !== 0 && (
            <div className="pagination-info">
              <span className="page-info">{paginationInfo}</span>
              <div className="prev-next-button">
                <Button
                  className="button-style"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  Previous
                </Button>
                <Button
                  className="button-style"
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  Next
                </Button>
              </div>
            </div>
          )} */
// }

// {
//   /* <div className="App"> */
// }
// {
//   /* <div style={{ backgroundColor: "FAFAFA" }}> */
// }
// {
/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            padding: "20px",
            backgroundColor: "#FAFAFA",
            width: "100%",
          }}
        >
          <div className="total-upper-search-container">
            <div className="upper-search-container">
              <Form.Group controlId="searchBar">
                <div className="search-container">
                  <input
                    type="text"
                    placeholder="Search"
                    className="forms"
                    style={{ paddingLeft: "40px" }}
                    onChange={searchFilterhandler}
                  />
                  <img src={searchIcon} alt="search" className="search-icon" />
                  {shortSearchError && (
                    <div className="search-container-notification">
                      <p>should have at least 3 characters</p>
                    </div>
                  )}
                </div>
              </Form.Group>
              <div className="date-filter-container">
                <div
                  onClick={() => setFilterDateSelected(!filterDateSelected)}
                  className="intermediate-click-handler"
                >
                  <div>
                    <img src={bars} alt="bars" />
                  </div>
                  <div>Filter</div>
                </div>

                {filterDateSelected && (
                  <div>
                    <DateRangePicker
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </div>
                )}

                {filterDateSelected && (
                  <div onClick={dateRangeFilterClearHandler}>
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="5.5" cy="6" r="5.5" fill="#FF9D87" />
                      <path
                        d="M8 4.00357L7.49643 3.5L5.5 5.49643L3.50357 3.5L3 4.00357L4.99643 6L3 7.99643L3.50357 8.5L5.5 6.50357L7.49643 8.5L8 7.99643L6.00357 6L8 4.00357Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{ paddingRight: "30px" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                className="r"
                style={{ marginTop: "auto" }}
                onClick={handleGenOpen}
              >
                <img src={genAiLogo} alt="" /> GEN AI
              </Button>
              <Button className="r" onClick={handleOpen}>
                + NEW TEST CASE
              </Button>
            </div>
            {isModalOpen && (
              <BasicModal
                isOpen={isModalOpen}
                handleClose={handleClose}
                closebychildmodal={handleClose}
                testCaseData={filteredData}
              />
            )}

            {executeModalOpen && (
              <ExecutionModalLinuxOnly
                mode={"testcase"}
                open={executeModalOpen}
                handleClose={ExecutionModalLinuxOnlyClose}
              />
            )}
            {DeleteModalOpen && (
              <DeleteModal
                open={DeleteModalOpen}
                handleClose={CloseDeleteModal}
                deleteData={deleteRecord}
                setDeleteRecord={setDeleteRecord}
                deleteRecord={deleteRecord}
              />
            )}
            {cloneModalOpen && (
              <CloneModal
                isOpen={cloneModalOpen}
                handleClose={() => {
                  setCloneModalOpen(false);
                  setCloneRecord(null);
                }}
                closebychildmodal={() => {
                  setCloneModalOpen(false);
                  setCloneRecord(null);
                }}
                cloneData={cloneRecord}
                testCaseData={filteredData}
              />
            )}
          </div>
        </div> */
// }

// expandable={{
//   rowExpandable: (record) => record.test_case_id,
//   expandedRowRender: (record) => (
//     // <NestedRowRender id={record} />
//     <div className="table-inner-content" id={record.test_case_id}>
//       <div></div>
//       <div>{record.name}</div>
//       <div></div>
//       <div>
//         {
//           <span style={{ fontSize: "0.8rem" }}>
//             <Moment format="DD/MM/YYYY HH:mm:ss">
//               {record.created_at * 1000}
//             </Moment>
//           </span>
//         }
//       </div>
//       <div>
//         {
//           <span style={{ fontSize: "0.8rem" }}>
//             <Moment format="DD/MM/YYYY HH:mm:ss">
//               {record.updated_at * 1000}
//             </Moment>
//           </span>
//         }
//       </div>
//       <div></div>
//     </div>
//   ),
//   expandIcon: ({ expanded, onExpand, record }) =>
//     !expanded ? (
//       <div
//         style={{
//           padding: "13px 0px",
//           borderBottom: "1px solid #f0f0f0",
//         }}
//       >
//         <img
//           className="arrowEC"
//           src={arrow}
//           alt="arrow-expand"
//           onClick={() => handleExpand(record, true)}
//         />
//       </div>
//     ) : (
//       <div
//         style={{
//           padding: "13px 0px",
//           borderBottom: "1px solid #f0f0f0",
//         }}
//       >
//         <img
//           className="arrowEC"
//           src={arrow}
//           alt="arrow-expand"
//           onClick={() => handleExpand(record, false)}
//         />
//       </div>
//       // {fetchJobsData(record.execution_id)}
//     ),
// }}

// const mapedFolders = [];
// const folders = response;

// setFolderList(response);

// folders.forEach((folder) => {
//   const mapedFolder = {
//     id: folder.id,
//     name: folder.name,
//     test_cases: [],
//   };

//   mapedFolders.push(mapedFolder);
// });

// setFilteredData((previous) => {
//   const uniqueFoldersMap = new Map();

//   previous.forEach((folder) => {
//     uniqueFoldersMap.set(folder.id, folder);
//   });

//   mapedFolders.forEach((folder) => {
//     uniqueFoldersMap.set(folder.id, folder);
//   });

//   const uniqueFoldersArray = Array.from(uniqueFoldersMap.values());

//   return uniqueFoldersArray;
// });

// const fetchData = async (pageNumber, search) => {
//   try {
//     setLoading(true);

//     const dataDetails = await fetchTestcasePage(pageNumber, search);
//     if (dataDetails && dataDetails.results && dataDetails.results.length) {
//       setData((prev) =>
//         pageNumber === 1
//           ? dataDetails.results
//           : [...prev, ...dataDetails.results]
//       );
//       isNext.current = !!dataDetails.next;
//     } else {
//       isNext.current = false;
//       if (pageNumber === 1) setData([]);
//     }
//     setLoading(false);
//   } catch (error) {
//     setLoading(false);
//     console.error("An error occurred:", error);
//   }
// };

// filteredData.forEach((_data) => {
//   if (_data.id === record.id) {
//     _data.isEdit = false;
//   }
// });
// setFilteredData([...filteredData]);

// filteredData.forEach((_data) => {
//   if (_data.id === record.id) {
//     _data.name = folderStatus.editValue;
//   }
// });

// filteredData.forEach((_data) => {
//   if (_data.id === record.id) {
//     _data.isEdit = option;
//     if (option) {
//       setEditValue(_data.name);
//     }
//   }
// });
// setFilteredData([...filteredData]);

// filteredData.forEach((_data) => {
//   _data.isExpanded = option;
// });
// setFilteredData([...filteredData]);

// if (searchTerm.length > 0 && searchTerm.length < 3) {
//   setShortSearchError(true);
//   setLoading(false);
//   return;
// } else {
//   setShortSearchError(false);
// }

// filteredData.forEach((_data) => {
//   if (_data.id === record.id) _data.isExpanded = option;
// });

// setFilteredData([...filteredData]);   // filteredData.forEach((_data) => {
//   if (_data.id === record.id) _data.isExpanded = option;
// });

// setFilteredData([...filteredData]);

// useEffect(() => {
//   // const effectiveData = data;
//   if (startDate && endDate) {
//     // const startDat = moment(
//     //   startDate,
//     //   "ddd MMM DD YYYY HH:mm:ss ZZ"
//     // ).toDate();
//     // const endDat = moment(endDate, "ddd MMM DD YYYY HH:mm:ss ZZ").toDate();
//     // const filtered = effectiveData.filter((item) => {
//     //   const createdAt = new Date(item.created_at * 1000).setHours(0, 0, 0, 0);
//     //   return createdAt >= startDat && createdAt <= endDat;
//     // });
//     // const _filtered = createTestCaseModules(filtered);
//     // setFilteredData(_filtered);
//     // setCurrentPage(1);
//     // setTotalPages(Math.ceil(filtered.length / pageSize));
//   } else {
//     // const _effectiveData = createTestCaseModules(effectiveData);
//     // setFilteredData(_effectiveData);
//   }
// }, [data, startDate, endDate]);
