import {
    SET_TOTAL_THREADS,
    SET_ALLOCATED_COMPILATION_THREADS,
    SET_ALLOCATED_EXECUTION_THREADS,
    SET_USED_COMPILATION_THREADS,
    SET_USED_EXECUTION_THREADS,
    SET_DYNAMIC_THREADS,
  } from '../constants/thread-types';

  const initialState = {
    totalThread: 20,
    allocatedCompilationThread: 10,
    allocatedExecutionThread: 10,
    usedCompilationThread: 3,
    usedExecutionThread: 5,
    dynamicThread: true,
  };


  const threadReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_TOTAL_THREADS:
        if(action.payload < state.allocatedCompilationThread + state.allocatedExecutionThread){
          return {
            ...state,
            totalThread: state.allocatedCompilationThread + state.allocatedExecutionThread,
          };
        }
        return {
          ...state,
          totalThread: action.payload,
        };
      case SET_ALLOCATED_COMPILATION_THREADS:
        return {
          ...state,
          allocatedCompilationThread: action.payload,
          allocatedExecutionThread: state.totalThread - action.payload,
        };
      case SET_ALLOCATED_EXECUTION_THREADS:
        return {
          ...state,
          allocatedExecutionThread: action.payload,
          allocatedCompilationThread: state.totalThread - action.payload,
        };
      case SET_USED_COMPILATION_THREADS:
        return {
          ...state,
          usedCompilationThread: action.payload,
        };
      case SET_USED_EXECUTION_THREADS:
        return {
          ...state,
          usedExecutionThread: action.payload,
        };
      case SET_DYNAMIC_THREADS:
        return {
          ...state,
          dynamicThread: action.payload,
        };
      default:
        return state;
    }
  };

  export { threadReducer };