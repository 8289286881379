import { useState } from "react";
import { startExecutionTestCase } from "../../services/executionServices";
import { suiteExecutions } from "../../services/suitesServices";
import CircularProgress from "@mui/material/CircularProgress";

export const useExecuteTest = (mode, setActiveKey) => {
  const [showCircularProgress, setShowCircularProgress] = useState(false);

  const executeTestHandler = async (
    TestCase,
    chromeSelected,
    firefoxSelected
  ) => {
 
    const config = [];

    if (chromeSelected) {
      config.push({
        browser: "chrome",
        platform: "linux",
        browser_version: "91",
      });
    }

    if (firefoxSelected) {
      config.push({
        browser: "firefox",
        platform: "linux",
        browser_version: "109",
      });
    }

    const payload = {
      testcase_id: TestCase,
      config: config,
    };

 
    setShowCircularProgress(true);
    try {
      let responseData;
      const postData = {};
      switch (mode) {
        case "suite":
          postData.suite_id = payload.testcase_id;
          postData.browsers = payload.config;
          responseData = await suiteExecutions(postData);
          break;
        case "testing":
        case "testcase":
          postData.testcase_id = payload.testcase_id;
          postData.config = payload.config;
          responseData = await startExecutionTestCase(payload);
          break;
      }
 
      setActiveKey("3");
    } catch (error) {
      console.error("Error starting execution:", error);
    } finally {
      setShowCircularProgress(false);
    }
  };

  return {
    executeTestHandler,
    showCircularProgress,
  };
};
