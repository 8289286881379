import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import close from "../../assets/testcaseeditor/close.svg"; // Import the close image
import { toast } from "react-toastify";
import { deleteTestCase } from "../../services/testCaseServices";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxSizing: "border-box",
  padding: "1rem",
  width: 408,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "auto",
  p: 0,
};

const innerStyleUpperButton = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: ".2rem",
  borderBottom: "1px solid #F5F5F5",
  height: "66px",
};

export default function DeleteModal({
  open,
  handleClose,
  // deleteValue,
  // deleteData,
  // setDeleteRecord,
  deleteRecord,
}) {
  // const deleteHandler=()=>{
 
  //      deleteValue(deleteData);
  //      removeDeleteId(null);
  //      handleClose();
  // }

  const handleDelete = async (id) => {
    try {
      const response = await deleteTestCase(id);
      if (response) {
        toast.success("Testcase deleted successfully");
        handleClose();
      }
    } catch (error) {
      toast.error("Error deleting test case");
      handleClose();
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={innerStyleUpperButton}>
          <div style={{ marginLeft: "10px", padding: "9px" }}>Delete Data</div>
          <div onClick={handleClose}>
            <svg
              style={{ marginRight: "10px", cursor: "pointer" }}
              width="32"
              height="26"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="36" height="36" rx="6" fill="#F5F5F5" />
              <path
                d="M24.4166 12.876L23.1241 11.5835L17.9999 16.7077L12.8758 11.5835L11.5833 12.876L16.7074 18.0002L11.5833 23.1243L12.8758 24.4168L17.9999 19.2927L23.1241 24.4168L24.4166 23.1243L19.2924 18.0002L24.4166 12.876Z"
                fill="#BDBDBD"
              />
            </svg>
            {/* Use the imported close image */}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            textAlign: "left",
            boxSizing: "border-box",
            alignItems: "center",
            justifyContent: "left",
            height: "89px",
            color: "#616161",
            padding: "1.5rem",
            borderBottom: "1px solid #F5F5F5",
          }}
        >
          Are you sure to delete this testcase?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "1rem",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
            gap: "0.5rem",
            height: "69px",
            borderTop: "1px solid #F5F5F5",
          }}
        >
          <div
            style={{
              padding: "8px 20px",
              gap: "10px",

              borderRadius: "8px",
              border: "1px solid #eeeeee",
              color: "black",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            Close
          </div>

          <div
            style={{
              padding: "8px 20px",
              gap: "10px",
              borderRadius: "8px",
              border: "1px solid #eeeeee",
              color: "#fff",
              cursor: "pointer",
              background: "#EF4444",
            }}
            onClick={() => handleDelete(deleteRecord)}
          >
            Delete
          </div>
        </div>
      </Box>
    </Modal>
  );
}
