import './tsLinkedData.css';

import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Table } from 'antd';
import closeIcon from '../../../../assets/close-icon.svg';
import search from '../../../../assets/search-icon.svg';
import TsDropDown from './DropDown/tsDropDown';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetch_local } from '../../../../services/dataService';
import { add_testcase_association, remove_testcase_association } from '../../../../services/testCaseServices';
import { setLinkedLocalDataId } from '../../Testaction/cardsActions';
import { connect } from 'react-redux';

const TsLinkedData = ({ open, tcName, handleClose, selected_local_data_id, setLinkedLocalDataId }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState(selected_local_data_id || null);
  const [tempSelectedKey, setTempSelectedKey] = useState(selected_local_data_id);
  const [searchText, setSearchText] = useState('');
  const { id } = useParams();

  const handleSelect = (key) => {
    setTempSelectedKey(key);
  };


  const handleCancel = () => {
    setTempSelectedKey(selectedKey); 
    handleClose();
  };

  const handleConfirmReplace = async () => {

    // console.log('selectedKey:', selectedKey);
    if (selectedKey !== tempSelectedKey) {
      try {
        if(selectedKey) {
          console.log('selectedKey:', selectedKey);
          await remove_testcase_association(selectedKey,[id]);
        }
        console.log("tempSelectedKey:", tempSelectedKey);
        const response = await add_testcase_association(tempSelectedKey,[id]);
        console.log('response:', response);
        setLinkedLocalDataId(tempSelectedKey);
        setSelectedKey(tempSelectedKey);
        toast.success('Data replaced successfully');
      } catch (error) {
        console.error('Error replacing associations:', error);
        toast.error('Error replacing Data');
      }
    }

    handleClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        try {
          setLoading(true);
          const response = await fetch_local()
          const responseData = response.data.list_of_all_local_data;

          if (Array.isArray(responseData)) {
            setData(responseData);
            setFilteredData(responseData);
          } else {
            console.error('Expected an array but received:', responseData);
            setData([]);
            setFilteredData([]);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setData([]);
          setFilteredData([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [open]);

  useEffect(() => {
    if (searchText === '') {
      setFilteredData(data);
    } else {
      const lowercasedSearchText = searchText.toLowerCase();
      const filtered = data.filter(item => 
        item.name.toLowerCase().includes(lowercasedSearchText)
      );
      setFilteredData(filtered);
    }
  }, [searchText, data]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: ' ',
      dataIndex: 'local_data_id',
      width: "25%",
      render: (key) => (
        <label className='ts-linked-tc-modal-table-radio'>
          <input 
            type="radio"
            className={`input-radio ${tempSelectedKey === key ? 'on' : ''}`}
            checked={tempSelectedKey === key} 
            onChange={() => handleSelect(key)} 
          />
        </label>
      ),
    },
    {
      title: (
        <p className='ts-linked-tc-modal-table-title'>Local Data</p>
      ),
      dataIndex: 'name',
      render: (dataName) => <p className='ts-linked-tc-modal-table-column-bold'>{dataName}</p>,
    },
    {
      title: (
        <p className='ts-linked-tc-modal-table-title'>Linked Testcases</p>
      ),
      dataIndex: 'no_test_case_associated',
      render: (text, record) => (
        <TsDropDown localDataId={record.local_data_id} tc_linked={record.no_test_case_associated} />
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className="ts-linked-tc-modal">
        <div className='linked-tc-modal-box-header'>
          <h4>Select a data to replace</h4>
          <img onClick={handleClose} src={closeIcon} alt='close-modal' />
        </div>

        <div className='linked-tc-modal-box-search'>
          <h4>{tcName}</h4>
          <div>
            <img src={search} alt="search" />
            <input 
              placeholder='Search' 
              type="text" 
              value={searchText} 
              onChange={handleSearchChange} 
            />
          </div>
        </div>
        <Table
          dataSource={filteredData}
          columns={columns}
          pagination={false}
          scroll={{ y: "390px" }}
          loading={loading}
        />
        <div className='linked-tc-modal-box-footer'>
          <div 
            onClick={handleCancel}
            className='linked-tc-modal-box-footer-item'>
              Cancel
          </div>
          <div 
            onClick={handleConfirmReplace}
            className='linked-tc-modal-box-footer-item'>
              Replace
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {

  setLinkedLocalDataId,
};

export default connect(mapStateToProps, mapDispatchToProps)(TsLinkedData)