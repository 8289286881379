import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import LeftNav from '../../components/Nav/nav';
import TopNav from '../../components/Nav/topbar/topbar';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import windowsIcon from '../../assets/table/windows 1.svg'
import linuxIcon  from '../../assets/table/linux.svg'
import macIcon  from '../../assets/table/mac.svg'
import expallIcon from  '../../assets/arrow-expand-vertical.svg'
import colallIcon from  '../../assets/arrow-collapse-vertical.svg'
import playIcon from '../../assets/EWT/play-circle-outline.svg'
import failIcon from '../../assets/EWT/failed.svg'
import  ChevronDown from '../../assets/EWT/chevron-down.svg'
import  ChevronUp from '../../assets/EWT/chevron-up.svg'
import warningIcon from '../../assets/EWT/alert.svg'
import infoIcon  from '../../assets/EWT/info.svg'
import successIcon from '../../assets/EWT/success.svg'
import expandimgIcon from '../../assets/EWT/arrow-expand-all.svg'
import closeIcon   from '../../assets/EWT/close.svg'
import menu_upIcon  from '../../assets/EWT/menu-up.svg'
import menu_downIcon from '../../assets/EWT/menu-down.svg'
import chromeIcon   from '../../assets/EWT/chrome.svg'
import linkIcon   from '../../assets/EWT/link-variant.svg'
import databaseIcon from '../../assets/EWT/database.svg'
import './ewt.css'


const ERPWebTesting = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/Reports');
  };
  const [activeButton, setActiveButton] = useState('1');
  const [isActiveButton1, setIsActiveButton1] = useState(false);
  const [isActiveButton2, setIsActiveButton2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const data = [
    
    
    {
      "instruction":"open website",
      "status": 0,
      "message":"success",
      "screenshot_url": "https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "https://google.com",
      "step_no": 1
  },
  {
      "instruction":"enter username",
      "status": 1,
      "message":"failed",
      "screenshot_url": "https://blog.cloudflare.com/content/images/2022/05/image2-38.png",
      "data": "test",
      "step_no": 2
  },
  {
      "instruction":"select country",
      "status": 2,
      "message":"warning",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 3
  },
  {
      "instruction":"verify country is on the page",
      "status": 1,
      "message":"failed",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 4
  },
  {
      "instruction":"verify test is on the page",
      "status": 3,
      "message":"skipped",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 5
  },
  {
      "instruction":"enter username",
      "status": 1,
      "message":"failed",
      "screenshot_url":"https://blog.cloudflare.com/content/images/2022/05/image2-38.png",
      "data": "test",
      "step_no": 6
  },
  {
      "instruction":"select country",
      "status": 2,
      "message":"warning",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 7
  },
  {
      "instruction":"verify country is on the page",
      "status": 0,
      "message":"success",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 8
  },
  {
      "instruction":"verify test is on the page",
      "status": 3,
      "message":"skipped",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 9
  },
  {
      "instruction":"enter username",
      "status": 1,
      "message":"failed",
      "screenshot_url":"https://blog.cloudflare.com/content/images/2022/05/image2-38.png",
      "data": "test",
      "step_no": 10
  },
  {
      "instruction":"select country",
      "status": 2,
      "message":"warning",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 11
  },
  {
      "instruction":"verify country is on the page",
      "status": 1,
      "message":"failed",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 12
  },
  {
      "instruction":"verify test is on the page",
      "status": 3,
      "message":"skipped",
      "screenshot_url":"https://dmn.ca/wp-content/uploads/2019/10/publiclyavailable.png",
      "data": "india",
      "step_no": 13
  }
    
  ];

  const toggleOpen = index => {
    setIsOpen(prevState => ({ ...prevState, [index]: !prevState[index] }));
  };

    

    const openModal = index => {
      setCurrentImage(index);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const nextImage = () => {
      setCurrentImage((currentImage + 1) % data.length);
    };
  
    const prevImage = () => {
      setCurrentImage((currentImage - 1 + data.length) % data.length);
    };
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const closeVideoModal = () => {
      setIsVideoModalOpen(false);
    };

  return (
    
    <Container>     
      <div className='layout'> 
      <LeftNav />
      <div className='right-side'>
      <TopNav className="top-nav" />
     <div className="App"> 
     <div className='hed'style={{backgroundColor:'#FAFAFA', height:'40px'}}>
       <div className='hed1'>
            <div style={{paddingRight:'10px',cursor: 'pointer'}}  onClick={handleClick}>Reports </div> <div style={{color:'#616161'}}>  /  </div>  
             <div style={{color:'#616161',paddingLeft:'10px'}}>Tesla</div>
       </div>
    </div>
    <div style={{backgroundColor:'white',display:'flex',gap:'40px'}}>
      <div className='iconhed'>
        <div className='iconcon'>
          <img src={windowsIcon} alt='icon'/>
          <img src={failIcon} alt='icon' />
          </div>
        <div className='iconcon'>
          <img src={linuxIcon} alt='icon'/>
          <img src={failIcon} alt='icon' />
          </div>
        <div className='iconcon' >
          <div className='iconconsu'>
          <img src={macIcon} alt='icon'/>
          <img src={successIcon} style={{width:'15px' ,height:'15px',marginTop:'5px'}} alt='icon' />
          </div>
          </div>
        
</div>

<div className='btab'
  style={{
    cursor: 'pointer', 
    borderBottom: activeButton === '1' ? '2px solid #1890ff'  : 'none',
    padding: '10px',
    display:'flex',
    justifyContent:'space-between',
    gap:'10px',
    alignItems:'center'
  }}
  onClick={() => setActiveButton(activeButton === '1' ? '' : '1')}
>
{activeButton === '1' ? <div className='in'> <img src={chromeIcon} alt='icon'/> <div className='vtext'>v44</div>  <img src={failIcon} alt='icon'/> </div>: <div className='in'> <img src={chromeIcon} alt='icon'/>  <div className='vtext'>v44</div> </div>}
  
</div>

<div 
  style={{
    cursor: 'pointer', 
    borderBottom: isActiveButton1 ? '2px solid #1890ff' : 'none',
    padding: '10px',
    display:'flex',
    justifyContent:'space-between',
    gap:'10px',
    alignItems:'center'
  }}
  onClick={() => setIsActiveButton1(!isActiveButton1)}
>
{isActiveButton1 ? <div className='in'> <img src={chromeIcon} alt='icon'/> <div className='vtext'>v100</div>  <img src={successIcon} alt='icon'/> </div>: <div className='in'> <img src={chromeIcon} alt='icon'/>  <div className='vtext'>v100</div> </div>}
</div>

<div 
  style={{
    cursor: 'pointer', 
    borderBottom: isActiveButton2 ? '2px solid #1890ff' : 'none',
    padding: '10px',
    display:'flex',
    justifyContent:'space-between',
    gap:'10px',
    alignItems:'center'
  }}
  onClick={() => setIsActiveButton2(!isActiveButton2)}
>
{isActiveButton2 ? <div className='in'> <img src={chromeIcon} alt='icon'/> <div className='vtext'>v88</div>  <img src={failIcon} alt='icon'/> </div>: <div className='in'> <img src={chromeIcon} alt='icon'/>  <div className='vtext'>v88</div> </div>}
</div>
      
        </div>
        <div className='hed2'>
        <div 
    className='expcolIcon' 
    onClick={() => setIsOpen(true)}
  >
   <img src={expallIcon} alt='icon'/> 
  </div>
  <div 
    className='expcolIcon'  
    onClick={() => setIsOpen(false)}
  >
    <img src={colallIcon} alt='icon'/>   
  </div>
  <div className='sts'>
  <div style={{display:'flex',cursor: 'pointer'}} onClick={() => setIsVideoModalOpen(true)}>
    <img src={playIcon} alt='icon' />
    <div style={{color:'#1D86FF',paddingLeft:'8px',cursor: 'pointer'}}>Play</div>
    </div>
              <div style={{color:'#EF4444'}} >
                Error at {}
                </div>  
               
                <div style={{display:'flex',justifyContent:'space-between',gap:'9px'}} >
                <img src={failIcon} alt='icon' />
                <div> 10/10 </div>
                <div>  
                <img src={ChevronDown} alt='icon' />
                </div>
                <div>  
                <img src={ChevronUp} alt='icon' />
                </div>
                  </div>  
                 
                  <div style={{display:'flex',justifyContent:'space-between',gap:'9px'}} >
                <img src={warningIcon} alt='icon' />
                <div> 10 </div>
                <div>  
                <img src={ChevronDown} alt='icon' />
                </div>
                <div>  
                <img src={ChevronUp} alt='icon' />
                </div>
                  </div>  

                  <div style={{display:'flex',justifyContent:'space-between',gap:'9px'}} >
                <img src={infoIcon} alt='icon' />
                <div> 5 </div>
                <div>  
                <img src={ChevronDown} alt='icon' />
                </div>
                <div>  
                <img src={ChevronUp} alt='icon' />
                </div>
                  </div> 

                  <div style={{display:'flex',justifyContent:'space-between',gap:'9px'}} >
                <img src={successIcon} alt='icon' />
                <div> 100 </div>
                <div>  
                <img src={ChevronDown} alt='icon' />
                </div>
                <div>  
                <img src={ChevronUp} alt='icon' />
                </div>
                  </div> 

    
    </div>
        </div>

        <div className='collaps'>
        {data.map((item, index) => (
  <div key={index}>
    <div 
      style={{cursor: 'pointer'}} 
      onClick={() => toggleOpen(index)}
    >
      {isOpen[index] ? <img src={menu_downIcon} alt='icon' /> :  <img src={menu_upIcon} alt='icon' /> }
      {item.message === 'failed' && <img className='colicon'  src={failIcon} alt='icon' />}
      {item.message === 'success' && <img className='colicon' src={successIcon} alt='icon' />}
      {item.message === 'warning' && <img className='colicon' src={warningIcon} alt='icon' />}
      {item.message === 'skipped' && <img className='colicon' src={infoIcon} alt='icon' />}
      {item.instruction}
    </div>
    {isOpen[index] && (
      <div className='innercollaps'>
        {item.data.startsWith('https://') ?  <div> <img src={linkIcon} alt='icon' /> {item.data} </div> : <div> <img src={databaseIcon} alt='icon' /> {item.data} </div>}<br/>
        <div className='imageWrapper' 
          onMouseEnter={() => setIsHovered(true)} 
          onMouseLeave={() => setIsHovered(false)}
        >
          <img src={item.screenshot_url} alt='screenshot' onClick={() => openModal(index)} />
          {isHovered && <div className='expandIcon'>
            <img onClick={() => openModal(index)} src={expandimgIcon} alt='icon'/>
          </div>}
        </div>
      </div>
    )}
  </div>
))}
     <Modal className="modalStyle" isOpen={isModalOpen} onRequestClose={closeModal}>
      <div className='modaldiv'>
    <div className="modalHeader">
        <h2>Screenshot</h2>
        <div className="modalCloseButton" onClick={closeModal}>
        <img src={ closeIcon} alt='icon' />
        </div>
    </div>
    <div className='steptext'>Step {currentImage + 1}: {data[currentImage].instruction}</div>
    <a className='steplink' href={data[currentImage].screenshot_url} target="_blank" rel="noopener noreferrer">{data[currentImage].screenshot_url}</a> 
    <img className="modalImage" src={data[currentImage].screenshot_url} alt='screenshot' />
    <div className='stepbutton'>
    <button  className='modalbuutton' onClick={prevImage}>← Prev. Step</button>
    <button className='modalbuutton' onClick={nextImage}>Next Step →</button>  
    </div>
    </div>
</Modal>
<Modal className="modalStyle" isOpen={isVideoModalOpen} onRequestClose={closeVideoModal}>
<div className='modaldiv'>
<div className="modalHeader">
        <h2>Video</h2>
        <div className="modalCloseButton" onClick={closeVideoModal}>
        <img src={ closeIcon} alt='icon' />
        </div>
    </div>
    <video className="modalImage" controls>
        <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
    </div>
</Modal>
    </div>
        
    </div>
    </div>
    </div>
    </Container>
  );
};

export default ERPWebTesting;