import React, { useCallback, useEffect, useRef, useState } from "react";
import "./PresenceAi.css";
import Left from "./components/Left";
import Right from "./components/Right";
import { useLocation } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { apiWS } from "../../services/apiServices";

const PresenceAi = () => {
  const location = useLocation();
  const [testSessionId, setTestSessionId] = useState(
    location?.state?.session_id || `ai_${Date.now()}`
  );
  const authToken = localStorage.getItem("authToken");
  const [testcases, setTestcases] = useState([]);
  const [messages, setMessages] = useState([]);
  const [botLoading, setBotLoading] = useState(false);
  const initialMessageSent = useRef(false);

  const structureCards = useCallback((tcs) => {
    const restructureTestSteps = (testSteps) => {
      const restructured = [];
      const stepMap = new Map();
      testSteps.forEach((step) => {


        const parts = step.step_id.split(".");
        if (parts.length === 1) {
          restructured.push(step);
          stepMap.set(step.step_id, step);
        } else {
          const parentId = parts.slice(0, -1).join(".");
          const parent = stepMap.get(parentId);
          if (parent) {
            if (!parent.sub_steps) {
              parent.sub_steps = [];
            }
            parent.sub_steps.push(step);
            stepMap.set(step.step_id, step);
          }
        }
      });
      return restructured;
    };

    return tcs.map((testcase) => {
      return {
        ...testcase,
        testcase_json: {
          ...testcase.testcase_json,
          test_steps_json: restructureTestSteps(
            testcase.testcase_json.test_steps_json
          ),
        },
      }
    });
  }, []);

  const addMessages = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };


  const handleMessage = useCallback((event) => {
    if (event.data) {
      try {
        const messageData = JSON.parse(event.data);
        switch (messageData.data_type) {
          case 16:
            setBotLoading(false);
            break;
          case 15:
            setBotLoading(true);
            break;
          case 13:
            setMessages((prevMessages) => [...prevMessages, messageData]);
            break;
          case 12:
            const restructuredTestcases = structureCards(messageData.tcs);
            setTestcases(restructuredTestcases);
            break;
          case 24:
            // console.log("newMessage", messageData);
            addMessages(messageData);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error("Invalid message from server:", error);
      }
    }
  }, [structureCards]);

  const { sendJsonMessage, getWebSocket } = useWebSocket(
    testSessionId ? `wss://${apiWS}/platform/ai?token=${authToken}` : null,
    {
      onOpen: () => {
        if (testSessionId && !initialMessageSent.current) {
          sendJsonMessage({
            data_type: 0,
            session_id: testSessionId,
          });
          initialMessageSent.current = true;
        }
      },
      shouldReconnect: (closeEvent) => !closeEvent.wasClean,
      onMessage: handleMessage,
    }
  );

  const onContextSubmit = useCallback((fileData) => {
    addMessages({data_type: 10});
    sendJsonMessage({
      data_type: 10,
      ...fileData,
    });
  }, [sendJsonMessage]);
  const handleMessageSubmit = useCallback((message) => {
    let obj = {
      data_type: 23,
      query: message,
    };

    addMessages(obj);
    sendJsonMessage(obj);
  }, [sendJsonMessage]);

  const terminateSession = useCallback(() => {
    const ws = getWebSocket();
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.close();
      setTestSessionId(null);
      initialMessageSent.current = false;
    }
  }, [getWebSocket]);

  useEffect(() => {
    return () => {
      if (location.pathname !== "/presence-ai") {
        terminateSession();
      }
    };
  }, [location.pathname, terminateSession]);

  return (
    <div className="presenceai-container">
      {/* {loading && (
          <div className='loading-content'>
            <CircularProgress
              style={{ width: '30px', height: '30px', color: '#0036af' }}
            />
          </div>
        )} */}
      <Left
        messages={messages}
        botLoading={botLoading}
        onContextSubmit={onContextSubmit}
        handleMessageSubmit={handleMessageSubmit}
      />
      <Right testcases={testcases} />
    </div>
  );
};

export default PresenceAi;


