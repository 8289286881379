import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Button } from "antd";
import acttesticon from "../../assets/testcaseeditor/acttesticon.svg";
import actexhistry from "../../assets/testcaseeditor/actexhistry.svg";
import inacexhistry from "../../assets/testcaseeditor/inacexhistry.svg";
import inactesticon from "../../assets/testcaseeditor/inactesticon.svg";
import TestSteps from "./TestSteps/ts";
import ExecutionModalLinuxOnly from "../Testcase/ExecutionModalLinuxOnly";
import Exehstry from "./tabs/ExecutionHistory/eh";
import actData from "../../assets/database-teststep-active.png";
import inacData from "../../assets/database-teststep.png";
import "./testing.css";
import DataTable from "../Data/viewData/Table/dataTable";
import TsLinkedTestcases from "./Modals/tsLinkedTestcases/tsLinkedTestcases";
import TsLinkedData from "./Modals/tsLinkedData/tsLinkedData";
import { WebSocketProvider } from "./context/WebSocketContext";
import { connect } from "react-redux";
import { fetch_Linked_Testcase_to_Data } from "../../services/testCaseServices";
import UploadData from "../Data/components/UploadDataModal";
import ResponseContextProvider from "./context/ResponseContext";
import { useSelector } from "react-redux";

const Testing = ({ currentTestcaseName, linkedLocalDataId }) => {
  const [executeModalOpen, setExecuteModalOpen] = useState(false);
  const [linkedTestcasesModalOpen, setLinkedTestcasesModalOpen] =
    useState(false);
  const [linkedDataModalOpen, setLinkedDataModalOpen] = useState(false);
  const [replaceDataModalOpen, setReplaceDataModalOpen] = useState(false);
  const [uploadNewDataModalOpen, setUploadNewDataModalOpen] = useState(false);

  const [localDataId, setLocalDataId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [linkedTestcaseData, setLinkedTestcaseData] = useState([]);
  const [linkedTestcaseNumber, setLinkedTestcaseNumber] = useState(0);

  const { id, tab } = useParams();

  const [activeKey, setActiveKey] = useState(tab || "1");

  const cards = useSelector((state) => state.cards.cards);

  console.log("cardsTesting", cards);
  useEffect(() => {
    // console.log("URL Parameters:", { id, tab });
    if (tab) {
      setActiveKey(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (linkedLocalDataId) {
      setLocalDataId(linkedLocalDataId);
    }
  }, [linkedLocalDataId]);

  useEffect(() => {
    if (localDataId) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch_Linked_Testcase_to_Data(localDataId);
          setLinkedTestcaseData(response);
          setLinkedTestcaseNumber(response.length);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [localDataId]);

  const ExeButton = (
    <Button className="r" onClick={() => setExecuteModalOpen(true)}>
      Execute
    </Button>
  );

  const DataButton = (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <p>LoginCredentials</p>
      <div
        className="text-row view-linked-tests-row"
        onClick={() => setLinkedTestcasesModalOpen(true)}
      >
        <span>{linkedTestcaseNumber || 0}</span>
        <p>View</p>
      </div>
      <Button
        onClick={() => setReplaceDataModalOpen(!replaceDataModalOpen)}
        className="r"
      >
        Replace
      </Button>
      {replaceDataModalOpen && (
        <div className="replace-data-modal" key="replaceDataModal">
          <p
            onClick={() => {
              setReplaceDataModalOpen(false);
              setLinkedDataModalOpen(true);
            }}
          >
            Link Existing
          </p>
          <p
            onClick={() => {
              setReplaceDataModalOpen(false);
              setUploadNewDataModalOpen(true);
            }}
          >
            Upload New
          </p>
        </div>
      )}
    </div>
  );

  const tabBarExtraContent = (key) => {
    switch (key) {
      case "1":
        return ExeButton;
      case "2":
        return DataButton;
      case "3":
        return "";
      default:
        return "";
    }
  };

  return (
    <div className="App" style={{ margin: "0rem 1rem" }}>
      <div
        style={{
          overflowY: activeKey === "1" ? "hidden" : "auto",
        }}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={setActiveKey}
          tabBarExtraContent={tabBarExtraContent(activeKey)}
          className="tab"
          style={{ padding: "0 10px" }}
        >
          <Tabs.TabPane
            tab={
              <div style={{ display: "flex", alignContent: "center" }}>
                <img
                  src={activeKey === "1" ? acttesticon : inactesticon}
                  alt="icon"
                  className={`icon ${
                    activeKey === "1" ? "active-tab" : "other-tab"
                  }`}
                />
                <span
                  className={activeKey === "1" ? "active-tab" : "other-tab"}
                >
                  Test Steps
                </span>
              </div>
            }
            key="1"
          >
            <WebSocketProvider>
              <ResponseContextProvider>
                <TestSteps />
              </ResponseContextProvider>
            </WebSocketProvider>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={activeKey === "2" ? actData : inacData}
                  width={16}
                  height={16}
                  alt="icon"
                  className={`icon ${
                    activeKey === "2" ? "active-tab" : "other-tab"
                  }`}
                />
                <span
                  className={activeKey === "2" ? "active-tab" : "other-tab"}
                >
                  Data
                </span>
              </div>
            }
            key="2"
          >
            <DataTable
              tcTable
              localDataId={localDataId}
              activeKey={activeKey}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div style={{ display: "flex", alignContent: "center" }}>
                <img
                  src={activeKey === "3" ? actexhistry : inacexhistry}
                  alt="icon"
                  className={`icon ${
                    activeKey === "3" ? "active-tab" : "other-tab"
                  }`}
                />
                <span
                  className={activeKey === "3" ? "active-tab" : "other-tab"}
                >
                  Execution History
                </span>
              </div>
            }
            key="3"
          >
            <Exehstry activeKey={activeKey} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      {executeModalOpen && (
        <ExecutionModalLinuxOnly
          mode={"testing"}
          open={executeModalOpen}
          handleClose={() => setExecuteModalOpen(false)}
          TestCase={id}
          setActiveKey={setActiveKey}
        />
      )}
      {linkedTestcasesModalOpen && (
        <TsLinkedTestcases
          open={linkedTestcasesModalOpen}
          handleClose={() => setLinkedTestcasesModalOpen(false)}
          TestCase={id}
          setActiveKey={setActiveKey}
          loading={loading}
          dataSource={linkedTestcaseData}
        />
      )}
      {linkedDataModalOpen && (
        <TsLinkedData
          open={linkedDataModalOpen}
          handleClose={() => setLinkedDataModalOpen(false)}
          TestCase={id}
          setActiveKey={setActiveKey}
          tcName={currentTestcaseName}
          selected_local_data_id={localDataId}
        />
      )}
      {uploadNewDataModalOpen && (
        <UploadData
          testcaseModal
          open={uploadNewDataModalOpen}
          handleClose={() => setUploadNewDataModalOpen(false)}
          dataType="2"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  linkedLocalDataId: state.cards.linkedLocalDataId,
});

export default connect(mapStateToProps)(Testing);
