import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import deleteIcon from "../../assets/delete_icon.svg";
import moment from "moment";
import { connect } from "react-redux";
import { fetchGlobalData, triggerGlobalRefresh } from "./DataAction/dataAction";
import { toast } from "react-toastify";
import { delete_global } from "../../services/dataService";

const GlobalData = ({
  globalData,
  fetchGlobalData,
  triggerGlobalRefresh,
  refreshGlobal,
  searchValue,
  handleEditModel,
}) => {
  // const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataRef = useRef([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchGlobalData();

      if (response?.data) {
        dataRef.current = response.data;
        setFilteredData([...response.data]);
      }
    } catch (error) {
      console.error("Error fetching global data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refreshGlobal]);

  useEffect(() => {
    if (globalData && globalData?.GlobalDatas) {
      dataRef.current = globalData?.GlobalDatas;
      setFilteredData([...globalData?.GlobalDatas]);
    }
  }, [globalData]);

  useEffect(() => {
    if (searchValue.length && dataRef.current && dataRef.current.length) {
      const analysedQuery = searchValue.trim().toLowerCase();
      const filtered = dataRef.current.filter(
        (item) => item.key.toLowerCase().includes(analysedQuery)
        //  || item.value.toLowerCase().includes(analysedQuery)
      );
      setFilteredData(filtered);
    } else if (searchValue === "") {
      setFilteredData([...dataRef.current]);
    }
  }, [searchValue]);

  const doDelete = async (record) => {
    setLoading(true);
    const { key } = record;
    try {
      const response = await delete_global(key);
      if (!response) {
        throw new Error("Failed to delete data");
      }
      triggerGlobalRefresh(); // Call the function
    } catch (error) {
      toast.error("Failed to delete data");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (text, value) => {
    // Define the callback to refresh data after editing
    const onEditClose = async () => {
      await fetchData(); // Fetch the data after editing is complete
    };

    handleEditModel(text, value, onEditClose);
  };

  const columns = [
    {
      title: <div className="column-title">Key</div>,
      dataIndex: "key",
      key: "key",
      render: (text, record) => (
        <span
          className="text-row-key"
          onClick={() => handleClick(text, record.value)}
          style={{ cursor: "pointer" }}
        >
          {text}
        </span>
      ),
      width: 150,
    },
    {
      title: <div className="column-title">Value</div>,
      dataIndex: "value",
      key: "value",
      render: (text) => {
        const truncatedText =
          text.length > 25 ? `${text.substring(0, 25)}...` : text;
        return <span className="text-row">{truncatedText}</span>;
      },
      width: 300,
    },
    {
      title: <div className="column-title">Created</div>,
      dataIndex: "created_at",
      key: "created_at",
      render: (timestamp) => {
        const date = moment(timestamp * 1000);
        return (
          <div className="text-row">
            <p>{date.format("DD-MM-YYYY HH:mm")}</p>
          </div>
        );
      },
      width: 200,
    },
    {
      title: <div className="column-title">Updated</div>,
      dataIndex: "updated_at",
      key: "updated_at",
      render: (timestamp) => {
        const date = moment(timestamp * 1000);
        return (
          <div className="text-row">
            <p>{date.format("DD-MM-YYYY HH:mm")}</p>
          </div>
        );
      },
      width: 200,
    },
    {
      title: <div className="column-title">Last Updated by</div>,
      dataIndex: "last_updated_by",
      key: "last_updated_by",
      render: (text) => <span className="text-row">{text}</span>,
      width: 150,
    },
    {
      title: <div className="column-title"></div>,
      dataIndex: "",
      key: "delete-icon",
      render: (record) => (
        <img
          src={deleteIcon}
          alt="Delete"
          onClick={() => doDelete(record)}
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
        />
      ),
      width: 50,
    },
  ];

  return (
    <>
      <div className="parent-div-cont">
        <div className="table-cont" style={{ height: "80vh" }}>
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={false}
            className="table"
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  globalData: state.data.globalData,
});

const mapDispatchToProps = {
  fetchGlobalData,
  triggerGlobalRefresh, // Add this here
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalData);
