import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import { Form, Switch, Button, Input, Modal, Select, DatePicker } from "antd";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import styled from "styled-components";
import Linux from "../../assets/OSLogo/Linux.svg";
import Chrome from "../../assets/BrowserLogos/Chrome.svg";
import FireFox from "../../assets/BrowserLogos/Firefox.svg";
import {
  createSchedule,
  fetchScheduleById,
  updateSchedule,
} from "../../services/suitesServices";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;
const { RangePicker } = DatePicker;

const StyledSwitch = styled(Switch)`
  &&.ant-switch {
    ${"" /* background-color: #fff; */}
    height: 14px;
    width: 30px;
    padding: 0;
  }

  &&.ant-switch-checked {
    background-color: #b3c3e7;
  }

  &&.ant-switch-checked:hover {
    background-color: #b3c3e7;
  }

  &&.ant-switch .ant-switch-handle::before {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #0036af;
    transform: translate(-1.252px, -5.5px);
    ${"" /* transform: translateX(-1px) */}
  }

  &&.ant-switch.ant-switch-inner {
    display: none;
    height: 10px;
  }
`;

const frequencyOptionList = [
  {
    id: 1,
    key: "Do not repeat",
    value: "Do not repeat",
  },
  {
    id: 2,
    key: "Every 15 minutes",
    value: "Every 15 minutes",
  },
  {
    id: 3,
    key: "Every 30 minutes",
    value: "Every 30 minutes",
  },
  {
    id: 4,
    key: "Every 1 hour",
    value: "Every 1 hour",
  },
  {
    id: 5,
    key: "Daily",
    value: "Daily",
  },
  {
    id: 6,
    key: "Custom",
    value: "Custom",
  },
];

const repeatTypeOptionList = [
  {
    id: 1,
    key: "Hour",
    value: "hours",
  },
  {
    id: 2,
    key: "Day",
    value: "days",
  },
  {
    id: 3,
    key: "Week",
    value: "weeks",
  },
];

const platformList = [
  {
    id: 1,
    image: Linux,
    platform: "linux",
  },
  //   {
  //     id: 2,
  //     image: Linux,
  //     platform: "linux",
  //   },
];

const browsersList = [
  {
    id: 1,
    image: Chrome,
    browser: "chrome",
    browser_version: "91",
  },
  {
    id: 2,
    image: FireFox,
    browser: "firefox",
    browser_version: "109",
  },
];

const SuitePlatformContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 0.5rem;
  padding: 10px 0px;

  /* Styling the scrollbar */
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;
const getYearMonth = (date) => date.year() * 12 + date.month();

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const disabledDate = (current) => {
  return current && current < dayjs().startOf("day");
};

const disabledRangeTime = (current, type) => {
  const now = dayjs();
  const currentHour = now.hour();
  const currentMinute = now.minute();
  const currentDate = now.startOf("day");

  if (type === "start") {
    return {
      disabledHours: () => {
        if (current.isBefore(currentDate)) {
          return range(0, 24);
        }

        if (current.isSame(currentDate, "day")) {
          const hours = range(0, 24).filter((hour) => hour < currentHour);
          return hours;
        }
        return [];
      },
      disabledMinutes: () => {
        if (current.isBefore(currentDate)) {
          return range(0, 60);
        }

        if (
          current.isSame(currentDate, "day") &&
          currentHour === current.hour()
        ) {
          return range(0, 60).filter((minute) => minute < currentMinute + 1);
        }
        return [];
      },
    };
  }

  return {
    disabledHours: () => {
      if (current.isBefore(currentDate)) {
        return range(0, 24);
      }

      if (current.isSame(currentDate, "day")) {
        const hours = range(0, 24).filter((hour) => hour < currentHour);
        return hours;
      }
      return [];
    },
    disabledMinutes: () => {
      if (current.isBefore(currentDate)) {
        return range(0, 60);
      }

      if (
        current.isSame(currentDate, "day") &&
        currentHour === current.hour()
      ) {
        return range(0, 60).filter((minute) => minute < currentMinute + 1);
      }
      return [];
    },
  };
};

const ScheduleModal = forwardRef(
  ({ schedulerMode, selectedRecord, handleSchedulerUpdate }, ref) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [schedulerModalOpen, setSchedulerModalOpen] = useState(false);
    const [showCustomField, setShowCustomField] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState("linux");
    const [selectedBrowsers, setSelectedBrowsers] = useState([]);
    const [browserError, setBrowserError] = useState(false);
    const [activeSchedulId, setActiveSchedulId] = useState(null);

    const schedulerModalClose = () => {
      setSchedulerModalOpen(false);
      setShowCustomField(false);
      setBrowserError(false);
      setSelectedBrowsers([]);
      setActiveSchedulId(null);
      // setSchedulerId(null);
      form.resetFields();
    };

    const mountScheduleDetails = (details) => {
      const startDateTime = dayjs
        .utc(`${details.start_date} ${details.start_time}`, "YYYY-MM-DD HH:mm")
        .local();

      const endDateTime = dayjs
        .utc(`${details.end_date} ${details.end_time}`, "YYYY-MM-DD HH:mm")
        .local();

      if (!startDateTime.isValid() || !endDateTime.isValid()) {
        console.error("Invalid date format", { startDateTime, endDateTime });
        return;
      }

      form.setFieldsValue({
        dateRange: [startDateTime, endDateTime],
        repeatFrequency: details.repeat_frequency,
        enabled: details.enabled,
      });

      if (details.repeat_frequency === "Custom") {
        setShowCustomField(true);
        form.setFieldsValue({
          repeatEvery: details.repeat_every.toString(),
          repeatType: details.repeat_type,
        });
      } else {
        setShowCustomField(false);
      }

      if (details.browsers && details.browsers.length > 0) {
        const selectedBrowsers = details.browsers
          .map((browser) => {
            const foundBrowser = browsersList.find(
              (b) =>
                b.browser === browser.browser &&
                b.browser_version === browser.browser_version
            );
            return foundBrowser || null;
          })
          .filter(Boolean);

        setSelectedBrowsers(selectedBrowsers);

        // Set platform (assuming all browsers have the same platform)
        if (details.browsers[0].platform) {
          setSelectedPlatform(details.browsers[0].platform);
        }
      }
    };

    const getSchedule = async (scheduleId) => {
      if (!!scheduleId) {
        setModalLoading(true);
 
        const response = await fetchScheduleById(scheduleId);
        if (response?.data) {
          setActiveSchedulId(response.data?.id);
          mountScheduleDetails(response.data);
        } else {
          toast.error("Failed to fetch schedule details");
        }
        setModalLoading(false);
      } else {
        return;
      }
    };

    useImperativeHandle(ref, () => ({
      handleModal: (option) => {
        setLoading(false);
        if (option) {
          setSchedulerModalOpen(true);
        } else {
          schedulerModalClose();
        }
      },
    }));

    useEffect(() => {
      if (schedulerMode === "create" && selectedRecord?.schedulers) {
        form.setFieldValue("enabled", true);
      } else if (
        schedulerMode === "edit" &&
        selectedRecord?.schedulers &&
        selectedRecord?.schedulers.length
      ) {
        getSchedule(selectedRecord.schedulers[0]?.id);
      }
    }, [selectedRecord, schedulerMode]);

    const onFinishScheduler = (values) => {
      setBrowserError(false);

      if (!selectedBrowsers.length) {
        setBrowserError(true);
        return;
      }

      const startMoment = moment(values.dateRange[0].$d).utc();
      const endMoment = moment(values.dateRange[1].$d).utc();

      const now = moment().utc();

      if (startMoment.isBefore(now, "day")) {
        toast.error("Start date must be today or later.");
        return;
      }

      if (startMoment.isBefore(now)) {
        toast.error("Start time must be greater than the current time.");
        return;
      }

      const postData = {
        start_date: startMoment.format("YYYY-MM-DD"),
        start_time: startMoment.format("HH:mm"),
        end_date: endMoment.format("YYYY-MM-DD"),
        end_time: endMoment.format("HH:mm"),
        repeat_frequency: values.repeatFrequency,
        repeat_every:
          values.repeatFrequency === "Custom"
            ? parseInt(values.repeatEvery, 10)
            : 0,
        repeat_type:
          values.repeatFrequency === "Custom" ? values.repeatType : null,
        enabled: values.enabled,
        browsers: selectedBrowsers.map((browser) => ({
          browser: browser.browser,
          platform: selectedPlatform,
          browser_version: browser.browser_version,
        })),
        suite_id: selectedRecord.id,
      };

      setLoading(true);

      if (schedulerMode === "create") {
        createNewSchedule(postData);
      } else if (schedulerMode === "edit") {
        updateExistSchedule(postData);
      }

 
    };

    const createNewSchedule = async (postData) => {
      const response = await createSchedule(postData);
 

      if (response?.data) {
        toast.success("Schedule added successfully");
        handleSchedulerUpdate();
        schedulerModalClose();
      } else {
        toast.error("Failed to add schedule");
      }

      setLoading(false);
    };

    const updateExistSchedule = async (postData) => {
      if (activeSchedulId === null || activeSchedulId === undefined) return;

      const response = await updateSchedule(activeSchedulId, postData);
 

      if (response?.data) {
        toast.success("Schedule updated successfully");
        handleSchedulerUpdate();
        schedulerModalClose();
      } else {
        toast.error("Failed to update schedule");
      }

      setLoading(false);
    };

    const handleBrowserSelect = (browser) => {
      setSelectedBrowsers((prevSelected) => {
        if (prevSelected.some((b) => b.id === browser.id)) {
          return prevSelected.filter((b) => b.id !== browser.id);
        } else {
          browser.platform = selectedPlatform;
          return [...prevSelected, browser];
        }
      });

      setBrowserError(false);
    };

    const handleSwitchChange = (checked) => {
      form.setFieldsValue({ enabled: checked });
    };

    // const disabled7DaysDate = (current, { from }) => {
    //   if (from) {
    //     const minDate = moment().startOf("day");
    //     if (current.isBefore(minDate)) {
    //       return true;
    //     }
    //   }
    //   return false;
    // };

    const onRangeChange = (dates, dateStrings) => {
      if (dates) {
        console.log("From: ", dates[0], ", to: ", dates[1]);
        console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      } else {
        console.log("Clear");
      }
    };

    return (
      <div className="schedule-modal">
        <Modal
          title={
            <div style={{ fontSize: "18px", textAlign: "center" }}>
              {schedulerMode === "create" ? "Add Schedule" : "Edit Schedule"}
            </div>
          }
          open={schedulerModalOpen}
          onCancel={schedulerModalClose}
          footer={[]}
          className="schedule-modal-content"
        >
          {modalLoading ? (
            <div className="schedule-loader">
              <CircularProgress
                size={22}
                thickness={4}
                style={{ color: "#0036af" }}
              />
            </div>
          ) : (
            <></>
          )}

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishScheduler}
            style={{ padding: "2rem 1rem 0rem 1rem" }}
          >
            {/* Date and Time Range */}
            <Form.Item
              name="dateRange"
              label="Date and Time Range"
              rules={[
                {
                  required: true,
                  message: "Please select a date and time range",
                },
                // ({ getFieldValue }) => ({
                //   validator(_, value) {
                //     if (!value || !Array.isArray(value) || value.length !== 2) {
                //       return Promise.resolve();
                //     }
                //     const [startDate, endDate] = value;
                //     if (endDate.isAfter(startDate)) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject(
                //       new Error(
                //         "End date and time must be after start date and time"
                //       )
                //     );
                //   },
                // }),
              ]}
            >
              <RangePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                style={{ width: "100%" }}
                // defaultValue={[moment("2024-08-14"), moment("2024-08-18")]}
                // defaultValue={[
                //   dayjs("2015-01-01 00:00", "YYYY-MM-DD HH:mm"),
                //   dayjs("2015-01-03 00:00", "YYYY-MM-DD HH:mm"),
                // ]}
                // disabledDate={disabled7DaysDate}
                disabledTime={disabledRangeTime}
                disabledDate={disabledDate}
                onChange={onRangeChange}
              />
            </Form.Item>

            {/* Repeat Frequency */}
            <div style={{ display: "flex", gap: "10px" }}>
              <Form.Item
                name="repeatFrequency"
                label="Repeat Frequency"
                rules={[
                  {
                    required: true,
                    message: "Please select a repeat frequency",
                  },
                ]}
              >
                <Select
                  placeholder="Select repeat frequency"
                  onChange={(value) => setShowCustomField(value === "Custom")}
                >
                  {frequencyOptionList.map((option) => (
                    <Option key={option.id} value={option.value}>
                      {option.key}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Repeat Every (only show when repeatFrequency is 'custom') */}
              {showCustomField && (
                <div style={{ display: "flex", gap: "10px", width: "67%" }}>
                  <Form.Item
                    name="repeatEvery"
                    label="Repeat Every"
                    rules={[
                      {
                        required: true,
                        message: "Please specify a custom repeat frequency",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter only numbers",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter custom"
                      style={{ width: "136px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="repeatType"
                    label="Type"
                    rules={[
                      {
                        required: true,
                        message: "Please specify a custom repeat type",
                      },
                    ]}
                  >
                    <Select placeholder="Ex: day" style={{ width: "150px" }}>
                      {repeatTypeOptionList.map((repeatType) => (
                        <Option key={repeatType.id} value={repeatType.value}>
                          {repeatType.key}
                        </Option>
                      ))}
                      {/* <Option value="minute">Minute</Option> */}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </div>

            <div className="suite-platform">
              <div>
                <label>Operating System</label>
                <SuitePlatformContainer>
                  {platformList.map((platform) => (
                    <div
                      key={platform.id}
                      className={`modal-platform-browser ${
                        selectedPlatform && "selected"
                      }`}
                    >
                      <img src={platform.image} alt={platform.platform} />
                      <span className="modal-label">{platform.platform}</span>
                    </div>
                  ))}
                </SuitePlatformContainer>
              </div>
              <div className="modal-browser-container">
                <label>Browsers</label>
                <SuitePlatformContainer>
                  {browsersList.map((browser) => (
                    <div
                      key={browser.id}
                      className={`modal-platform-browser ${
                        selectedBrowsers.some((b) => b.id === browser.id) &&
                        "selected"
                      }`}
                      onClick={() => handleBrowserSelect(browser)}
                    >
                      <img src={browser.image} alt={browser.browser} />
                      {/* <span>v{browser.browser_version}</span> */}
                    </div>
                  ))}
                </SuitePlatformContainer>
                <span
                  className={`modal-error-message ${
                    browserError && "is-error"
                  }`}
                >
                  Please select atleast one browser
                </span>
              </div>
            </div>

            {/* Enabled Toggle */}
            <Form.Item
              name="enabled"
              label={
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) =>
                    getFieldValue("enabled") ? "Enabled" : "Disabled"
                  }
                </Form.Item>
              }
              valuePropName="checked"
            >
              <StyledSwitch onChange={handleSwitchChange} />
            </Form.Item>
            <div
              style={{
                display: "flex",
                gap: "10px",
                paddingLeft: "auto",
                justifyContent: "end",
              }}
            >
              <Button key="back" onClick={schedulerModalClose}>
                Cancel
              </Button>
              <Button
                key="submit"
                style={{
                  backgroundColor: "#0036af",
                  borderColor: "#0036af",
                  color: "#fff",
                }}
                onClick={() => {
                  form.submit();
                }}
              >
                {loading ? (
                  <CircularProgress
                    size={18}
                    thickness={4}
                    style={{ color: "white" }}
                  />
                ) : (
                  <>{schedulerMode === "create" ? "Add" : "Save"}</>
                )}
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
);
ScheduleModal.displayName = "ScheduleModal";

export default ScheduleModal;

// console.log("Success:", values);
// if (schedulerMode === "create") {
//   console.log(schedulerId);
//   const index = data.findIndex((item) => item.suiteId === schedulerId);
//   if (index !== -1) {
//     // Update the scheduler property of the found object
//     const newData = [...data]; // Create a copy of the data array
//     newData[index] = {
//       ...newData[index],
//       scheduler: {
//         ...values,
//         enabled: undefined, // Remove the enabled property from the scheduler object
//       },
//       enabled: values.enabled, // Update the enabled property
//     };
//     setData(newData);
//     console.log(newData); // Update the state with the modified data
//   }
// } else if (schedulerMode === "edit") {
//   console.log(schedulerId);
//   const index = data.findIndex((item) => item.suiteId === schedulerId);
//   if (index !== -1) {
//     // Update the scheduler property of the found object
//     const newData = [...data]; // Create a copy of the data array
//     newData[index] = {
//       ...newData[index],
//       scheduler: {
//         ...values,
//         enabled: undefined, // Remove the enabled property from the scheduler object
//       },
//       enabled: values.enabled, // Update the enabled property
//     };
//     setData(newData);
//     console.log(newData); // Update the state with the modified data
//   }
// }

// schedulerModalClose();
