import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
} from "react";
import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Input, Button } from "antd";
import TestCases from "./TestCases";
import "./testCaseModal.css";

const contentStyles = {
  fontFamily: "Roboto, sans-serif",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxSizing: "border-box",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "auto",
  width: "40rem",
};

const TestCasesModal = forwardRef(
  ({ selectedTestCases, handleActions, isNew = false }, ref) => {
    const initialError = (isName = false, isTestCase = false) => ({
      isName,
      isTestCase,
    });

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameText, setNameText] = useState("");
    const [errors, setErrors] = useState(initialError());

    const testCaseRef = useRef(null);

    useImperativeHandle(ref, () => ({
      handleModal: (option) => {
        setLoading(false);
        setShowModal(option);
      },
    }));

    const handleModalClose = () => {
      setLoading(false);
      setShowModal(false);
      setErrors(initialError());
    };

    const handleContinue = () => {
      let modalData = {};
      setErrors(initialError());
      let isError = false;
      if (isNew) {
        if (nameText && nameText.length) {
          modalData.suiteName = nameText;
        } else {
          isError = true;
          setErrors((prevErrors) => ({ ...prevErrors, isName: true }));
        }
      }
      if (testCaseRef && testCaseRef.current) {
        const _testCases = testCaseRef.current.getCheckedTestCases();
        if (_testCases && _testCases.length) {
          modalData.testCases = _testCases;
        } else {
          isError = true;
          setErrors((prevErrors) => ({ ...prevErrors, isTestCase: true }));
        }
      }

      if (isError) {
        return;
      }
      setLoading(true);
      handleActions(modalData);
    };

    return (
      <Modal
        open={showModal}
        onCancel={handleModalClose}
        onClose={handleModalClose}
      >
        <Box sx={contentStyles}>
          <div className="tc-modal-header">
            <div
              className="tc-modal-heading"
              style={{ marginLeft: "10px", padding: "9px" }}
            >
              Select test cases
            </div>

            <div onClick={handleModalClose}>
              <svg
                style={{ marginRight: "5px", cursor: "pointer" }}
                width="42"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="36" rx="6" fill="#F5F5F5" />
                <path
                  d="M24.4166 12.876L23.1241 11.5835L17.9999 16.7077L12.8758 11.5835L11.5833 12.876L16.7074 18.0002L11.5833 23.1243L12.8758 24.4168L17.9999 19.2927L23.1241 24.4168L24.4166 23.1243L19.2924 18.0002L24.4166 12.876Z"
                  fill="#BDBDBD"
                />
              </svg>
            </div>
          </div>

          <div className="tc-modal-content">
            <div className="tc-input-content">
              {isNew && (
                <div className="tc-input-wrap">
                  <label className="tc-input-label">
                    Suite Name <span>*</span>
                  </label>
                  <Input
                    placeholder="Enter suite name"
                    value={nameText}
                    onChange={(e) => setNameText(e.target.value)}
                  />
                  <span className="tc-error input-error">
                    {errors.isName ? "Please enter a suite name" : ""}
                  </span>
                </div>
              )}
            </div>

            <TestCases
              ref={testCaseRef}
              selectedTestCases={selectedTestCases}
              errors={errors}
            />
          </div>

          <div className="tc-modal-actions">
            <button
              style={{
                color: "black",
                background: "#ffffff",
              }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>

            <button
              style={{
                color: "#fff",
                background: "#0036af",
              }}
              onClick={handleContinue}
            >
              {loading ? (
                <CircularProgress
                  size={18}
                  thickness={4}
                  style={{ color: "white" }}
                />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
);
TestCasesModal.displayName = "TestCasesModal";

export default TestCasesModal;

//   const handleRowClick = (record) => {
//     console.log("Record -->", record);
//     if (!selectedTestCases.includes(record.id)) {
//       const rowElement = document
//         .querySelector(`.test-case-name [data-row-id="${record.test_case_id}"]`)
//         ?.closest("tr");
//       if (rowElement) {
//         rowElement.classList.add("highlight-row");
//       }
//       setTimeout(() => {
//         const newValue = [...selectedTestCases, record.test_case_id];
//         console.log("newValue", newValue);
//         // onChange(newValue);
//         // setDelayedSelectedRows((prev) => [...prev, record.id]);
//       }, 3000); // Delay of 3000ms
//     }
//   };
