import React, { useState } from 'react';
import { Input, Button } from 'antd';
import GlobalLocalDropdown from '../../../../../components/GlobalLocalDropdown';
import deleteIcon from "../../../../../assets/delete_icon.svg";
import gbutton from "../../../../../assets/G-button.svg";
import lbutton from "../../../../../assets/lbutton.svg";
import closeIcon from "../../../../../assets/close-icon.svg";
import { set } from 'lodash';

const Headers = ({ data, setData }) => {
    const [newHeader, setNewHeader] = useState({ key: '', value: '' });
    const [showGlobalLocalDropdown, setShowGlobalLocalDropdown] = useState(false);
    const [focusedParamKey, setFocusedParamKey] = useState('');
    const [globalLocalDropdownData, setGlobalLocalDropdownData] = useState(null); // Assuming this state is used for dropdown data

    const [editedKeys, setEditedKeys] = useState({});
    const [focusedHeaderKey, setFocusedHeaderKey] = useState(null);


    const handleParamChange = (key, value) => {
        // Update header with new value
        setData(prevData => ({
            ...prevData,
            headers: {
                ...prevData.headers,
                [key]: value
            }
        }));
    };

    const handleClearParamValue = (key) => {
        const updatedValue = "";
        setData(prevData => ({
            ...prevData,
            headers: {
                ...prevData.headers,
                [key]: updatedValue
            }
        }));
    };

    const handleGlobalLocalFocus = (key) => {
        setFocusedParamKey(key);
        setShowGlobalLocalDropdown(true);
        // Additional logic to set globalLocalDropdownData if needed
    };
    const handleGlobalLocalBlur = () => {
        setFocusedParamKey('');
        setShowGlobalLocalDropdown(false);
    };

    const handleAddParam = () => {
        const { key, value } = newHeader;
        if (key && value) {
            setData(prevData => ({
                ...prevData,
                headers: {
                    ...prevData.headers,
                    [key]: value
                }
            }));
            setNewHeader({ key: '', value: '' });
        }
    };

    const setGlobalLocalValue = (obj) => {
 
        setData(prevData => ({
            ...prevData,
            headers: {
                ...prevData.headers,
                [focusedParamKey]: obj
            }
        }));
    };

    const handleDeleteParam = (key) => {
        // Remove the parameter from query_params
        const { [key]: omit, ...rest } = data.headers;
        setData(prevData => ({
            ...prevData,
            headers: rest
        }));
    };

    const handleChangeKey = (oldKey, newKey) => {
        if (oldKey === newKey || !newKey.trim()) {
            return; // Do nothing if the new key is the same as the old key or empty
        }

        // Update editedKeys state to store the new key temporarily
        setEditedKeys(prevEditedKeys => ({
            ...prevEditedKeys,
            [oldKey]: newKey
        }));
    };


    const handleBlurChangeKey = (oldKey, newKey) => {
        // Check if the new key is different from the old key and not empty
        if (oldKey !== newKey && newKey.trim()) {
            // Create a copy of headers and update the key
            const updatedHeaders = { ...data.headers };
            const value = updatedHeaders[oldKey];
            delete updatedHeaders[oldKey];
            updatedHeaders[newKey] = value;

            // Update the state with the new headers and clear editedKeys
            setData(prevData => ({
                ...prevData,
                headers: updatedHeaders
            }));
        }

        // Remove the key from editedKeys
        setEditedKeys(prevEditedKeys => {
            const { [oldKey]: omit, ...rest } = prevEditedKeys;
            return rest;
        });
    };

    const handleNewHeaderDataChange = (obj) => {
        setNewHeader({ key: newHeader.key, value: obj });
        setShowGlobalLocalDropdown(false);
    }

    return (
        <div>
            {/* Render existing headers */}
            {Object.entries(data.headers).map(([key, value]) => (
                <div className="form-row" key={key} style={{ marginBottom: '10px' }}>
                    <input
                        placeholder="Key"
                        className="param-input-key"
                        value={editedKeys[key] !== undefined ? editedKeys[key] : key}
                        onChange={(e) => handleChangeKey(key, e.target.value)}
                        onBlur={(e) => handleBlurChangeKey(key, e.target.value)}
                    />
                    <div style={{ position: "relative" }} className='param-input-value-container'>
                        {typeof value === 'object' ? (
                            <div className="input-data-values">
                                {value.data_type === 1 ? (
                                    <img src={gbutton} alt='gbutton' />
                                ) : value.data_type === 2 ? (
                                    <img src={lbutton} alt='lbutton' />
                                ) : null}
                                <p style={{ color: value.data_type === 1 ? "#991AE1" : (value.data_type === 2 ? "" : "") }}>
                                    {value.value ? value.value : value.save_data_name}
                                </p>
                                <img
                                    src={closeIcon}
                                    className='input-close-icon'
                                    width={18}
                                    alt="remove file"
                                    onClick={() => handleClearParamValue(key)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        ) : (
                            <>
                                <input
                                    placeholder="Value"
                                    className="param-input-value"
                                    value={typeof value === 'string' ? value : ''}
                                    onChange={(e) => handleParamChange(key, e.target.value)}
                                    onFocus={() => handleGlobalLocalFocus(key)}
                                />
                                {showGlobalLocalDropdown && focusedParamKey === key && (
                                    <div style={{ position: "absolute", top: "40px" }}>
                                        <GlobalLocalDropdown
                                            setGlobalLocalValue={setGlobalLocalValue}
                                            onClose={handleGlobalLocalBlur}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <img
                        src={deleteIcon}
                        alt='delete'
                        onClick={() => handleDeleteParam(key)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ))}

            {/* Add new header */}
            <div className="form-row" style={{ marginBottom: '10px' }}>
                <input
                    placeholder="New Header Key"
                    className="param-input-key"
                    value={newHeader.key}
                    onChange={(e) => setNewHeader({ ...newHeader, key: e.target.value })}
                />
                <div style={{ position: "relative" }} className='param-input-value-container'>
                    {typeof newHeader.value === 'object' ? (
                        <div className="input-data-values">
                            {newHeader.value.data_type === 1 ? (
                                <img src={gbutton} alt='gbutton' />
                            ) : newHeader.value.data_type === 2 ? (
                                <img src={lbutton} alt='lbutton' />
                            ) : null}
                            <p style={{ color: newHeader.value.data_type === 1 ? "#991AE1" : (newHeader.value.data_type === 2 ? "" : "") }}>
                                {newHeader.value.value ? newHeader.value.value : newHeader.value.save_data_name}
                            </p>
                            <img
                                src={closeIcon}
                                className='input-close-icon'
                                width={18}
                                alt="remove file"
                                onClick={() => setNewHeader({ ...newHeader, value: "" })}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    ) : (
                        <input
                            placeholder="New Header Value"
                            className="param-input-value"
                            value={newHeader.value}
                            onChange={(e) => setNewHeader({ ...newHeader, value: e.target.value })}
                            onFocus={() => handleGlobalLocalFocus('new')}
                            // onBlur={() => setShowGlobalLocalDropdown(false)}
                        />
                    )}
                    {showGlobalLocalDropdown && focusedParamKey === 'new' && (
                        <div style={{ position: "absolute", top: "40px" }}>
                            <GlobalLocalDropdown
                                showGlobalOptions={true}
                                showLocalOptions={true}
                                data={globalLocalDropdownData}
                                setGlobalLocalValue = {handleNewHeaderDataChange}
                                onClose={handleGlobalLocalBlur}
                            />
                        </div>
                    )}
                </div>
            </div>

            <Button className="add-param-button" onClick={handleAddParam}>Add Header</Button>
        </div>
    );
};

export default Headers;
