export const authorizationType = [
    {
        type: "Bearer",
        token: ""
    },
    {
        type: "Basic",
        username: "",
        password: ""
    },
    {
        type: "API Key",
        key_name: "",
        key_value: "",
    },
    // {
    //     type: "OAuth2",
    //     access_token: null,
    //     refresh_token: null,
    //     token_type: "Bearer"
    // }
]

