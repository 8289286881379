import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import deleteIcon from '../../../../../assets/delete_icon.svg';
import gbutton from "../../../../../assets/G-button.svg";
import lbutton from "../../../../../assets/lbutton.svg";
import closeIcon from '../../../../../assets/close-icon-local-data.svg';
import GlobalLocalDropdown from '../../../../../components/GlobalLocalDropdown'; // Adjust path as necessary
import { set } from 'lodash';

const Params = ({ data, setData }) => {
    const [pathParams, setPathParams] = useState({});
    const [showGlobalLocalDropdown, setShowGlobalLocalDropdown] = useState(false);
    const [focusedParamKey, setFocusedParamKey] = useState(null);
    const [editedKeys, setEditedKeys] = useState({});

    const [newQueryParamText, setNewQueryParamText] = useState("");
    const [newPathParamText, setNewPathParamText] = useState("");

    const [openPathParamDropdown, setOpenPathParamDropdown] = useState("");
    useEffect(() => {
        // Initialize pathParams from data if available
        setPathParams(data.path_params || {});
    }, [data]);

    const handleOpenPathParamDropdown = (key) => {
        setFocusedParamKey(key);
        setOpenPathParamDropdown(key);
    }
    const handleParamChange = (key, value) => {
        setData(prevData => ({
            ...prevData,
            query_params: {
                ...prevData.query_params,
                [key]: value
            }
        }));
    };

    const handleAddParam = () => {
 
        const newKeyElement = document.getElementById('new-param-key');

        const newKey = newKeyElement.value.trim();
        const newValue = newQueryParamText

        // Check if both key and value are present
        if (newKey && newValue) {
            setData(prevData => ({
                ...prevData,
                query_params: {
                    ...prevData.query_params,
                    [newKey]: newValue
                }
            }));

            // Clear input fields after adding
            newKeyElement.value = '';
            setNewQueryParamText('');
        }
    };


    const handlePathParamChange = (key, value) => {
        const updatedPathParams = { ...pathParams, [key]: value };
        setPathParams(updatedPathParams);

        setData(prevData => ({
            ...prevData,
            path_params: updatedPathParams
        }));
    };

    const handleClearParamValue = (key) => {
        const updatedValue = "";
        setData(prevData => ({
            ...prevData,
            query_params: {
                ...prevData.query_params,
                [key]: updatedValue
            }
        }));
    };

    const handleDeleteParam = (key) => {
        // Remove the parameter from query_params
        const { [key]: omit, ...rest } = data.query_params;
        setData(prevData => ({
            ...prevData,
            query_params: rest
        }));
    };

    const handleGlobalLocalFocus = (key) => {
        setShowGlobalLocalDropdown(true);
        setFocusedParamKey(key);
    };
    const handleGlobalLocalBlur = () => {
        setShowGlobalLocalDropdown(false);
        setFocusedParamKey(null);
    };

    const setGlobalLocalValue = (obj) => {
        setData(prevData => ({
            ...prevData,
            query_params: {
                ...prevData.query_params,
                [focusedParamKey]: obj
            }
        }));
    };

    const setNewQueryParamTextToData = (obj) => {
        setNewQueryParamText(obj);
        setShowGlobalLocalDropdown(false);
    };

    const handleChangeKey = (oldKey, newKey) => {
        if (oldKey === newKey || !newKey.trim()) {
            return; // Do nothing if the new key is the same as the old key or empty
        }

        // Update editedKeys state to store the new key temporarily
        setEditedKeys(prevEditedKeys => ({
            ...prevEditedKeys,
            [oldKey]: newKey
        }));
    };

    const handleBlurChangeKey = (oldKey, newKey) => {
        // Check if the new key is different from the old key and not empty
        if (oldKey !== newKey && newKey.trim()) {
            // Create a copy of query_params and update the key
            const updatedQueryParams = { ...data.query_params };
            const value = updatedQueryParams[oldKey];
            delete updatedQueryParams[oldKey];
            updatedQueryParams[newKey] = value;

            // Update the state with the new query_params and clear editedKeys
            setData(prevData => ({
                ...prevData,
                query_params: updatedQueryParams
            }));
        }

        // Remove the key from editedKeys
        setEditedKeys(prevEditedKeys => {
            const { [oldKey]: omit, ...rest } = prevEditedKeys;
            return rest;
        });
    };

    const handlePathParamGlobalLocalClick = (key, obj) => {
        handlePathParamChange(key, obj);
        setOpenPathParamDropdown("");
    }
 
    return (
        <>
            <div>
                <p style={{ fontWeight: "500" }}>Query Params</p>
                {/* Render existing query params */}
                {Object.entries(data.query_params).map(([key, value]) => (
                    <div className="form-row" key={key} style={{ marginBottom: '10px' }}>
                        <input
                            placeholder="Key"
                            className="param-input-key"
                            value={editedKeys[key] !== undefined ? editedKeys[key] : key}
                            onChange={(e) => handleChangeKey(key, e.target.value)}
                            onBlur={(e) => handleBlurChangeKey(key, e.target.value)}
                        />
                        <div style={{ position: "relative" }} className='param-input-value-container'>
                            {typeof value === 'object' ? (
                                <div className="input-data-values">
                                    {value.data_type === 1 ? (
                                        <img src={gbutton} alt='gbutton' />
                                    ) : value.data_type === 2 ? (
                                        <img src={lbutton} alt='lbutton' />
                                    ) : null}
                                    <p style={{ color: value.data_type === 1 ? "#991AE1" : (value.data_type === 2 ? "#96A7E6" : "") }}>
                                        {value.value ? value.value : value.save_data_name}
                                    </p>
                                    <img
                                        className='input-close-icon'
                                        src={closeIcon}
                                        width={18}
                                        alt="remove file"
                                        onClick={() => handleClearParamValue(key)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <input
                                        placeholder="Value"
                                        className="param-input-value"
                                        value={typeof value === 'string' ? value : ''}
                                        onChange={(e) => handleParamChange(key, e.target.value)}
                                        onFocus={() => handleGlobalLocalFocus(key)}
                                    />
                                    {showGlobalLocalDropdown && focusedParamKey === key && (
                                        <div style={{ position: "absolute", top: "40px" }}>
                                            <GlobalLocalDropdown
                                                setGlobalLocalValue={setGlobalLocalValue}
                                                onClose={handleGlobalLocalBlur}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <img
                            src={deleteIcon}
                            alt='delete'
                            onClick={() => handleDeleteParam(key)}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                ))}

                {/* Add new query param */}
                <div className="form-row" style={{ marginBottom: '10px' }}>
                    <input
                        id="new-param-key"
                        placeholder="Key"
                        className="param-input-key"
                    />
                    <div style={{ position: "relative" }} className='param-input-value-container'>
                        {typeof newQueryParamText === 'object' ? (
                            <div className="input-data-values">
                                {newQueryParamText.data_type === 1 ? (
                                    <img src={gbutton} alt='gbutton' />
                                ) : newQueryParamText.data_type === 2 ? (
                                    <img src={lbutton} alt='lbutton' />
                                ) : null}
                                <p style={{ color: newQueryParamText.data_type === 1 ? "#991AE1" : (newQueryParamText.data_type === 2 ? "#96A7E6" : "") }}>
                                    {newQueryParamText.value ? newQueryParamText.value : newQueryParamText.save_data_name}
                                </p>
                                <img
                                    className='input-close-icon'
                                    src={closeIcon}
                                    width={18}
                                    alt="remove file"
                                    onClick={() => setNewQueryParamText("")}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        ) : (
                            <input
                                id="new-param-value"
                                placeholder="Value"
                                value={newQueryParamText}
                                className="param-input-value"
                                onFocus={() => handleGlobalLocalFocus('new')}
                                onChange={(e) => setNewQueryParamText(e.target.value)}
                            />
                        )}
                        {showGlobalLocalDropdown && focusedParamKey === 'new' && (
                            <div style={{ position: "absolute", top: "40px" }}>
                                <GlobalLocalDropdown
                                    setGlobalLocalValue={setNewQueryParamTextToData}
                                    onClose={handleGlobalLocalBlur}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <Button className="add-param-button" onClick={handleAddParam}>Add Parameter</Button>
            </div>

            {/* Render path params if there are any */}
            {Object.keys(pathParams).length > 0 && (
                <div>
                    <p style={{ fontWeight: "500" }}>Path Params</p>
                    {Object.entries(pathParams).map(([key, value]) => (
                        <div className="form-row" key={key} style={{ marginBottom: '10px', position:"relative" }}>
                            <input
                                placeholder="Key"
                                className="param-input-key"
                                readOnly
                                value={key}
                                onChange={(e) => handlePathParamChange(key, e.target.value)}
                            />
                            <div className='param-input-value-container' style={{ position:"relative" }}>
                                {typeof value === 'object' ? (
                                    <div className="input-data-values">
                                        {value.data_type === 1 ? (
                                            <img src={gbutton} alt='gbutton' />
                                        ) : value.data_type === 2 ? (
                                            <img src={lbutton} alt='lbutton' />
                                        ) : null}
                                        <p style={{ color: value.data_type === 1 ? "#991AE1" : (value.data_type === 2 ? "#96A7E6" : "") }}>
                                            {value.value ? value.value : value.save_data_name}
                                        </p>
                                        <img
                                            className='input-close-icon'
                                            src={closeIcon}
                                            width={18}
                                            alt="remove file"
                                            onClick={() => handlePathParamChange(key, "")}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                ) : (

                                    <input
                                        placeholder="Value"
                                        className="param-input-value"
                                        value={value}
                                        onFocus={() => handleOpenPathParamDropdown(key)}
                                        onChange={(e) => handlePathParamChange(key, e.target.value)}
                                    />
                                )}
                                {openPathParamDropdown === key && (
                                    <div style={{ position: "absolute", top: "40px" }}>
                                        <GlobalLocalDropdown
                                            setGlobalLocalValue={obj => handlePathParamGlobalLocalClick(key, obj)}
                                            onClose={() => setOpenPathParamDropdown("")}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Params;
