import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import SeGroup from "./components/seGroup";
import "./suiteEditor.css";
import { getSuiteDetailsById } from "../../../services/suitesServices";
import { updateSuiteGroups } from "../../../services/suitesServices";
import { setTitle } from "../../../providers/titleProvider";

const SuiteEditor = ({ suiteId }) => {
  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [suiteData, setSuiteData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAndReplaceTestCases = async (suiteId) => {
    try {
      setLoading(true);
      const suiteDetails = await getSuiteDetailsById(suiteId);

      if (!suiteDetails) return;
      if (suiteDetails.groups && suiteDetails.groups.length) {
        const _selectedTestCasesMap = new Map();
        suiteDetails.groups.forEach((suiteDetail) => {
          if (suiteDetail?.test_cases && suiteDetail.test_cases.length) {
            suiteDetail.test_cases.forEach((testCase) => {
              _selectedTestCasesMap.set(testCase.id, testCase);
            });
          }
        });

        setSelectedTestCases(Array.from(_selectedTestCasesMap.values()));
        setSuiteData(suiteDetails.groups);
      }

      if (suiteDetails?.name && suiteDetails?.name.length) {
        setTitle(suiteDetails?.name, true);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const createPostData = (suitePostData) => {
    const suitePostGroup = {
      groups: [],
    };

    suitePostGroup.groups = suitePostData.filter(
      (group) => group.test_cases.length > 0
    );

    return suitePostGroup;
  };

  const handleTestCasesUpdate = (groupIndex, newTestCases) => {
    const groups = [...suiteData];
    const targetGroup = groups[groupIndex];
    const selectedTestCasesMap = new Map(
      selectedTestCases.map((tc) => [tc.id, tc])
    );
    const updatedTestCases = [...targetGroup.test_cases, ...newTestCases];

    targetGroup.test_cases = updatedTestCases;
    newTestCases.forEach((testCase) => {
      if (!selectedTestCasesMap.has(testCase.id)) {
        selectedTestCasesMap.set(testCase.id, testCase);
      }
    });

    const _selectedTestCases = Array.from(selectedTestCasesMap.values());

    setSelectedTestCases(_selectedTestCases);
    setSuiteData(groups);
    updateSuiteGroups(suiteId, createPostData(groups));
  };

  const handleDrop = (
    sourceGroupIndex,
    sourceTestIndex,
    targetGroupIndex,
    targetTestIndex
  ) => {
    const groups = [...suiteData];
    const sourceGroup = groups[sourceGroupIndex];
    const targetGroup = groups[targetGroupIndex];
    const sourceTestCase = sourceGroup.test_cases[sourceTestIndex];

    sourceGroup.test_cases.splice(sourceTestIndex, 1);
    targetGroup.test_cases.splice(targetTestIndex, 0, sourceTestCase);

    setSuiteData(groups);
    updateSuiteGroups(suiteId, createPostData(groups));
  };

  const handleGroupDrop = (sourceGroupIndex, targetGroupIndex) => {
    const groups = [...suiteData];
    const sourceGroup = groups[sourceGroupIndex];
    const targetGroup = groups[targetGroupIndex];

    groups[sourceGroupIndex] = targetGroup;
    groups[targetGroupIndex] = sourceGroup;

    setSuiteData(groups);
    updateSuiteGroups(suiteId, createPostData(groups));
  };

  const handleCardDelete = (groupIndex, cardIndex) => {
    const groups = [...suiteData];
    const targetGroup = groups[groupIndex];
    const targetTestCase = targetGroup.test_cases[cardIndex];
    const _selectedTestCases = selectedTestCases.filter(
      (testCase) => testCase.id !== targetTestCase.id
    );

    targetGroup.test_cases.splice(cardIndex, 1);

    setSelectedTestCases(_selectedTestCases);
    setSuiteData(groups);
    updateSuiteGroups(suiteId, createPostData(groups));
  };

  const handleGroupDelete = (groupIndex) => {
    const groups = [...suiteData];
    const targetGroup = groups[groupIndex];
    let flag = false;
    if (targetGroup.test_cases && targetGroup.test_cases.length) {
      const selectedTestCasesMap = new Map(
        selectedTestCases.map((tc) => [tc.id, tc])
      );

      targetGroup.test_cases.forEach((testCase) => {
        if (selectedTestCasesMap.has(testCase.id)) {
          selectedTestCasesMap.delete(testCase.id);
        }
      });

      flag = true;
      const _selectedTestCases = Array.from(selectedTestCasesMap.values());
      setSelectedTestCases(_selectedTestCases);
    }

    groups.splice(groupIndex, 1);
    setSuiteData(groups);
    flag && updateSuiteGroups(suiteId, createPostData(groups));
  };

  const handleAddGroup = (groupIndex) => {
    const groups = [...suiteData];
    const newGroup = {
      is_parallel: true,
      test_cases: [],
    };

    groups.splice(groupIndex + 1, 0, newGroup);

    setSuiteData(groups);
  };

  const handleMode = (groupIndex, mode) => {
    const groups = [...suiteData];
    const targetGroup = groups[groupIndex];
    targetGroup.is_parallel = mode === "Parallel Execution" ? true : false;

    setSuiteData(groups);
    targetGroup.test_cases.length &&
      updateSuiteGroups(suiteId, createPostData(groups));
  };

  useEffect(() => {
    fetchAndReplaceTestCases(suiteId);
  }, [suiteId]);

  return (
    <div className="se-container">
      {loading ? (
        <div className="se-loding-screen">
          <CircularProgress
            size={32}
            thickness={6}
            style={{ color: "#0036af" }}
          />
        </div>
      ) : (
        <>
          {suiteData &&
            suiteData.map((group, index) => (
              <SeGroup
                key={index}
                group={group}
                groupIndex={index}
                selectedCards={selectedTestCases}
                handleDrop={handleDrop}
                handleGroupDrop={handleGroupDrop}
                handleCardDelete={handleCardDelete}
                handleGroupDelete={handleGroupDelete}
                handleAddGroup={handleAddGroup}
                handleMode={handleMode}
                setSelectedCards={handleTestCasesUpdate}
              />
            ))}
        </>
      )}
    </div>
  );
};

export default SuiteEditor;

// import SelectTestCases from "./components/SelectTestCases";

// const [data, setData] = useState(testCasesData);
// const [deletable, setDeletable] = useState(true);
// const [selectedCards, setSelectedCards] = useState([]);
// const [enabledGroupOrder, setEnabledGroupOrder] = useState(null);

// onTestCasesUpdate={(order, newTestCases) =>
//   handleTestCasesUpdate(order, newTestCases)
// }
// enabledGroupOrder={enabledGroupOrder}

// deletable={deletable}
// selectedCards={selectedCards}
//setSelectedCards={setSelectedCards}

// if (sourceTestIndex !== null) {
//   const movedTestCases = selectedCards.map(
//     (card) => sourceGroup.testCase[card.index]
//   );
//   const isDroppingSelectedCard = selectedCards.some(
//     (card) => card.id === sourceGroup.testCase[sourceTestIndex].id
//   );

//   if (isDroppingSelectedCard) {
//     movedTestCases.forEach((movedTestCase) => {
//       sourceGroup.testCase = sourceGroup.testCase.filter(
//         (tc) => !selectedCards.some((card) => card.id === tc.id)
//       );
//     });

//     if (targetTestIndex === -1) {
//       targetGroup.testCase.push(...movedTestCases);
//     } else {
//       targetGroup.testCase.splice(targetTestIndex, 0, ...movedTestCases);
//     }

//     setSelectedCards([]);
//   } else {
//     const movedTestCase = { ...sourceGroup.testCase[sourceTestIndex] };
//     let flag = false;

//     if (targetTestIndex === -1) {
//       targetGroup.testCase.push(movedTestCase);
//       flag = true;
//     } else {
//       if (
//         !targetGroup.testCase.some((card) => card.id === movedTestCase.id)
//       ) {
//         targetGroup.testCase.splice(targetTestIndex, 0, movedTestCase);
//         flag = true;
//       }
//     }

//     if (flag) {
//       sourceGroup.testCase = sourceGroup.testCase.filter(
//         (tc) => tc.id !== movedTestCase.id
//       );
//     } else {
//       toast.error("Test case already exist");
//     }
//   }
// } else {
//   const [movedGroup] = data.splice(sourceGroupIndex, 1);
//   data.splice(targetGroupIndex, 0, movedGroup);
// }

// const sourceGroupIndex = data.findIndex(
//   (group) => group.order === sourceGroupOrder
// );
// const targetGroupIndex = data.findIndex(
//   (group) => group.order === targetGroupOrder
// );

// useEffect(() => {
//   const isOnlyOneGroupWithEmptyTestCases =
//     data.length === 1 && data[0].testCase.length === 0;
//   // setDeletable(!isOnlyOneGroupWithEmptyTestCases);
// }, [data]);

// useEffect(() => {
//   const groupOrders = selectedCards.map((card) => card.groupOrder);
//   if (groupOrders.length === 1) {
//     setEnabledGroupOrder(groupOrders[0]);
//   } else {
//     setEnabledGroupOrder(null);
//   }
// }, [selectedCards]);

// const handleContinue = (newTestCases) => {
//   const updatedData = data.map((group) => {
//     if (group.order === "1") {
//       return {
//         ...group,
//         testCase: newTestCases.map((tc) => ({
//           id: tc.test_case_id,
//           name: tc.name,
//         })),
//       };
//     }
//     return group;
//   });
//   setData(updatedData);
// };

// const testCasesData = [
//   {
//     order: "1",
//     Group: "1",
//     isParallel: true,
//     testCase: [
//       // { id: '432', name: 'Test Case 1' },
//       // { id: '430', name: 'Test Case 2' },
//       // { id: '332', name: 'Test Case 3' },
//     ],
//   },
// ];
