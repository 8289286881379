import React, { useEffect, useRef } from "react";
import RFB from "./core/rfb.js";
import "./liveTestingScreen.css";
import { apiWS } from "../../../services/apiServices";
import { useWebSocketContext } from "./../context/WebSocketContext";

export default function LiveTestingScreen(props) {
  let rfb;
  let desktopName;
  const { sessionId } = props;

  // When this function is called we have
  // successfully connected to a server
  function connectedToServer(e) {
    status("Connected to " + desktopName);
  }

  // This function is called when we are disconnected
  function disconnectedFromServer(e) {
    if (e.detail.clean) {
      // console.log("Clean disconnection");
      status("Disconnected");
    } else {
      status("Something went wrong, connection is closed");
    }
  }

  // When this function is called, the server requires
  // credentials to authenticate
  function credentialsAreRequired(e) {
    const password = prompt("Password Required:");
    rfb.sendCredentials({ password: password });
  }

  // When this function is called we have received
  // a desktop name from the server
  function updateDesktopName(e) {
    desktopName = e.detail.name;
  }

  // Since most operating systems will catch Ctrl+Alt+Del
  // before they get a chance to be intercepted by the browser,
  // we provide a way to emulate this key sequence.
  function sendCtrlAltDel() {
    rfb.sendCtrlAltDel();
    return false;
  }

  // Show a status text in the top bar
  function status(text) {
    if (document.getElementById("status")) {
      document.getElementById("status").textContent = text;
    }
    //implemented this check because in /settings/Test-case, redirecting to
    //Test-case lead to crash, saying reading textContent of undefined.
  }

  // This function extracts the value of one variable from the
  // query string. If the variable isn't defined in the URL
  // it returns the default value instead.
  function readQueryVariable(name, defaultValue) {
    // A URL with a query parameter can look like this:
    // https://www.example.com?myqueryparam=myvalue
    //
    // Note that we use location.href instead of location.search
    // because Firefox < 53 has a bug w.r.t location.search
    const re = new RegExp(".*[?&]" + name + "=([^&#]*)"),
      match = "".concat(document.location.href, window.location.hash).match(re);

    if (match) {
      // We have to decode the URL since want the cleartext value
      return decodeURIComponent(match[1]);
    }

    return defaultValue;
  }

  function resizeCanvas() {
    const screenElement = document.getElementById("screen");
    if (screenElement && rfb) {
      const width = screenElement.clientWidth;
      const height = screenElement.clientHeight;

      // Update the RFB display size
      rfb.scaleViewport = true;

      // Update viewport settings if necessary
      // Uncomment the following lines if you want to change viewport settings
      // rfb.scaleViewport = true;
      // rfb.clipViewport = false;

      // Request a remote session resize if the server supports it
      // Uncomment the following line if you want the remote session to match the client size
      // rfb.resizeSession = true;
    }
  }

  useEffect(() => {
    let s_id = sessionId;
    if (!rfb && s_id) {
      // console.log(el); // 👉️ null
      document.getElementById("sendCtrlAltDelButton").onclick = sendCtrlAltDel;

      // Read parameters specified in the URL query string
      // By default, use the host and port of server that served this file
      const host = readQueryVariable("host", window.location.hostname);
      let port = readQueryVariable("port", window.location.port);
      const password = readQueryVariable("password");
      const path = readQueryVariable("path", "websockify");

      // | | |         | | |
      // | | | Connect | | |
      // v v v         v v v

      status("Connecting");

      // const apiUrl = window.location.hostname;

      const authToken = localStorage.getItem("authToken");
      let url = `wss://${apiWS}/livebrowser?token=${authToken}`;
      // Build the websocket URL used to connect
      //let url = `ws://${apiUrl}/livebrowser`;

      // console.log("sessionId in live screen", sessionId);
      // Creating a new RFB object will start a new connection

      rfb = new RFB(
        document.getElementById("screen"),
        url,
        { credentials: { password: "selenoid" } },
        s_id
      );

      // Add listeners to important events from the RFB module
      rfb.addEventListener("connect", connectedToServer);
      rfb.addEventListener("disconnect", disconnectedFromServer);
      rfb.addEventListener("credentialsrequired", credentialsAreRequired);
      rfb.addEventListener("desktopname", updateDesktopName);

      // Set parameters that can be changed on an active connection
      rfb.viewOnly = readQueryVariable("view_only", false);
      rfb.scaleViewport = readQueryVariable("scale", false);

      // Call resizeCanvas after the RFB connection is established
      rfb.addEventListener("connect", () => {
        resizeCanvas();
      });

      // Add a window resize event listener
      window.addEventListener("resize", resizeCanvas);

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("resize", resizeCanvas);
      };
    }
  }, []);

  return (
    <div className="App" style={{ height: "100%", border: "2px solid blue" }}>
      <div
        id="top_bar"
        style={{
          backgroundColor: "#6e84a3",
          color: "white",
          font: "bold 12px Helvetica",
          padding: "6px 5px 4px 5px",
          borderBottom: "1px outset",
        }}
      >
        <div id="status" style={{ textAlign: "center" }}>
          Loading
        </div>
        <div
          id="sendCtrlAltDelButton"
          style={{
            position: "fixed",
            top: "0px",
            right: "0px",
            border: "1px outset",
            display: "hidden",
            padding: "5px 5px 4px 5px",
            cursor: "pointer",
          }}
        >
          Send CtrlAltDel
        </div>
      </div>
      <div id="screen" style={{ flex: "1", overflow: "hidden" }}></div>
      {/* this is where screen appear */}
    </div>
  );
}
