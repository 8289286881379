export const loopActions = [
    {
        instr_type: 200,
        clickValue: "first",
        instr: 'Loop from "<span class="sp-e label">X</span>" to "<span class="sp-e context">Y</span>"',
        variables: [{ name: "x_label", idx: 2 }, { name: "y_label", idx: 4 }],
    },
    {
        instr_type: 201,
        clickValue: "second",
        instr: 'Loop till "<span class="sp-e label">X</span>"',
        variables: [{ name: "x_label", idx: 2 }],
    },
    {
        instr_type: 202,
        clickValue: "third",
        instr: 'Loop all'
    }
]

function getInstrTypes(actions) {
    return actions.map(action => action.instr_type);
}

const instrTypes = getInstrTypes(loopActions);

export function doesLoopInstrTypeExist(type) {
    return instrTypes.includes(type);
}
