import React, { useEffect, Suspense } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import history from "./history";
import { Provider } from "react-redux";

import TopNav from "./components/Nav/topbar/topbar";
import LeftNav from "./components/Nav/nav";

// import store from './redux/store/store';
import Settings from "./pages/Settings/settings";
// import { useSetupAxiosInterceptors } from "./services/useSetupAxiosInterceptors";
import HomePage from "./pages/Home/home";
import LoginPage from "./pages/Login/login";
import Signup from "./pages/Signup/signup";
import Dashboard from "./pages/Dashboard/dashboard";
import ForgotPassword from "./pages/ForgotPassword/forgotpass";
import Reset from "./pages/ForgotPassword/reset";
import EcomTesting from "./pages/EcomTesting/testing";
import Reports from "./pages/Reports/reports";
import SuiteReports from "./pages/SuiteReports/Report";
import TestCase from "./pages/Testcase/testcase";
import ERPWebTesting from "./pages/ERPWebTesting/ewt";
import Changed from "./pages/ForgotPassword/changedPassword";
import TestingDetails from "./pages/Reports/TestingDetails/TestingDetails";
import DateRangePicker from "./pages/Testcase/datePicker";
import OsSelection from "./components/select/select";
import DataPage from "./pages/Data/DataPage";
import Suites from "./pages/suites/Suites";

// import DeleteModal from "./pages/Data/components/DeleteModal";
import ViewData from "./pages/Data/viewData/viewData";
import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./context/AuthContext";
import Suite from "./pages/suites/suite/Suite";
import SuitesTestingDetails from "./pages/SuiteReports/SuiteTestingDetails/SuitesTestingDetails";

import PresenceAi from "./pages/PresenceAi/PresenceAi";
import { WebSocketProvider } from "./pages/EcomTesting/context/WebSocketContext";
import { useFetchPrivileges } from "./hooks";
import { MdSignalWifiStatusbarConnectedNoInternet } from "react-icons/md";
import { useSelector } from "react-redux";

const Assets = React.lazy(() => import("./pages/Assets/assets"));

const RedirectToPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/Dashboard");
  }, [navigate]);

  return null;
};

function App() {
  const isOnline = useSelector((state) => state.isOnline.isOnline);

  return (
    <div>
      {/* screen for Disabling while internet is lost */}
      {!isOnline && (
        <div className="ts-interent-error-disabling">
          <MdSignalWifiStatusbarConnectedNoInternet
            style={{ color: "red", fontSize: "50px" }}
          />
          <p style={{ color: "red", margin: "0" }}>
            Please check your internet connection
          </p>
        </div>
      )}

      <AuthProvider>
        <HistoryRouter history={history}>
          {/* <RouterSetup /> */}
          <Routes>
            {/* Route doesn't having Sidebar as well as TopBar */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset-password-success" element={<Changed />} />
            <Route path="/demo" element={<OsSelection />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/date" element={<DateRangePicker />} />
            <Route path="/reset" element={<Reset />} />
            <Route
              path="/ERP-Web-Testing"
              element={
                <RequireAuth>
                  <ERPWebTesting />
                </RequireAuth>
              }
            />

            {/* Route having Sidebar and Topbar created generic layout */}
            <Route
              path="/"
              element={
                <RequireAuth>
                  <RedirectToPage />
                </RequireAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/homepage" element={<HomePage />} />
              <Route path="/data" element={<DataPage />} />
              {/* <Route path="/assets" element={<Assets />} /> */}
              <Route
                path="/assets"
                element={
                  <Suspense fallback={<div></div>}>
                    <Assets />
                  </Suspense>
                }
              />
              <Route path="/Execution" element={<Reports />} />
              <Route path="/data/:id" element={<ViewData />} />
              <Route
                path="/report/testcase/:exeid/:jobid?"
                element={<TestingDetails />}
              />
              {/* <Route path="/settings/Test-case/" element={<TestCase />} />
                <Route path="/suite-reports" element={<SuiteReports />} />
                <Route path="/suites/" element={<Suites />} />
                <Route path="/suites/:id" element={<Suite />} />
                <Route
                  path="/suite-reports/:exeid/:pid?"
                  element={<SuitesTestingDetails />}
                /> */}
              <Route path="/settings/Test-case/" element={<TestCase />} />
              <Route path="/suite-reports" element={<SuiteReports />} />
              <Route path="/suites/" element={<Suites />} />
              <Route path="/suites/:id" element={<Suite />} />
              {/* <Route
                path="/suite-reports/:exeid/:pid?/:name"
                element={<SuitesTestingDetails />}
              /> */}
              <Route
                path="/suite-reports/:exeid/:pid?"
                element={<SuitesTestingDetails />}
              />

              {/* <Route path="/Test-case/:id/" element={<EcomTesting />} /> */}
              {/* <Route path="/Test-case" element={<EcomTesting />} /> */}

              <Route path="/Test-case/:id/" element={<EcomTesting />} />
              <Route path="/Test-case/:id/tab/:tab" element={<EcomTesting />} />
              <Route path="/Test-case" element={<EcomTesting />} />
              <Route
                path="/presence-ai"
                element={
                  <WebSocketProvider>
                    <PresenceAi />
                  </WebSocketProvider>
                }
              />
            </Route>
          </Routes>
        </HistoryRouter>
      </AuthProvider>
    </div>
  );
}
// A component that setups Axios interceptors and should be placed inside BrowserRouter
function RouterSetup() {
  useFetchPrivileges();
  //useSetupAxiosInterceptors();
  return null;
}
export default App;

// Layout design for pages having sidebar and topbar
const Layout = () => {
  return (
    <div
      className=""
      style={{ display: "flex", height: "100vh", overflow: "hidden" }}
    >
      <LeftNav />
      <div
        className=""
        style={{ flex: "1 1 0", display: "flex", flexDirection: "column" }}
      >
        <TopNav />
        <div className="" style={{ flex: "1 1 0", overflowY: "auto" }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
