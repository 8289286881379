import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import CircularProgress from "@mui/material/CircularProgress";
import uploadIcon from "./uploadIcon.svg";
import { uploadFileForAI } from "../../../services/apiServices";

const { Dragger } = Upload;

const GenAiTestCaseModal = (props) => {
  const { isOpen, handleClose } = props;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   setIsModalOpen(isOpen);
  // }, [isOpen]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    handleClose();
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("url", values.url);
    formData.append("user_id", 1); // Replace with actual user ID if needed

    // Add file data if available
    if (file) {
 
      formData.append("file", file);
    } else {
      console.log("No file to append");
    }

    try {
      setLoading(true);
      const response = await uploadFileForAI(formData);
 
      setLoading(false);
      handleCancel();
      // Handle success, e.g., show a success message
    } catch (error) {
      console.error("Failed to upload file:", error);
      setLoading(false);
      // Handle error, e.g., show an error message
    }

    // setIsModalOpen(false);
    // form.resetFields();
    // handleClose();
  };

  const handleFileChange = (info) => {
    const { status, file } = info;
    if (status !== "uploading") {
 
      setFile(file);
    }
    if (status === "done") {
      message.success(`${file.name} file added successfully.`);
    } else if (status === "error") {
      message.error(`${file.name} file addition failed.`);
    }
  };

  return (
    <>
      <Modal
        title="New Test Case"
        open={isModalOpen}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key="reset" onClick={() => form.resetFields()}>
            Reset
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="generate"
            type="primary"
            style={{
              backgroundColor: "#0036af",
              padding: loading ? "0px 35px" : "4px 15px",
              transition: "all 1s ease",
            }}
            onClick={() => form.submit()}
          >
            {loading ? (
              <CircularProgress
                size={18}
                thickness={4}
                style={{ color: "white" }}
              />
            ) : (
              "Generate"
            )}
          </Button>,
        ]}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          style={{ paddingTop: "15px" }}
        >
          <p style={{ margin: "5px auto" }}>
            Empower your test case creation with AI by providing description and
            document uploads.
          </p>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the name",
              },
            ]}
          >
            <Input placeholder="Name *" />
          </Form.Item>
          <p style={{ margin: "5px auto" }}>URL</p>
          <Form.Item
            name="url"
            rules={[{ required: true, message: "Please enter the URL" }]}
          >
            <Input placeholder="URL *" />
          </Form.Item>
          <p style={{ margin: "5px auto" }}>Upload assets</p>
          <Form.Item name="files">
            <Dragger
              name="files"
              multiple={false}
              showUploadList={true}
              beforeUpload={() => false}
              onChange={handleFileChange}
              onDrop={(e) => {
                console.log("Dropped files", e.dataTransfer.files);
              }}
            >
              <div
                style={{
                  padding: "10px",
                  height: "4rem",
                  color: "#0036af",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={uploadIcon}
                  alt="upload icon"
                  style={{ marginRight: "10px", height: "22px" }}
                />
                <p>Click or drag file to this area to upload</p>
              </div>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default GenAiTestCaseModal;
