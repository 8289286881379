import React, { useEffect, useState } from "react";
import { Tabs, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import { Select, Button } from "antd";
import {
  setAllocatedCompilationThreads,
  setAllocatedExecutionThreads,
  setDynamicThreads,
} from "../../../redux/actions/threadAction";
import "./cm.css";
import {
  PurgeActiveSessions,
  PurgeQueues,
} from "../../../services/settingServices";
import { setTimezone } from "../../../redux/actions/timezoneAction";

const LicenseSettings = ({editable=false}) => {
  const dispatch = useDispatch();
  const {
    totalThread,
    allocatedCompilationThread,
    allocatedExecutionThread,
    dynamicThread,
    usedCompilationThread,
    usedExecutionThread,
  } = useSelector((state) => state.thread);

  const [tolThread, settolThread] = useState(totalThread);
  const [compilationThread, setCompilationThread] = useState(
    allocatedCompilationThread
  );
  const [executionThread, setExecutionThread] = useState(
    allocatedExecutionThread
  );
  const [dyThread, setdyThread] = useState(dynamicThread);

  useEffect(() => {
    settolThread(totalThread);
    setCompilationThread(allocatedCompilationThread);
    setdyThread(dynamicThread);
  }, [
    totalThread,
    allocatedCompilationThread,
    allocatedExecutionThread,
    dynamicThread,
  ]);

  const handleSave = (e) => {
    e.preventDefault();
    if (
      (compilationThread < usedCompilationThread ||
        executionThread < usedExecutionThread) &&
      !dyThread
    ) {
      alert("Can't Save, Invalid Thread Allocation");
      return;
    }
    if (!dyThread) {
      dispatch(setAllocatedCompilationThreads(compilationThread));
    }
    dispatch(setDynamicThreads(dyThread));
  };

  const handleCompilationThreadChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= tolThread) {
      setCompilationThread(newValue);
      setExecutionThread(tolThread - newValue);
    } else if (isNaN(newValue)) {
      setCompilationThread(0);
      setExecutionThread(tolThread);
    }
  };

  const handleExecutionThreadChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= tolThread) {
      setExecutionThread(newValue);
      setCompilationThread(tolThread - newValue);
    } else if (isNaN(newValue)) {
      setExecutionThread(0);
      setCompilationThread(tolThread);
    }
  };

  return (
    <>
      <div>
        <h4>License Settings</h4>
      </div>
      <form onSubmit={handleSave} className="license-setting-form">
        <div className="form-group">
          <label>Total Threads</label>
          <input
            type="string"
            className="form-control"
            style={{ background: "transparent", border: "none" }}
            placeholder="Number of Threads"
            value={tolThread}
            readOnly
          />
        </div>
        <div style={{ marginBottom: "1rem", marginTop: "-1rem" }}>
          <label>Dynamic Thread Allocation</label>
          <Switch
            checked={dyThread}
            style={{ marginLeft: "1rem" }}
            onChange={(value) => setdyThread(value)}
            disabled={!editable}
          />
        </div>
        {!dyThread && (
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="form-group">
              <label>Compilation Thread</label>
              <input
                type="integer"
                className="form-control"
                placeholder="Number of Threads"
                value={compilationThread}
                onChange={handleCompilationThreadChange}
                min={usedCompilationThread}
                max={tolThread}
                readOnly={!editable}
              />
            </div>
            <div className="form-group">
              <label>Execution Thread</label>
              <input
                type="integer"
                className="form-control"
                placeholder="Number of Threads"
                value={executionThread}
                onChange={handleExecutionThreadChange}
                min={usedExecutionThread}
                max={tolThread}
                readOnly={!editable}
              />
            </div>
          </div>
        )}
        <div>
          <button className={`save-button ${!editable && "disabled"}`} type="submit" disabled={!editable}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};

const PurgeSettings = ({editable=false}) => {
  const [PurgeQueuesLoading, setPurgeQueuesLoading] = useState(false);
  const [PurgeActiveSessionsLoading, setPurgeActiveSessionsLoading] =
    useState(false);

  const handlePurgeActiveSessions = async (e) => {
    e.preventDefault();
    // if (!editable) return;
    setPurgeActiveSessionsLoading(true);
    const response = await PurgeActiveSessions();
    if (response) {
 
      toast.success("Active Sessions Purged Successfully");
    } else {
      toast.error("Failed to purge active sessions");
    }
    setPurgeActiveSessionsLoading(false);
  };

  const handlePurgeQueues = async (e) => {
    e.preventDefault();
    // if (!editable) return;
    setPurgeQueuesLoading(true);
    const response = await PurgeQueues();
    if (response) {
 
      toast.success("Queues Purged Successfully");
    } else {
      toast.error("Failed to purge queues");
    }
    setPurgeQueuesLoading(false);
  };

  return (
    <>
      <div>
        <h4>Purge Settings</h4>
      </div>
      <div className="purge-btn">
        <button
          style={{ width: "170px" }}
          // className={`${(!editable || PurgeActiveSessionsLoading) && "disabled"}`}
          className={`${ PurgeActiveSessionsLoading && "disabled"}`}
          onClick={handlePurgeActiveSessions}
        >
          {PurgeActiveSessionsLoading ? (
            <CircularProgress
              style={{ width: "20px", height: "20px", color: "#fff" }}
            />
          ) : (
            "Purge Active Sessions"
          )}
        </button>
        <button
          style={{ width: "124px" }}
          className={`${PurgeQueuesLoading && "disabled"}`}
          onClick={handlePurgeQueues}
        >
          {PurgeQueuesLoading ? (
            <CircularProgress
              style={{ width: "20px", height: "20px", color: "#fff" }}
            />
          ) : (
            "Purge Queues"
          )}
        </button>
      </div>
    </>
  );
};

const TimezoneSettings = ({editable=false}) => {
  const dispatch = useDispatch();
  const selectedTimezone = useSelector(
    (state) => state.timezone.selectedTimezone
  );
  const [localTimezone, setLocalTimezone] = useState(selectedTimezone);
  const timezones = moment.tz.names();

  const timezoneOptions = timezones.map((zone) => ({
    value: zone,
    label: <span>{zone}</span>,
  }));

  const onChange = (value) => {
 
    setLocalTimezone(value);
  };

  const onSearch = (value) => {
 
  };

  const saveTimezone = () => {
    dispatch(setTimezone(localTimezone));
    toast.success("Timezone updated successfully");
  };

  return (
    <div className="tz-container">
      <h4>Timezone Settings</h4>
      <Select
        showSearch
        allowClear
        optionFilterProp="value"
        onChange={onChange}
        onSearch={onSearch}
        placeholder="Select a timezone"
        options={timezoneOptions}
        style={{ minWidth: "150px" }}
        value={localTimezone}
        disabled={!editable}
      />
      <div style={{ textAlign: "end" }}>
        <Button
          style={{ width: "fit-content" }}
          type="primary"
          onClick={saveTimezone}
          disabled={!editable}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const GeneralSettings = () => {
  
  const tabPosition = "left";

  const items = [
    {
      label: "Licence",
      key: "1",
      children: <LicenseSettings editable={true} />,
    },
    {
      label: "Purge",
      key: "2",
      children: <PurgeSettings editable={true} />,
    },
    {
      label: "Help",
      key: "3",
      children: "Help Settings",
    },
    {
      label: "Timezone",
      key: "4",
      children: <TimezoneSettings editable={true} />,
    },
  ];

  return (
    <>
      <div style={{ margin: "1.5rem 0" }}>
        <h3>Settings</h3>
      </div>
      <Tabs tabPosition={tabPosition} items={items} />
    </>
  );
};

export default GeneralSettings;
