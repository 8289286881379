import ReactDragListView from "react-drag-listview";
import { Table } from "antd";
import "./dataTable.css";
import Options from "../options/options";
import { Button } from "react-bootstrap";
import drag from "../../../../assets/drag-icon.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import { setCurrentTableName } from "../../DataAction/dataAction";
import {
  add_Column,
  add_row,
  change_column_order,
  change_row_order,
  delete_column,
  delete_row,
  fetch_localdata_by_id,
  save_cell_value,
} from "../../../../services/dataService";

const DataTable = ({ setCurrentTableName, tcTable, localDataId, dataId }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  // const sampleData = {
  //     "name": "test",
  //     "no_of_rows": 9,
  //     "no_of_columns": 3,
  //     "columns": [],
  //     "rows": [],
  //     "local_data_id": "662d1a1ce7a1f36af72be437",
  //     "no_of_associated_test_cases": 0
  // }

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(null);

  const [data, setData] = useState(null);
  const [tempColumns, setTempColumns] = useState([]);

  // console.log("tempColumnsWWWWWWWWW", tempColumns)

  const [newColumns, setNewColumns] = useState([]);
  const editCellValueRef = useRef("");
  const [editCell, setEditCell] = useState({ rowIndex: null, colIndex: null });

  const [addingNewColumn, setAddingNewColumn] = useState(false);

  const triggerRefresh = () => {
    setRefreshTrigger((prevState) => !prevState);
  };

  const addColumn = useCallback(
    async (orderOfNewCol, nameOfNewCol) => {
      // console.log("add columnNNNNNNNNNNNNNNNNN", orderOfNewCol, nameOfNewCol)
      if (orderOfNewCol === -1) return;

      setLoading(true);

      const newColumn = {
        name: nameOfNewCol,
        data_type: "string",
        type: "AddColumn",
        order: orderOfNewCol,
      };

      try {
        const response = await add_Column(localDataId, newColumn);
        triggerRefresh();
      } catch (error) {
        console.error("Error adding column to the left:", error);
        toast.error("Failed to add column");
        setLoading(false);
      }

      setLoading(false);
    },
    [data, localDataId]
  );

  const handleAddingNewColumnClick = (e) => {
    setAddingNewColumn(true);
    triggerRefresh();
  };

  const handleAddingNewColumnBlur = (e) => {
    commitColumn(e.target.value, 0);
    setAddingNewColumn(false);
  };

  const addRow = useCallback(
    async (indexOfNewRow) => {
      if (indexOfNewRow === -1) return;

      setLoading(true);

      const newRow = {
        type: "AddRow",
        values: [{}],
        row_index: indexOfNewRow,
      };

      tempColumns.forEach((col) => {
        newRow.values[0][col.name] = "";
      });

      try {
        const response = await add_row(localDataId, newRow);
        triggerRefresh();
      } catch (error) {
        console.error("Error adding Row", error);
        toast.error("Failed to add Row");
        setLoading(false);
      }

      setLoading(false);
    },
    [data, localDataId]
  );

  const addTemporaryColumn = useCallback(
    (order) => {
      const updatedTempColumns = tempColumns.map((col) => ({
        ...col,
        order: col.order >= order ? col.order + 1 : col.order,
      }));

      // console.log("add temporary columnNNm", updatedTempColumns)
      const newTempColumn = { order, name: "", isEditing: true };

      const newTempColumns = [...updatedTempColumns, newTempColumn].sort(
        (a, b) => a.order - b.order
      );
      // console.log("temporary columnNNNNNNNNN", newTempColumns)
      setTempColumns(newTempColumns);
    },
    [tempColumns]
  );

  const commitColumn = useCallback(
    (name, order) => {
      // console.log("commit columnNNNNNNNNNNNN", "NAme", name, "Order", order)
      if (!name.trim()) {
        setTempColumns(tempColumns.filter((col) => col.order !== order));
        return;
      }
      const nameLower = name.toLowerCase();
      const nameExists = tempColumns.some(
        (col) => col.name.toLowerCase() === nameLower
      );

      if (nameExists) {
        return toast.error("Column name already exists");
      }

      addColumn(order, name);
    },
    [tempColumns, addColumn]
  );

  const dragPropsRow = {
    async onDragEnd(fromIndex, toIndex) {
      setLoading(true);
      try {
        await change_row_order(localDataId, fromIndex, toIndex);
        triggerRefresh();
      } catch (error) {
        console.error("Error changing row order:", error);
        toast.error("Failed to change row order");
      } finally {
        setLoading(false);
      }
    },
    nodeSelector: "tr",
    handleSelector: ".drag-icon",
  };

  const dragPropsCol = {
    async onDragEnd(fromIndex, toIndex) {
      const adjustedFromIndex = fromIndex - 2;
      const adjustedToIndex = toIndex - 2;
      // console.log("from index", adjustedFromIndex, "to index", adjustedToIndex);

      const minIndex = 0;

      if (adjustedToIndex < minIndex) {
        // console.log("Dragging below minimum index is not allowed.");
        return;
      }

      const columnOrderChange = {
        local_data_id: localDataId,
        updates: [
          {
            type: "ChangeColumnOrder",
            current_index: adjustedFromIndex,
            new_index: adjustedToIndex,
          },
        ],
      };

      setLoading(true);
      try {
        await change_column_order(
          localDataId,
          adjustedFromIndex,
          adjustedToIndex
        );
        triggerRefresh();
      } catch (error) {
        console.error("Error changing column order:", error);
        toast.error("Failed to change column order");
      } finally {
        setLoading(false);
      }
    },
    nodeSelector: "th",
    handleSelector: ".dragHandler",
  };

  const deleteRow = useCallback(
    async (indexOfNewRow) => {
      // console.log("delete row #####################", indexOfNewRow)
      if (indexOfNewRow === -1) return;

      setLoading(true);

      try {
        const response = await delete_row(localDataId, indexOfNewRow);
        triggerRefresh();
      } catch (error) {
        console.error("Error deleting Row", error);
        toast.error("Failed to delete Row");
        setLoading(false);
      }

      setLoading(false);
    },
    [data, localDataId]
  );

  const deleteColumn = useCallback(
    async (order) => {
      // console.log("delete columnNNNNNNNNNNNNNN", order)
      setLoading(true);
      try {
        if (!data || !data.columns) {
          throw new Error("Data or columns not available");
        }

        const columnIndex = data.columns.findIndex(
          (col) => col.order === order
        );
        if (columnIndex === -1) {
          throw new Error("Column not found");
        }

        await delete_column(localDataId, columnIndex);

        triggerRefresh();
      } catch (error) {
        console.error("Error deleting column:", error);
        toast.error("Failed to delete column");
      } finally {
        setLoading(false);
      }
    },
    [localDataId, data, triggerRefresh]
  );

  const addColumnToLeft = useCallback(
    (order) => {
      addTemporaryColumn(order);

      // console.log("add column to left", tempColumns)
    },
    [addTemporaryColumn]
  );

  const addColumnToRight = useCallback(
    (order) => {
      addTemporaryColumn(order + 1);
      // console.log("add column to right", tempColumns)
    },
    [addTemporaryColumn]
  );

  const handleEditCellValueChange = (e) => {
    // console.log("edit cell value", e.target.value);
    editCellValueRef.current = e.target.value;
  };

  // Function to handle saving the edited cell value
  const handleSaveCellValue = async () => {
    // console.log("edddd", editCellValueRef.current)
    if (editCell.rowIndex !== null && editCell.colIndex !== null) {
      setLoading(true);
      try {
        const response = await save_cell_value(
          localDataId,
          editCell.rowIndex,
          editCell.colIndex,
          editCellValueRef.current
        );
        // console.log("save cell value", editCell.rowIndex, editCell.colIndex)

        // console.log("responseEEEEEEEEE", editCellValueRef.current)
      } catch (error) {
        // console.error("Error updating cell value:", error);
        toast.error("Failed to update cell value");
      } finally {
        triggerRefresh();
        setLoading(false);
        setEditCell({ rowIndex: null, colIndex: null });
      }
    }
  };

  const handleCellClick = (rowIndex, colIndex, text) => {
    // console.log("cell clickedDDDDDDDDDDDDDDDD", rowIndex, colIndex)
    setEditCell({ rowIndex: rowIndex, colIndex: colIndex });
    editCellValueRef.current = text;
    triggerRefresh();
  };

  const renderCellContent = (text, record, colOrder, rowIndex) => {
    const columnIndex = tempColumns.findIndex((col) => col.order === colOrder);
    // Check if the current cell is being edited
    if (editCell.rowIndex === rowIndex && editCell.colIndex === columnIndex) {
      return (
        <input
          defaultValue={text}
          onChange={handleEditCellValueChange}
          onBlur={handleSaveCellValue}
          autoFocus
        />
      );
    } else {
      return (
        <div
          style={{ minWidth: "90%", minHeight: "20px", cursor: "text" }}
          onClick={() => handleCellClick(rowIndex, columnIndex, text)}
        >
          {text}
        </div>
      );
    }
  };

  const columns = useMemo(() => {
    // console.log("column rendered")
    if (!data) {
      return [];
    }

    const fixedColumns = [
      {
        title: " ",
        dataIndex: "editColumn",
        key: "editColumn",
        width: "10%",
        render: (text, record, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <Button className="table-button btn dragHandler" type="primary">
              <img
                className="drag-icon"
                style={{ cursor: "grab" }}
                src={drag}
                alt="drag"
              />
            </Button>
            <Options
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              addRowAbove={() => addRow(index)}
              addRowBelow={() => addRow(index + 1)}
              deleteRow={() => deleteRow(index)}
              horizontal={true}
              lastRow={index === data.rows.length - 1}
            />
          </div>
        ),
      },
      {
        width: "8%",
        title: (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 44,
              color: "#667085",
              fontSize: 12,
              fontWeight: 700,
              padding: "0 22px",
            }}
          >
            {"No."}
          </div>
        ),
        dataIndex: "serialNumber",
        key: "serialNumber",
        render: (text, record, index) => index + 1,
      },
    ];

    if (tempColumns.length === 0) {
      return [
        ...fixedColumns,
        {
          title: addingNewColumn ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                autoFocus
                style={{ maxWidth: "400px", padding: "10px 5px" }}
                onBlur={handleAddingNewColumnBlur}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    commitColumn(e.target.value, 0);
                  }
                }}
              />
            </div>
          ) : (
            <p
              style={{
                color: "#667085",
                fontSize: "12px",
                fontWeight: "500",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleAddingNewColumnClick}
            >
              Click Here to add Column
            </p>
          ),
          dataIndex: "emptyColumn",
          key: "emptyColumn",
          render: () => "",
        },
      ];
    } else {
      const dynamicColumns = tempColumns
        .filter((col) => col.name !== "1")
        .map((col, index) => ({
          key: col.name,
          title: col.isEditing ? (
            <div
              style={{
                minWidth: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                autoFocus
                defaultValue={col.name}
                onBlur={(e) => commitColumn(e.target.value, col.order)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    commitColumn(e.target.value, col.order);
                  }
                }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
                color: "#667085",
                fontSize: 12,
                fontWeight: 700,
                padding: "0 16px",
              }}
            >
              <div style={{ width: "48px" }}></div>
              <span style={{ flexGrow: 1, textAlign: "center" }}>
                {/* {col.name.charAt(0).toUpperCase() + col.name.slice(1)} */}
                {col.name}
              </span>
              <div style={{ display: "flex", gap: "4px" }}>
                <Button className="table-button btn dragHandler" type="primary">
                  <img style={{ cursor: "grab" }} src={drag} alt="drag" />
                </Button>
                <Options
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  addColumnToLeft={() => addColumnToLeft(col.order)}
                  addColumnToRight={() => addColumnToRight(col.order)}
                  deleteColumn={() => deleteColumn(col.order)}
                  lastColumn={index === tempColumns.length - 1}
                  oneRow={data.rows.length === 1}
                />
              </div>
            </div>
          ),
          dataIndex: col.name,

          render: (text, record) =>
            col.isEditing
              ? ""
              : renderCellContent(
                  text,
                  record,
                  col.order,
                  sortedRows.indexOf(record)
                ),
        }));

      return [...fixedColumns, ...dynamicColumns];
    }
  }, [data, tempColumns]);

  const sortedRows = useMemo(() => {
    if (!data || !data.rows) {
      return [{ noData: "", id: "empty" }];
    }
    if (data.rows.length === 0) {
      return [{ noData: "", id: "empty" }];
    }
    return [...data.rows].sort((a, b) => a["1"] - b["1"]);
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      if (!localDataId) return;
      setLoading(true);
      try {
        const response = await fetch_localdata_by_id(localDataId);
        setData(response);
        setTempColumns(response.columns);
        setCurrentTableName(response.name);
        // console.log("data###LOCAL", response);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [localDataId, refreshTrigger]);

  return (
    <div
      style={{
        maxWidth: "calc(100vw - 140px)",
        maxHeight: tcTable ? "calc(100vh - 162px)" : "",
        margin: tcTable ? "0" : "20px",
      }}
      className="viewdata-table-container"
    >
      {localDataId ? (
        <div className="viewdata-table-scrollable">
          <ReactDragListView {...dragPropsRow}>
            <ReactDragListView.DragColumn {...dragPropsCol}>
              <Table
                className="table viewdata-table"
                columns={columns}
                dataSource={sortedRows}
                bordered
                pagination={false}
                loading={loading}
              />
            </ReactDragListView.DragColumn>
          </ReactDragListView>
        </div>
      ) : (
        <div className="no-data-alert">No data is linked</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setCurrentTableName,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
