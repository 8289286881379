import React, { useState, useEffect } from "react";
import "./Accordion.css";

import { Tooltip } from "@mui/material";
import ProgressBar from "../../components/ProgressBar/progressBar";
import ErrorIcon from "@mui/icons-material/Error"; // Adjust icon import based on actual usage
import { ReactComponent as FailIcon } from "../../assets/failStatus.svg";
import { ReactComponent as SuccessIcon } from "../../assets/successStatus.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";
import { ReactComponent as WaitingIcon } from "../../assets/waitingIcon.svg";
import { ReactComponent as ProcessIcon } from "../../assets/inProcess.svg";
import CircularProgress from "@mui/material/CircularProgress";

import downArrow from "./arrowDown.svg";

class StepStatus {
  static SUCCESS = 0;
  static FAILED = 1;
  static WARNING = 2;
  static SKIPPED = 3;
  static IN_PROGRESS = 4;
  static NOT_STARTED = 5;
}

const getStatusIcon = (status) => {
  const iconStyle = { fontSize: 12 }; // Example style, adjust as needed
  switch (status) {
    case StepStatus.SUCCESS:
      return (
        <Tooltip title="Success">
          <SuccessIcon style={iconStyle} />
        </Tooltip>
      );
    case StepStatus.FAILED:
      return (
        <Tooltip title="Failed">
          <FailIcon style={{ ...iconStyle, color: "red" }} />
        </Tooltip>
      );
    case StepStatus.WARNING:
      return (
        <Tooltip title="Warning">
          <WaitingIcon style={{ ...iconStyle, color: "yellow" }} />
        </Tooltip>
      );
    case StepStatus.SKIPPED:
      return (
        <Tooltip title="Skipped">
          <InfoIcon style={{ ...iconStyle, color: "gray" }} />
        </Tooltip>
      );
    case StepStatus.IN_PROGRESS:
      return (
        <Tooltip title="In Progress">
          <CircularProgress
            style={{
              ...iconStyle,
              color: "#0036af",
              width: "12px",
              height: "12px",
            }}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Not Started">
          <ErrorIcon style={iconStyle} />
        </Tooltip>
      );
  }
};

function Accordion({
  heading,
  description,
  headingColor = "#424242",
  open = false,
  id,
  status = 1,
  closeAll = false,
  setCloseAll,
}) {
  const [isOpen, setIsOpen] = useState(open);
  const [iD,setiD]=useState(id);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  useEffect(()=>{
    setiD(id);
  },[id])
  useEffect(() => {
    if (closeAll) {
      setIsOpen(false);
      setCloseAll(false);
    }
  }, [closeAll]);

  return (
    <div className="accordion" id={iD}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="accordion-heading">
          <img
            src={downArrow}
            style={{ paddingleft: "20px" }}
            alt="Not found"
            className={` accordion-icon ${isOpen ? "open" : "closed"}`}
          />
          <span>{getStatusIcon(status)}</span>{" "}
          <span
            className="heading-title"
            style={{ paddingLeft: "5px", color: `${headingColor}` }}
          >
            {heading}
          </span>
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content" style={{ paddingLeft: "2rem" }}>
          {description}
        </div>
      )}
    </div>
  );
}

export default Accordion;
