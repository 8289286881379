export function isIdLessThanOrEqualToCurrentStep(id, currentStep) {
    // Convert id to string if it's not already
    const idStr = id.toString();
    
    // Split both strings into arrays of numbers
    const idParts = idStr.split('.').map(Number);
    const stepParts = currentStep.split('.').map(Number);
    
    // Compare parts
    for (let i = 0; i < Math.max(idParts.length, stepParts.length); i++) {
      const idPart = idParts[i] || 0;
      const stepPart = stepParts[i] || 0;
      
      if (idPart < stepPart) {
        return true;  // id is less than currentStep
      } else if (idPart > stepPart) {
        return false; // id is greater than currentStep
      }
      // If equal, continue to next part
    }
    
    // If all parts are equal, return true (less than or equal to)
    return true;
  }