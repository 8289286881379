import React, { useState, forwardRef, useImperativeHandle } from "react";
import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const contentStyles = {
  fontFamily: "Roboto, sans-serif",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxSizing: "border-box",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "auto",
};

const headerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: ".2rem",
  borderBottom: "1px solid #F5F5F5",
  height: "66px",
  fontWeight: "600",
  padding: "0rem 0.5rem",
};

const DeleteGroupModal = forwardRef(({ groupData, handleActions }, ref) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setLoading(false);
    setShowModal(false);
  };

  const handleDeletegroup = () => {
    setLoading(true);
    handleActions(true);
    setTimeout(() => {
      handleCloseModal();
    }, 1000);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setLoading(false);
      setShowModal(true);
    },
  }));

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={contentStyles}>
        <div style={headerStyles}>
          <div style={{ marginLeft: "10px", padding: "9px" }}>Delete Group</div>

          <div onClick={handleCloseModal}>
            <svg
              style={{ marginRight: "5px", cursor: "pointer" }}
              width="42"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="36" height="36" rx="6" fill="#F5F5F5" />
              <path
                d="M24.4166 12.876L23.1241 11.5835L17.9999 16.7077L12.8758 11.5835L11.5833 12.876L16.7074 18.0002L11.5833 23.1243L12.8758 24.4168L17.9999 19.2927L23.1241 24.4168L24.4166 23.1243L19.2924 18.0002L24.4166 12.876Z"
                fill="#BDBDBD"
              />
            </svg>
          </div>
        </div>

        <div className="tc-delete-modal-content">
          <p>
            Do you want to delete this block <br />
            {/* {groupData && groupData.name ? groupData.name : ""} */}
          </p>
        </div>

        <div className="tc-delete-modal-actions">
          <button
            style={{
              color: "black",
              background: "#ffffff",
            }}
            onClick={handleCloseModal}
          >
            Cancel
          </button>

          <button
            style={{
              color: "#fff",
              background: "#0036af",
            }}
            onClick={handleDeletegroup}
          >
            {loading ? (
              <CircularProgress
                size={18}
                thickness={4}
                style={{ color: "white" }}
              />
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </Box>
    </Modal>
  );
});
DeleteGroupModal.displayName = "DeleteGroupModal";

export default DeleteGroupModal;
