import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import { Input } from "antd";
import deleteIcon from "../../../../../assets/delete_icon.svg";
import { useResponseContext } from "../../../context/ResponseContext";

const StoreValues = forwardRef(({ data, setData }, ref) => {
  const [inputList, setInputList] = useState(
    data?.save_response ? data?.save_response : []
  );
  const [inputErrorList, setInputErrorList] = useState([]);

  const { responseData } = useResponseContext();

  const convertDotToArrayNotation = (_input) => {
    return _input.replace(/\.(\d+)(?=\.|$)/g, "[$1]");
  };

  useEffect(() => {
    if (!responseData?.json || !data?.save_response) return;

    const jsonData = responseData.json;
    const updatedInputList = data.save_response.map((item) => {
      let value = "";
      if (item.path_to_key) {
        const path = convertDotToArrayNotation(item.path_to_key);
        value = path.split(/[.\[\]]+/).reduce((obj, key) => {
          if (obj === null || obj === undefined) return obj;
          return obj[key];
        }, jsonData);
      } else {
        value = JSON.stringify(jsonData);
      }

      return {
        key: item.key,
        path_to_key: item.path_to_key,
        value: value,
      };
    });

    setInputList(updatedInputList);
  }, [data]);

  const getInputPostData = (inplutListData) => {
    const saveResponse = JSON.parse(JSON.stringify(inplutListData));

    saveResponse.forEach((inputData) => {
      if (inputData.value) delete inputData.value;
    });

    return saveResponse;
  };

  useImperativeHandle(ref, () => ({
    addInputValue: (key, value, isText = false) => {
      const newInput = {
        key: "", //key.split(".").pop(),
        path_to_key: isText ? "" : convertDotToArrayNotation(key),
        value: value,
      };
      const saveResponse = getInputPostData([...inputList, newInput]);

      setInputList((previous) => [...previous, newInput]);
      setData({ ...data, save_response: saveResponse });
    },
    setVariableNameErrors: (variableErrorList) => {
 
      if (variableErrorList && variableErrorList.length) {
        setInputErrorList([...variableErrorList]);
      }
    },
    getVariablesName: () => {
      return inputList;
    },
  }));

  const handleDeleteValue = (pathToKey) => {
    const _inputList = inputList.filter(
      (input) => input.path_to_key !== pathToKey
    );
    const saveResponse = getInputPostData([..._inputList]);

    setInputList(_inputList);
    setData({ ...data, save_response: saveResponse });
  };

  const setVariableName = (value, index) => {
 
    const _inputList = [...inputList];
    _inputList[index].key = value;
    const saveResponse = getInputPostData([..._inputList]);

    setInputList(_inputList);
    setData({ ...data, save_response: saveResponse });
  };

  return (
    <div className="store-value-container">
      {inputList &&
        inputList.map((input, index) => (
          <div className="store-value-content" key={index}>
            <div className="store-input-container">
              <div>
                <label className="response-lable-container">
                  Variable name <span className="warning">*</span>
                </label>
                <Input
                  className={`${inputErrorList.includes(index) && "required"}`}
                  placeholder="Ex: Products"
                  value={input.key}
                  onChange={(e) => setVariableName(e.target.value, index)}
                />
              </div>
              <div>
                <div className="store-lable-container">
                  <label>
                    Value <span className="warning">*</span>
                  </label>
                  {input.path_to_key && input.path_to_key.length ? (
                    <label>path: {input.path_to_key}</label>
                  ) : (
                    <></>
                  )}
                </div>
                <Input
                  placeholder="Ex: Products"
                  value={input?.value}
                  // onChange={(e) => setNameText(e.target.value)}
                />
              </div>
            </div>
            <div>
              <img
                style={{ cursor: "pointer" }}
                src={deleteIcon}
                alt="delete"
                onClick={() => handleDeleteValue(input.path_to_key)}
              />
            </div>
          </div>
        ))}
    </div>
  );
});

StoreValues.displayName = "StoreValues";

export default StoreValues;

// useEffect(() => {
//   if (!data?.recent_response?.json || !data?.save_response) return;

//   const jsonData = data.recent_response.json;
//   const updatedInputList = data.save_response.map((item) => {
//     let value = "";
//     if (item.path_to_key) {
//       value = item.path_to_key
//         .split(".")
//         .reduce((obj, key) => obj && obj[key], jsonData);
//     } else {
//       value = JSON.stringify(jsonData);
//     }

//     return {
//       key: item.key,
//       path_to_key: item.path_to_key,
//       value: value,
//     };
//   });

//   setInputList(updatedInputList);
// }, [data]);

// data?.recent_response?.json = {
//   People: [
//     {
//       ID: "001",
//       Name: "John Wick",
//       City: "New York",
//       Address: "1 Wall Street, Apt 5",
//       Contacts: {
//         "Phone number": "111-222-3333",
//         "E-mail": "johnwick@gmail.com",
//         Skype: "johnwick123",
//       },
//     },
//   ]
// }
// save_response: [
//   {
//     key: "Name",
//     path_to_key: "People.0.Name",
//   },
//   {
//     key: "City",
//     path_to_key: "People.0.City",
//   },
//   {
//     key: "Skype",
//     path_to_key: "People.0.Contacts.Skype",
//   },
//   {
//     key: "Address",
//     path_to_key: null, // # Save entire response text if not JSON
//   },
// ],
