import React from "react";
import { Tabs } from "antd";
import { Container } from "react-bootstrap";
import LeftNav from "../../components/Nav/nav";
import TopNav from "../../components/Nav/topbar/topbar";
import "./settings.css";
import CompanyManagement from "./screens/CompanyManagement";
import UserManagement from "./screens/UserManagement";
import GeneralSettings from "./screens/GeneralSettings";
import { useSelector } from "react-redux";
import { withPrivilegeCheck } from "../../HOC/withPrivilegeCheck";

const Settings = () => {

   const tab = [
    {
      label: "User Management",
      key: "1",
      children: <UserManagement />,
    },
    {
      label: "Company Management",
      key: "2",
      children: <CompanyManagement />,
    },
    {
      label: "General Settings",
      key: "3",
      children: <GeneralSettings />,
    },
  ];

  return (
    <Container className="settings">
      <div className="App">
        <div>
          <Tabs
            defaultActiveKey="1"
            className="tab"
            items={tab}
            tabPosition={"top"}
          />
        </div>
      </div>
    </Container>
  );
};

export default Settings;
