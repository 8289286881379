import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import cross from "../../../../../assets/failStatus.svg";
import "./groupDropdown.css";
import { setExistingGroups } from "../../../Testaction/cardsActions";
import { fetch_existing_groups } from "../../../../../services/testCaseServices";
import { toast } from 'react-toastify';
import refreshIcon from '../../../../../assets/refresh.svg';

const GroupDropdown = ({
  handleDivClick,
  handleClose,
  existingGroups,
  setExistingGroups,
}) => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [refreshingGroup, setRefreshingGroup] = useState(false);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    const fetchGroup = async () => {
      setLoading(true);
      setRefreshingGroup(true);

      try {
        const response = await fetch_existing_groups();
 
        setExistingGroups(response);
      } catch (err) {
 
        toast.error("Failed to fetch groups");
      } finally {
        setLoading(false);
        setRefreshingGroup(false);
      }
    };

    fetchGroup();
  }, [refresh]);

  // Filter groups based on search value
  const filteredGroups = existingGroups.filter(group =>
    group.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="step-dropdown">
      <div className="info-text-cont">
        <div>Existing Groups</div>
        <img onClick={handleClose} className="c-img" src={cross} alt="close" />
      </div>
      <div style={{ position: "relative" }}>
        <input
          className="input-field"
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search Groups"
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "5px",
            borderRadius: "4px",
            width: "calc(100% - 33px)",
            color: "black",
          }}
        />
        <img
          width={14}
          src={refreshIcon}
          className={`refresh-icon ${refreshingGroup ? "rotate" : ""}`}
          alt="refresh"
          onClick={handleRefresh}
        />
      </div>
      {loading ? (
        <div className="loading-container">
          <CircularProgress
            style={{
              width: "18px",
              height: "18px",
              color: "#0036af",
              marginLeft: "16px",
            }}
          />
        </div>
      ) : (
        <div className="info-data">
          {filteredGroups.length ? (
            filteredGroups.map((key, index) => (
              <div
                key={index}
                onClick={() => handleDivClick(key.id, key.name)}
                className="suggestion-box"
              >
                <div className="group-block">G</div>
                {key.name}
              </div>
            ))
          ) : (
            <div style={{ color: "#9E9E9E" }}>No matching groups</div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  existingGroups: state.cards.existingGroups,
});

const mapDispatchToProps = {
  setExistingGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDropdown);
