import React from 'react';

const ProgressBar = ({ progress, backgroundColor, fillColor }) => {
  return (
    <div style={{ height:'2px', width: '100%', backgroundColor: backgroundColor || '#D9D9D9', borderRadius: '4px' }}>
      <div
        style={{
          width: `${progress}%`,
          height: '2px',
          backgroundColor: fillColor || '#0036AF',
          borderRadius: '4px',
        }}
      />
    </div>
  );
};

export default ProgressBar;