export const ifActions = [
    {
        instr_type: 500,
        clickValue: "first",
        instr: 'sample condition "<span class="sp-e label">X</span>" to "<span class="sp-e context">Y</span>"',
        variables: [{ name: "x_label", idx: 2 }, { name: "y_label", idx: 4 }],
    },
    {
        instr_type: 501,
        clickValue: "second",
        instr: 'till "<span class="sp-e label">X</span>"',
        variables: [{ name: "x_label", idx: 2 }],
    },
    {
        instr_type: 502,
        clickValue: "third",
        instr: 'if'
    }
]

export const ElseInstrType = 505;

function getInstrTypes(actions) {
    return actions.map(action => action.instr_type);
}

const instrTypes = getInstrTypes(ifActions);

export function doesIfInstrTypeExist(type) {
    return instrTypes.includes(type);
}

