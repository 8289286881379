import React, { useState, useEffect, useMemo, useRef } from "react";
import { Form } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import { Button, Select } from "antd";
import { useNavigate } from "react-router-dom";
import LeftNav from "../../components/Nav/nav";
import TopNav from "../../components/Nav/topbar/topbar";

import { Badge, Dropdown, Space, Table } from "antd";
import arrow from "../../assets/arrow-down.svg";
import searchIcon from "../../assets/magnify.svg";
import windowsIcon from "../../assets/table/windows 1.svg";
import linuxIcon from "../../assets/table/linux.svg";
import macIcon from "../../assets/table/mac.svg";
import firefoxIcon from "../../assets/table/firefox.svg";
import chromeIcon from "../../assets/table/chrome.svg";
import safariIcon from "../../assets/table/safari.svg";
import success from "../../assets/table/Step Status.svg";
import calIcon from "../../assets/calendar-range.svg";

import { Tooltip } from "@mui/material";
import ProgressBar from "../../components/ProgressBar/progressBar";
import ErrorIcon from "@mui/icons-material/Error";
import { ReactComponent as FailIcon } from "../../assets/failStatus.svg";
import { ReactComponent as SuccessIcon } from "../../assets/successStatus.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";
import { ReactComponent as WaitingIcon } from "../../assets/waitingIcon.svg";
import { ReactComponent as ProcessIcon } from "../../assets/inProcess.svg";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getSuiteReports,
  getSuiteReportDetails,
} from "./../../services/suitesServices";
import ReportPagination from "./components/ReportPagination";
import { toast } from "react-toastify";

import "./report.css";
import { setTitle } from "../../providers/titleProvider";

const NestedRowRender = ({ id, name }) => {
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const platformIconMap = {
    linux: linuxIcon,
    windows: windowsIcon,
    mac: macIcon,
  };

  const browserIconMap = {
    chrome: chromeIcon,
    firefox: firefoxIcon,
  };

  const handleClick = (rowIndex) => {
    const data = jobsData[rowIndex];
    console.log(data);
    setTimeout(() => {
      setTitle(name);
    }, 0);
    if (data?.progress_id) navigate(`/suite-reports/${id}/${data.progress_id}`);
    else navigate(`/suite-reports/${id}`);
  };

  const fetchSuiteReportsDetails = async (suiteId) => {
    setLoading(true);
    const response = await getSuiteReportDetails(suiteId);
    if (response && response?.browser_configurations) {
      setJobsData(response.browser_configurations);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSuiteReportsDetails(id);
    }
  }, [id]);

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Platform</div>,
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: <div style={{ textAlign: "center" }}>Browser</div>,
      dataIndex: "browser",
      key: "browser",
    },
    {
      title: <div style={{ textAlign: "center" }}>Status</div>,
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const iconStyle = {
          cursor: "pointer",
          fontSize: "17px",
        };

        switch (status) {
          case "0":
            return (
              <Tooltip title="success">
                <SuccessIcon style={iconStyle} />
              </Tooltip>
            );
          case 1:
            return (
              <Tooltip title="failed">
                <FailIcon style={{ ...iconStyle, color: "red" }} />
              </Tooltip>
            );
          case 2:
            return (
              <Tooltip title="warning">
                <WaitingIcon style={{ ...iconStyle, color: "yellow" }} />
              </Tooltip>
            );
          case 3:
            return (
              <Tooltip title="skipped">
                <InfoIcon style={{ ...iconStyle, color: "gray" }} />
              </Tooltip>
            );
          case 4:
            return (
              <Tooltip title="in progress">
                <CircularProgress
                  style={{
                    ...iconStyle,
                    color: "#0036af",
                    width: "10px",
                    height: "10px",
                  }}
                />
              </Tooltip>
            );
          case 5:
          default:
            return (
              <Tooltip title="not started">
                <ErrorIcon style={iconStyle} />
              </Tooltip>
            );
        }
      },
    },
  ];
  const data = jobsData.map((job, index) => ({
    key: `${index + 1}`,
    platform: (
      <img
        src={platformIconMap[job.platform]}
        alt="icon"
        style={{ alignItems: "center" }}
      />
    ),
    job_id: job.job_unique_id,
    browser: (
      <div className="" style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={browserIconMap[job.browser]}
          alt="bwr"
          style={{ paddingRight: "5px" }}
        />
        <p> v{job.version}</p>
      </div>
    ),
    status: job.status,
  }));
  return (
    <div style={{ padding: "0 5px" }}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              handleClick(rowIndex);
            },
            style: { cursor: "pointer" },
          };
        }}
      />
    </div>
  );
};

let showErrorToast = false;

const SuiteReports = () => {
  const navigate = useNavigate();
  const [data1, setData1] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data1);
  const page = useRef(1);
  const isNext = useRef(true);
  const [searchText, setSearchText] = useState("");
  // const [jobsData, setJobsData] = useState([]);
  const fetchSuiteReports = async (pageNumber = 1, search = "") => {
    try {
      setLoading(true);
      const data = await getSuiteReports(pageNumber, search);

      if (data && data.results && data.results.length) {
        const newTestCases = data.results;
        setFilteredData((prev) =>
          pageNumber === 1 ? newTestCases : [...prev, ...newTestCases]
        );
        isNext.current = !!data.next; // Correctly determine if more pages are available
      } else {
        isNext.current = false;
        if (pageNumber === 1) setFilteredData([]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      isNext.current
    ) {
      console.log("Fetching more data...");
      page.current += 1;
      fetchSuiteReports(page.current, searchText);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    page.current = 1;
    if (value.length > 2) {
      fetchSuiteReports(1, value);
    } else if (value === "") {
      fetchSuiteReports(1);
    }
  };

  useEffect(() => {
    fetchSuiteReports(1, searchText);
  }, [searchText]);

  const handlePageNav = (pageNo) => {
    if (pageNo && !loading) fetchSuiteReports(pageNo);
  };
  useEffect(() => {}, [filteredData]);

  const handleClick = (id, rowIndex) => {
    setTimeout(() => {
      setTitle(rowIndex);
    }, 0);
    navigate(`/suite-reports/${id}`);
    // console.log(id, rowIndex);
    // const data = jobsData[rowIndex];
    // console.log(data);
    // if (data?.progress_id) navigate(`/suite-reports/${id}/${data.progress_id}`);
    // else navigate(`/suite-reports/${id}/${rowIndex}`);
  };
  // const data = jobsData.map((job, index) => ({
  //   key: `${index + 1}`,
  //   platform: (
  //     <img
  //       // src={platformIconMap[job.platform]}
  //       alt="icon"
  //       style={{ alignItems: "center" }}
  //     />
  //   ),
  //   job_id: job.job_unique_id,
  //   browser: (
  //     <div className="" style={{ display: "flex", justifyContent: "center" }}>
  //       <img
  //         //src={browserIconMap[job.browser]}
  //         alt="bwr"
  //         style={{ paddingRight: "5px" }}
  //       />
  //       <p>
  //         {" "}
  //         v{job.version}
  //         {console.log(job.version)}
  //       </p>
  //     </div>
  //   ),
  //   status: job.status,
  // }));

  const formatTimeString = (timeString) => {
    const utcDate = new Date(timeString + "Z");
    return utcDate.toLocaleString();
  };

  const columns = [
    {
      title: (
        <div className="column-title">
          Suite <img src={arrow} alt="icon" />
        </div>
      ),
      dataIndex: "suite_name",
      key: "1",
      render: (text, record) => {
        return (
          <div
            className="column-data"
            style={{ cursor: "pointer", color: "#0036af" }}
            onClick={() =>
              handleClick(record.suite_execution_id, record.suite_name)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: <div className="column-title">Status</div>,
      dataIndex: "status",
      key: "2",
      className: "column-data",
      render: (status, record) => {
        const iconStyle = {
          cursor: "pointer",
          fontSize: "17px",
        };

        switch (status) {
          case 0:
            return (
              <Tooltip title="success">
                <SuccessIcon style={iconStyle} />
              </Tooltip>
            );
          case 1:
            return (
              <Tooltip title="failed">
                <FailIcon style={{ ...iconStyle, color: "red" }} />
              </Tooltip>
            );
          case 2:
            return (
              <Tooltip title="warning">
                <WaitingIcon style={{ ...iconStyle, color: "yellow" }} />
              </Tooltip>
            );
          case 3:
            return (
              <Tooltip title="skipped">
                <InfoIcon style={{ ...iconStyle, color: "gray" }} />
              </Tooltip>
            );
          case 4:
            return (
              <Tooltip title="in progress">
                <CircularProgress
                  style={{
                    ...iconStyle,
                    color: "#0036af",
                    width: "10px",
                    height: "10px",
                  }}
                />
              </Tooltip>
            );
          case 5:
          default:
            return (
              <Tooltip title="not started">
                <ErrorIcon style={iconStyle} />
              </Tooltip>
            );
        }
      },
    },

    {
      title: <div className="column-title">No. of testcases</div>,
      dataIndex: "no_of_test_cases",
      key: "4",
      className: "column-data",
    },
    {
      title: <div className="column-title">Triggered At</div>,
      dataIndex: "triggered_at",
      key: "5",
      className: "column-data",
      render: (timeString) => formatTimeString(timeString),
    },
  ];

  useEffect(() => {
    fetchSuiteReports();
  }, [searchTerm]);

  const [buttonTitle, setButtonTitle] = useState({
    platform: "",
    browser: "",
    status: "",
  });

  const { Option } = Select;

  return (
    <Container>
      <div className="App">
        <div style={{ backgroundColor: "#FAFAFA" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              margin: "30px",
              width: "98px",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <Form.Group controlId="searchBar">
                <div className="search-container">
                  <input
                    type="text"
                    placeholder="Search"
                    className="forms"
                    style={{ paddingLeft: "40px" }}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <img src={searchIcon} alt="search" className="search-icon" />
                </div>
              </Form.Group>
              <Select
                className="select-input"
                placeholder={buttonTitle.platform || "Platform"}
                onChange={(value) =>
                  setButtonTitle({ ...buttonTitle, platform: value })
                }
              >
                <Option value="Option 1">windows</Option>
                <Option value="Option 2">linux</Option>
                <Option value="Option 3">mac</Option>
              </Select>

              <Select
                className="select-input"
                placeholder={buttonTitle.browser || "Browser"}
                onChange={(value) =>
                  setButtonTitle({ ...buttonTitle, browser: value })
                }
              >
                <Option value="Option 1">chrome 100</Option>
                <Option value="Option 2">firefox 99</Option>
                <Option value="Option 3">safari 99</Option>
              </Select>

              <Select
                className="select-input"
                placeholder={buttonTitle.status || "Status"}
                onChange={(value) =>
                  setButtonTitle({ ...buttonTitle, status: value })
                }
              ></Select>
              <Button variant="primary" type="" className="filt">
                Date <img src={calIcon} alt="icon" />
              </Button>
            </div>
          </div>
          <Table
            className="table"
            columns={columns}
            dataSource={filteredData} // Ensure this is correct
            loading={loading}
            pagination={false}
            scroll={{ y: 400 }}
            onScroll={handleScroll}
            rowKey="suite_execution_id"
            expandable={{
              rowExpandable: (record) => record.suite_execution_id,
              expandedRowRender: (record) => (
                <NestedRowRender
                  key={record.suite_execution_id}
                  id={record.suite_execution_id}
                  name={record.suite_name}
                />
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                !expanded ? (
                  <RightOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ),
            }}
            size="medium"
          />

          <ReportPagination
            dataLength={dataLength}
            handlePageNav={handlePageNav}
          />
        </div>
      </div>
    </Container>
  );
};

export default SuiteReports;
