export const getQueryParam = (url, page = 0, search = null) => {
  if (url && url.length) {
    let params = "?";

    if (page) {
      params += "page=" + page;
    }

    if (search && search.length) {
      params += (params === "?" ? "" : "&") + "search=" + search;
    }

    if (params.length > 1) {
      url += params;
    }
  }

  return url ? url : "";
};
