import apiService, { apiUrl } from "./apiServices";

export const executionJobs = async (id) => {
  try {
    const response = await apiService.get(`/platform/execution-jobs/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting", error);
    throw error;
  }
};

export const executionReports = async () => {
  try {
    const response = await apiService.get(`/platform/reports/executions`);
    return response.data;
  } catch (error) {
    console.error("Error fetch the list of executions", error);
    throw error;
  }
};

export const executionJobsByExecutionId = async (id) => {
  try {
    const response = await apiService.get(
      `/platform/reports/executions/${id}/jobs`
    );
    if (!response.status === 200 || !response.data) {
      throw new Error("Failed to fetch jobs data");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetch jobs list by execution id", error);
    throw error;
  }
};

export const jobReports = async (id) => {
  try {
    const response = await apiService.get(`/platform/reports/?job_id=${id}`);
    if (!response.status === 200) {
      throw new Error("Failed to fetch jobs data");
    }
    return response.data;
  } catch (error) {
    console.error("Error fetch jobs report by job id", error);
    throw error;
  }
};

export const getVideoUrlbyJobId = async (jobId) => {
  const authToken = localStorage.getItem("authToken");
  const src = `${apiUrl}/platform/video/${jobId}?auth_token=${authToken}`;
  return src;
};

// const apiWS = window.location.hostname;
// const wsProtocol = window.location.protocol;
// const apiUrl = `${wsProtocol}//${apiWS}`;
// const apiUrl = "https://dev.botgauge.xyz";
