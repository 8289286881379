import React, { useState, useEffect, useRef, useCallback } from "react";
import { Table } from "antd";
import deleteIcon from "../../assets/delete_icon.svg";
import downloadIcon from "../../assets/download-icon.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import LinkedTestcaseModal from "./Modals/LinkedTestcaseModal";
import "./LocalData.css";
import { connect } from "react-redux";
import { fetchLocalData } from "./DataAction/dataAction";
import { toast } from "react-toastify";
import { delete_local } from "../../services/dataService";

const LocalData = ({
  localData,
  fetchLocalData,
  searchValue,
  refreshLocal,
  triggerLocalRefresh,
}) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState(null);
  const [currentRecordKey, setCurrentRecordKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const pageSize = 8;

  const tableRef = useRef(null);

  // Fetch data function
  const fetchData = useCallback(
    async (page) => {
      setLoading(true);
      try {
        const response = await fetchLocalData(page, pageSize);

        // Ensure response is an array
        if (!Array.isArray(response)) {
          console.error("Expected response to be an array");
          setHasMore(false);
          return;
        }

        if (response.length < pageSize) {
          setHasMore(false);
        }

        setData((prevData) => [...prevData, ...response]);
      } catch (error) {
        console.error("Error fetching local data:", error);
        toast.error("Failed to load data");
      } finally {
        setLoading(false);
      }
    },
    [fetchLocalData]
  );

  useEffect(() => {
    fetchData(page);
  }, [page, fetchData, refreshLocal]);

  useEffect(() => {
    if (localData && localData.list_of_all_local_data) {
      const reversedData = [...localData.list_of_all_local_data].reverse();
      setData(reversedData);
    }
  }, [localData]);

  useEffect(() => {
    if (searchValue.length) {
      const analysedQuery = searchValue.trim().toLowerCase();
      const filtered = data.filter(
        (item) => item.name.toLowerCase().includes(analysedQuery)
        // || item.value.toLowerCase().includes(analysedQuery)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData([...data]);
    }
  }, [searchValue, data]);

  const loadMoreData = useCallback(() => {
    if (loading || loadingMore || !hasMore) return;
    setLoadingMore(true);
    setPage((prevPage) => prevPage + 1);
    setLoadingMore(false);
  }, [loading, loadingMore, hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      if (!tableRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        loadMoreData();
      }
    };

    const ref = tableRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
      return () => {
        ref.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loadMoreData]);

  const doDelete = async (record) => {
    setLoading(true);
    const key = record.local_data_id;
    const response = await delete_local(key);

    if (!response) {
      setLoading(false);
      toast.error("Failed to delete data");
      throw new Error("Failed to delete data");
    }
    setLoading(false);
    triggerLocalRefresh();
  };

  const openModal = (record) => {
    setSelectedFilename(record.name);
    setModalOpen(true);
    setCurrentRecordKey(record.local_data_id);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedFilename(null);
    setCurrentRecordKey(null);
  };

  const columns = [
    {
      title: <div className="column-title">Name</div>,
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span
          onClick={() => navigate(`/data/${record.local_data_id}`)}
          className="text-row data-name-row"
          style={{ cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: <div className="column-title">Created</div>,
      dataIndex: "created_at",
      key: "created_at",
      render: (record) => (
        <div className="text-row">
          <p>{moment(record?.created_at).format("DD-MM-YYYY HH")}</p>
        </div>
      ),
    },
    {
      title: <div className="column-title">Updated</div>,
      dataIndex: "updated_at",
      key: "updated_at",
      render: (record) => (
        <div className="text-row">
          <p>{moment(record?.updated_at).format("DD-MM-YYYY HH:MM")}</p>
        </div>
      ),
    },
    {
      title: <div className="column-title">Linked Test cases</div>,
      dataIndex: "",
      key: "no_test_case_associated",
      render: (record) => (
        <div
          onClick={() => openModal(record)}
          className="text-row view-linked-tests-row"
        >
          <span>{record.no_test_case_associated || 0}</span>
          <p>View</p>
        </div>
      ),
    },
    {
      title: <div className="column-title">Last Updated by</div>,
      dataIndex: "last_updated_by",
      key: "last_updated_by",
      render: (text) => (
        <span className="text-row">{text ? text : "null"}</span>
      ),
    },
    {
      title: " ",
      dataIndex: "",
      key: "actions",
      render: (record) => (
        <div>
          <img
            src={downloadIcon}
            alt="Download"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              marginRight: "10px",
            }}
          />
          <img
            src={deleteIcon}
            alt="Delete"
            onClick={() => doDelete(record)}
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="parent-div-cont">
      <div
        className="table-cont"
        ref={tableRef}
        style={{ height: "80vh", overflow: "auto" }}
      >
        <Table
          dataSource={filteredData}
          columns={columns}
          pagination={false}
          loading={loading}
          className="table local-data-table"
        />
        {loadingMore && <div>Loading more...</div>}
      </div>

      {modalOpen && (
        <LinkedTestcaseModal
          local_data_id={currentRecordKey}
          open={modalOpen}
          handleClose={closeModal}
          name={selectedFilename}
          triggerLocalRefresh={triggerLocalRefresh}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  localData: state.data.localData,
});

const mapDispatchToProps = {
  fetchLocalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalData);
