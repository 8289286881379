import apiService from "./apiServices";

export const get_all_scripts = async () => {
    try {
        const response = await apiService.get("/platform/scripts/");
        return response.data;
    } catch (error) {
        console.error("Error creating user:", error);
    }
};

export const get_script_by_id = async (id) => {
    try {
        const response = await apiService.get(`/platform/scripts/${id}/`);
        return response.data;
    } catch (error) {
        console.error("Error creating user:", error);
    }
};


export const create_script = async (data) => {
    try {
        const response = await apiService.post("/platform/scripts/", data);
        return response.data;
    } catch (error) {
        console.error("Error creating user:", error);
    }
};


export const delete_script = async (id) => {
    try {
        const response = await apiService.delete(`/platform/scripts/${id}/`);
        return response.data;
    } catch (error) {
        console.error("Error deleting user:", error);
    }
};

export const rename_script = async (id, name) => {
    try {
        const response = await apiService.put(`/platform/scripts/${id}/rename`,
            {
                script_name: name
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error renaming user:", error);
    }
};