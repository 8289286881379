import cross from "../../../../../assets/failStatus.svg";
import { elseIfActions } from "../data/elseIf";

const ElseIfDropdown = ({ handleDivClick, handleClose }) => {
    return (
        <div className='step-dropdown'>
            <div className="info-text-cont">
                <div>Else If Actions</div>
                <img
                    onClick={handleClose}
                    className="c-img"
                    src={cross}
                    alt="close"
                />
            </div>
            <div className="info-data">
                {elseIfActions.map((key, index) => (
                    <div
                        key={index}
                        onClick={() =>
                            handleDivClick(key.clickValue, key.instr_type, key.variables)
                        }
                        className="suggestion-box"
                    >
                        <div
                            className={key.clickValue}
                            dangerouslySetInnerHTML={{ __html: key.instr }}
                        ></div>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default ElseIfDropdown