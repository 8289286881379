import { Modal, Input } from 'antd';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import closeIcon from '../../../../../../assets/close-icon.svg';
import { create_script } from '../../../../../../services/scriptService';
import { toast } from 'react-toastify';


const SaveTemplateModal = ({ open, onCancel, data }) => {
    const [name, setName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
 
    const handleSaveChanges = async () => {
        if (name === '') {
            return toast.error("Please enter a name for the template");
        }

        if (data.script === `// In this script, you can declare inputs using 'args' followed by an index number.
// For example:
var title = args[0]; // This sets 'title' to the value of the first input.

// You can also return multiple values by using an object notation with {} at the end.
// For example:
return { "output1": "123", "output2": "456", "output3": title };
` || data.script.trim() === '') {
            return toast.error("Please enter a valid script to save the template");
        }

        setLoading(true);
        try {
            const response = await create_script(
                { 
                    script_name: name,
                    script_content: data.script
                 }
            );
           
          
            toast.success("Template saved successfully");
            setLoading(false);
            onCancel();
            setName('');
        }
        catch (error) {
            console.error("Error creating user:", error);
            setLoading(false);
            toast.error("Error saving template");
        }
    }

    const handleCancel = () => {
        onCancel();
        setName('');
    }
    
return (
    <Modal
        className="script-modal"
        open={open}
        onCancel={handleCancel}
        width={506}
        footer={null}
        header={null}
        centered={true}
    >
        <Box>
            <div className='linked-tc-modal-box-header'>
                <h4>Save Template</h4>
                <img onClick={onCancel} src={closeIcon} alt='close-modal' />
            </div>

            <div style={{ padding: "30px 20px 40px 20px" }}>
                <p style={{ fontWeight: "500", marginTop: "0" }} >Template Name</p>
                <Input style={{ height: "44px" }} placeholder='Name' type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className='linked-tc-modal-box-footer'>
                <div
                    className='linked-tc-modal-box-footer-item'
                onClick={handleCancel}
                >Cancel</div>
                {
                    loading ? <div
                        className='linked-tc-modal-box-footer-item'
                    ><CircularProgress style={{color:"white", width:"12px", height:"12px"}} /></div> :
                    <div
                        className='linked-tc-modal-box-footer-item'
                        onClick={handleSaveChanges}
                    >Save Changes</div>
                }
            </div>
        </Box>
    </Modal >
);
};

export default SaveTemplateModal;
