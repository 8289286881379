import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./TestingDetails.css";
import Accordion from "../../../components/Accordian/Accordian";
import VideoModel from "../../../components/VideoModel/VideoModel";
import Modal from "../TestingDetails/components/picModal";

import downArrow from "./assets/arrowDown.svg";
import upArrow from "./assets/arrowUp.svg";

import arrowCollapse from "../../../assets/arrow-collapse-vertical.svg";
import arrowExpand from "../../../assets/arrow-expand-vertical.svg";
import successStatus from "../../../assets/successStatus.svg";
import failStatus from "../../../assets/failStatus.svg";
import waitingStatus from "../../../assets/waitingSuccess.svg";
import skippedStatus from "../../../assets/skippedStatus.svg";
import playAndPause from "./assets/playandpause.svg";
import db from "./assets/db.svg";
import { jobReports } from "../../../services/jobsServices";
import TreeViewReport from "./components/TreeViewReport";

class StepStatus {
  static SUCCESS = 0;
  static FAILED = 1;
  static WARNING = 2;
  static SKIPPED = 3;
  static IN_PROGRESS = 4;
  static NOT_STARTED = 5;
}

const getStatusIcon = (status) => {
  switch (status) {
    case StepStatus.FAILED:
      return failStatus;
    case StepStatus.SUCCESS:
      return successStatus;
    case StepStatus.WARNING:
      return waitingStatus;
    case StepStatus.SKIPPED:
      return skippedStatus;
    default:
      return null;
  }
};

const Description = ({ placeholder }) => {
  return (
    <div className="description" style={{ display: "flex" }}>
      <img src={db} alt="Not found" />
      <span>
        <input
          placeholder={placeholder}
          className="input-form"
          style={{ paddingLeft: "10px", margin: "4px" }}
        />
      </span>
    </div>
  );
};

// list of all commands
const AllDataforAccordian = ({
  data,
  isAccordionOpen,
  label,
  closeAll = true,
  reverseClose,
}) => {
  const traversedata = [...data];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [openLabel, setOpenLabel] = useState(label);
  const [isOpen, setIsOpen] = useState(isAccordionOpen);
  const [closeall, setCloseAll] = useState(closeAll);

  const scrollContainerRef = useRef(null);
  const elementToScrollToRef = useRef(null);

  // console.log("AllDataforAccordian --> ", data);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setIsOpen(isAccordionOpen);
    // console.log(isOpen);
  }, [isAccordionOpen]);

  useEffect(() => {
    setOpenLabel(label);
    scrollToElement("filterone");
  }, [label]);

  useEffect(() => {
    setCloseAll(closeAll);
    if (closeAll) reverseClose(false);
    // console.log(closeAll);
  }, [closeAll, reverseClose]);

  const scrollToElement = (elementId) => {
    const elementToScrollTo = document.getElementById(elementId);
    const scrollContainer = document.getElementsByClassName(
      "testingAnalyticsDetails"
    )[0];

    if (elementToScrollTo && scrollContainer) {
      const elementPosition = findPosition(elementToScrollTo, scrollContainer);
      scrollContainer.scrollTop =
        elementPosition - scrollContainer.offsetTop - 100;
    }
  };

  const findPosition = (obj, container) => {
    let currentTop = 0;
    let currentObj = obj;

    while (currentObj && currentObj !== container) {
      currentTop += currentObj.offsetTop;
      currentObj = currentObj.offsetParent;
    }

    return currentTop;
  };

  const openModal = (accordion) => {
    setSelectedAccordion(accordion);
    setIsModalOpen(true);
  };

  return (
    <>
      <div ref={scrollContainerRef}>
        {/* {data.map((accordion, index) => (
          <Accordion
            key={index}
            id={accordion.step_id === openLabel ? "filterone" : undefined}
            heading={accordion.inst || accordion.instr}
            status={accordion.status}
            closeAll={closeall}
            setCloseAll={setCloseAll}
            open={!isOpen ? accordion.step_id === openLabel : false}
            description={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <React.Fragment key={accordion.step_id}>
                    <Description placeholder={accordion.data} />
                  </React.Fragment>
                </div>
                {accordion.screenshot_url && (
                  <div>
                    <img
                      src={accordion.screenshot_url}
                      onClick={() => openModal(accordion)}
                      style={{ cursor: "pointer" }}
                      alt="Not Found"
                    />
                  </div>
                )}
              </div>
            }
          />
        ))} */}
        <TreeViewReport reportData={data} />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={toggleModal}
        data={traversedata}
        initialUid={selectedAccordion ? selectedAccordion.uid : ""}
      />
    </>
  );
};

const dummyfetchJobData = {
  report_id: 2,
  steps_status_info: {
    failed: [],
    skipped: [],
    success: ["1", "2", "3", "4"],
    warning: [],
    "not started": [],
  },
  testcase_json: {
    overall_status: 0,
    overall_message: "success",
    test_steps_json: [
      {
        data: "http://google.com",
        instr: "open website",
        status: 0,
        message: "Action Successful",
        ordinal: null,
        step_id: "1",
        screenshot_url: null,
      },
      {
        data: "csk",
        instr: 'enter "search"',
        status: 0,
        message: "Action Successful",
        ordinal: null,
        step_id: "2",
        screenshot_url: null,
      },
      {
        data: "",
        instr: 'click on "google search"',
        status: 0,
        message: "Action Successful",
        ordinal: 1,
        step_id: "3",
        screenshot_url: null,
      },
      {
        data: "",
        instr: 'click on "news"',
        status: 0,
        message: "Action Successful",
        ordinal: 1,
        step_id: "4",
        screenshot_url: null,
      },
    ],
  },
  created_at: "2024-05-22T04:02:32.952222Z",
  last_updated: "2024-05-22T04:02:32.952242Z",
};

const IndividualReport = ({ job_id, testcaseName = null }) => {
  // Change here to destructure job_id from props
  const apiUrl = process.env.REACT_APP_API_URL;
  // const [fetchData, setFetchData] = useState([]);
  const initialMaxStatusTag = [0, 0, 0, 0];
  const initialFilterArray = {
    0: [],
    1: [],
    2: [],
    3: [],
  };
  const initialMessageData = {
    overall_message: "",
    status: "",
  };
  const [messageData, setMessageData] = useState(initialMessageData);
  const [accordionData, setAccordionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const [filterArray, setFilterArray] = useState(initialFilterArray);
  const [tempVar, setTempVar] = useState(false);
  const [label, setLabel] = useState(null);
  const [errorStatusTag, setErrorStatusTag] = useState(0);
  const [successStatusTag, setSuccessStatusTag] = useState(0);
  const [waitingStatusTag, setWaitingStatusTag] = useState(0);
  const [skippedStatusTag, setSkippedStatusTag] = useState(0);
  const [maxStatusTag, setMaxStatusTag] = useState(initialMaxStatusTag);
  // useEffect(() => {
  //   // if (tempVar !== "") setSelectedBrowser(tempVar);
  //   setTempVar("");
  // }, [tempVar]);

  //filterSystem

  // const [dataReport, setDataReport] = useState([
  //   {
  //     data: {
  //       value: "https://demoqa.com/",
  //       data_type: 0,
  //       save_data_name: null,
  //     },
  //     inst: {
  //       instr: "open website",
  //       instr_type: 0,
  //     },
  //     isExpanded: false,
  //   },
  //   {
  //     data: {
  //       value: "pram",
  //       data_type: 0,
  //       save_data_name: null,
  //     },
  //     inst: {
  //       actual_label: "first name",
  //       instr: 'Enter "Text"',
  //       instr_type: 6,
  //       label: "first name",
  //       label_type: 1,
  //     },
  //     isExpanded: false,
  //   },
  //   {
  //     data: {
  //       value: "",
  //       data_type: 0,
  //       save_data_name: "",
  //     },
  //     inst: {
  //       instr: "forms",
  //       instr_type: 400,
  //     },
  //     group_hash:
  //       "c9e8bedaa78997488ab82e6562f5b8c579d8501255f02aae2fe87f6ef51c8572",
  //     group_id: "66c6faa0724d37323bc7074c",
  //     is_local_group: false,
  //     last_updated: "1724317494",
  //     message: "Not Yet Started",
  //     ordinal: null,
  //     resolved_data: {},
  //     status: 5,
  //     step_id: "3",
  //     steps: [
  //       {
  //         data: {
  //           value: "pram",
  //           data_type: 0,
  //           save_data_name: null,
  //         },
  //         inst: {
  //           actual_label: "first name",
  //           instr: 'Enter "Text"',
  //           instr_type: 6,
  //           label: "first name",
  //           label_type: 1,
  //         },
  //       },
  //       {
  //         data: {
  //           value: "pram",
  //           data_type: 0,
  //           save_data_name: null,
  //         },
  //         inst: {
  //           actual_label: "first name",
  //           instr: 'Enter "Text"',
  //           instr_type: 6,
  //           label: "first name",
  //           label_type: 1,
  //         },
  //       },
  //       {
  //         data: {
  //           value: "pram",
  //           data_type: 3,
  //           save_data_name: "Qq",
  //         },
  //         inst: {
  //           actual_label: "first name",
  //           instr: 'Enter "Text" after "Text"',
  //           instr_type: 8,
  //           label: "first name",
  //           label_type: 0,
  //           contextual_label: "last name",
  //           contextual_label_type: 1,
  //         },
  //       },
  //     ],
  //     unique_id: "d4d57fff-d79e-488c-8ae1-ac1658f5a6d2_20240825204145",
  //     isExpanded: false,
  //   },
  //   {
  //     data: {
  //       value: "",
  //       data_type: 0,
  //       save_data_name: "",
  //     },
  //     inst: {
  //       instr: "groupA",
  //       instr_type: 400,
  //     },
  //     group_hash:
  //       "b49e0787632d35b7359bd4b5932225e9d739e9ebb2d5b6441ad231a1b1a6ed27",
  //     group_id: "66c6fb9e724d37323bc7074d",
  //     is_local_group: false,
  //     last_updated: "1724316710",
  //     message: "Not Yet Started",
  //     ordinal: null,
  //     resolved_data: {},
  //     status: 5,
  //     step_id: "4",
  //     steps: [],
  //     unique_id: "1da2a49d-0e21-445c-9823-5791767cbdf4_20240825204145",
  //     isExpanded: false,
  //   },
  // ]);

  const handleStatusChange = (type, increment) => {
    let currentTag, setTag, maxTag;

    switch (type) {
      case 0:
        currentTag = successStatusTag;
        setTag = setSuccessStatusTag;
        maxTag = maxStatusTag[0];
        break;
      case 1:
        currentTag = errorStatusTag;
        setTag = setErrorStatusTag;
        maxTag = maxStatusTag[1];
        break;
      case 2:
        currentTag = waitingStatusTag;
        setTag = setWaitingStatusTag;
        maxTag = maxStatusTag[2];
        break;
      case 3:
        currentTag = skippedStatusTag;
        setTag = setSkippedStatusTag;
        maxTag = maxStatusTag[3];
        break;
      default:
        return;
    }

    if (increment) {
      if (currentTag <= maxTag - 1) {
        setTag((prev) => prev + 1);
      }
    } else {
      if (currentTag >= 1) {
        setTag((prev) => prev - 1);
      }
    }
    // console.log(currentTag);
  };

  const closeAccordion = () => {
    setLabel(null);
    setErrorStatusTag(0);
    setSuccessStatusTag(0);
    setWaitingStatusTag(0);
    setSkippedStatusTag(0);
    setIsAccordionOpen(false);
    setTempVar(true);
    setAccordionData((prevData) =>
      prevData.map((item) => ({
        ...item,
        ...(item.group_id ? { isExpanded: false } : {}),
      }))
    );
  };
  const expandAccordian = () => {
    setIsAccordionOpen(true);
    setAccordionData((prevData) =>
      prevData.map((item) => ({
        ...item,
        ...(item.group_id ? { isExpanded: true } : {}),
      }))
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchJobsData = async (jobid) => {
      try {
        const jsonData = await jobReports(jobid);
        // const jsonData = dummyfetchJobData;

        if (jsonData) {
          // console.log(jsonData, "data");
          setMessageData({
            overall_message: jsonData.testcase_json.overall_message,
            status: jsonData.testcase_json.overall_status,
          });
          // console.log(jsonData.testcase_json.overall_message, "message");
          // Update filterArray state
          setFilterArray({
            [StepStatus.SUCCESS]: jsonData.steps_status_info.success,
            [StepStatus.FAILED]: jsonData.steps_status_info.failed,
            [StepStatus.WARNING]: jsonData.steps_status_info.warning,
            [StepStatus.SKIPPED]: jsonData.steps_status_info.skipped,
          });

          // Calculate maxStatusTag
          const maxStatusTagArray = [
            jsonData.steps_status_info.success.length,
            jsonData.steps_status_info.failed.length,
            jsonData.steps_status_info.warning.length,
            jsonData.steps_status_info.skipped.length,
          ];
          setMaxStatusTag(maxStatusTagArray);

          // Update fetchData state
          // setFetchData(jsonData);
          setAccordionData(jsonData.testcase_json.test_steps_json);
        } else {
          setMessageData(initialMessageData);
          setFilterArray(initialFilterArray);
          setMaxStatusTag(initialMaxStatusTag);
          setAccordionData([]);
        }
      } catch (error) {
        console.error("Error fetching report jobs data:", error);
      }
    };

    fetchJobsData(job_id);
  }, [job_id]);

  if (job_id !== 0) {
    // Updated condition to check if job_id is not equal to 0
    if (!job_id) {
      return <div>Invalid Job ID</div>;
    }
  }

  return (
    <>
      <>
        <div className="testingAnalyticsControlBar">
          <div style={{ padding: "10px", display: "flex", gap: "15px" }}>
            <img
              className="arrowEC"
              src={arrowExpand}
              alt="arrow-expand"
              onClick={expandAccordian}
            />
            <img
              className="arrowEC"
              src={arrowCollapse}
              alt="arrow-collapse"
              onClick={closeAccordion}
            />
            {testcaseName && (
              <p
                style={{
                  fontSize: "16px",
                  margin: "0",
                  padding: "auto 0.5rem",
                  textAlign: "center",
                }}
              >
                {testcaseName}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            <img
              className="arrowEC"
              src={playAndPause}
              alt="Not Found"
              onClick={toggleModal}
            />
            <VideoModel
              isOpen={isModalOpen}
              onClose={toggleModal}
              job_id={job_id}
              // isPlaying={isVideoPlaying}
              // togglePlayPause={toggleVideoPlayback}
            />

            <div
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.4px",
                textAlign: "center",
                padding: "5px",
                color:
                  messageData.overall_message && messageData.status === 0
                    ? "#10B981"
                    : messageData.status === 1
                    ? "#ef4444"
                    : "#9E9E9E",
              }}
            >
              {messageData.overall_message &&
                messageData.overall_message.charAt(0).toUpperCase() +
                  messageData.overall_message.slice(1)}
            </div>

            <div className="statusControl" style={{ gap: "" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1px",
                  marginRight: "10px",
                  gap: "2px",
                  paddingRight: "10px",
                }}
              >
                <img src={getStatusIcon(StepStatus.FAILED)} alt="" />
                <p style={{ padding: "0 1px" }}>
                  {errorStatusTag !== 0 && <>{errorStatusTag}/</>}
                  {maxStatusTag[StepStatus.FAILED]}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.FAILED, false);
                      setLabel(
                        filterArray[StepStatus.FAILED][errorStatusTag - 2]
                      );
                    }}
                  >
                    <img src={downArrow} style={{}} alt="" />
                  </button>
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.FAILED, true);
                      setLabel(filterArray[StepStatus.FAILED][errorStatusTag]);
                    }}
                  >
                    <img src={upArrow} style={{}} alt="" />
                  </button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1px",
                  gap: "2px",
                  paddingRight: "10px",
                }}
              >
                <img src={getStatusIcon(StepStatus.SUCCESS)} alt="" />
                <p style={{ padding: "0 1px" }}>
                  {successStatusTag !== 0 && <>{successStatusTag}/</>}
                  {maxStatusTag[StepStatus.SUCCESS]}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.SUCCESS, false);
                      setLabel(
                        filterArray[StepStatus.SUCCESS][successStatusTag - 2]
                      );
                    }}
                  >
                    <img src={downArrow} style={{}} alt="" />
                  </button>
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.SUCCESS, true);
                      setLabel(
                        filterArray[StepStatus.SUCCESS][successStatusTag]
                      );
                    }}
                  >
                    <img src={upArrow} style={{}} alt="" />
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  paddingRight: "10px",
                }}
              >
                <img src={getStatusIcon(StepStatus.WARNING)} alt="" />
                <p style={{ padding: "0 1px" }}>
                  {waitingStatusTag !== 0 && <>{waitingStatusTag}/</>}
                  {maxStatusTag[StepStatus.WARNING]}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.WARNING, false);
                      setLabel(
                        filterArray[StepStatus.WARNING][waitingStatusTag - 2]
                      );
                    }}
                  >
                    <img src={downArrow} style={{}} alt="" />
                  </button>
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.WARNING, true);
                      setLabel(
                        filterArray[StepStatus.WARNING][waitingStatusTag]
                      );
                    }}
                  >
                    <img src={upArrow} style={{}} alt="" />
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1px",
                  gap: "2px",
                  paddingRight: "10px",
                }}
              >
                <img src={getStatusIcon(StepStatus.SKIPPED)} alt="" />
                <p style={{ padding: "0 1px" }}>
                  {skippedStatusTag !== 0 && <>{skippedStatusTag}/</>}
                  {maxStatusTag[StepStatus.SKIPPED]}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.SKIPPED, false);
                      setLabel(
                        filterArray[StepStatus.SKIPPED][skippedStatusTag - 2]
                      );
                    }}
                  >
                    <img src={downArrow} style={{}} alt="" />
                  </button>
                  <button
                    onClick={() => {
                      handleStatusChange(StepStatus.SKIPPED, true);
                      setLabel(
                        filterArray[StepStatus.SKIPPED][skippedStatusTag]
                      );
                    }}
                  >
                    <img src={upArrow} style={{}} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testingAnalyticsDetails">
          <AllDataforAccordian
            data={accordionData}
            isAccordionOpen={isAccordionOpen}
            reverseClose={setTempVar}
            // loopData={loopData}
            closeAll={tempVar}
            label={label}
          />
        </div>
      </>
    </>
  );
};

export default IndividualReport;

//  fetch(
//   `${apiUrl}/platform/reports/?job_id=${jobid}`
// );
// if (!response.ok) {
//   throw new Error("Failed to fetch jobs data");
// }
