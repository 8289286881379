import React, { useState, useEffect } from "react";
import { Tabs, Button } from "antd";
import { useParams, useLocation } from "react-router-dom";
import acttesticon from "../../../assets/testcaseeditor/acttesticon.svg";
import actexhistry from "../../../assets/testcaseeditor/actexhistry.svg";
import inacexhistry from "../../../assets/testcaseeditor/inacexhistry.svg";
import inactesticon from "../../../assets/testcaseeditor/inactesticon.svg";
import "./Suite.css";
import SuiteEditor from "../suiteEditor/suiteEditor";
import SuiteExehstry from "../executionHistory/eh";
import ExecutionModalLinuxOnly from "../../Testcase/ExecutionModalLinuxOnly";

const Suite = () => {
  const { id } = useParams(); // Read the `id` parameter from the route
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("1"); // Default to "Suite Editor"
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Read query parameters from the URL and set active tab
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab) {
      setActiveKey(tab);
    }
  }, [location.search]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const ExeButton = (
    <Button className="r" onClick={showModal}>
      Execute
    </Button>
  );

  const DataButton = (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <p>LoginCredentials</p>
      <div
        className="text-row view-linked-tests-row"
        // onClick={openLinkedTestcasesModalHandler}
      >
        <span>{0}</span>
        <p>View</p>
      </div>
      <Button
        // onClick={openLinkedDataModalHandler}
        className="r"
      >
        Replace
      </Button>
    </div>
  );

  const tabBarExtraContent = (key) => {
    switch (key) {
      case "1":
        return ExeButton;
      case "2":
        return DataButton;
      default:
        return "";
    }
  };

  return (
    <div className="App">
      <div>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={setActiveKey}
          tabBarExtraContent={tabBarExtraContent(activeKey)}
          className="tab"
        >
          <Tabs.TabPane
            tab={
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <img
                  src={activeKey === "1" ? acttesticon : inactesticon}
                  alt="icon"
                  className={`icon ${
                    activeKey === "1" ? "active-tab" : "other-tab"
                  }`}
                />
                <span
                  className={activeKey === "1" ? "active-tab" : "other-tab"}
                >
                  Suite Editor
                </span>
              </div>
            }
            key="1"
          >
            <SuiteEditor suiteId={id} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <img
                  src={activeKey === "2" ? actexhistry : inacexhistry}
                  alt="icon"
                  className={`icon ${
                    activeKey === "2" ? "active-tab" : "other-tab"
                  }`}
                />
                <span
                  className={activeKey === "2" ? "active-tab" : "other-tab"}
                >
                  Execution History
                </span>
              </div>
            }
            key="2"
          >
            <SuiteExehstry activeKey={activeKey} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      {isModalVisible && (
        <ExecutionModalLinuxOnly
          mode={"suite"}
          open={isModalVisible}
          handleClose={handleModalClose}
          TestCase={id} // Assuming you want to pass the suite ID as TestCase
          setActiveKey={setActiveKey}
        />
      )}
    </div>
  );
};

export default Suite;
