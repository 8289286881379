import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, Modal, Select, Tabs } from "antd";
// import deleteIcon from "../../../../assets/delete_icon.svg";
import Params from "./ApiModalComponent/Param";
import Response from "./ApiModalComponent/Response";
import StoreValues from "./ApiModalComponent/StoreValues";
import Authorization from "./ApiModalComponent/Authorization";
import Headers from "./ApiModalComponent/Headers";
import "./apiModal.css";
// import Body from "./ApiModalComponent/Body";
import JSONEditor from "./ApiModalComponent/JSONEditor.js";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { create_globalData } from "../../../../services/dataService.js";
import { useResponseContext } from "../../context/ResponseContext.js";
import NewJsonEditor from "./ApiModalComponent/NewJsonEditor.js";
// import { edit } from "ace-builds";
const initialModalData = {
  name: "",
  instr_type: null,
  method: null,
  url: "",
  path_params: {},
  query_params: {},
  headers: {},
  body: {},
  verify_response: {
    code: null,
  },
  save_response: [],
  original_response: {
    code: null,
    headers: null,
    json: null,
    text: null,
  },
  recent_response: {
    code: null,
    headers: null,
    json: null,
    text: null,
  },
};

const ApiModal = ({
  modalData,
  open,
  onCancel,
  handleApiSendClick,
  handleAddApiCard,

  sendMessage,
  received502,
  setReceived502,
}) => {
  const [data, setData] = useState(
    modalData ? { ...modalData } : initialModalData
  );
  const storeInputRef = useRef(null);
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [nameEmpty, setNameEmpty] = useState(false);
  const [urlEmpty, setUrlEmpty] = useState(false);
  const [methodEmpty, setMethodEmpty] = useState(false);
  const [responseCode, setResponseCode] = useState("");
  const [waitingFor502, setWaitingFor502] = useState(false);
  const [responseValueError, setResponseValueError] = useState(false);

  const {
    updateResponseData,
    activeModalTab,
    updateActiveTab,
    responseLoading,
  } = useResponseContext();

 

  const handleCopiedValue = (key, value, isText = false) => {
    if (storeInputRef && storeInputRef.current)
      storeInputRef.current.addInputValue(key, value, isText);
  };

  useEffect(() => {
    if (modalData) {
      const _data = { ...modalData };
      setUrl(_data.url || "");
      setName(_data.name || "");
      setResponseCode(_data.verify_response?.code || "");
      updateResponseData({ ..._data.recent_response });
      setData(_data);
 
    } else {
      setUrl("");
      setName("");
      setResponseCode("");
      updateResponseData({ ...initialModalData.recent_response });
      setData(initialModalData);
    }
  }, [modalData, open]);

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);

    setUrlEmpty(false);

    // Update data with new URL
    setData((prevData) => ({
      ...prevData,
      url: newUrl,
      path_params: extractPathParams(newUrl),
    }));
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    setNameEmpty(false);

    setData((prevData) => ({
      ...prevData,
      name: newName,
    }));
  };

  const handleMethodChange = (value) => {
    setMethodEmpty(false);

    let methodCode;
    switch (value) {
      case "GET":
        methodCode = 600;
        break;
      case "POST":
        methodCode = 601;
        break;
      case "PUT":
        methodCode = 602;
        break;
      case "PATCH":
        methodCode = 603;
        break;
      case "DELETE":
        methodCode = 604;
        break;
      default:
        methodCode = null;
    }

    setData((prevData) => ({
      ...prevData,
      method: value,
      instr_type: methodCode,
    }));
  };

  const extractPathParams = (url) => {
    const regex = /\/:([^\/]+)/g;
    let match;
    const pathParams = {};

    while ((match = regex.exec(url)) !== null) {
      const paramName = match[1];
      if (!(paramName in data.path_params)) {
        pathParams[paramName] = "";
      }
    }

    return pathParams;
  };

  const setAuthStructure = (obj) => {
    setData((prevData) => {
      // If obj is null, remove authorization from data
      if (obj === null) {
        const { authorization, ...updatedData } = prevData;
        return updatedData;
      }
      return {
        ...prevData,
        authorization: obj,
      };
    });
  };

  const handleSend = () => {
    if (name === "") {
      setNameEmpty(true);
    }
    if (url === "") {
      setUrlEmpty(true);
    }
    if (data.method === null) {
      setMethodEmpty(true);
    }

    if (name === "" || url === "" || data.method === null) {
      return;
    }

    handleApiSendClick(data);
  };

  const handleReset = () => {
 
    if (modalData) {
      setData({ ...modalData });
      setUrl(modalData?.url || "");
      setName(modalData?.name || "");

      updateResponseData({ ...modalData.recent_response });
    } else {
      const parsedData = initialModalData;
      setData({  ...parsedData  });
      setUrl(parsedData.url || "");
      setName(parsedData.name || "");

      updateResponseData({ ...initialModalData.recent_response });

      setMethodEmpty(false);
      setNameEmpty(false);
      setUrlEmpty(false);

    }

    setWaitingFor502(false);
    // setActiveTab("1");
    updateActiveTab("1");
  };

  const handleSaveClick = (data) => {
    if (name === "") {
      toast.error("Please enter a Name");
      setNameEmpty(true);
    }
    if (url === "") {
      toast.error("Please enter a Url");
      setUrlEmpty(true);
    }
    if (data.method === null) {
      toast.error("Please select a Method");
      setMethodEmpty(true);
    }

    if (name === "" || url === "" || data.method === null) {
      return;
    }

    if (!responseCode || responseCode === "") {
      toast.error("Please enter a Status Code in the response tab");
      setResponseValueError(true);
      return;
    } else {
      setResponseValueError(false);
    }

    if (data?.save_response && data?.save_response.length) {
      const _variableNameErrors = [];
      data.save_response.forEach((inputValue, index) => {
        if (!inputValue?.key || !inputValue?.key.length) {
          _variableNameErrors.push(index);
        }
      });
      if (_variableNameErrors.length) {
        storeInputRef.current.setVariableNameErrors(_variableNameErrors);
        return;
      }
    } else {
      if (storeInputRef && storeInputRef.current) {
        storeInputRef.current.setVariableNameErrors([]);
      }
    }

    // Indicate that we are waiting for a response with status code 502
    setWaitingFor502(true);
    handleAddGlobalVariable();

    // Send the message
    console.log("SENDING MESSAGE", 501);
    sendMessage({ data_type: 501 });
  };

  const handleAddGlobalVariable = () => {
    if(!storeInputRef.current) return;
    const allVariable = storeInputRef.current.getVariablesName();
 

    allVariable.forEach(async (inputData) => {
      const response = await create_globalData({
        key: inputData.key,
        value: inputData.value,
      });
 
    });
  };

  useEffect(() => {
    
    if (received502 && waitingFor502) {
      handleAddApiCard(data);
      onCancel();
      handleReset();
      setWaitingFor502(false);
      setReceived502(false);

      updateResponseData({ ...initialModalData.recent_response });
      setData(initialModalData);
    }
  }, [received502, waitingFor502]);

  const updateVerifyResponse = (value) => {
    setResponseCode(value);
    setData((previous) => ({ ...previous, verify_response: { code: value } }));
  };
  const handleCancel = () => {
    sendMessage({ data_type: 501 });
    onCancel();
    handleReset();

    setData(initialModalData);
  };

  // console.log("API DATA", data);
  // console.log("API DATA2", url);
  return (
    <Modal
      title="REST API Call"
      visible={open}
      onCancel={handleCancel}
      footer={null}
      width={800}
      centered
      getContainer={() => document.getElementById("modal-wrapper")}
    >
      <Form layout="vertical" className="modal-form">
        <div className="api-modal-name-box">
          <p className="form-heading">
            Name <span className="warning">*</span>
          </p>
          <input
            className={`default-input  ${nameEmpty && "required"}`}
            value={name}
            placeholder="Ex: Get Products"
            onChange={handleNameChange}
          />
        </div>

        <div>
          <p className="form-heading">
            REST API <span className="warning">*</span>
          </p>

          <div className="form-row">
            <div className="api-modal-select-box">
              <Select
                placeholder={"GET"}
                onChange={handleMethodChange}
                value={data.method}
                className={` ${methodEmpty && "required"}`}
                style={{ minWidth: "88px", height: "40px", borderColor: "red" }}
              >
                <Select.Option value="GET">GET</Select.Option>
                <Select.Option value="POST">POST</Select.Option>
                <Select.Option value="PUT">PUT</Select.Option>
                <Select.Option value="PATCH">PATCH</Select.Option>
                <Select.Option value="DELETE">DELETE</Select.Option>
              </Select>
            </div>

            <div className="api-modal-url-box">
              <input
                value={url}
                className={`default-input ${urlEmpty && "required"}`}
                placeholder="URL"
                onChange={handleUrlChange}
              />
            </div>

            <div>
              <Button
                type="primary"
                className="send-button"
                style={{ backgroundColor: "#0036af", borderColor: "#0036af" }}
                onClick={handleSend}
              >
                {responseLoading ? (
                  <CircularProgress
                    style={{
                      width: "14px",
                      height: "14px",
                      color: "#fff",
                      padding: "0px 8.4px",
                    }}
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </div>
        </div>

        <div className="tabs-container">
          <Tabs
            defaultActiveKey="1"
            onChange={updateActiveTab}
            activeKey={activeModalTab}
          >
            <Tabs.TabPane tab="Params" key="1">
              <Params data={data} setData={setData} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Authorization" key="2">
              <Authorization
                data={data}
                setData={setData}
                setAuthStructure={setAuthStructure}
                type={data.authorization ? data.authorization.type : "No Auth"}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Headers" key="3">
              <Headers data={data} setData={setData} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Body" key="4">
              {/* <Form.Item name="body">
              </Form.Item> */}
              <NewJsonEditor data={data} setData={setData} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Response" key="5">
              {activeModalTab === "5" && (
                <Response
                  // responseDetails={responseData}
                  handleCopiedValue={handleCopiedValue}
                />
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>

        {activeModalTab === "5" && (
          <div className="response_values_wrap">
            <div
              className={`store-input-container ${data.save_response && data.save_response.length
                  ? "response_status_wrap"
                  : ""
                }`}
            >
              <div>
                <label className="response-lable-container">
                  Success When (Status Code) <span className="warning">*</span>
                </label>
                <Input
                  className={`${responseValueError && "required"}`}
                  placeholder="Ex: 200"
                  value={responseCode}
                  style={{ width: "50%" }}
                  onChange={(e) => updateVerifyResponse(e.target.value)}
                />
              </div>
              {/* <div>
                <label className="store-lable-container">Timeout check</label>
                <Input
                  placeholder="Ex: 5s"
                    value={input.key}
                  onChange={(e) => setNameText(e.target.value)}
                />
              </div> */}
            </div>
            <StoreValues ref={storeInputRef} data={data} setData={setData} />
          </div>
        )}

        <div className="modal-footer">
          <Button onClick={handleReset}>Reset</Button>
          <div className="footer-buttons">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              style={{ backgroundColor: "#0036af", borderColor: "#0036af" }}
              onClick={() => handleSaveClick(data)}
            >
              {waitingFor502 ? (
                <CircularProgress
                  style={{ width: "14px", height: "14px", color: "#fff" }}
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ApiModal;

// const getAllHeaders = (headers) => {
//   const headersObj = {};
//   headers.forEach((value, key) => {
//     headersObj[key] = value;
//   });
//   return headersObj;
// };

// const newDataString = JSON.stringify({
//   name: "",
//   instr_type: null,
//   method: null,
//   url: "",
//   path_params: {},
//   query_params: {},
//   headers: {},
//   body: {},
//   verify_response: {
//     code: 200,
//   },
//   save_response: [
//     {
//       key: "Name",
//       path_to_key: "People.0.Name",
//     },
//     {
//       key: "City",
//       path_to_key: "People.0.City",
//     },
//     {
//       key: "Skype1",
//       path_to_key: "People.0.Contacts.Skype",
//     },
//     {
//       key: "Address",
//       path_to_key: null, // # Save entire response text if not JSON
//     },
//   ],
//   original_response: {
//     code: null,
//     headers: null,
//     json: null,
//     text: null,
//   },
//   recent_response: {
//     code: "200",
//     headers: sampleJson,
//     json: sampleJson,
//     text: null,
//   },
// });

// const sampleJson = {
//   People: [
//     {
//       ID: "001",
//       Name: "John Wick",
//       City: "New York",
//       Address: "1 Wall Street, Apt 5",
//       Contacts: {
//         "Phone number": "111-222-3333",
//         "E-mail": "johnwick@gmail.com",
//         Skype: "johnwick123",
//       },
//     },
//     {
//       ID: "002",
//       Name: "Peter Parker",
//       City: "Queens, NY",
//       Address: "Chelsea Street, 410",
//       Contacts: {
//         "Phone number": "123-456-7890",
//         "E-mail": "spiderman@gmail.com",
//         Skype: "spiderman4ever",
//       },
//     },
//     {
//       ID: "003",
//       Name: "Rustin Cohle",
//       City: "West Monroe, Louisiana",
//       Address: "14 Oklakhoma Street, Apt 13",
//       Contacts: {
//         "Phone number (office)": "131-313-1313",
//         "Phone number (home)": "666-666-6666",
//         "E-mail": "truedetective@gmail.com",
//         Skype: "rustcohle888",
//       },
//     },
//     {
//       ID: "004",
//       Name: "Tommy Shelby",
//       City: "Birmingham",
//       Address: "Mount Steet, 19/18",
//       Contacts: {
//         "Phone number": "777-777-777",
//         "E-mail": "peakyblinders@gmail.com",
//         Skype: "shelby1918",
//       },
//     },
//   ],
// };

// const initializeApiResponse = (
//   headers = null,
//   body = null,
//   time = null,
//   size = null,
//   status = null,
//   ok = false
// ) => ({
//   headers,
//   body,
//   time,
//   size,
//   status,
//   ok,
// });
