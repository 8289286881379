import React, { useState } from 'react';
import dropdownIcon from '../../../../assets/arrow-drop-down.svg'
import './ordinal.css';


const Ordinal = ({
  card,
  do_ordinal_selection = "false",
  ordinal_value = null,
  sendMsgForOrdinalHover,
  sendMsgForOrdinalSelect
}) => {

  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);

  function createList(n) {
    return Array.from({ length: n }, (_, i) => i + 1);
  }

  let condition = "showNothing";
  let dropdownOptions = [];
  // console.log("ordinal value is ", ordinal_value)
  // Example conditions
  if (do_ordinal_selection === "true") {
    condition = "showDropdown";
    dropdownOptions = createList(ordinal_value)
    // console.log("here going to ahow ", condition, dropdownOptions, card)

  }
  else if (ordinal_value) {

    condition = "showNumber"
  }
  else {
    condition = "showNothing";
    // console.log("here goingasdasd to ahow ", condition, dropdownOptions, card)
  }

  // Example dropdown options
  // console.log("here goiDQWEWASDEWEng to ahow ", condition, dropdownOptions, card)

  // Handlers
  const handleDropdownClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleHover = (option) => {
    // console.log(`Hovering over ${option}`);
    sendMsgForOrdinalHover(option)
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setDropdownVisible(false);
    // console.log(`Selected ${option}`);
    sendMsgForOrdinalSelect(option)
  };
  return (
    <div style={{ }}>
        <React.Fragment>
          {condition === "showDropdown" && (
            <div className="dropdown-container">
              <button onClick={handleDropdownClick} className="dropdown-button">
                {selectedOption ? 
                <div className='ordinal-btn'>{selectedOption}</div>
                 : 
                (<div className='ordinal-btn'><img src={dropdownIcon} width={10} alt='' /></div>)}
              </button>
              {dropdownVisible && (
                <ul className="dropdown-options">
                  {dropdownOptions.map((option) => (
                    <li
                      key={option}
                      onMouseEnter={() => handleHover(option)}
                      onClick={() => handleSelect(option)}
                      className="dropdown-option"
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {condition === "showNumber" && (
            <div className="number-display">
              {ordinal_value}
            </div>
          )}
        </React.Fragment>
    </div>
  )
}

export default Ordinal