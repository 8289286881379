import React from "react";
import dragCard from "../../assets/group-drag.svg";
import testcaseDelete from "../../assets/testcase-delete-icon.svg";
import { Checkbox } from "antd";

const SeCard = ({
  testCase,
  handleDrop,
  handleCardDelete,
  groupIndex,
  index,
}) => {
  const onDragStart = (e) => {
    e.dataTransfer.setData("sourceGroupIndex", groupIndex);
    e.dataTransfer.setData("sourceTestIndex", index);
  };

  const onDrop = (e) => {
    e.stopPropagation();
    const sourceGroupIndex = e.dataTransfer.getData("sourceGroupIndex");
    const sourceTestIndex = e.dataTransfer.getData("sourceTestIndex");
    handleDrop(sourceGroupIndex, sourceTestIndex, groupIndex, index);
    resetDraggableAttributes();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const handleMouseDown = (e) => {
    e.currentTarget
      .closest(".se-card-container")
      .setAttribute("draggable", true);
  };

  const handleMouseUp = (e) => {
    e.currentTarget
      .closest(".se-card-container")
      .setAttribute("draggable", false);
  };

  const resetDraggableAttributes = () => {
    const cardContainers = document.querySelectorAll(".se-card-container");
    cardContainers.forEach((container) => {
      container.setAttribute("draggable", false);
    });
  };

  return (
    <div
      className="se-card-container"
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      draggable={false}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Checkbox className="custom-checkbox" />
        {testCase.name}
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <img
          src={dragCard}
          alt="drag-card"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          className="drag-card-icon"
        />
        <img
          src={testcaseDelete}
          alt="delete"
          onClick={() => handleCardDelete(groupIndex, index)}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default SeCard;

//onChange={handleSelect}
//checked={selectedCards.some(card => card.id === testCase.id)}
//disabled={disabled}

// const handleSelect = (e) => {
//     const isChecked = e.target.checked;
//     if (isChecked) {
//         setSelectedCards([...selectedCards, { id: testCase.id, index, groupOrder }]);
//     } else {
//         setSelectedCards(selectedCards.filter(card => !(card.id === testCase.id && card.index === index && card.groupOrder === groupOrder)));
//     }
// };
