import React, { useEffect } from "react";
import "./Modal.css";
import Carousel from "./carousel"; // Import your Carousel component

const Modal = ({ isOpen, onClose, data, initialUid }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="pic-modal">
      <div className="modal-overlay">
        <div className="modal-content">
          <div>
            <div
              className=""
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.1px",
                textAlign: "center",
              }}
            >
              Screenshot
            </div>

            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>
          <hr style={{ padding: "0" }} />
          <div
            style={{
              padding: "0",
              margin: "0",
              height: "610px",
              width: "100%",
            }}
          >
            <div className="carousel-container">
              <Carousel data={data} initialUid={initialUid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
