import React, { useState } from "react";
import arrowRight from "../../../../../assets/arrow-right-circle.svg";

const JsonFormatter = ({ json, handleCopiedValue, showArrow }) => {
  const [hoveredKey, setHoveredKey] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState({});

  const toggleExpand = (key) => {
    setExpandedKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleCopy = (key, value) => {
    navigator.clipboard.writeText(JSON.stringify(value));
    handleCopiedValue(key, value);
  };

  const renderJson = (obj, depth = 0, parentKey = "") => {
    const entries = obj ? Object.entries(obj) : {};
    return (
      <div style={{ paddingLeft: depth === 0 ? 20 + "px" : depth + 20 + "px" }}>
        {entries.map(([key, value], index) => {
          const fullKey = parentKey ? `${parentKey}.${key}` : key;
          const isLast = index === entries.length - 1;
          return (
            <div key={fullKey} className="json-line">
              {renderKeyValue(key, value, depth, fullKey, !isLast)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderKeyValue = (key, value, depth, fullKey, hasComma) => {
    const isNested = typeof value === "object" && value !== null;
    const isExpanded = expandedKeys[fullKey];

    return (
      <>
        <span className="json-key">"{key}"</span>:
        {isNested ? (
          <>
            <span
              className="expand-collapse"
              onClick={() => toggleExpand(fullKey)}
            >
              {isExpanded ? " −" : " +"}
            </span>
            <span>{Array.isArray(value) ? "[" : "{"}</span>
            {isExpanded && (
              <>
                {renderJson(value, depth + 1, fullKey)}
                <div>
                  {/* style={{ paddingLeft: depth * 10 + "px" }} */}
                  {Array.isArray(value) ? "]" : "}"}
                  {hasComma && ","}
                </div>
              </>
            )}
            {!isExpanded && (
              <span>
                {Array.isArray(value) ? "...]" : "...}"}
                {hasComma && ","}
              </span>
            )}
          </>
        ) : (
          <>
            {renderSimpleValue(value, fullKey)}
            {hasComma && ","}
          </>
        )}
      </>
    );
  };

  const renderSimpleValue = (value, key) => {
    return (
      <span
        className="json-value-wrapper"
        onMouseEnter={() => setHoveredKey(key)}
        onMouseLeave={() => setHoveredKey(null)}
      >
        <span className={`json-value ${typeof value}`}>
          {JSON.stringify(value)}
        </span>
        {hoveredKey === key && showArrow ? (
          <button
            className="copy-button"
            onClick={() => handleCopy(key, value)}
          >
            <img className="arrow-right" src={arrowRight} alt="arrow-right" />
          </button>
        ) : (
          <></>
        )}
      </span>
    );
  };

  return (
    <div className="json-formatter">
      <div className="json-content">
        {"{"}
        {renderJson(json)}
        {"}"}
      </div>
      {/* {copiedValue && <div>Copied: {copiedValue}</div>} */}
      <style jsx="true">{`
        .json-formatter {
          font-family: monospace;
          font-size: 14px;
          //   background-color: #f8f8f8;
          padding: 10px;
          border-radius: 4px;
        }
        .json-content {
          width: 95%;
        }
        .json-line {
          line-height: 1.5;
        }
        .json-key {
          color: #0000ff;
        }
        .json-value {
          color: #000;
        }
        .json-value.string {
          color: #a31515;
          word-break: break-all;
        }
        .json-value.number {
          color: #098658;
        }
        .json-value-wrapper {
          position: relative;
          display: inline-block;
        }
        .copy-button {
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          cursor: pointer;
          font-size: 16px;
          color: #888;
          opacity: 0.7;
        }
        .copy-button:hover {
          opacity: 1;
        }
        .expand-collapse {
          cursor: pointer;
          font-weight: bold;
          background-color: #bcbdbc;
          color: #fefffe;
          height: 1rem;
          width: 1rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin: 0 5px;
        }
        .arrow-right {
          height: 16px;
          weight: 16px;
          color: #fefffe;
          cursor: pointer;
          background: #bcbdbc;
          border-radius: 50%;
          transform: translate(14px, 2px);
        }
      `}</style>
    </div>
  );
};

export default JsonFormatter;
