import "./testcaseModal.css";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import {
  createTestCase,
  changeTestCaseFolder,
  fetchTestcaseByFolderId,
} from "../../services/testCaseServices";
import inactData from "../../assets/database-teststep.png";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Tooltip } from "@mui/material";
import { fetchLocalData } from "../Data/DataAction/dataAction";
import lbutton from "../../assets/lbutton.svg";
import closeIcon from "../../assets/close-icon-local-data.svg";
import ModuleDropdown from "./ModuleDropdown";
import { toast } from "react-toastify";

import { setSessionIdForNewTC } from "../EcomTesting/Testaction/cardsActions";
import { setTitle } from "../../providers/titleProvider";

const urlRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-!$&'()*+,;=:@/?.[\]]+)(:[0-9]+)?(\/[^\s]*)?$/;

const LocalDataModalItem = ({
  name,
  id,
  setAttachedLocaldata,
  setAttachedLocalDataName,
  handleLocalDataModalClick,
}) => {
  const handleOnClick = () => {
    handleLocalDataModalClick();
    setAttachedLocaldata(id);
    setAttachedLocalDataName(name); // Set the name of the attached local data
  };

  return (
    <div onClick={handleOnClick} className="tcmodal_data-modal-item">
      <img src={lbutton} alt="attach data" />
      {name}
    </div>
  );
};

const BasicModal = ({
  isOpen,
  handleClose,
  closebychildmodal,
  testCaseData,
  recordDetails,
  isEdit,
  fetchLocalData,
  folderList,
  refreshModules,
  setSessionIdForNewTC,
  // localData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [localRefresh, setLocalRefresh] = useState(false);
  const [Data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [showLocalDataModal, setShowLocalDataModal] = useState(false);
  const [testCaseDetails, setTestCaseDetails] = useState({
    name: isEdit && recordDetails ? recordDetails.testcaseName : "",
    url: "",
  });
  const [attachedLocaldata, setAttachedLocaldata] = useState(null);
  const [attachedLocalDataName, setAttachedLocalDataName] = useState(""); // New state for attached data name
  const [dataSheetInputValue, setDataSheetInputValue] = useState(""); // State for data sheet input value
  const [errors, setErrors] = useState({
    nameError: "",
    urlError: "",
    moduleError: "",
  });
  const [selectedFolder, setSelectedFolder] = useState(null);

  // const fetchRan = useRef(false);

  const validateName = (name) => {
    if (!name || !name.length) return false;
    const trimName = name.trim().toLowerCase();
    if (!trimName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nameError: "Name can't be empty.",
      }));
      return false;
    } else if (trimName.length) {
      return trimName;
    }
    return false;
  };

  const validateURL = (url) => {
    if (!url.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        urlError: "URL can't be empty.",
      }));
      return false;
    } else if (!urlRegex.test(url)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        urlError: "Enter a valid URL.",
      }));
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTestCaseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: "",
    }));
  };

  const handleCreate = async () => {
    if (!selectedFolder) {
      setErrors((previous) => ({
        ...previous,
        moduleError: "Please select module",
      }));
      return;
    }

    const { name, url } = testCaseDetails;
    setShowCircularProgress(true);
    const validName = validateName(name);
    if (validName && validateURL(url)) {
      const data_to_send = {
        ...testCaseDetails,
        name: validName,
        user_id: 1,
        folder_id: selectedFolder,
      };

      if (attachedLocaldata) {
        data_to_send.local_data_id = attachedLocaldata;
      }

      console.log("data_to_send", data_to_send);
      try {
        const data = await createTestCase(data_to_send);
        if (data) {
          console.log("createTestCase", data);
          setSessionIdForNewTC(data.session_id);
          setTitle(name);
          toast.success("Successfully created test case");
          navigate(`/Test-case/${data.testcase_id}`);
        }
        closebychildmodal();
      } catch (error) {
        toast.error(error?.detail ? error.detail : "Error creating test case");
        if (error?.detail && error.detail.includes("TestCaseData")) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            nameError: "Duplicate name",
          }));
        } else {
          closebychildmodal();
        }
      } finally {
        setShowCircularProgress(false);
      }
    } else {
      setShowCircularProgress(false);
    }
  };

  const handleUpdate = async () => {
    if (!selectedFolder || selectedFolder === recordDetails?.folderId) {
      setErrors((previous) => ({
        ...previous,
        moduleError: "Please select different module",
      }));
      return;
    }

    setShowCircularProgress(true);

    if (!validateName(recordDetails.testcaseName)) {
      setShowCircularProgress(false);
      return;
    }

    try {
      const data = await changeTestCaseFolder(
        recordDetails?.testcaseId,
        selectedFolder
      );
      if (data) {
        // setShowCircularProgress(false);
        toast.success("Successfully updated test case");
        refreshModules();
        closebychildmodal();
      }
    } catch (error) {
      // setShowCircularProgress(false);
      toast.error(error?.detail ? error.detail : "Error updating test case");
      if (error?.detail && error.detail.includes("TestCaseData")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: "Duplicate name",
        }));
      } else {
        closebychildmodal();
      }
    } finally {
      setShowCircularProgress(false);
    }
  };

  const handleCreateUpdate = async () => {
    if (showCircularProgress) return;
    if (isEdit) handleUpdate();
    else handleCreate();
  };

  const handleLocalDataModalClick = () => {
    setShowLocalDataModal(!showLocalDataModal);
    setDataSheetInputValue(""); // Reset the input value
  };

  const handleSearchOnchange = (value) => {
    setShowLocalDataModal(true);
    setSearchQuery(value);
    setDataSheetInputValue(value); // Update the input value
  };

  const handleRemoveAttachedData = () => {
    setAttachedLocaldata(null);
    setAttachedLocalDataName("");
    setDataSheetInputValue(""); // Reset the input value
  };

  const handleSelectFolder = (folderId) => {
    if (folderId) {
      setSelectedFolder(folderId);
      setErrors((previous) => ({ ...previous, moduleError: "" }));
    }
  };

  // const createModule = async () => {
  //   const response = await createFolder("default");
  //   if (!response) return;

  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await fetchLocalData();
        if (res) {
          setData(res.list_of_all_local_data);
        }
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [localRefresh]);

  useEffect(() => {
    const filtered = Data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, Data]);

  useEffect(() => {
    if (
      // !errors.nameError &&
      // !errors.urlError &&
      selectedFolder &&
      testCaseDetails.name.trim().length >= 1 &&
      urlRegex.test(testCaseDetails.url)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    // errors.nameError,
    // errors.urlError,
    selectedFolder,
    testCaseDetails.name,
    testCaseDetails.url,
  ]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    background: "white",
    border: "1px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container_testcasemodal">
            <div className="top-content_testcasemodal">
              <div>{isEdit ? "Edit" : "New"} Test Case</div>
              <div
                onClick={closebychildmodal}
                className="close_button_testcasemodal"
              >
                <CloseIcon />
              </div>
            </div>

            <div className="name-input_testcasemodal">
              <div>
                Name <span style={{ color: "#EF4444" }}>*</span>
              </div>
              <div>
                <input
                  name="name"
                  value={testCaseDetails.name}
                  onChange={handleChange}
                  className="input_textcasemodal"
                  disabled={isEdit}
                />
                {errors.nameError && (
                  <div className="error">{errors.nameError}</div>
                )}
              </div>
            </div>

            {!isEdit && (
              <>
                <div className="url-input_testcasemodal">
                  <div>
                    URL <span style={{ color: "#EF4444" }}>*</span>
                  </div>
                  <div>
                    <input
                      name="url"
                      value={testCaseDetails.url}
                      onChange={handleChange}
                      className="input_textcasemodal"
                    />
                    {errors.urlError && (
                      <div className="error">{errors.urlError}</div>
                    )}
                  </div>
                </div>

                <div className="url-input_testcasemodal">
                  <div>Attach Data Sheet</div>
                  <div
                    style={{ position: "relative" }}
                    className="input_textcasemodal-dropdown"
                  >
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="input_textcasemodal"
                    >
                      {attachedLocalDataName && (
                        <div className="textcasemodal_datasheet_selected">
                          <p>{attachedLocalDataName}</p>
                          <img
                            src={closeIcon}
                            width={18}
                            alt="remove file"
                            onClick={handleRemoveAttachedData}
                          />
                        </div>
                      )}
                      <input
                        style={{
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                        }}
                        name="data-sheet"
                        value={dataSheetInputValue} // Bind input value to state
                        className="input_textcasemodal_datasheet"
                        onFocus={handleLocalDataModalClick}
                        onChange={(e) =>
                          handleSearchOnchange(e.target.value.trim())
                        }
                      />
                    </div>
                    {showLocalDataModal && (
                      <div className="testcasemodal_local-data-modal">
                        {loading ? (
                          <div className="tcmodal_data-spinner">
                            <CircularProgress
                              style={{
                                width: "16px",
                                height: "16px",
                                color: "#0036af",
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                        ) : (
                          <div className="tcmodal_data-modal-items scrollar">
                            {filteredData.length > 0 ? (
                              filteredData.map((item, idx) => (
                                <LocalDataModalItem
                                  setAttachedLocaldata={setAttachedLocaldata}
                                  setAttachedLocalDataName={
                                    setAttachedLocalDataName
                                  }
                                  handleLocalDataModalClick={
                                    handleLocalDataModalClick
                                  }
                                  key={idx}
                                  name={item.name}
                                  id={item.local_data_id}
                                />
                              ))
                            ) : (
                              <div className="tcmodal-no-data">
                                No data found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <ModuleDropdown
              folderList={folderList}
              recordDetails={recordDetails}
              selectedFolder={handleSelectFolder}
              refreshModules={refreshModules}
            />
            {errors.moduleError.length > 0 && (
              <div className="error">{errors.moduleError}</div>
            )}
            {/* <div className="url-input_testcasemodal">
              <div>
                Module <span style={{ color: "#EF4444" }}>*</span>
              </div>
              <div>
                <input
                  name="module"
                  value="Default"
                  // onChange={handleChange}
                  className="input_textcasemodal"
                />
                {errors.nameError && (
                  <div className="error">{errors.nameError}</div>
                )}
              </div>
            </div> */}

            <div className="button_container_testcasemodal">
              <div>
                <button
                  className="button_testcasemodal"
                  onClick={closebychildmodal}
                >
                  Cancel
                </button>
              </div>

              <div>
                {!showCircularProgress && (
                  <button
                    onClick={handleCreateUpdate}
                    className={
                      isButtonDisabled && !isEdit
                        ? "button_testcasemodalcreate_disabled"
                        : "button_testcasemodalcreate"
                    }
                    disabled={isButtonDisabled && !isEdit}
                  >
                    <span>{isEdit ? "Update" : "Create"}</span>
                  </button>
                )}
                {showCircularProgress && (
                  <button className="button_testcasemodalcreate-spinner">
                    <span>
                      <CircularProgress
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#ffffff",
                        }}
                      />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  localData: state.data.localData,
});

const mapDispatchToProps = {
  fetchLocalData,
  setSessionIdForNewTC,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicModal);
