import cross from "../../../../../assets/failStatus.svg";
import { loopActions } from "../data/loop";

import './loopDropdown.css';

const LoopDropdown = ({ handleDivClick, handleClose }) => {


  return (
    <div className='step-dropdown'>
      <div className="info-text-cont">
        <div>Loop Actions</div>
        <img
          onClick={handleClose}
          className="c-img"
          src={cross}
          alt="close"
        />
      </div>
      <div className="info-data">
        {loopActions.map((key,index) => (
          <div
            key={index}
            onClick={() =>
              handleDivClick(key.clickValue, key.instr_type, key.variables)
            }
            className="suggestion-box"
          >
            <div
              className={key.clickValue}
              dangerouslySetInnerHTML={{ __html: key.instr }}
            ></div>
            </div>
          
        ))}
      </div>
    </div>
  )
}

export default LoopDropdown