// ./reducers/dataReducer.js

const initialState = {
  localData: [],
  globalData: [],
  loading: false,
  error: null,
  currentTableName: null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_LOCAL_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FETCH_LOCAL_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        localData: action.payload,
      };

    case "FETCH_LOCAL_DATA_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "FETCH_GLOBAL_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "FETCH_GLOBAL_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        globalData: action.payload,
      };

    case "FETCH_GLOBAL_DATA_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "SET_CURRENT_TABLE_NAME":
      return {
        ...state,
        currentTableName: action.payload,
      };

    case "TRIGGER_GLOBAL_REFRESH":
      // Implement logic for global refresh if necessary
      // For example, resetting global data or refetching
      return {
        ...state,
        loading: true, // Optionally set loading to true if you want to fetch new data
      };

    default:
      return state;
  }
};

export default dataReducer;
