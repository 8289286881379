import { Tooltip, Typography } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle"; // Ensure correct import for icons
import ErrorIcon from "@mui/icons-material/Error"; // Adjust icon import based on actual usage
import { ReactComponent as FailIcon } from "../failStatus.svg";
import { ReactComponent as SuccessIcon } from "../successStatus.svg";
import { ReactComponent as InfoIcon } from "../infoIcon.svg";
import { ReactComponent as WaitingIcon } from "../waitingIcon.svg";
import { ReactComponent as ProcessIcon } from "../inProcess.svg";
import CircularProgress from "@mui/material/CircularProgress";



export const getStatusIcon = (status) => {
    // Convert the status to a string for consistent comparison
    const statusStr = typeof status === 'string' ? status.toLowerCase() : StepStatus[status].toLowerCase();
  
    switch (statusStr) {
      case 'success':
      case String(StepStatus.SUCCESS).toLowerCase():
        return (
          <Tooltip title="success">
            <SuccessIcon style={{ fontSize: '18px', marginLeft: '10px' }} />
          </Tooltip>
        );
      case 'failed':
      case String(StepStatus.FAILED).toLowerCase():
        return (
          <Tooltip title="failed">
            <FailIcon style={{ color: 'red', fontSize: '18px', marginLeft: '10px' }} />
          </Tooltip>
        );
      case 'warning':
      case String(StepStatus.WARNING).toLowerCase():
        return (
          <Tooltip title="warning">
            <InfoIcon style={{ color: 'yellow', fontSize: '18px', marginLeft: '10px' }} />
          </Tooltip>
        );
      case 'skipped':
      case String(StepStatus.SKIPPED).toLowerCase():
        return (
          <Tooltip title="skipped">
            <InfoIcon style={{ color: 'gray', fontSize: '18px', marginLeft: '10px' }} />
          </Tooltip>
        );
      case 'in progress':
      case String(StepStatus.IN_PROGRESS).toLowerCase():
        return (
          <Tooltip title="in progress">
            <CircularProgress
              style={{ width: '10px', height: '10px', color: '#0036af', marginLeft: '10px' }}
            />
          </Tooltip>
        );
      case 'not started':
      case String(StepStatus.NOT_STARTED).toLowerCase():
        return (
          <Tooltip title="not started">
            <ErrorIcon style={{ color: 'black', fontSize: '18px', marginLeft: '10px' }} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="not started">
            <ErrorIcon style={{ color: 'black', fontSize: '18px', marginLeft: '10px' }} />
          </Tooltip>
        );
    }
  };
  
  // Enum for step status
  class StepStatus {
    static SUCCESS = 0;
    static FAILED = 1;
    static WARNING = 2;
    static SKIPPED = 3;
    static IN_PROGRESS = 4;
    static NOT_STARTED = 5;
  }
  
