import React, { useState } from "react";

const ReportPagination = ({ dataLength, handlePageNav }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const totalItems = dataLength;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePrevPage = () => {
    const newPage = Math.max(currentPage - 1, 1);
    // const newStartIndex = (newPage - 1) * pageSize;
    // const newEndIndex = Math.min(newStartIndex + pageSize, totalItems);

    setCurrentPage(newPage);
    handlePageNav(newPage);
  };

  const handleNextPage = () => {
    const newPage = Math.min(currentPage + 1, totalPages);
    // const newStartIndex = (newPage - 1) * pageSize;
    // const newEndIndex = Math.min(newStartIndex + pageSize, totalItems);

    setCurrentPage(newPage);
    handlePageNav(newPage);
  };

  const paginationInfo = `Page ${currentPage} out of ${totalPages}`;

  return (
    <div className="pagination-container">
      {totalItems !== 0 && (
        <div className="pagination-info">
          <span className="page-info">{paginationInfo}</span>
          <div className="prev-next-button">
            <button
              className="button-style"
              disabled={currentPage === 1}
              onClick={handlePrevPage}
            >
              Previous
            </button>
            <button
              className="button-style"
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportPagination;
