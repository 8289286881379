import React, { useState } from 'react';
import { Input, Select } from 'antd';
import { authorizationType } from './util/data';
import GlobalLocalDropdown from '../../../../../components/GlobalLocalDropdown';

import gbutton from "../../../../../assets/G-button.svg";
import lbutton from "../../../../../assets/lbutton.svg";
import closeIcon from '../../../../../assets/close-icon-local-data.svg';

const DataDropdownInput = ({
    value,
    paramKey,
    handleClearParamValue,
    handleInputChange,
    handleGlobalLocalFocus,
    showGlobalLocalDropdown,
    placeholder,
    focusedParamKey,
    setGlobalLocalValue,
    handleGlobalLocalBlur
}) => {
    return (
        <div style={{  width: "100%" }}>
            <div style={{ position: "relative", width: "100%" }} className='param-input-value-container'>
                {typeof value === 'object' ? (
                    <div className="input-data-values">
                        {value.data_type === 1 ? (
                            <img src={gbutton} alt='gbutton' />
                        ) : value.data_type === 2 ? (
                            <img src={lbutton} alt='lbutton' />
                        ) : null}
                        <p style={{ color: value.data_type === 1 ? "#991AE1" : (value.data_type === 2 ? "#96A7E6" : "") }}>
                            {value.value ? value.value : value.save_data_name}
                        </p>
                        <img
                        className='input-close-icon'
                            src={closeIcon}
                            width={18}
                            alt="remove file"
                            onClick={() => handleClearParamValue(paramKey)}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                ) : (
                    <>
                        <input
                            placeholder={placeholder}
                            className="param-input-value"
                            value={value}
                            onChange={(e) => handleInputChange(paramKey, e.target.value)}
                            onFocus={() => handleGlobalLocalFocus(paramKey)}
                        />
                        {showGlobalLocalDropdown && focusedParamKey === paramKey && (
                            <div style={{ position: "absolute", top: "40px" }}>
                                <GlobalLocalDropdown
                                    setGlobalLocalValue={setGlobalLocalValue}
                                    onClose={handleGlobalLocalBlur}
                                    
                                // onBlur={() => setShowGlobalLocalDropdown(false)} // Change here to onBlur
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
};

const Authorization = ({
    type,
    setAuthStructure,
    data,
    setData
}) => {
    const [autType, setAuthType] = useState(authorizationType);
    const [showGlobalLocalDropdown, setShowGlobalLocalDropdown] = useState(false);
    const [focusedParamKey, setFocusedParamKey] = useState(null);

    const handleTypeChange = (value) => {
        if (value === 'No Auth') {
            setAuthStructure(null);
        } else {
            const selectedAuth = authorizationType.find(auth => auth.type === value);
            setAuthStructure(selectedAuth);
        }
    };

    const handleInputChange = (key, value) => {
        const updatedAuthType = autType.map(auth => {
            if (auth.type === type) {
            console.log("AUTH6", {
                ...auth,
                [key]: value
            });
                return {
                    ...auth,
                    [key]: value
                };
            }

            // console.log("AUTH4", auth);
            return auth;
        });

        setAuthType(updatedAuthType);
        const selectedAuth = updatedAuthType.find(auth => auth.type === type);
        setAuthStructure(selectedAuth);
    };

    const handleGlobalLocalFocus = (key) => {
        setShowGlobalLocalDropdown(true);
        setFocusedParamKey(key);
    };

    const handleGlobalLocalBlur = () => {
        setShowGlobalLocalDropdown(false);
        setFocusedParamKey(null);

    };

    const setGlobalLocalValue = (obj) => {
 
        setData(prevData => ({
            ...prevData,
            authorization: {
                ...prevData.authorization,
                [focusedParamKey]: obj
            }
        }));
    };

    const handleClearParamValue = (key) => {
        const updatedValue = "";
        setData(prevData => ({
            ...prevData,
            authorization: {
                ...prevData.authorization,
                [key]: updatedValue
            }
        }));
    };

    return (
        <div className='auth-container'>
            <Select style={{ minWidth: '130px', height: "40px" }} placeholder="No Auth" value={type} onChange={handleTypeChange}>
                <Select.Option value="No Auth">No Auth</Select.Option>
                <Select.Option value="Basic">Basic Auth</Select.Option>
                <Select.Option value="Bearer">Bearer Token</Select.Option>
                <Select.Option value="API Key">Api Key</Select.Option>
            </Select>

            {type === 'Basic' ? (
                <>
                    <DataDropdownInput
                        paramKey="username"
                        placeholder="Username"
                        value={data.authorization.username}
                        handleInputChange={handleInputChange}
                        handleClearParamValue={handleClearParamValue}
                        handleGlobalLocalFocus={handleGlobalLocalFocus}
                        showGlobalLocalDropdown={showGlobalLocalDropdown}
                        focusedParamKey={focusedParamKey}
                        setGlobalLocalValue={setGlobalLocalValue}
                        handleGlobalLocalBlur={handleGlobalLocalBlur}
                    />

                    <DataDropdownInput
                        paramKey="password"
                        placeholder="Password"
                        value={data.authorization.password}
                        handleInputChange={handleInputChange}
                        handleClearParamValue={handleClearParamValue}
                        handleGlobalLocalFocus={handleGlobalLocalFocus}
                        showGlobalLocalDropdown={showGlobalLocalDropdown}
                        focusedParamKey={focusedParamKey}
                        setGlobalLocalValue={setGlobalLocalValue}
                        handleGlobalLocalBlur={handleGlobalLocalBlur}
                    />
                </>
            ) : type === "Bearer" ? (
                <DataDropdownInput
                    paramKey="token"
                    placeholder="Token"
                    value={data.authorization.token}
                    handleInputChange={handleInputChange}
                    handleClearParamValue={handleClearParamValue}
                    handleGlobalLocalFocus={handleGlobalLocalFocus}
                    showGlobalLocalDropdown={showGlobalLocalDropdown}
                    focusedParamKey={focusedParamKey}
                    setGlobalLocalValue={setGlobalLocalValue}
                    handleGlobalLocalBlur={handleGlobalLocalBlur}
                />
            ) : type === "API Key" ? (
                <>
                    <DataDropdownInput
                        paramKey="key_name"
                        placeholder="API Key Name"
                        value={data.authorization.key_name}
                        handleInputChange={handleInputChange}
                        handleClearParamValue={handleClearParamValue}
                        handleGlobalLocalFocus={handleGlobalLocalFocus}
                        showGlobalLocalDropdown={showGlobalLocalDropdown}
                        focusedParamKey={focusedParamKey}
                        setGlobalLocalValue={setGlobalLocalValue}
                        handleGlobalLocalBlur={handleGlobalLocalBlur}
                    />
                    <DataDropdownInput
                        paramKey="key_value"
                        placeholder="API Key"
                        value={data.authorization.key_value}
                        handleInputChange={handleInputChange}
                        handleClearParamValue={handleClearParamValue}
                        handleGlobalLocalFocus={handleGlobalLocalFocus}
                        showGlobalLocalDropdown={showGlobalLocalDropdown}
                        focusedParamKey={focusedParamKey}
                        setGlobalLocalValue={setGlobalLocalValue}
                        handleGlobalLocalBlur={handleGlobalLocalBlur}
                    />
                </>
            ) : null
            }
        </div>
    );
};

export default Authorization;
