import { fetch_global, fetch_local } from "../../../services/dataService";

export const FETCH_LOCAL_DATA = "FETCH_LOCAL_DATA";
export const FETCH_LOCAL_DATA_REQUEST = "FETCH_LOCAL_DATA_REQUEST";
export const FETCH_LOCAL_DATA_SUCCESS = "FETCH_LOCAL_DATA_SUCCESS";
export const FETCH_LOCAL_DATA_FAILURE = "FETCH_LOCAL_DATA_FAILURE";

export const FETCH_GLOBAL_DATA = "FETCH_GLOBAL_DATA";
export const FETCH_GLOBAL_DATA_REQUEST = "FETCH_GLOBAL_DATA_REQUEST";
export const FETCH_GLOBAL_DATA_SUCCESS = "FETCH_GLOBAL_DATA_SUCCESS";
export const FETCH_GLOBAL_DATA_FAILURE = "FETCH_GLOBAL_DATA_FAILURE";
export const TRIGGER_GLOBAL_REFRESH = "TRIGGER_GLOBAL_REFRESH";

// Define action creator
export const triggerGlobalRefresh = () => ({
  type: TRIGGER_GLOBAL_REFRESH,
});

export const fetchLocalData = () => {
  return (dispatch) => {
    dispatch(fetchLocalDataRequest());

    return fetch_local()
      .then((response) => {
        if (response.status !== 200 || !response.data) {
          throw new Error("Failed to fetch local data");
        }
        return response.data;
      })
      .then((data) => {
        dispatch(fetchLocalDataSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(fetchLocalDataFailure(error.message));
        throw error;
      });
  };
};

export const fetchLocalDataRequest = () => ({
  type: FETCH_LOCAL_DATA_REQUEST,
});

// Action creator for successful data fetching
export const fetchLocalDataSuccess = (data) => ({
  type: FETCH_LOCAL_DATA_SUCCESS,
  payload: data,
});

export const fetchLocalDataFailure = (error) => ({
  type: FETCH_LOCAL_DATA_FAILURE,
  payload: error,
});

export const fetchGlobalData = () => {
  return (dispatch) => {
    dispatch(fetchGlobalDataRequest());

    return fetch_global()
      .then((response) => {
        if (response.status !== 200 || !response.data) {
          throw new Error("Failed to fetch global data");
        }
        return response.data;
      })
      .then((data) => {
        dispatch(fetchGlobalDataSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(fetchGlobalDataFailure(error.message));
        throw error;
      });
  };
};
export const fetchGlobalDataRequest = () => ({
  type: FETCH_GLOBAL_DATA_REQUEST,
});

export const fetchGlobalDataSuccess = (data) => ({
  type: FETCH_GLOBAL_DATA_SUCCESS,
  payload: data,
});

export const fetchGlobalDataFailure = (error) => ({
  type: FETCH_GLOBAL_DATA_FAILURE,
  payload: error,
});

export const setCurrentTableName = (tableName) => ({
  type: "SET_CURRENT_TABLE_NAME",
  payload: tableName,
});
