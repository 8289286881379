// [create:{
//     instr_type: 400,
//     instr: "group:text",
//     group_intent: "login"
// },
// existing:{
//     instr_type: 401,
//     instr: "group:text",
//     group_id : 1
// }
// ]

export const groupActions = [
    {
        instr_type: 400,
        clickValue: "first",
    },
    {
        instr_type: 401,
        clickValue: "second",
    }
];

function getInstrTypes(actions) {
    return actions.map(action => action.instr_type);
}

const instrTypes = getInstrTypes(groupActions);

export function doesGroupInstrTypeExist(type) {
    return instrTypes.includes(type);
}
