const isValidJavaScript = (script) => {
    try {
        // Create a new Function object to parse the script
        new Function(script);
        return true; // If creation succeeds, the script is valid
    } catch (error) {
        console.error("Syntax error in script:", error);
        return false; // If creation fails, the script is invalid
    }
};

export default isValidJavaScript;
