import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Table } from 'antd';
import closeIcon from '../../../../assets/close-icon.svg';
import search from '../../../../assets/search-icon.svg';
import moment from 'moment';

import './tsLinkedTestcases.css';

const TsLinkedTestcases = ({ open, name, handleClose, loading, dataSource, localDataId }) => {

  const [data, setData] = useState([]);

      const columns = [
        {
            title: (
                <p className='ts-linked-tc-modal-table-title'>Test case</p>
            ),
            dataIndex: 'name',
            render: (name) => (
                <p className='ts-linked-tc-modal-table-column-bold'>{name}</p>
            ),
        },
        {
            title: (
                <p className='ts-linked-tc-modal-table-title'>Created</p>
            ),
            dataIndex: 'created_at',
            render: (record) => (
                <p 
                    className='ts-linked-tc-modal-table-column'
                >
                    {moment(record.created_at).format("DD-MM-YYYY HH:MM:SS")}
                </p>
            ),
        },
        {
            title: (
                <p className='ts-linked-tc-modal-table-title'>Updated</p>
            ),
            dataIndex: 'updated_at',
            render: (updated_at) => (
                <p
                    className='ts-linked-tc-modal-table-column'
                >
                    {moment(updated_at).format("DD-MM-YYYY HH:MM:SS")}
                </p>
            ),
        },
    ];

    useEffect(()=>{
      setData(dataSource);
    },[dataSource])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className="ts-linked-tc-modal">

        <div className='linked-tc-modal-box-header'>
          <h4>Linked Testcases</h4>
          <img onClick={handleClose} src={closeIcon} alt='close-modal' />
        </div>

        <div className='linked-tc-modal-box-search'>
          <h4>{name}</h4>
          <div>
            <img src={search} alt="search" />
            <input placeholder='Search' type="text" />
          </div>
        </div>

        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ y: "440px" }}
          loading={loading}
        />
      </Box>
    </Modal>
  );
};

export default TsLinkedTestcases;