import { Container } from "react-bootstrap";
import LiveTestingScreen from "../../noVncScreen/liveTestingScreen";
import SampleVid from "../../../../components/VideoModel/SampleVideo.mp4";
import VcNav from "./containerNav/VcNav";
import { getVideoUrl } from "../../../../services/testCaseServices";

import { useEffect, useState } from "react";
import { getVideoUrlbyJobId } from "../../../../services/jobsServices";
import { useResponseContext } from "../../context/ResponseContext";


const VideoContainer = ({
  sessionId,
  recordActive,
  prevTestVideo,
  handleRecorderClick,
}) => {
  // const apiUrl = process.env.REACT_APP_API_URL;
  // const videoUrl = getVideoUrl(prevTestVideo);
  // const [videoUrl, setVideoUrl] = useState('');
  const { tsVideoUrl, setTsVideoUrl } = useResponseContext();

  useEffect(() => {

    if (prevTestVideo) {

      // console.log("prevTestVideOo", prevTestVideo);
      const fetchVideoUrl = async () => {
        try {
          const url = await getVideoUrlbyJobId(prevTestVideo);
          setTsVideoUrl(url);
        } catch (error) {
          console.error('Error fetching video URL:', error);
        }
      };

      fetchVideoUrl();
    }
  }, [prevTestVideo, sessionId]);


  // console.log("prevTestVideoCont", tsVideoUrl);

  console.log("sessionIdOnVideo", sessionId)
  return (
    <div
      style={{
        borderLeft: "6px solid #EEEEEE",
        width: "100%",
        height: "100%",
      }}
    >
      <Container>
        <VcNav
          sessionId={sessionId}
          recordActive={recordActive}
          handleRecorderClick={handleRecorderClick}
        />

        {!sessionId ? (
          <>
            <div className="video-container" style={{ marginTop: "0" }}>

              {tsVideoUrl && (
                <video
                  width="750"
                  height="500"
                  autoPlay
                  controls
                  loop
                  muted
                  playsInline
                >
                  <source
                    src={tsVideoUrl}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              padding: "1rem",
              margin: " 1rem",
              // height: "857px",
            }}
            className="outer-live"
          >
            <div style={{ width: "100%" }} className="live-testing-screen">
              <LiveTestingScreen sessionId={sessionId} />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default VideoContainer;
