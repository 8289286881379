import apiService from "../services/apiServices";
import { getQueryParam } from "../utils/query_params";

export const getAllSuites = async () => {
  try {
    const response = await apiService.get("/platform/v1/suites/");
    return response.data;
  } catch (error) {
    console.error("Error fetching all suites:", error);
    throw error;
  }
};

export const createSuiteCall = async (suiteData) => {
  try {
    const response = await apiService.post("/platform/v1/suite/", suiteData);
    return response.data;
  } catch (error) {
    console.error("Error creating suite:", error);
    throw error;
  }
};

export const getSuiteDetails = async (suiteId) => {
  try {
    const response = await apiService.get(`/platform/v1/suites/${suiteId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching suite details:", error);
    throw error;
  }
};

export const updateSuiteGroups = async (suiteId, updatedGroups) => {
  try {
    const response = await apiService.patch(
      `/platform/v1/suites/${suiteId}`,
      updatedGroups
    );
    return response.data;
  } catch (error) {
    console.error("Error updating suite groups:", error);
    throw error;
  }
};

//Function to get all test cases for suites
// export const getAllTestcasesForSuites1 = async () => {
//   try {
//     const response = await apiService.get("/platform/testcases/");
 
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching test cases:", error);
//     throw error;
//   }
// };

// export const getAllTestcasesForSuites = async (page, search) => {
//   try {
//     let url = `/platform/testcases/?page=${page}`;

//     if (search) {
//       url += `&search=${search}`;
//     }

//     const response = await apiService.get(url);
//     if (response.status !== 200) {
//       throw new Error("Network response was not ok");
//     }

//     return response.data;
//   } catch (error) {
//     console.error("An error occurred:", error);
//     throw error;
//   }
// };

// Example service function assuming it supports pagination
// export const getAllTestcasesForSuitesSearch = async (page = 1, search = "") => {
//   try {
//     const response = await apiService.get("/platform/testcases/", {
//       params: {
//         page,
//         search,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching test cases:", error);
//     throw error;
//   }
// };

// export const getAllTestcasesForSearch = async (page = 1, search = "") => {
//   try {
//     const response = await apiService.get("/platform/testcases/", {
//       params: {
//         page,
//         search,
//       },
//     });
 
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching test cases:", error);
//     throw error;
//   }
// };

export const fetchTestcases = async (page = 0, search = null) => {
  try {
    let url = getQueryParam("/platform/testcases/", page, search);

    const response = await apiService.get(url);

    if (response.status !== 200) {
      throw new Error("Failed to fetch test cases");
    }

 
    return response.data;
  } catch (error) {
    console.error("Error fetching test cases:", error);
    throw error;
  }
};

// Function to delete a suite
export const deleteSuite = async (suiteId) => {
  try {
    const response = await apiService.delete(`/platform/v1/suites/${suiteId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting suite:", error);
    throw error;
  }
};

// Function to get suite details by suiteId
export const getSuiteDetailsById = async (suiteId) => {
  try {
    const response = await apiService.get(`/platform/v1/suites/${suiteId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching suite details by ID:", error);
    throw error;
  }
};

export const getAllTestCases = async (page = 1, search = "") => {
  try {
    const response = await apiService.get("/v1/get_all_testcases/", {
      params: {
        page,
        search,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching test cases:", error);
    throw error;
  }
};

export const suiteExecutions = async (suiteData) => {
  try {
    const response = await apiService.post(
      `/platform/v1/suite_executions`,
      suiteData
    );
    if (response && response.data) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.error("Error suite executions:", error);
    return error;
  }
};

export const getExecutionHistory = async (suiteId) => {
  try {
    const response = await apiService.get(
      `/platform/v1/suite_executions/history/?suite_id=${suiteId}`
    );
    if (response && response.data) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.error("Error fetching suite execution history:", error);
    return error;
  }
};

// export const getSuiteReports = async (page, search) => {
//   try {
//     let url = getQueryParam("/platform/v1/suite/reports", page, search);

//     const response = await apiService.get(url);

//     if (response && response.data) {
//       return response.data;
//     }
//     return response;
//   } catch (error) {
//     console.error("Error fetching suite reports:", error);
//     return error;
//   }
// };
export const getSuiteReports = async (page = 0, search = null) => {
  try {
    let url = getQueryParam("/platform/v1/suite/reports", page, search);

    const response = await apiService.get(url);

    if (response.status !== 200) {
      throw new Error("Failed to fetch test cases");
    }
 
    return response.data;
  } catch (error) {
    console.error("Error fetching test cases:", error);
    throw error;
  }
};

export const getSuiteReportDetails = async (executionId) => {
  try {
    const response = await apiService.get(
      `/platform/v1/suite/report/${executionId}/details`
    );
    if (response && response.data) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.error("Error fetching suite report details:", error);
    return error;
  }
};

export const getSuiteReportData = async (postData) => {
  try {
    const response = await apiService.post(
      `/platform/v1/suite/report`,
      postData
    );
    if (response && response.data) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.error("Error fetching suite report details:", error);
    return error;
  }
};

export const getSuiteProgress = async (postData) => {
  try {
    const response = await apiService.post(
      `/platform/v1/suite/progress`,
      postData
    );
    if (response && response.data) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.error("Error fetching suite progress:", error);
    return error;
  }
};

export const getJobReports = async (jobId) => {
  try {
    const response = await apiService.get(`/platform/reports/`, {
      params: {
        job_id: jobId,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.error("Error fetching job reports:", error);
    return error;
  }
};

const SCHEDULES_ENDPOINT = "/platform/v1/schedules";

// Create a new schedule
export const createSchedule = async (scheduleData) => {
  try {
    const response = await apiService.post(SCHEDULES_ENDPOINT, scheduleData);
    return response;
  } catch (error) {
    console.error("Error creating schedule:", error);
    return false;
  }
};

// Update an existing schedule
export const updateSchedule = async (schedulerId, scheduleData) => {
  try {
    const response = await apiService.put(
      `${SCHEDULES_ENDPOINT}/${schedulerId}`,
      scheduleData
    );
    return response;
  } catch (error) {
    console.error(`Error updating schedule with ID ${schedulerId}:`, error);
    return false;
  }
};

// Delete a schedule
export const deleteSchedule = async (scheduleId) => {
  try {
    const response = await apiService.delete(
      `${SCHEDULES_ENDPOINT}/${scheduleId}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error deleting schedule with ID ${scheduleId}:`, error);
    throw error;
  }
};

// toggle an existing schedule
export const toggleSchedule = async (scheduleId, enabled) => {
  try {
    const response = await apiService.patch(
      `${SCHEDULES_ENDPOINT}/${scheduleId}/toggle`,
      null,
      {
        params: {
          enabled: enabled,
        },
      }
    );
    return response?.data ? response.data : response;
  } catch (error) {
    console.error(`Error updating schedule with ID ${scheduleId}:`, error);
    return error?.response?.data ? error.response.data : error;
  }
};

// get existing schedule by id
export const fetchScheduleById = async (scheduleId) => {
  try {
    const response = await apiService.get(
      `${SCHEDULES_ENDPOINT}/${scheduleId}`
    );
    return response;
  } catch (error) {
    console.error(`Error fetching schedule with ID ${scheduleId}:`, error);
    return error;
  }
};
