import React, { useRef, useEffect, useState } from "react";
import "./ExecutionModal.css"; // Import your CSS file for styling
import close from "../../assets/EWT/close-button.svg";
// import Windows from "../../assets/OSLogo/windows.svg";
import Linux from "../../assets/OSLogo/Linux.svg";
// import Mac from "../../assets/OSLogo/Mac.svg";
import Chrome from "../../assets/BrowserLogos/Chrome.svg";
import FireFox from "../../assets/BrowserLogos/Firefox.svg";
// import Safari from "../../assets/BrowserLogos/Safari.svg";
// import CheckBox from "../../components/select/select";
import ChromeBlack from "../../assets/BrowserLogos/Chrome_Black_text.svg";
import FirefoxBlack from "../../assets/BrowserLogos/Firefox_Black_text.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { startExecutionTestCase } from "../../services/executionServices";
import { suiteExecutions } from "../../services/suitesServices";

const ExecutionModalLinuxOnly = ({
  open,
  handleClose,
  TestCase,
  setActiveKey,
  mode,
}) => {
  const navigate = useNavigate();
  const modalRef = useRef();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isExecuteDisabled, setExecuteDisabled] = useState(true);
  const [chromeSelected, setChromeSelected] = useState(true);
  const [firefoxSelected, setFireFoxSelected] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(false);

  useEffect(() => {
    if (!chromeSelected && !firefoxSelected) {
      setExecuteDisabled(true);
    } else {
      setExecuteDisabled(false);
    }
  }, [chromeSelected, firefoxSelected]);

  const ExecuteTestHandler = () => {
 
    const config = [];

    if (chromeSelected) {
      config.push({
        browser: "chrome",
        platform: "linux",
        browser_version: "91",
      });
    }

    if (firefoxSelected) {
      config.push({
        browser: "firefox",
        platform: "linux",
        browser_version: "109",
      });
    }
    const payload = {
      testcase_id: TestCase,
      config: config,
    };

 
    setShowCircularProgress(true);
    startExecution(payload)
      .then((responseData) => {
 
        setActiveKey("3");
      })
      .catch((error) => {
        console.error("Error starting execution:", error);
      });
  };
  const startExecution = async (payload) => {
    try {
      const postData = {};
      let responseData = {};
      switch (mode) {
        case "suite":
          postData.suite_id = payload.testcase_id;
          postData.browsers = payload.config;
          responseData = await suiteExecutions(postData);
          break;
        case "testing":
        case "testcase":
          postData.testcase_id = payload.testcase_id;
          postData.config = payload.config;
          responseData = await startExecutionTestCase(payload);
          break;

        default:
          break;
      }

      // const responseData = await response.json();
      // Handle response data if needed
 
    } catch (error) {
      console.error("Error starting execution:", error);
    } finally {
      setShowCircularProgress(false);
      handleClose();
    }
  };

  const toggleFirefoxSelect = () => {
    setFireFoxSelected(!firefoxSelected);
  };

  const toggleChromeSelect = () => {
    setChromeSelected(!chromeSelected);
  };

  const ResetAllfieldsVal = () => {
    setChromeSelected(false);
    setFireFoxSelected(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, handleClose]);

  return (
    <>
      {open && (
        <div className="modal-overlay">
          <div ref={modalRef}>
            <div className="modal-content">
              <div className="upper-loc">
                <div className="text-m"> Test Execution {TestCase}</div>
                <div className="img-close-modal-exec">
                  <img src={close} onClick={handleClose} />
                </div>
              </div>
              <div className="os-container-cont">
                <div className="os"> Operating Systemss</div>

                <div className="operating-system-container">
                  <div className="browser-choice">
                    {/* <CheckBox select={linux} selecthandler={selectLinux} /> */}
                    <img src={Linux} alt="linux-logo" />
                  </div>
                </div>
              </div>
              <div className="browsers-container-cont">
                <div className="os"> Browsers </div>

                <div className="linux-cont">
                  <div className="browser-version-container">
                    <div
                      onClick={toggleChromeSelect}
                      className={
                        chromeSelected
                          ? "chrome-selected"
                          : "chrome-not-selected"
                      }
                    >
                      <img
                        className="logo-alignment"
                        src={chromeSelected ? ChromeBlack : Chrome}
                        alt="chrome-logo"
                      />
                    </div>
                  </div>

                  <div className="browser-version-container">
                    <div
                      onClick={toggleFirefoxSelect}
                      className={
                        firefoxSelected
                          ? "firefox-selected"
                          : "firefox-not-selected"
                      }
                    >
                      <img
                        className="logo-alignment"
                        src={firefoxSelected ? FirefoxBlack : FireFox}
                        alt="firefox-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="button-container-exec">
                <div className="clear-fields">
                  <button onClick={ResetAllfieldsVal} className="buttons-manip">
                    Reset
                  </button>
                </div>

                <div className="manip-buttons">
                  <button onClick={handleClose} className="buttons-manip">
                    Cancel
                  </button>
                  <button
                    onClick={ExecuteTestHandler}
                    className={
                      isExecuteDisabled
                        ? "buttons-manip-exect-disabled"
                        : "buttons-manip-exect"
                    }
                  >
                    {showCircularProgress ? (
                      <CircularProgress
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#ffffff",
                        }}
                      />
                    ) : (
                      <span>Execute</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExecutionModalLinuxOnly;
