import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Menu } from "antd";
import SeCard from "./seCard";
import dragGroup from "../../assets/group-drag.svg";
import parallel from "../../assets/parallel-execution.svg";
import arrowDown from "../../assets/arrow-down.svg";
import groupDelete from "../../assets/group-delete-icon.svg";
import addIcon from "../../assets/add-icon.svg";
import addGroup from "../../assets/add-group.svg";
import TestCasesModal from "./TestCasesModal";
import DeleteGroupModal from "./DeleteGroupModal";

const AUTO_SCROLL_THRESHOLD = 50;
const AUTO_SCROLL_SPEED = 10;

const SeGroup = ({
  group,
  groupIndex,
  handleDrop,
  handleGroupDrop,
  handleCardDelete,
  handleGroupDelete,
  handleAddGroup,
  handleMode,
  selectedCards,
  setSelectedCards,
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [executionMode, setExecutionMode] = useState(
    group && group.is_parallel ? "Parallel Execution" : "Serial Execution"
  );
  const containerRef = useRef(null);
  const modalRef = useRef(null);
  const deleteGroupModalRef = useRef(null);

  useEffect(() => {
    if (group) {
      setExecutionMode(
        group?.is_parallel ? "Parallel Execution" : "Serial Execution"
      );
    }
  }, [group]);

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
    autoScroll(e);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const onDragLeave = () => {
    setIsDraggingOver(false);
  };

  const onGroupDragStart = (e) => {
    e.dataTransfer.setData("sourceGroupIndex", groupIndex);
    e.dataTransfer.setDragImage(e.currentTarget, 0, 0);
  };

  const onDrop = (e) => {
    e.preventDefault();
    const sourceGroupIndex = e.dataTransfer.getData("sourceGroupIndex");
    const sourceTestIndex = e.dataTransfer.getData("sourceTestIndex");

    if (sourceTestIndex !== "") {
      handleDrop(sourceGroupIndex, sourceTestIndex, groupIndex, 0);
    } else {
      handleGroupDrop(sourceGroupIndex, groupIndex);
    }
    setIsDraggingOver(false);
    resetDraggableAttributes();
  };

  const handleMouseDown = (e) => {
    e.currentTarget
      .closest(".se-group-container")
      .setAttribute("draggable", true);
  };

  const handleMouseUp = (e) => {
    e.currentTarget
      .closest(".se-group-container")
      .setAttribute("draggable", false);
  };

  const resetDraggableAttributes = () => {
    const groupContainers = document.querySelectorAll(".se-group-container");
    groupContainers.forEach((container) => {
      container.setAttribute("draggable", false);
    });
  };

  useEffect(() => {
    return () => {
      setIsDraggingOver(false);
    };
  }, []);

  const autoScroll = (e) => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;

    const { clientY } = e;
    // const viewportHeight = window.innerHeight;
    const containerRect = scrollContainer.getBoundingClientRect();

    if (clientY < containerRect.top + AUTO_SCROLL_THRESHOLD) {
      scrollContainer.scrollBy(0, -AUTO_SCROLL_SPEED);
    } else if (clientY > containerRect.bottom - AUTO_SCROLL_THRESHOLD) {
      scrollContainer.scrollBy(0, AUTO_SCROLL_SPEED);
    }
  };

  const toggleTestCaseModal = (option) => {
    // setCreateModalOpen(true);
    if (modalRef && modalRef.current) modalRef.current.handleModal(option);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleMenuClick = (e) => {
    setExecutionMode(e.key);
    setDropdownVisible(false);
    handleMode(groupIndex, e.key);
  };

  const handleModalData = (modalData) => {
 
    if (modalData && modalData.testCases && modalData.testCases.length) {
      const testCases = modalData.testCases.map((testCasedata) => ({
        id: testCasedata.test_case_id,
        name: testCasedata.name,
      }));
      setSelectedCards(groupIndex, testCases);
      toggleTestCaseModal(false);
    }
  };

  const showConfirmModal = () => {
    if (deleteGroupModalRef && deleteGroupModalRef.current) {
      deleteGroupModalRef.current.openModal();
    }
  };

  const handleConfirmation = (response) => {
    if (response) {
      handleGroupDelete(groupIndex);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Parallel Execution">Parallel Execution</Menu.Item>
      <Menu.Item key="Serial Execution">Serial Execution</Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`se-group-container ${isDraggingOver ? "dragging-over" : ""}`}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      draggable={false}
      ref={containerRef}
    >
      <img
        src={dragGroup}
        alt="drag-group"
        className="drag-group-icon"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onDragStart={onGroupDragStart}
      />
      <div className="main">
        <div className="header">
          <div className="header-left">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src={parallel} alt="icon" />
              <p>{executionMode}</p>
              <img
                src={arrowDown}
                alt="more"
                onClick={toggleDropdown}
                style={{ cursor: "pointer" }}
              />
              <Dropdown
                overlay={menu}
                visible={dropdownVisible}
                onVisibleChange={setDropdownVisible}
              >
                <div />
              </Dropdown>
            </div>
          </div>
          <div className="header-right">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              }}
              onClick={() => toggleTestCaseModal(true)}
            >
              <p>Add Test case(s)</p>
              <img src={addIcon} alt="add testcase" />
            </div>
            <img
              src={groupDelete}
              alt="Delete"
              onClick={showConfirmModal}
              style={{ cursor: "pointer" }}
              // opacity: deletable ? 1 : 0.5
            />
            <DeleteGroupModal
              groupData={group}
              ref={deleteGroupModalRef}
              handleActions={handleConfirmation}
            />
          </div>
        </div>
        <div className={`se-cards-container`}>
          {group.test_cases.length === 0 ? (
            <div className="empty-message">Drag and Drop test case(s)</div>
          ) : (
            group.test_cases.map((test, index) => (
              <SeCard
                key={test.id}
                testCase={test}
                handleDrop={handleDrop}
                handleCardDelete={handleCardDelete}
                groupIndex={groupIndex}
                index={index}
              />
            ))
          )}
        </div>
      </div>
      <div
        className="add-group-container"
        onClick={() => handleAddGroup(groupIndex)}
      >
        <img src={addGroup} alt="Add" />
      </div>

      <TestCasesModal
        ref={modalRef}
        selectedTestCases={selectedCards}
        handleActions={handleModalData}
      />
    </div>
  );
};

export default SeGroup;

// import SelectTestCases from "./SelectTestCases"; // Import the SelectTestCases component

// const [createModalOpen, setCreateModalOpen] = useState(false);
// const [createSuite] = Form.useForm();

// onTestCasesUpdate,
// deletable,
// groupIndex,
// enabledGroupOrder,

// {
//   /* <Modal
//         title="Select test cases"
//         open={createModalOpen}
//         onCancel={handlecloseCreateModal}
//         footer={null}
//         width={800}
//         style={{
//           top: "50%",
//           transform: "translateY(-50%)",
//         }}
//       >
//         <Form
//           form={createSuite}
//           layout="vertical"
//           onFinish={onFinishCreateSuite}
//           style={{ padding: "2rem 1rem 1rem 1rem" }}
//           initialValues={{ testCases: [] }}
//         >
//           <Form.Item
//             name="testCases"
//             label="Test Cases"
//             rules={[
//               {
//                 required: true,
//                 message: "Please select at least one test case",
//               },
//             ]}
//           >
//             <SelectTestCases
//               value={selectedCards}
//               onContinue={handleContinue}
//               onHandleCloseModal={handlecloseCreateModal}
//               existingTestCases={group.testCase}
//             />
//           </Form.Item>
//         </Form>
//       </Modal> */
// }

// onClick={() =>
//   deleteGroupModalRef &&
//   deleteGroupModalRef.current &&
//   deleteGroupModalRef.current.openModal()
// }
// onClick={() => handleGroupDelete(group.order)}

// const handlecloseCreateModal = () => {
//   // setCreateModalOpen(false);
//   if (modalRef && modalRef.current) modalRef.current.handleModal(false);
// };

// const onFinishCreateSuite = (values) => {
//   console.log("Received values from form: ", values);
//   handlecloseCreateModal();
//   // You can further process the values here and handle the addition of test cases
// };

// const handleContinue = (newTestCases) => {
//   onTestCasesUpdate(group.order, newTestCases);
// };
