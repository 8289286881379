import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Modal,
  Tooltip,
  // Select,
  // DatePicker,
  // TimePicker,
} from "antd";
import { Switch } from "antd";
import Table from "antd/lib/table";
import { Container } from "react-bootstrap";
import Moment from "react-moment";
import searchIcon from "../../assets/magnify.svg";
import play from "../../assets/table/table-play-icon.svg";
import wait from "../../assets/table/table-waitlist-icon.svg";
import deleteIcon from "../../assets/table/table-delete-icon.svg";
import { useNavigate } from "react-router-dom";
import TestCasesModal from "./suiteEditor/components/TestCasesModal";
import ScheduleModal from "./ScheduleModal";
import styled from "styled-components";
import addIcon from "../../assets/add_icon.svg";
import { toggleSchedule } from "../../services/suitesServices";
import moment from "moment";
// import { toggleSchedule } from "../../services/suitesServices";
// import { useSelector } from "react-redux";
// import addScheduler from "./assets/addScheduler.svg";
// import viewScheduler from "./assets/viewScheduler.svg";

import "./Suites.css";
//import { Tooltip,  } from "antd";
import { useExecuteTest } from "./useExecuteTest"; // Adjust the path as needed
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import {
  getAllSuites,
  createSuiteCall,
  fetchTestcases,
  deleteSuite,
} from "../../services/suitesServices";
//import { toast } from "react-toastify";
import ExecutionModalLinuxOnly from "../Testcase/ExecutionModalLinuxOnly";

const StyledSwitch = styled(Switch)`
  &&.ant-switch {
    ${"" /* background-color: #fff; */}
    height: 14px;
    width: 30px;
    padding: 0;
  }

  &&.ant-switch-checked {
    background-color: #b3c3e7;
  }

  &&.ant-switch-checked:hover {
    background-color: #b3c3e7;
  }

  &&.ant-switch .ant-switch-handle::before {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #0036af;
    transform: translate(-1.252px, -5.5px);
    ${"" /* transform: translateX(-1px) */}
  }

  &&.ant-switch.ant-switch-inner {
    display: none;
    height: 10px;
  }
`;

const Suites = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  const totalItems = data.length;
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalItems / pageSize)
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [schedulerMode, setschedulerMode] = useState("create");
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchDataRan = useRef(false);
  const [shortSearchError, setShortSearchError] = useState(false);
  const [suitesMap, setSuitesMap] = useState({});
  const modalRef = useRef(null);
  const scheduleModalRef = useRef(null);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [mode, setMode] = useState("suite");
  const [executeModalOpen, setExecuteModalOpen] = useState(false);
  const [selectedRecordForExecution, setSelectedRecordForExecution] =
    useState(null);

  // const selectedTimezone = useSelector(
  //   (state) => state.timezone.selectedTimezone
  // );

  const getSuites = async () => {
    try {
      setLoading(true);
      const response = await getAllSuites();
      const suites = response.suites;
      const suitesMap = suites.reduce((map, suite, index) => {
        map[index + 1] = suite.id; // Use index + 1 as key and suite.id as value
        return map;
      }, {});

      setSuitesMap(suitesMap);
      setData(suites);
      setFilteredData(suites);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching suites:", error);
      setLoading(false);
    }
  };

  const handleSchedulerUpdate = () => {
    getSuites();
  };
  useEffect(() => {
    getSuites();
  }, []);

  const handleSchedulerToggle = async (scheduler, value) => {
    if (!scheduler?.id) return;
    if (!scheduler?.next_run) {
      toast.error("Expired! please reschedule again.");
      return;
    }

    const response = await toggleSchedule(scheduler.id, value);

    if (response && response?.message) {
      toast.success(response.message);
      handleSchedulerUpdate();
    } else {
      toast.error("Failed to update schedule");
    }
  };

  useEffect(() => {
    if (!fetchDataRan.current) {
      getSuites();
      fetchDataRan.current = true;
    }
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  const handleSuiteClick = async (id) => {
    try {
      // Fetch all suites again
      const response = await getAllSuites();
      const suites = response.suites;

      // Find the clicked suite
      const clickedSuite = suites.find((suite) => suite.id === id);

      if (clickedSuite) {
        // Remove the clicked suite from the list and place it at the top
        const updatedData = suites.filter((suite) => suite.id !== id);
        setData([clickedSuite, ...updatedData]);

        // Navigate to the Suite page with the appropriate state
        navigate(`/Suites/${id}`, {
          state: { SuiteName: clickedSuite.name },
        });
      }
    } catch (error) {
      console.error("Error handling suite click:", error);
    }
  };
  // useEffect(() => {
  //   if (location.state?.SuiteName) {
  //     // Fetch all suites again
  //     getSuites();
  //   }
  // }, [location.state?.SuiteName]);
  // const { executeTestHandler, showCircularProgress } = useExecuteTest(
  //   mode,
  //   setActiveKey
  // );

  const handleExecuteClick = (record) => {
    // if (record) {
    //   setSelectedRecord(record);
    //   // Assuming `TestCase` is available as `record.id` or another identifier
    //   executeTestHandler(record.id, true, false); // Adjust the `chromeSelected` and `firefoxSelected` as needed
    // }
    setSelectedRecordForExecution(record);
    setExecuteModalOpen(true);
  };
  const handleExecutionModalClose = () => {
    setExecuteModalOpen(false);
    setSelectedRecordForExecution(null);
    toast.success("Execution has started", {
      position: "bottom-right", // Position the toast at the bottom-right
      autoClose: 5000, // Auto-close after 5 seconds
      hideProgressBar: false, // Show progress bar
    });
  };

  //Column
  const columns = [
    {
      title: <div className="column-title"> Suite Name</div>,
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <p
          style={{ cursor: "pointer", color: "#0036af" }}
          onClick={() => {
            handleSuiteClick(record.id); // Log to console
            navigate(`/Suites/${record.id}`, {
              state: { SuiteName: record.name },
            });
          }}
        >
          {record.name}
        </p>
      ),
    },
    {
      title: <div className="column-title"> Scheduler</div>,
      dataIndex: "",
      key: "b",

      render: (text, record) => (
        <div className="modal-option-container">
          {record?.schedulers &&
          record?.schedulers.length &&
          record?.schedulers[0].id ? (
            <div
              className="modal-btn-common modal-edit-schedule"
              onClick={() => handleCreateEditSchedule(record, "edit")}
            >
              View Schedule
            </div>
          ) : (
            <div
              className="modal-btn-common modal-add-schedule"
              onClick={() => handleCreateEditSchedule(record, "create")}
            >
              <img src={addIcon} alt="add_schedule" />
              <span>Add Schedule</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "enabled",
      width: 50,

      render: (text, record) => (
        <>
          {record?.schedulers &&
            record?.schedulers.length > 0 &&
            !!record?.schedulers[0].id && (
              <StyledSwitch
                checked={record?.schedulers[0].active}
                // style={{}}
                onChange={(value) =>
                  handleSchedulerToggle(record.schedulers[0], value)
                }
              />
            )}
        </>
      ),
    },
    {
      title: <div className="column-title"> Frequency</div>,
      dataIndex: "",
      key: "frequency",

      render: (record) => (
        <>
          {record?.schedulers &&
            record?.schedulers.length > 0 &&
            !!record?.schedulers[0].id &&
            record?.schedulers[0]?.active && (
              <div>
                {record.schedulers[0]?.frequency !== "Custom" ? (
                  <p className="suite-schedule-frequency schedule-frequency-label">
                    {record.schedulers[0].frequency}
                  </p>
                ) : (
                  record.schedulers[0]?.next_run && (
                    <p className="suite-schedule-frequency schedule-frequency-label">
                      Every {` `}
                      {/* {moment(record.schedulers[0].next_run).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )} */}
                    </p>
                  )
                )}
                {record.schedulers[0]?.next_run_epoch && (
                  <p className="suite-schedule-frequency schedule-frequency-date-time">
                    Next Run: {` `}
                    {moment
                      .unix(record.schedulers[0]?.next_run_epoch)
                      .format("DD/MM/YYYY HH:mm:ss")}
                  </p>
                )}
              </div>
            )}
        </>
      ),
    },
    {
      title: <div className="column-title"> Created</div>,
      dataIndex: "createdAt",
      key: "c",
      render: (text, record) => (
        <Moment format="DD/MM/YYYY HH:mm:ss">{record.createdAt}</Moment>
        // <p>{record.name}</p>
      ),
      sorter: (a, b) => b.created_at - a.created_at,
    },
    {
      title: <div className="column-title">Action</div>,
      dataIndex: "e",
      width: 180,
      key: "e",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px", // Space between icons
          }}
        >
          <Tooltip title="Execute" placement="top">
            <Button
              className="table-button btn d-flex"
              type="primary"
              onClick={() => handleExecuteClick(record)}
              style={{ padding: 0, border: "none", background: "transparent" }} // Remove default button styling
            >
              <img
                src={play}
                alt="execute-testcase"
                style={{ width: "20px", height: "20px" }} // Adjust size as needed
              />
            </Button>
          </Tooltip>
          <Tooltip title="Execution History" placement="top">
            <Button
              className="table-button btn d-flex"
              type="danger"
              onClick={() => execHistory(record)}
              style={{ padding: 0, border: "none", background: "transparent" }}
            >
              <img
                src={wait}
                alt="wait"
                style={{ width: "20px", height: "20px" }}
              />
            </Button>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <Button
              className="table-button btn d-flex"
              type="default"
              onClick={() => doDelete(record)}
              style={{ padding: 0, border: "none", background: "transparent" }}
            >
              <img
                src={deleteIcon}
                alt="delete"
                style={{ width: "20px", height: "20px" }}
              />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  //Navigation
  const handleStop = () => {};
  const execHistory = (record) => {
    handleStop(record);

    // Navigate to the Suite page with a query parameter to set the active tab
    navigate(`/suites/${record.id}?tab=2`);
  };

  //Pagination
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Pagination handlers
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    scrollToTop();
  };
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    scrollToTop();
  };
  // Pagination display information
  const paginationInfo = `Page ${currentPage} out of ${totalPages}`;

  // Filter System
  // const testcases = {props}
  const searchFilterhandler = (event) => {
    const searchTerm = event.target.value.toLowerCase(); // Convert to lower case for case-insensitive search
    setSearchTerm(searchTerm);

    if (searchTerm.length >= 3) {
      // Ensure search term is at least 3 characters long
      setShortSearchError(false);
      const filtered = data.filter(
        (item) => item.name.toLowerCase().includes(searchTerm) // Assuming you're searching by name
      );
      setFilteredData(filtered);
    } else {
      setShortSearchError(true);
    }
  };
  useEffect(() => {
    if (searchTerm.length >= 3) {
      // Ensure search term is valid
      const filtered = data.filter(
        (item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()) // Case-insensitive search
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data); // Show all data if search term is too short
    }
  }, [data, searchTerm]);

  const handleCreateEditSchedule = (record, mode) => {
    setSelectedRecord({ ...record });
    setschedulerMode(mode);
    scheduleModalRef.current.handleModal(true);
  };

  //CreateModal
  const handleCreateModal = () => {
    // setCreateModalOpen(true);
    modalRef.current.handleModal(true);
  };
  const handlecloseCreateModal = () => {
    modalRef.current.handleModal(false);
    // setCreateModalOpen(false);
    // createSuite.resetFields();
  };

  const onFinishCreateSuite = async (value) => {
    //console.log("selectedoptions:", filteredOptions);
    try {
      // Call the createSuite function from the service

      const testCases = value.testCases.map((testCasedata) => ({
        id: testCasedata.test_case_id,
        name: testCasedata.name,
      }));

      // const testCases = value.testCases.map(tc => ({
      //   id: 1,
      //   name: "string",
      // }));

      const newSuite = await createSuiteCall({
        name: value.suiteName,
        description: value.suiteName,
        groups: [
          {
            is_parallel: true,
            test_cases: testCases,
          },
        ],
      });
      //console.log("New suite created:", newSuite);
      // Handle any additional logic after successful suite creation
      // For example, close the modal or trigger a reload of suite data

      const newData = {
        suiteId: data.length + 1,
        name: value.suiteName,
        associatedTestCases: value.testCases,
        createdAt: new Date(),
        schedulers: null,
        enabled: false,
      };

      setData([...data, newData]);

      handlecloseCreateModal();

      navigate(`/Suites/${newSuite.id}`, {
        state: { SuiteName: value.suiteName },
      });
    } catch (error) {
      console.error("Error creating suite:", error);
      // Handle error cases, such as displaying an error message to the user
    }
  };

  const handleModalData = (modalData) => {
    onFinishCreateSuite(modalData);
  };

  const toggleModalSchedule = async (isEnabled) => {
    // const response = await toggleSchedule(schedulerId, isEnabled);
    // console.log(response);
  };

  // DeleteModal
  const doDelete = async (record) => {
    try {
      await deleteSuite(record.suiteId);
      //message.success('Suite deleted successfully');
      //fetchSuites(); // Refresh the list after deletion
    } catch (error) {
      //message.error('Error deleting suite');
    } finally {
      setLoading(false);
      setDeleteModalOpen(false);
      setDeleteRecordId(null);
    }
    setDeleteModalOpen(true);

    setDeleteRecordId(record.suiteId);
  };

  return (
    <Container>
      <div className="App">
        <div style={{ backgroundColor: "FAFAFA" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              padding: "20px",
              backgroundColor: "#FAFAFA",
            }}
          >
            <div className="total-upper-search-container">
              <div className="upper-search-container">
                <Form.Item>
                  <div className="search-container">
                    <Input
                      type="text"
                      placeholder="Search"
                      className="forms"
                      style={{ paddingLeft: "40px", height: "2.5rem" }}
                      onChange={searchFilterhandler}
                    />
                    <img
                      src={searchIcon}
                      alt="search"
                      className="search-icon"
                    />
                    {shortSearchError && (
                      <div className="search-container-notification">
                        <p>should have at least 3 characters</p>
                      </div>
                    )}
                  </div>
                </Form.Item>
              </div>
            </div>

            <div style={{ paddingRight: "30px" }}>
              <Button className="r" onClick={handleCreateModal}>
                + NEW SUITE
              </Button>
            </div>
          </div>
          <div>
            <Table
              className="table"
              loading={loading}
              columns={columns}
              dataSource={filteredData}
              pagination={false}
              rowKey="suiteId"
            />
          </div>

          {/* Pagination */}
          {totalItems !== 0 && (
            <div className="pagination-info">
              <span className="page-info">{paginationInfo}</span>
              <div className="prev-next-button">
                <Button
                  className="button-style"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  Previous
                </Button>
                <Button
                  className="button-style"
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <>
        {executeModalOpen && (
          <ExecutionModalLinuxOnly
            mode={"suite"}
            open={executeModalOpen}
            handleClose={handleExecutionModalClose}
            TestCase={selectedRecordForExecution?.id}
            //setActiveKey={setActiveKey}
          />
        )}
        {/* delete Modal */}
        <Modal
          title={<div style={{ fontSize: "20px" }}>Delete Suite</div>}
          open={DeleteModalOpen}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
          footer={[
            <Button key="back" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                backgroundColor: "#ef4444",
                borderColor: "#ef4444",
                color: "#fff",
              }}
              onClick={() => {
                const index = data.findIndex(
                  (user) => user.suiteId === deleteRecordId
                );
                if (index !== -1) {
                  const newData = [
                    ...data.slice(0, index),
                    ...data.slice(index + 1),
                  ];
                  setData(newData);
                } else {
                  console.log("suite not found:");
                }
                setDeleteRecordId(null);
                setDeleteModalOpen(false);
              }}
            >
              Delete
            </Button>,
          ]}
        >
          <p
            style={{
              fontSize: "16px",
              color: "#667085",
              padding: " 2rem 1rem",
            }}
          >
            Are you sure want to delete this suite?
          </p>
        </Modal>
        {/* Scheduler Modal */}
        <ScheduleModal
          ref={scheduleModalRef}
          selectedRecord={selectedRecord}
          schedulerMode={schedulerMode}
          handleSchedulerUpdate={handleSchedulerUpdate}
        />
        {/* Create New Suite */}

        <TestCasesModal
          ref={modalRef}
          isNew={true}
          handleActions={handleModalData}
        />
      </>
    </Container>
  );
};

export default Suites;
