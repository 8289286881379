import React, { useState } from "react";
import { Tabs } from "antd";
import JsonFormatter from "./JsonFormatter";
import ScrollContainer from "../../../../../components/ScrollContainer";
import { useResponseContext } from "../../../context/ResponseContext";

const DisplayHTMLResponse = ({ text, handleCopiedValue }) => {
  return (
    <div
      className="display-container"
      onClick={() => handleCopiedValue("", text)}
    >
      {text}
      <div>Click to copy</div>
    </div>
  );
};

const Response = ({ handleCopiedValue }) => {
  const [activeTab, setActiveTab] = useState("2");
  const { responseData } = useResponseContext();

  return (
    <div className="response-tab-container">
      <Tabs defaultActiveKey="2" onChange={setActiveTab}>
        <Tabs.TabPane tab="Headers" key="1">
          {responseData && responseData?.headers ? (
            <ScrollContainer height={"450px"}>
              <JsonFormatter
                json={responseData.headers ? responseData.headers : {}}
                showArrow={false}
                handleCopiedValue={handleCopiedValue}
              />
            </ScrollContainer>
          ) : (
            <></>
          )}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Body" key="2">
          {responseData && (
            <ScrollContainer height={"450px"}>
              {responseData?.json ? (
                <JsonFormatter
                  json={responseData?.json ? responseData.json : {}}
                  showArrow={true}
                  handleCopiedValue={handleCopiedValue}
                />
              ) : responseData?.text && responseData?.code !== 1000 ? (
                <DisplayHTMLResponse
                  text={responseData.text}
                  handleCopiedValue={handleCopiedValue}
                />
              ) : (
                <div style={{ textAlign: "center", padding: "1rem" }}>
                  {responseData?.text ? responseData.text : ""}
                </div>
              )}
            </ScrollContainer>
          )}
        </Tabs.TabPane>
      </Tabs>
      <div className="response-status">
        {/* <div>
          Size: <span>0 B</span>
        </div>
        <div>
          Time: <span>0 ms</span>
        </div> */}
        <div>
          {responseData?.code && (
            <>
              <div>Status:</div>
              <p>
                <span
                  style={{
                    color: `${
                      responseData?.code < 300 ? "#0036af" : "#e70000"
                    }`,
                  }}
                >
                  {responseData?.code ? responseData.code : ""}
                </span>{" "}
                {responseData?.code && responseData?.code === 200 ? "OK" : ""}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Response;

// const [responseData, setResponseData] = useState({});

// console.log("ResponseFromAPI", responseDetails);

// useEffect(() => {
//   console.log("useEffect triggered with data:", responseDetails);
//   setResponseData(responseDetails || {});
// }, [responseDetails]);

// useImperativeHandle(ref, () => ({
//   setResponse: (_data) => {
//     setResponseData(_data || {});
//   },
// }));

// const handleCopiedValue = () => {};
