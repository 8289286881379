import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from 'react-router-dom';


import './viewData.css';
import DataTable from "./Table/dataTable";

const ViewData = () => {

    const { id } = useParams();

    return (
        <Container className="app">
            <DataTable localDataId={id} />
        </Container>
    );
};

export default ViewData;
