import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import uploadSvg from "./upload.svg";
import useFileUpload from "react-use-file-upload";
import "./UploadDataAlert.css";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import closeIcon from "../../../assets/close-icon-local-data.svg";
// import closeIcon from "../../../assets/close-icon.svg";
import {
  create_globalData,
  create_localData,
  update_globalData,
} from "../../../services/dataService";
import { add_testcase_association } from "../../../services/testCaseServices";
import { setLinkedLocalDataId } from "../../EcomTesting/Testaction/cardsActions";
import { connect } from "react-redux";

const style = {
  fontFamily: "Roboto, sans-serif",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxSizing: "border-box",
  padding: "1rem",

  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "auto",
  p: 0,
};

const innerStyleUpperButton = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: ".2rem",
  borderBottom: "1px solid #F5F5F5",
  height: "66px",
};

const UploadData = ({
  triggerGlobalRefresh,
  triggerLocalRefresh,
  open,
  handleClose,
  dataType = "1",
  isUpdate = false,
  dataValue,

  testcaseModal,
  setLinkedLocalDataId,
}) => {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const { id } = useParams();
  const inputRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const uploadHandler = async (e) => {
    e.preventDefault();

    const trimmedName = name.trim();
    const trimmedValue = value.trim();

    if (name !== "" && trimmedName !== "") {
      try {
        setLoading(true);

        // for new local data
        if (dataType === "2") {
          const formData = new FormData();
          formData.append("name", trimmedName);
          // console.log("forma data name", formData.get("name"));
          // Append files to formData only if files are present
          if (files.length > 0) {
            formData.append(`file`, files[0]);
            // console.log("form data", formData);
            // console.log("form data file", formData.get("file"));
          }

          // console.log("form data", formData);
          const response = await create_localData(formData);
          // console.log("Localresponse", response);
          if (response) {
            if (testcaseModal) {
              const localDataId = response.local_data_id;
              try {
                const associationResponse = await add_testcase_association(
                  localDataId,
                  [id]
                );
                if (associationResponse.error_message) {
                  // console.log(
                  //   "Failed to associate test cases....",
                  //   associationResponse.error_message
                  // );
                  throw new Error(associationResponse.error_message);
                }
                setLinkedLocalDataId(localDataId);
              } catch (error) {
                console.error("Failed to submit data or files.", error);
                toast.error("Failed to submit data or files.");
              }
            } else {
              triggerLocalRefresh();
              navigate(`/data/${response.local_data_id}`);
            }
          }
        }

        // for new global data & update global data
        if (dataType === "1") {
          setLoading(true);
          const response = await create_globalData({
            key: name,
            value: value,
          });

          if (response) triggerGlobalRefresh();
        }
        setName("");
        setValue("");
        clearAllFiles();
        setLoading(false);
        handleClose();
      } catch (error) {
        console.error("Failed to submit data or files.", error);
        toast.error("Failed to submit data or files.");
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  const [name, setName] = useState(
    isUpdate ? (dataValue && dataValue.key ? dataValue.key : "") : ""
  );
  const [value, setValue] = useState(
    isUpdate ? (dataValue && dataValue.value ? dataValue.value : "") : ""
  );
  const [file, setFile] = useState(null);

  const removeUploadedFile = () => {
    clearAllFiles();
    inputRef.current.disabled = false;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={innerStyleUpperButton}>
          <div style={{ marginLeft: "10px", padding: "9px" }}>
            {isUpdate ? "Update" : "New"}
            {dataType === "1" ? " Global Data" : " Local Data"}
          </div>
          <div onClick={handleClose}>
            <svg
              style={{ marginRight: "10px", cursor: "pointer" }}
              width="32"
              height="26"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="36" height="36" rx="6" fill="#F5F5F5" />
              <path
                d="M24.4166 12.876L23.1241 11.5835L17.9999 16.7077L12.8758 11.5835L11.5833 12.876L16.7074 18.0002L11.5833 23.1243L12.8758 24.4168L17.9999 19.2927L23.1241 24.4168L24.4166 23.1243L19.2924 18.0002L24.4166 12.876Z"
                fill="#BDBDBD"
              />
            </svg>
            {/* Use the imported close image */}
          </div>
        </div>
        <>
          {dataType === "1" ? (
            <div
              style={{ padding: "1.5rem", borderBottom: "1px solid #F5F5F5" }}
            >
              <label
                className="name"
                style={{
                  display: "flex",
                  textAlign: "left",
                  paddingBottom: "5px",
                  color: "#212121",
                }}
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  width: "412px",
                  height: "2rem",
                  top: "50px",
                  gap: "0px",
                  borderRadius: "4px",
                  border: "2px solid #9E9E9E",
                  borderTopWidth: "2px",
                  opacity: "",
                }}
              />
              <br />
              <label
                className="value"
                style={{
                  display: "flex",
                  textAlign: "left",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  color: "#212121",
                }}
              >
                Value:
              </label>
              <input
                type="text"
                id="value"
                value={value}
                style={{
                  width: "412px",
                  height: "2rem",
                  top: "50px",
                  gap: "0px",
                  borderRadius: "4px",
                  border: "2px solid #9E9E9E",
                  borderTopWidth: "2px",
                  opacity: "",
                }}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          ) : dataType === "2" ? (
            <div
              style={{ padding: "1.5rem", borderBottom: "1px solid #F5F5F5" }}
            >
              <label
                className="name"
                style={{
                  display: "flex",
                  textAlign: "left",
                  paddingBottom: "5px",
                  color: "#212121",
                }}
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  width: "412px",
                  height: "2rem",
                  top: "50px",
                  gap: "0px",
                  borderRadius: "4px",
                  border: "2px solid #9E9E9E",
                  borderTopWidth: "2px",
                  opacity: "",
                }}
              />
              <br />
              <label
                className="uploadDataFile"
                style={{
                  display: "flex",
                  textAlign: "left",
                  paddingBottom: "5px",
                  color: "#212121",
                  paddingTop: "5px",
                }}
              >
                Upload File:{" "}
                <span style={{ color: "#616161" }}>(Optional)</span>
              </label>

              {/* <input
                type="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
              /> */}
              {/* upload form */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "0.5rem",
                }}
              >
                <div className="form-container">
                  {/* Display the files to be uploaded */}
                  {fileNames.length > 0 && (
                    <div className="form-file-uploaded-name">
                      <p>{fileNames[0]}</p>
                      <img
                        src={closeIcon}
                        alt="remove file"
                        onClick={removeUploadedFile}
                      />
                    </div>
                  )}
                  {/* {console.log(
                    "just uploaded:",
                    fileNames.map((name) => ({
                      name: name,
                    }))
                  )} */}

                  {/* Provide a drop zone and an alternative button inside it to upload files. */}
                  <div
                    onDragEnter={handleDragDropEvent}
                    onDragOver={handleDragDropEvent}
                    onDrop={(e) => {
                      handleDragDropEvent(e);
                      setFiles(e, "a");
                    }}
                  >
                    <button
                      style={{ background: "white", border: "none" }}
                      onClick={() => inputRef.current.click()}
                    >
                      <img
                        src={uploadSvg}
                        alt="click to uplaod"
                        style={{ margin: "0" }}
                      />
                    </button>

                    {/* Hide the crappy looking default HTML input */}
                    <input
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setFiles(e, "a");
                        inputRef.current.value = null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              boxSizing: "border-box",
              padding: "1rem",
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
              gap: "0.5rem",
              height: "69px",
              borderTop: "1px solid #F5F5F5",
            }}
          >
            <div
              style={{
                height: "37px",
                width: "90px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                border: "1px solid #eeeeee",
                color: "black",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              Cancel
            </div>

            <div
              style={{
                height: "37px",
                width: "90px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                border: "1px solid #eeeeee",
                color: "#fff",
                cursor: "pointer",
                background: "#0036af",
              }}
              onClick={uploadHandler}
            >
              {loading ? (
                <CircularProgress
                  size={18}
                  thickness={4}
                  style={{ color: "white" }}
                />
              ) : isUpdate ? (
                "Update"
              ) : (
                "Create"
              )}
            </div>
          </div>
        </>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setLinkedLocalDataId,
  //getRecorderActivate,
  // setRecordActive
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadData);
