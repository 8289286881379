import React, { useState, useEffect, useMemo } from "react";
import { Button, Select } from "antd";
import windowsIcon from "../../../../assets/table/windows 1.svg";
import linuxIcon from "../../../../assets/table/linux.svg";
import macIcon from "../../../../assets/table/mac.svg";
import firefoxIcon from "../../../../assets/table/firefox.svg";
import chromeIcon from "../../../../assets/table/chrome.svg";
import safariIcon from "../../../../assets/table/safari.svg";
import calIcon from "../../../../assets/calendar-range.svg";
import Table from "antd/lib/table";
import Moment from "react-moment";
import { CheckCircle, EditIcon, SaveAltOutlined } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import DateRangePicker from "../../../Testcase/datePicker";
import { executionJobs } from "../../../../services/jobsServices";
import "./eh.css";
import { Tooltip } from "@mui/material";
import ProgressBar from "../../../../components/ProgressBar/progressBar";
import ErrorIcon from "@mui/icons-material/Error"; // Adjust icon import based on actual usage
import { ReactComponent as FailIcon } from "../../../../assets/failStatus.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/successStatus.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/infoIcon.svg";
import { ReactComponent as WaitingIcon } from "../../../../assets/waitingIcon.svg";
import { ReactComponent as ProcessIcon } from "../../../../assets/inProcess.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { apiUrl } from "../../../../services/apiServices";

const Exehstry = (activeKey) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const onStopClick = () => {
    // status stop button
  };
  const handleClick = (id, jobs_id = null) => {
    if (jobs_id === null) {
      navigate(`/report/testcase/${id}/`);
    } else {
      navigate(`/report/testcase/${id}/${jobs_id}`);
    }
  };

  // const StatusProgressComponent = ({ status }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <div style={{ display: "inline-block", width: "89px" }}>
  //         <p style={{ marginTop: "0", fontSize: "12px", color: "#1D86FF" }}>
  //           {status}% Completed
  //         </p>
  //         <ProgressBar progress={status} />
  //       </div>
  //       <button
  //         onClick={() => onStopClick()}
  //         style={{
  //           cursor: "pointer",
  //           margin: "14px",
  //           border: "none",
  //           backgroundColor: "#B91C1C",
  //           width: "11px",
  //           height: "12px",
  //         }}
  //       ></button>
  //     </div>
  //   );
  // };

  const columns = [
    {
      title: <div className="column-title">Triggered At</div>,
      dataIndex: "last_updated",
      key: "last_updated",
      render: (date) => <Moment format="DD-MM-YYYY HH:mm:ss">{date}</Moment>,
    },
    {
      title: (
        <div className="column-title" style={{ textAlign: "center" }}>
          Platform
        </div>
      ),
      dataIndex: "platform",
      key: "platform",
      render: (platform) => {
        let icon;
        switch (platform.toLowerCase()) {
          case "windows":
            icon = <img src={windowsIcon} alt="Windows" />;
            break;
          case "linux":
            icon = <img src={linuxIcon} alt="Linux" />;
            break;
          case "mac":
            icon = <img src={macIcon} alt="Mac" />;
            break;
          default:
            icon = null;
            break;
        }
        return icon;
      },
    },
    {
      title: <div className="column-title">Browser</div>,
      dataIndex: "browser",
      key: "browser",
      render: (browser) => {
        let icon;
        switch (browser.toLowerCase()) {
          case "chrome":
            icon = <img src={chromeIcon} alt="Chrome" />;
            break;
          case "firefox":
            icon = <img src={firefoxIcon} alt="Firefox" />;
            break;
          case "safari":
            icon = <img src={safariIcon} alt="Safari" />;
            break;
          default:
            icon = null;
            break;
        }
        return icon;
      },
    },
    {
      title: <div className="column-title">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const iconStyle = {
          cursor: "pointer",
          fontSize: "17px",
          marginLeft: "10px",
        };

        switch (status) {
          case "success":
            return (
              <Tooltip title="success">
                <SuccessIcon style={iconStyle} />
              </Tooltip>
            );
          case "failed":
            return (
              <Tooltip title="failed">
                <FailIcon style={{ ...iconStyle, color: "red" }} />
              </Tooltip>
            );
          case "warning":
            return (
              <Tooltip title="warning">
                <WaitingIcon style={{ ...iconStyle, color: "yellow" }} />
              </Tooltip>
            );
          case "skipped":
            return (
              <Tooltip title="skipped">
                <InfoIcon style={{ ...iconStyle, color: "gray" }} />
              </Tooltip>
            );
          case "in progress":
            return (
              <Tooltip title="in progress">
                <CircularProgress
                  style={{
                    ...iconStyle,
                    color: "#0036af",
                    width: "10px",
                    height: "10px",
                  }}
                />
              </Tooltip>
            );
          case "not started":
            return (
              <Tooltip title="not started">
                <ErrorIcon style={iconStyle} />
              </Tooltip>
            );
          default:
            return (
              <Tooltip title="facing problem">
                <ErrorIcon style={iconStyle} />
              </Tooltip>
            );
        }
      },
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const jsonData = await executionJobs(id);
        // console.log(jsonData, "data");
        const jsonData = await executionJobs(id)
        // console.log("jsondata", jsonData);
        /// use this data to show all the details
        // console.log(jsonData);
        // console.log(jsonData, "data");
        setData(jsonData.jobs.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // console.log(activeKey, "activeKey");
    if (activeKey !== "1") {
      fetchData();
    }
    // fetchData();
  }, [activeKey]);

  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const currentPageData = filteredData.slice(startIndex, endIndex);
  const [buttonTitle, setButtonTitle] = useState({
    platform: "",
    browser: "",
    status: "",
  });

  const [isDateFilter, setIsDateFilter] = useState(false);

  const showDateFilterHandler = () => {
    setIsDateFilter(!isDateFilter);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dateRangeFilterClearHandler = () => {
    setFilteredData(data);
    setIsDateFilter(false);
    setStartDate(null);
    setEndDate(null);
  };

  // useEffect(() => {
  //   const enteredDateAfter = new Date(startDate);
  //   const enteredDateBefore = new Date(endDate);

  // }, [startDate, endDate]);

  // Pagination handlers
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
  // Pagination display information
  const paginationInfo = `Page ${currentPage} out of ${totalPages}`;

  useEffect(() => {
    let filtered = data;

    if (startDate && endDate) {
      const startDateWithoutTime = new Date(startDate);
      startDateWithoutTime.setHours(0, 0, 0, 0);
      const endDateWithoutTime = new Date(endDate);
      endDateWithoutTime.setHours(23, 59, 59, 999);

      filtered = filtered.filter((item) => {
        const itemDate = new Date(item.last_updated);
        return (
          itemDate >= startDateWithoutTime && itemDate <= endDateWithoutTime
        );
      });
    }

    if (searchTerm !== "") {
      filtered = filtered.filter((item) =>
        item.platform.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (buttonTitle.platform !== "") {
      filtered = filtered.filter(
        (item) =>
          item.platform.toLowerCase() === buttonTitle.platform.toLowerCase()
      );
    }

    if (buttonTitle.browser !== "") {
      filtered = filtered.filter(
        (item) =>
          item.browser.toLowerCase() === buttonTitle.browser.toLowerCase()
      );
      // console.log(filtered, buttonTitle.browser);
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchTerm, buttonTitle, data, startDate, endDate]);

  const { Option } = Select;

  return (
    <div className="">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            margin: "30px",
            marginTop: "5px",
            width: "98px",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <Select
              className="select-input"
              placeholder={buttonTitle.platform || "Platform"}
              onChange={(value) =>
                setButtonTitle({ ...buttonTitle, platform: value })
              }
            >
              <Option value="windows">Windows</Option>
              <Option value="linux">Linux</Option>
              <Option value="mac">Mac</Option>
            </Select>

            <Select
              className="select-input"
              placeholder={buttonTitle.browser || "Browser"}
              onChange={(value) =>
                setButtonTitle({ ...buttonTitle, browser: value })
              }
            >
              <Option value="chrome">Chrome 100</Option>
              <Option value="firefox">Firefox 99</Option>
              <Option value="safari">Safari 99</Option>
            </Select>

            <Select
              className="select-input"
              placeholder={buttonTitle.status || "Status"}
              onChange={(value) =>
                setButtonTitle({ ...buttonTitle, status: value })
              }
            ></Select>

            <div className="date-button-filter-cont">
              <div
                onClick={showDateFilterHandler}
                className="toggle-filter-button"
              >
                <div> Date </div>
                <img src={calIcon} alt="icon" />
              </div>
              {isDateFilter && (
                <div>
                  <DateRangePicker
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              )}
              {isDateFilter && (
                <div onClick={dateRangeFilterClearHandler}>
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5.5" cy="6" r="5.5" fill="#FF9D87" />
                    <path
                      d="M8 4.00357L7.49643 3.5L5.5 5.49643L3.50357 3.5L3 4.00357L4.99643 6L3 7.99643L3.50357 8.5L5.5 6.50357L7.49643 8.5L8 7.99643L6.00357 6L8 4.00357Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <Table
            className="execution-history__table"
            columns={columns}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleClick(record.execution_id, record.job_id);
                },
                style: { cursor: "pointer" },
              };
            }}
            dataSource={currentPageData.map((item) => ({
              ...item,
              key: item.job_unique_id,
            }))}
          />
        </div>

        {/* Pagination */}
        {totalItems !== 0 && (
          <div
            style={{
              backgroundColor: "#FFF",

              height: "65px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                color: "#344054",
                marginLeft: "6rem",
                fontWeight: "bold",
              }}
            >
              {paginationInfo}
            </span>

            <div style={{ display: "flex", gap: "12px", paddingRight: "9rem" }}>
              <Button
                style={{
                  color: "#67707f",
                  height: "36px",
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  backgroundColor: "transparent",
                  fontSize: "1.03rem",
                  ":hover": {
                    color: "#fff",
                    backgroundColor: "#979797",
                    cursor: "pointer",
                  },
                }}
                disabled={currentPage === 1}
                onClick={handlePrevPage}
              >
                Previous
              </Button>
              <Button
                style={{
                  color: "#67707f",
                  height: "36px",
                  border: "1px solid #D0D5DD",
                  borderRadius: "8px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  backgroundColor: "transparent",
                  fontSize: "1.03rem",
                  ":hover": {
                    color: "#fff",
                    backgroundColor: "#979797",
                    cursor: "pointer",
                  },
                }}
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Exehstry;
