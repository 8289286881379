import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./TestingDetails.css";
import LeftNav from "../../../components/Nav/nav";
import TopNav from "../../../components/Nav/topbar/topbar";
import Linux from "./assets/linux.svg";
import MacOS from "./assets/macOS.svg";
import Windows from "./assets/windows.svg";
import ChromeLogo from "../../../assets/chrome.svg";
import MozillaFirefoxLogo from "../../../assets/firefox.svg";
import SafariLogo from "../../../assets/safari.svg";
import upArrow from "./assets/arrowUp.svg";
import downArrow from "./assets/arrowDown.svg";

import successStatus from "../../../assets/successStatus.svg";
import failStatus from "../../../assets/failStatus.svg";
import skippedStatus from "../../../assets/skippedStatus.svg";
import waitingStatus from "../../../assets/waitingSuccess.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { Resizable } from "re-resizable";
import IndividualReport from "../../Reports/TestingDetails/individualReport";
import {
  getSuiteReportData,
  getSuiteProgress,
} from "../../../services/suitesServices";
import { ReactComponent as S_icon } from "../../../assets/s-icon.svg";
import { ReactComponent as P_icon } from "../../../assets/p-icon.svg";
import { Tooltip } from "antd";

// These are Enum for logos
const getSystemIcon = (systemType) => {
  switch (systemType.toLowerCase()) {
    case "linux":
      return Linux;
    case "macos":
      return MacOS;
    case "windows":
      return Windows;
    default:
      return null;
  }
};
class StepStatus {
  static SUCCESS = 0;
  static FAILED = 1;
  static WARNING = 2;
  static SKIPPED = 3;
  static IN_PROGRESS = 4;
  static NOT_STARTED = 5;
}

const getStatusIcon = (status) => {
  switch (status) {
    case StepStatus.FAILED:
      return failStatus;
    case StepStatus.SUCCESS:
      return successStatus;
    case StepStatus.WARNING:
      return waitingStatus;
    case StepStatus.SKIPPED:
      return skippedStatus;
    case StepStatus.IN_PROGRESS:
      return <CircularProgress size={12} />;
    default:
      return failStatus; //null;
  }
};

const getBrowserIcon = (browserType) => {
  switch (browserType.toLowerCase()) {
    case "chrome":
      return ChromeLogo;
    case "mozillafirefox":
      return MozillaFirefoxLogo;
    case "safari":
      return SafariLogo;
    default:
      return ChromeLogo;
  }
};
// sytemMode Component
const SystemModeButton = ({ systemDetails, selectedSystem, onSelect }) => (
  <div
    className="system-mode-button"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
      border:
        systemDetails?.platform === selectedSystem
          ? "2px solid #e0e7f5"
          : "2px solid transparent",
      backgroundColor: systemDetails?.platform === selectedSystem && "#e0e7f5",
      padding: "8px",
      cursor: "pointer",
    }}
    onClick={() => onSelect(systemDetails)}
  >
    <img
      src={getSystemIcon(systemDetails?.platform)}
      alt={systemDetails?.platform}
    />
    {systemDetails.status === 4 ? (
      getStatusIcon(4)
    ) : (
      <img src={getStatusIcon(systemDetails.status)} alt="Status" />
    )}
  </div>
);
// BrowserMode Component
const BrowserOption = ({ browserDetails, selectedBrowser, onSelect }) => (
  <div
    className="browser-option"
    style={{
      display: "flex",
      alignItems: "center",
      gap: "5px",
      borderBottom:
        browserDetails?.progress_id === selectedBrowser
          ? "2px solid #00299f"
          : "2px solid transparent",
      padding: "8px",
      cursor: "pointer",
    }}
    onClick={() => onSelect(browserDetails)}
  >
    <img
      src={getBrowserIcon(browserDetails?.browser)}
      alt="Browser Icon"
      style={{ width: "16px", height: "16px" }}
    />
    {/* <div style={{ color: "#00008B" }}>v{browserDetails?.version}</div> */}
    <div style={{ marginLeft: "auto" }}>
      <img
        src={getStatusIcon(browserDetails?.status)}
        alt="Status"
        style={{ width: "11px", height: "11px" }}
      />
    </div>
  </div>
);

const TestcaseCard = ({
  testcase,
  selectedTestcaseId,
  setSelectedTestcaseId,
}) => {
  const handleClick = () => {
    setSelectedTestcaseId(testcase, testcase.job_id);
  };

  return (
    <div
      style={{
        backgroundColor:
          selectedTestcaseId === testcase.id ? "#e0e7f5" : "transparent",
        padding: "10px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
      }}
      onClick={handleClick}
    >
      <Tooltip title={testcase.name} placement="top">
        <div
          style={{
            width: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {testcase.name}
        </div>
      </Tooltip>
      {testcase.status === 4 ? (
        getStatusIcon(testcase.status)
      ) : (
        <img src={getStatusIcon(testcase.status)} alt="Status Icon" />
      )}
    </div>
  );
};

const ListforTestcase = ({
  testcaseList,
  selectedTestcaseId,
  setSelectedTestcaseId,
}) => {
  const scrollContainerRef = useRef(null);
  // const elementToScrollToRef = useRef(null);

  useEffect(() => {
    if (selectedTestcaseId !== null) {
      scrollToElement(selectedTestcaseId);
    }
  }, [selectedTestcaseId]);

  const scrollToElement = (testcaseId) => {
    const elementToScrollTo = document.getElementById(`testcase-${testcaseId}`);
    const scrollContainer = scrollContainerRef.current;

    if (elementToScrollTo && scrollContainer) {
      const elementPosition = findPosition(elementToScrollTo, scrollContainer);
      scrollContainer.scrollTop =
        elementPosition + scrollContainer.offsetTop - 400;
    }
  };

  const findPosition = (obj, container) => {
    let currentTop = 0;
    let currentObj = obj;
    while (currentObj && currentObj !== container) {
      currentTop += currentObj.offsetTop;
      currentObj = currentObj.offsetParent;
    }
    return currentTop;
  };

  return (
    <div ref={scrollContainerRef} className="testingAnalyticsDetails">
      {testcaseList.map((group, groupIndex) => (
        <div key={groupIndex} className="testcase-group">
          <div className="mode-container">
            {group.is_parallel ? (
              <P_icon className="icon-style" />
            ) : (
              <S_icon className="icon-style" />
            )}
          </div>
          <div>
            {group?.test_cases &&
              group.test_cases.map((testcase) => (
                <div key={testcase.id} id={`testcase-${testcase.id}`}>
                  <TestcaseCard
                    testcase={testcase}
                    selectedTestcaseId={selectedTestcaseId}
                    setSelectedTestcaseId={setSelectedTestcaseId}
                  />
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

//  THIS IS MAIN FUNCTION //
const SuitesTestingDetails = () => {
  // const navigate = useNavigate();
  // const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  // const [fetchData, setFetchData] = useState([]);

  // const [Testingroutes, setTestingroutes] = useState("ERP Web Testing");

  // const [tempVar, setTempVar] = useState("");

  // const apiUrl = process.env.REACT_APP_API_URL;
  // const [selectedjob, setSelectedJob] = useState(jobid);

  const { exeid, pid } = useParams();
  const [errorStatusTag, setErrorStatusTag] = useState(null);
  const [job_id, setJob_id] = useState(null);
  const [loading, setLoading] = useState(false);
  const [suiteReportData, setSuiteReportData] = useState([]);
  const [testcaseList, setTestcaseList] = useState([]);
  // const [failedTestcaseIds, setFailedTestcaseIds] = useState([]);
  const [selectedProgressDetails, setSelectedProgressDetails] = useState(null);
  const [selectedTestcase, setSelectedTestcase] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [selectedProgressId, setSelectedProgressId] = useState(
    pid ? pid : null
  );

  const failedTestCaseIdsRef = useRef(null);

  // const failedTestCaseIds = testcaseList
  //   .filter((testcase) => testcase.status === 1)
  //   .map((testcase) => testcase.testcase_id);

  // const fetchJobReports = async (jobId) => {
  //   try {
  //     const response = await getJobReports(jobId);
  //     if (response) {
  //       // setTestcaseList(response.testcase_details);
  //       // setSelectedTestcaseId(response.testcase_details[0]?.id);
  //     } else {
  //       // setTestcaseList([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching report jobs data:", error);
  //   }
  // };

  function transformProgressData(data) {
    const result = {};

    data.forEach((item) => {
      if (!result[item.platform]) {
        result[item.platform] = {
          platform: item.platform,
          browsers: [],
          status: null,
        };
      }

      result[item.platform].browsers.push({ ...item });

      // Update the platform status if it's not set or if the current item has a status
      if (result[item.platform].status === null || item.status !== null) {
        result[item.platform].status = item.status;
      }
    });

    return Object.values(result);
  }

  const fetchSuiteProgress = async (progressId, option = false) => {
    try {
      setLoading(true);
      const payload = {
        progress_id: progressId,
      };
      const response = await getSuiteProgress(payload);
      if (response) {
        if (response?.failed_testcases && response.failed_testcases.length) {
          // setFailedTestcaseIds(response.failed_testcases);
          failedTestCaseIdsRef.current = response.failed_testcases;
        }
        if (response?.testcase_details && response?.testcase_details.length) {
          setTestcaseList(response.testcase_details);
          if (
            (!selectedTestcase || option) &&
            response.testcase_details[0]?.test_cases &&
            response.testcase_details[0]?.test_cases.length
          ) {
            setSelectedTestcase({
              ...response.testcase_details[0]?.test_cases[0],
            });
            setJob_id(response.testcase_details[0]?.test_cases[0]?.job_id);
            if (response.testcase_details[0]?.test_cases[0]?.status === 1) {
              setErrorStatusTag(0);
            }
          }
        } else {
          setTestcaseList([]);
          selectedTestcase && setSelectedTestcase(null);
        }
      } else {
        setTestcaseList([]);
        selectedTestcase && setSelectedTestcase(null);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching report jobs data:", error);
      setLoading(false);
    }
  };

  const fetchSuiteReportData = async () => {
    try {
      const postData = {
        suite_execution_id: exeid,
      };

      if (pid) {
        postData.default_progress_id = pid;
      }
      const response = await getSuiteReportData(postData);

      if (
        response &&
        response?.list_of_progress_details &&
        response?.list_of_progress_details.length
      ) {
        const transformedData = transformProgressData(
          response.list_of_progress_details
        );
        setSuiteReportData(transformedData);
        if (selectedProgressId) {
          const progressId = selectedProgressId;
          const _details = response.list_of_progress_details.find(
            (details) => details.progress_id === progressId
          );
          if (_details) {
            setSelectedProgressDetails({ ..._details });
            setSelectedSystem(_details?.platform);
            fetchSuiteProgress(_details?.progress_id);
          }
        } else if (!selectedProgressDetails && !selectedProgressId) {
          setSelectedProgressDetails({
            ...response.list_of_progress_details[0],
          });
          setSelectedProgressId(
            response.list_of_progress_details[0]?.progress_id
          );
          setSelectedSystem(response.list_of_progress_details[0]?.platform);
          fetchSuiteProgress(response.list_of_progress_details[0]?.progress_id);
        }
      } else {
        setSuiteReportData([]);
      }
    } catch (error) {
      console.error("Error fetching report jobs data:", error);
    }
  };

  useEffect(() => {
    if (exeid) {
      fetchSuiteReportData();
    }
  }, [exeid]);

  const setTestCasesDetails = (_selected) => {
    if (_selected.progress_id === selectedProgressId) return;
    handletestCaseSelect(null, null);
    setTestcaseList([]);
    setSelectedProgressDetails((previous) => (previous = { ..._selected }));
    setSelectedProgressId(_selected.progress_id);
    fetchSuiteProgress(_selected.progress_id, true);
  };

  const handleSystemSelect = (_selectedSystem) => {
    if (selectedSystem.platform === selectedSystem) return;
    setSelectedSystem(_selectedSystem.platform);
    // setTestCasesDetails(_selectedSystem, progressId);
  };

  const handleBrowserSelect = (_selectedBrowser) => {
    if (!_selectedBrowser) return;
    setTestCasesDetails(_selectedBrowser);
  };

  const handletestCaseSelect = (_testcase, jobId) => {
    setSelectedTestcase(_testcase ? { ..._testcase } : null);
    setJob_id(jobId ? jobId : null);
  };

  // const closeAccordion = () => {
  //   // setTempVar(selectedBrowser);
  //   if (selectedBrowser) setSelectedBrowser(null);
  //   setIsAccordionOpen(false);
  // };

  const handleStatusChange = (option) => {
    if (failedTestCaseIdsRef.current == null) return;
    let idIndex = errorStatusTag != null ? errorStatusTag : 0;

    if (!option) {
      idIndex =
        idIndex + 1 < failedTestCaseIdsRef.current.length
          ? idIndex + 1
          : idIndex;
    } else {
      idIndex = idIndex - 1 > -1 ? idIndex - 1 : idIndex;
    }

    const _selectedTestcaseId = failedTestCaseIdsRef.current[idIndex];
    const _selectedTestcase = testcaseList
      ?.flatMap((item) => item.test_cases)
      .find((tc) => tc.id === _selectedTestcaseId);

    setErrorStatusTag(idIndex);
    setSelectedTestcase(_selectedTestcase ? { ..._selectedTestcase } : null);
    setJob_id(
      _selectedTestcase && _selectedTestcase?.job_id
        ? _selectedTestcase.job_id
        : null
    );
  };
  // const handleStatusChange = (type, increment) => {
  //   let currentTag, setTag, maxTag;

  //   switch (type) {
  //     case 1:
  //       currentTag = errorStatusTag;
  //       setTag = setErrorStatusTag;
  //       // maxTag = failedTestCaseIds.length;
  //       break;

  //     default:
  //       return;
  //   }

  //   if (increment) {
  //     if (currentTag <= maxTag - 1) {
  //       setTag((prev) => prev + 1);
  //     }
  //   } else {
  //     if (currentTag >= 1) {
  //       setTag((prev) => prev - 1);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (selectedjob !== undefined)
  //     navigate(`/report/testcase/${exeid}/${selectedjob}`, { replace: true });
  //   else navigate(`/report/testcase/${exeid}`, { replace: true });
  // }, [selectedjob]);

  return (
    <>
      <div className="app" style={{ margin: "1rem 1rem" }}>
        <div className="box" style={{ margin: "0px" }}>
          <div className="main-box" style={{ margin: "0px" }}>
            <div className="testingAnalytics">
              <div className="testingAnalyticsNavbar">
                <div
                  className="system-Mode"
                  style={{
                    backgroundColor: "#fafafa",
                    borderRadius: "10px",
                    padding: "8px 12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    {suiteReportData.map((systemDetails) => (
                      <SystemModeButton
                        key={systemDetails.platform}
                        systemDetails={systemDetails}
                        selectedSystem={selectedSystem}
                        onSelect={handleSystemSelect}
                      />
                    ))}
                  </div>
                </div>
                {selectedSystem && (
                  <div
                    className="tab-mode"
                    style={{
                      marginLeft: "50px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                    }}
                  >
                    {suiteReportData
                      .find((item) => item.platform === selectedSystem)
                      .browsers.map(
                        (browserDetails) => (
                          // browserDetails?.progress_id ===
                          //   selectedProgressId && (
                          <BrowserOption
                            key={browserDetails.progress_id}
                            browserDetails={browserDetails}
                            selectedBrowser={selectedProgressId}
                            onSelect={handleBrowserSelect}
                          />
                        )
                        // )
                      )}
                  </div>
                )}
              </div>
              {selectedProgressId && selectedTestcase && (
                <div style={{ display: "flex", position: "relative" }}>
                  {loading ? (
                    <div className="loding-screen">
                      <CircularProgress
                        size={32}
                        thickness={6}
                        style={{ color: "#0036af" }}
                      />
                    </div>
                  ) : (
                    <>
                      <Resizable
                        defaultSize={{
                          width: "14rem",
                          height: "100%",
                        }}
                        handleStyles={{
                          right: {
                            width: "10px",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            bottom: "0",
                            right: "-5px",
                            cursor: "col-resize",
                          },
                        }}
                        maxWidth="25%"
                        minWidth="10%"
                        enable={{
                          top: false,
                          right: true,
                          bottom: false,
                          left: true,
                          topRight: false,
                          bottomRight: false,
                        }}
                        className="resizable-container"
                        style={{ position: "relative" }}
                      >
                        <div style={{ height: "full", width: "auto" }}>
                          <div
                            className="testingAnalyticsControlBar"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="statusControl"
                              style={{ width: "100%", padding: "auto" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "1px",
                                  gap: "2px",
                                }}
                              >
                                <img
                                  src={getStatusIcon(StepStatus.FAILED)}
                                  alt=""
                                />
                                <p style={{ padding: "0 1px" }}>
                                  {errorStatusTag != null
                                    ? errorStatusTag + 1
                                    : "0"}
                                  /
                                  {failedTestCaseIdsRef?.current &&
                                  failedTestCaseIdsRef?.current.length
                                    ? failedTestCaseIdsRef.current.length.toString()
                                    : "0"}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingLeft: "5px",
                                    gap: "5px",
                                  }}
                                >
                                  <button
                                    onClick={() => {
                                      handleStatusChange(false);
                                      // setSelectedTestcaseId(
                                      //   failedTestCaseIds[errorStatusTag - 2]
                                      // );
                                    }}
                                  >
                                    <img src={downArrow} style={{}} alt="" />
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleStatusChange(true);
                                      // setSelectedTestcaseId(
                                      //   failedTestCaseIds[errorStatusTag]
                                      // );
                                    }}
                                  >
                                    <img src={upArrow} style={{}} alt="" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ maxHeight: "700px", overflow: "auto" }}>
                            <ListforTestcase
                              testcaseList={testcaseList}
                              selectedTestcaseId={selectedTestcase.id}
                              setSelectedTestcaseId={handletestCaseSelect}
                            />
                          </div>
                        </div>
                      </Resizable>
                      <div style={{ flex: "1 1 0" }}>
                        {job_id && (
                          <IndividualReport
                            job_id={job_id}
                            testcaseName={selectedTestcase?.name}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuitesTestingDetails;
